import { useState, MouseEvent } from "react";
import {
  ListItemIcon,
  IconButton,
  MenuItem,
  Divider,
  Tooltip,
  Avatar,
  Menu,
  Box,
} from "@mui/material";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { CardInfoNameUserConfig } from "src/MainLayout/Header/components/LogouImg/Components/CardInfoNameUserConfig";
import { ComponentPrivate } from "src/Pages/components/ComponentPrivate/ComponentPrivate";
import { UseVerifyIsPermissionsUser } from "src/Pages/components/hooks/UseVerifyIsPermissionsUser";
import {
  AccountBalanceIcon,
  HelpOutlineIcon,
  AddCardIcon,
  Logout,
} from "src/Pages/components/Icons/Icons";
import { LinkCore } from "src/Pages/components/LinkCore/LinkCore";
import { getToken } from "src/shared/setup/API/api";
import { UrlConfig } from "src/shared/Utils/paths";
import { valuesPermissions } from "src/shared/Utils/ValuesPermissions";

import { StyledBadgeAvatar } from "./StyleBadgeAvatar";

export const ImgLogout = () => {
  const { handleVerifyPermissionItem } = UseVerifyIsPermissionsUser();
  const { setAuthorization, userPerfil, isConectionNet, setUserPerfil } =
    useAuthContext();

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleCloseUserMenu = () => setAnchorElUser(null);

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const logout = async () => {
    localStorage.removeItem(getToken);
    setAuthorization(false);
    setUserPerfil(undefined);
  };

  const isNotViewProducer = userPerfil?.type_user === "PRODUCER";

  const isDisabledCompany = handleVerifyPermissionItem(
    valuesPermissions.COMPANY.VER_DETALHES.id
  );

  return (
    <>
      <Tooltip title="Abrir Menu">
        <IconButton onClick={handleOpenUserMenu}>
          <StyledBadgeAvatar
            actived={String(isConectionNet)}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            overlap="circular"
            variant="dot"
          >
            <Avatar
              sx={{
                bgcolor: "#3980f5",
                fontSize: "1.5rem",
              }}
            >
              {userPerfil?.nome[0].toUpperCase()}
            </Avatar>
          </StyledBadgeAvatar>
        </IconButton>
      </Tooltip>

      <Menu
        keepMounted
        PaperProps={{
          style: {
            width: "350px",
            padding: "12px",
          },
        }}
        anchorEl={anchorElUser}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(anchorElUser)}
        sx={{ mt: "48px" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleCloseUserMenu}
      >
        <CardInfoNameUserConfig
          subTitle={userPerfil?.email || ""}
          title={userPerfil?.nome as string}
          titleChip="Perfil"
          titleTooltip="Configurar perfil"
          urlConfigPerfil="perfil"
        />

        <Divider sx={{ margin: "12px 0 " }} />

        <Box
          sx={{
            "& > a > li": {
              border: `1px solid #919191`,
              borderRadius: "12px",
              transition: "background-color 0.3s",
              color: "#3b3b3b",
            },
          }}
        >
          <ComponentPrivate isView={!isDisabledCompany && !isNotViewProducer}>
            <LinkCore
              isDisabled={isDisabledCompany}
              to={UrlConfig.config.companyDetails.url}
            >
              <MenuItem
                sx={{ marginBottom: "8px" }}
                onClick={handleCloseUserMenu}
              >
                <ListItemIcon>
                  <AccountBalanceIcon fontSize="small" />
                </ListItemIcon>
                Configurações/Logs
              </MenuItem>
            </LinkCore>
          </ComponentPrivate>

          <ComponentPrivate isView={!isNotViewProducer}>
            <LinkCore isDisabled={isDisabledCompany} to={UrlConfig.help.url}>
              <MenuItem
                sx={{ marginBottom: "8px" }}
                onClick={handleCloseUserMenu}
              >
                <ListItemIcon>
                  <HelpOutlineIcon fontSize="small" />
                </ListItemIcon>
                Duvidas/Suporte
              </MenuItem>
            </LinkCore>
          </ComponentPrivate>

          <ComponentPrivate
            isView={
              userPerfil?.type_user === "ADM" ||
              userPerfil?.type_user === "ROOT"
            }
          >
            <LinkCore to={UrlConfig.config.plans.url}>
              <MenuItem
                sx={{ marginBottom: "8px" }}
                onClick={handleCloseUserMenu}
              >
                <ListItemIcon>
                  <AddCardIcon fontSize="small" />
                </ListItemIcon>
                Plano/Pagamentos
              </MenuItem>
            </LinkCore>
          </ComponentPrivate>

          <Divider sx={{ margin: "12px 0 " }} />

          <LinkCore to="login">
            <MenuItem onClick={logout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Sair
            </MenuItem>
          </LinkCore>
        </Box>
      </Menu>
    </>
  );
};
