import React, { useEffect } from "react";
import { useResetInputs } from "src/Contexts";
import { ModalCore } from "src/Pages/components";

import { FormReceberParcela } from "./FormReceberParcela/FormReceberParcela";
import { UseFormReceberParcela } from "./FormReceberParcela/hook/UseFormReceberParcela";

interface IModalReceberParcela {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalReceberParcela = ({
  openModal,
  setOpenModal,
}: IModalReceberParcela) => {
  const { resetInputs } = useResetInputs();

  const { handleSubmit, loading: loadingSubmit } = UseFormReceberParcela({
    setOpenModal,
  });

  useEffect(() => {
    if (!openModal) resetInputs();
  }, [openModal]);

  return (
    <ModalCore
      loadingSubmit={loadingSubmit}
      open={openModal}
      setOpen={setOpenModal}
      sxStyle={{ width: "500px" }}
      title="Receber parcela"
      titleSubmit="Salvar"
      onChange={handleSubmit}
    >
      <FormReceberParcela />
    </ModalCore>
  );
};
