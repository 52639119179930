import { useLocation } from "react-router-dom";

import { BasePageLogin } from "../components/BasePageLogin";
import { ButtonLoginAndSearch } from "../components/ButtonLoginAndSearch";

import { FooterRegister } from "./components/Footer";
import { FormLoginRegister } from "./components/Form/FormRegister";
import { UseFormRegister } from "./components/Form/Hooks/UseFormRegister";
import { HeaderRegister } from "./components/Header";
import { HeaderParceria, listParceiros } from "./components/HeaderParceria";

export const PageLoginRegister = () => {
  const { handleSubmit } = UseFormRegister();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const idParam = listParceiros.find(
    (item) => item.parceria === queryParams.get("cupom")
  );

  return (
    <BasePageLogin
      sxStyle={{
        width: { xs: "340px", sm: "400px", md: "435px", lg: "500px" },
      }}
    >
      <HeaderRegister />

      <HeaderParceria parceria={idParam?.parceria} />

      <FormLoginRegister />

      <ButtonLoginAndSearch
        title="Cadastre-se"
        onClick={() => handleSubmit(idParam?.id)}
      />

      <FooterRegister />
    </BasePageLogin>
  );
};
