import { Children } from "react";
import { useTheme } from "@mui/material";
import { useContextProdutor } from "src/Contexts/produtores/ProdutoresContext";
import { TableRowCore, TableCore, TdCore } from "src/Pages/components";
import { FormatMoney } from "src/shared/Utils/FormatMoney";

import { THeadRankingCorretores } from "./THead";

export const TableRankingCorretores = () => {
  const { listRankingCorretor } = useContextProdutor();

  const theme = useTheme();

  return (
    <TableCore
      isNotPagination
      THead={<THeadRankingCorretores />}
      qtdList={listRankingCorretor.data.length}
      sxStyle={{ margin: "16px 0" }}
    >
      {Children.toArray(
        listRankingCorretor.data.map((item) => {
          return (
            <>
              <TableRowCore>
                <TdCore textAlign="center" values={item.position} />
                <TdCore
                  isLimit
                  isLimitQTD={35}
                  values={`${item.corretor.id} - ${item.corretor.nome}`}
                />
                <TdCore
                  color="#3cb916"
                  textAlign="right"
                  values={`R$ ${FormatMoney(String(item.totalMensalidades))}`}
                />
                <TdCore textAlign="center" values={item.totalVidas} />
                <TdCore textAlign="center" values={item.totalContratos} />
              </TableRowCore>
            </>
          );
        })
      )}
      {listRankingCorretor.data.length > 0 && (
        <TableRowCore>
          <TdCore
            sxStyle={{ fontWeight: "600" }}
            textAlign="center"
            values="TOTAL"
          />
          <TdCore values="" />
          <TdCore
            color="#3cb916"
            sxStyle={{ fontWeight: "600" }}
            textAlign="right"
            values={FormatMoney(
              String(
                listRankingCorretor.resume.somas.totalMensalidades.toFixed(2)
              )
            )}
          />
          <TdCore
            sxStyle={{ fontWeight: "600" }}
            textAlign="center"
            values={listRankingCorretor.resume.somas.totalVidas}
          />
          <TdCore
            sxStyle={{ fontWeight: "600" }}
            textAlign="center"
            values={listRankingCorretor.resume.somas.totalContratos}
          />
        </TableRowCore>
      )}
    </TableCore>
  );
};
