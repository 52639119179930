import { KeyboardArrowDownIcon } from "src/Pages/components/Icons/Icons";

type IIconExpand = {
  IsOpen: boolean;
};

export const IconExpandIsOpen = ({ IsOpen }: IIconExpand) => {
  return (
    <KeyboardArrowDownIcon
      sx={{
        color: "#3b3b3b",
        width: "20px",
        transition: "transform 0.3s ease-out 0s",
        transform: IsOpen ? "rotate(360deg)" : "rotate(270deg)",
      }}
    />
  );
};
