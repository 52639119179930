import { CardHeaderCore } from "src/Pages/components/CardCore/Components/CardHeaderCore";
import { CompassCalibration } from "src/Pages/components/Icons/Icons";

export const CardHeaderCompany = () => {
  return (
    <CardHeaderCore
      icon={<CompassCalibration />}
      subTitle="Aqui você poderá gerenciar os dados da empresa"
      sxStyle={{ margin: 0, marginBottom: "16px" }}
      title="Empresa"
    />
  );
};
