import { ChangeEvent, useEffect } from "react";
import { formatString } from "@format-string/core";
import { Grid } from "@mui/material";
import { useContextProfileContext } from "src/Contexts/ContextProfile/ContextProfile";
import { IEditPerfil } from "src/Contexts/ContextProfile/ContextProfileTypes";
import { AppTextField, FormCore } from "src/Pages/components";

import { UseFormUpdateProfile } from "./Hooks/UseFormUpdateProfile";
import { UseGetProfile } from "./Hooks/UseGetProfile";

export const FormProfile = () => {
  const { setValuesEditPerfil, valuesEditPerfil } = useContextProfileContext();

  const { handleUpdate, loading: loadingUpdate } = UseFormUpdateProfile();

  const { handleGet } = UseGetProfile();

  useEffect(() => {
    handleGet();
  }, []);

  const handleChange =
    (prop: keyof IEditPerfil, option?: "phone") =>
    (event: ChangeEvent<HTMLInputElement>) => {
      if (option === "phone") {
        setValuesEditPerfil((prevValue) => ({
          ...prevValue,
          [prop]: formatString({ value: event.target.value, type: "phone" }),
        }));
      } else {
        setValuesEditPerfil((prevValue) => ({
          ...prevValue,
          [prop]: event.target.value,
        }));
      }
    };

  return (
    <FormCore
      handleCancel={handleGet}
      handleSubmit={handleUpdate}
      loadingSubmit={loadingUpdate}
      sxStyle={{ margin: 0 }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AppTextField
            label="Nome"
            sxStyle={{
              pointerEvents: "",
              width: "100%",
              opacity: "",
              margin: 0,
            }}
            value={valuesEditPerfil?.nome}
            variant="outlined"
            onChange={handleChange("nome")}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <AppTextField
            label="Telefone"
            sxStyle={{
              pointerEvents: "",
              width: "100%",
              opacity: "",
              margin: 0,
            }}
            value={valuesEditPerfil.telefone_1}
            variant="outlined"
            onChange={handleChange("telefone_1", "phone")}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <AppTextField
            disabled
            label="Telefone 2"
            sxStyle={{
              pointerEvents: "",
              width: "100%",
              opacity: "",
              margin: 0,
            }}
            variant="outlined"
          />
        </Grid>
      </Grid>
    </FormCore>
  );
};
