import { Toolbar } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";

import { useLayoutMainContext } from "../../Contexts/MainLayoutContext";

import { AsideTitle } from "./aside/AsideTitle";

export const DrawerMobile = () => {
  const { openAside, setOpenAside } = useLayoutMainContext();

  return (
    <Drawer open={openAside} onClose={() => setOpenAside(!openAside)}>
      <Toolbar />
      <List
        sx={{
          background: "#ffffff",
          minWidth: "220px",
          height: "100%",
        }}
      >
        <AsideTitle />
      </List>
    </Drawer>
  );
};
