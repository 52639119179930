import { Grid } from "@mui/material";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { CardsStatics } from "src/Pages/components/CardsStatics";

export const Cards = () => {
  const { valuesCards } = useAuthContext();

  return (
    <Grid container mb="16px" spacing={2}>
      <Grid item md={4} xs={12}>
        <CardsStatics
          data={{
            name: "Proposta criadas",
            value: valuesCards?.countPropostas || "0",
            icon: "/assets/images/dashboard/icon-avg-cost.svg",
          }}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <CardsStatics
          data={{
            name: "Parcelas recebidas",
            value: valuesCards?.countParcelasRecebidas || "0",
            icon: "/assets/images/dashboard/icon-avg-cost.svg",
          }}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <CardsStatics
          data={{
            name: "Parcelas pagas",
            value: valuesCards?.countParcelasPagas || "0",
            icon: "/assets/images/dashboard/icon-avg-cost.svg",
          }}
        />
      </Grid>
    </Grid>
  );
};
