import { IPDFTableHeader } from "src/Pages/components/PDF/TableHeader";
import { PDFTableHeader } from "src/Pages/components/PDF/TableHeader/PDFTableHeader";

export const HeadPDFConfirmationReceiptResume = () => {
  const dataHeaderPDF: IPDFTableHeader = [
    {
      style: {
        width: "100%",
        textAlign: "left",
        paddingLeft: "8px",
      },
      title: "Resumo das comissões",
    },
  ];

  return <PDFTableHeader data={dataHeaderPDF} />;
};
