import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useContextRecoverPassword } from "src/Contexts/RecoverPassword/RecoverPasswordContext";

import { BasePageLogin } from "../components/BasePageLogin";

import { PageFormRecoverPassword } from "./Form";
import { LinkExpiration } from "./LinkExpiration";
import { UseVerifyIsValidLinkRecoverPassword } from "./VerifyIsValidLinkRecoverPassword/Hooks/UseVerifyIsValidLinkRecoverPassword";

export const PageRecoverPassword = () => {
  const { isValidLink } = useContextRecoverPassword();

  const { id } = useParams();

  const {
    handleSubmit: handleVerifyIsValidLinkRecoverPassword,
    loading: loadingVerifyIsValidLinkRecoverPassword,
  } = UseVerifyIsValidLinkRecoverPassword();

  useEffect(() => {
    if (id) {
      handleVerifyIsValidLinkRecoverPassword({ id });
    }
  }, [id]);

  return (
    <BasePageLogin
      sxStyle={{
        width: { xs: "340px", sm: "400px", md: "435px", lg: "470px" },
      }}
    >
      {loadingVerifyIsValidLinkRecoverPassword && <CircularProgress />}

      {!loadingVerifyIsValidLinkRecoverPassword &&
        (isValidLink && id ? (
          <PageFormRecoverPassword id={id} />
        ) : (
          <LinkExpiration />
        ))}
    </BasePageLogin>
  );
};
