import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { CardContract } from "src/Pages/components/CardContract/CardContract";
import { TitleWithValueCore } from "src/Pages/components/TitleWithValueCore/TitleWithValueCore";
import { handleFormatCep } from "src/shared/Utils/FormatCEP";
import { cpfOurCnpj } from "src/shared/Utils/MaskCpfCnpj";

export const CardDadosInformaçõesContato = () => {
  const { valueContractInternal } = useContextContrato();

  if (!valueContractInternal) return null;

  const {
    email,
    telefone_1,
    telefone_2,
    cep,
    endereco,
    endereco_numero,
    bairro,
    uf,
    cidade,
    complemento,
  } = valueContractInternal;

  return (
    <>
      <CardContract title="Informações de contato">
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="Email:"
          value={email}
        />

        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="Telefone 1:"
          value={cpfOurCnpj({
            text: telefone_1,
            type: "phone",
          })}
        />
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="Telefone 2:"
          value={cpfOurCnpj({
            text: telefone_2,
            type: "phone",
          })}
        />
      </CardContract>
      <CardContract>
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="CEP:"
          value={handleFormatCep(cep)}
        />
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="Endereço:"
          value={endereco}
        />
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="Numero:"
          value={endereco_numero}
        />
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="UF:"
          value={uf}
        />
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="Cidade:"
          value={cidade}
        />
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="Bairro:"
          value={bairro}
        />
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="Complemento:"
          value={complemento}
        />
      </CardContract>
    </>
  );
};
