import React, { FC } from "react";
import {
  Box,
  Dialog,
  IconButton,
  DialogProps,
  DialogTitle,
} from "@mui/material";

import { CloseIcon } from "../Icons/Icons";

type IDialogCore = DialogProps & {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
};

export const DialogCore: FC<IDialogCore> = ({
  open,
  title,
  setOpen,
  children,
  ...props
}) => {
  return (
    <Dialog
      {...props}
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle
        sx={{
          display: "flex",
          padding: "12px",
          marginBottom: "1rem",
          justifyContent: "space-between",
          borderBottom: "1px solid gray",
          paddingBottom: ".5rem",
        }}
      >
        <Box>{title}</Box>

        <IconButton size="small" onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {children}
    </Dialog>
  );
};
