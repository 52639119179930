import { Button, ButtonGroup, Box, Chip, Typography } from "@mui/material";
import { CheckIcon } from "src/Pages/components/Icons/Icons";

interface ICardPersonalizedDetails {
  countUsers: number;
  setCountUser: React.Dispatch<React.SetStateAction<number>>;
}

export const CardPersonalizedDetails = ({
  countUsers,
  setCountUser,
}: ICardPersonalizedDetails) => {
  const handleCountSub = () => {
    if (countUsers > 3) setCountUser((item) => item - 1);
  };

  const handleCountSum = () => setCountUser(countUsers + 1);

  return (
    <Box sx={{ margin: "10px 0" }}>
      <Chip
        label="Personalizado para você"
        sx={{
          fontSize: { xs: ".8rem", lg: ".95rem" },
          padding: { xs: ".7rem", sm: "1rem" },
          color: (theme) => theme.palette.primary.main,
          border: (theme) => `1px solid ${theme.palette.primary.main}`,
          width: "100%",
          marginBottom: "1rem",
        }}
        variant="outlined"
      />

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <CheckIcon color="success" />

        <Box
          sx={{
            fontWeight: 500,
            margin: "0 0 0 4px",
            fontSize: ".9rem",
            minWidth: "80px",
          }}
        >
          {countUsers} Usuários
        </Box>

        <ButtonGroup
          color="info"
          orientation="horizontal"
          size="small"
          sx={{ marginRight: "8px" }}
          variant="outlined"
        >
          <Button
            key="one"
            disabled={countUsers === 3}
            sx={{ borderRadius: "20px", padding: "2px", width: "20px" }}
            onClick={handleCountSub}
          >
            -
          </Button>
          <Button
            key="three"
            sx={{ borderRadius: "20px", padding: "2px" }}
            onClick={handleCountSum}
          >
            +
          </Button>
        </ButtonGroup>

        <Typography sx={{ fontSize: "12px", opacity: 7, fontWeight: 500 }}>
          100,00 p/usuário
        </Typography>
      </Box>
    </Box>
  );
};
