import { Children, useEffect, useState } from "react";
import AddHomeWorkIcon from "@mui/icons-material/AddHomeWork";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextUsuarios } from "src/Contexts/Usuarios/UsuariosContext";
import {
  ModalDeleteCore,
  TableRowCore,
  TableCore,
  TdCore,
} from "src/Pages/components";
import { UseVerifyIsPermissionsUser } from "src/Pages/components/hooks/UseVerifyIsPermissionsUser";
import { DeleteForeverIcon, EditIcon } from "src/Pages/components/Icons/Icons";
import { UseDelete } from "src/Pages/components/ModalDeleteCore/Hooks/UseDelete";
import { ActionPopoverTable } from "src/Pages/components/table/ActionPopover/ActionPopover";
import { valuesPermissions } from "src/shared/Utils/ValuesPermissions";

import { ModalFilial } from "../FormFilial";

import { useHttpTableFiliais } from "./Hooks/useHttpTableFiliais";
import { THeadFiliais } from "./THead";

export const TableFiliais = () => {
  const { handleGet } = useHttpTableFiliais();
  const { handleVerifyPermissionItem } = UseVerifyIsPermissionsUser();
  const { listFiliais, setValuesInputsFiliais } = useContextUsuarios();

  const [openModalFilial, setOpenModalFilial] = useState(false);

  const {
    handleDelete,
    setId: setIdModal,
    open: openModalDelete,
    loading: loadingDelete,
    setOpen: setOpenModalDelete,
  } = UseDelete({ url: "/deleteFilial" });

  const {
    setNameSearch,
    itensPerPage,
    currentPage,
    nameSearch,
    attTable,
    setId,
  } = useConfigPageContext();

  const handleGetIdToEdit = (id: string, filial: string) => {
    setOpenModalFilial(true);
    setValuesInputsFiliais({ filial });
    setId(id);
  };

  useEffect(() => {
    handleGet({ itensPerPage, currentPage, nameSearch });
  }, [attTable, itensPerPage, currentPage, nameSearch]);

  return (
    <TableCore
      Modals={
        <ModalDeleteCore
          loading={loadingDelete}
          modalOpen={openModalDelete}
          no="Não, Cancelar"
          titlePrimary="Você deseja excluir da lista ?"
          yes="Sim, Deletar !"
          onClickFalse={() => setOpenModalDelete(false)}
          onClickTrue={() => handleDelete()}
        />
      }
      THead={<THeadFiliais />}
      cardAdd={{
        title: "Filiais",
        icon: <AddHomeWorkIcon />,
        buttonAdd: [
          <ModalFilial
            key={1}
            openModal={openModalFilial}
            setOpenModal={setOpenModalFilial}
          />,
        ],
      }}
      qtdList={listFiliais.length}
      setNameSearch={setNameSearch}
    >
      {Children.toArray(
        listFiliais.map((item) => {
          return (
            <TableRowCore>
              <TdCore
                values={
                  <ActionPopoverTable
                    optionsList={[
                      {
                        icon: <EditIcon fontSize="small" />,
                        title: "Editar",
                        background: "warning",
                        isDisabled: handleVerifyPermissionItem(
                          valuesPermissions.FILIAL.EDITAR.id
                        ),
                        onClick: () => {
                          handleGetIdToEdit(item.id, item.filial);
                        },
                      },
                      {
                        icon: <DeleteForeverIcon fontSize="small" />,
                        title: "Excluir",
                        background: "error",
                        isDisabled: handleVerifyPermissionItem(
                          valuesPermissions.FILIAL.DELETAR.id
                        ),
                        onClick: () => {
                          setIdModal(item.id);
                          setOpenModalDelete(true);
                        },
                      },
                    ]}
                  />
                }
              />
              <TdCore values={`${item.id} - ${item.filial}`} />
            </TableRowCore>
          );
        })
      )}
    </TableCore>
  );
};
