import { FormContaCorrente } from "./Components/FormContaCorrente/FormContaCorrente";
import { TableContaCorrente } from "./Components/TableContaCorrente/TableContaCorrente";

export const PageContaCorrente = () => {
  return (
    <>
      <FormContaCorrente />

      <TableContaCorrente />
    </>
  );
};
