import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { useContextUsuarios } from "src/Contexts/Usuarios/UsuariosContext";
import { api } from "src/shared/setup/API/api";

interface IUseFeaturesFormulario {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UseFormPermissions = ({
  setOpenModal,
}: IUseFeaturesFormulario) => {
  const { setAttTable, attTable, id, setLoadingGravar } =
    useConfigPageContext();

  const { valuesInputsPerfilAcesso } = useContextUsuarios();
  const { handleGetAlert } = useLayoutMainContext();

  const handleCreatePerfilAcesso = async () => {
    if (!valuesInputsPerfilAcesso.perfil_acesso)
      return handleGetAlert({ message: `Digite um nome de perfil de acesso` });

    const AllDatas = {
      ...valuesInputsPerfilAcesso,
      nameViewsPermissions: valuesInputsPerfilAcesso.permissions?.toString(),
    };

    setLoadingGravar(true);

    return api
      .post("/createPerfilAcesso", AllDatas)
      .then((res) => {
        setAttTable(!attTable);
        handleGetAlert({ message: res.data.message });
        setOpenModal(false);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoadingGravar(false));
  };

  const handleEditPerfilAcesso = () => {
    if (!valuesInputsPerfilAcesso.perfil_acesso)
      return handleGetAlert({ message: `Digite um nome de perfil de acesso` });

    const AllDatas = {
      ...valuesInputsPerfilAcesso,
      nameViewsPermissions: valuesInputsPerfilAcesso.permissions?.toString(),
    };

    setLoadingGravar(true);

    return api
      .put(`/updatePerfilAcesso/${id}`, AllDatas)
      .then((res) => {
        setAttTable(!attTable);
        handleGetAlert({ message: res.data.message });
        setOpenModal(false);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoadingGravar(false));
  };

  const handleSubmit = () => {
    if (id) {
      handleEditPerfilAcesso();
    } else {
      handleCreatePerfilAcesso();
    }
  };

  return { handleSubmit };
};
