import { IDataTh, ThCoreIndex } from "src/Pages/components";

export const THeadBordero = () => {
  const dataTh: IDataTh[] = [
    {
      title: "Ações",
      align: "left",
      width: "40px",
    },
    {
      title: "Status",
      align: "left",
      width: "100px",
    },
    {
      title: "Operadoras",
      align: "left",
      minWidth: "130px",
      width: "130px",
    },
    {
      title: "Proposta",
      align: "center",
      width: "120px",
      minWidth: "120px",
    },
    {
      title: "Parcelas",
      align: "center",
      width: "90px",
    },
    {
      title: "Vidas",
      align: "center",
      width: "100px",
    },
    {
      title: "D. vencimento",
      align: "center",
      width: "100px",
      minWidth: "120px",
    },

    {
      title: "Recebido ? ",
      tooltip: "Confirmado recebimento ? ",
      align: "center",
      width: "90px",
      minWidth: "100px",
    },
    {
      title: "R$ Mensalidade ",
      tooltip: "Valor Mensalidade",
      align: "left",
      width: "100px",
      minWidth: "130px",
    },
    {
      title: "D. Recebimento",
      tooltip: "Data do recebimento",
      align: "center",
      width: "100px",
      minWidth: "130px",
    },
    {
      title: "P. Corretor ?",
      tooltip: "Pago para o corretor",
      align: "center",
      width: "120px",
      minWidth: "120px",
    },
    {
      title: "P. Supervisor ?",
      tooltip: "Pago para o supervisor",
      align: "center",
      width: "120px",
      minWidth: "120px",
    },
    {
      title: "P. Gerente ?",
      tooltip: "Pago para o gerente",
      align: "center",
      width: "120px",
      minWidth: "120px",
    },
    {
      title: "R$ Contrato",
      tooltip: "Valor do contrato",
      align: "left",
      width: "100px",
      minWidth: "110px",
    },
    {
      title: "R$ Corretora",
      tooltip: "Valor que a corretora recebe",
      align: "left",
      width: "100px",
      minWidth: "110px",
    },
    {
      title: "R$ Corretor",
      tooltip: "Valor que a corretora paga para o corretor",
      align: "left",
      width: "100px",
      minWidth: "110px",
    },
    {
      title: "R$ Supervisor",
      tooltip: "Valor que a corretora paga para o supervisor",
      align: "left",
      width: "100px",
      minWidth: "120px",
    },
    {
      title: "R$ Gerente",
      tooltip: "Valor que a corretora paga para o gerente",
      align: "left",
      width: "100px",
      minWidth: "100px",
    },
    {
      title: "Lucro parc.",
      tooltip: "Valor que a corretora lucrou",
      align: "left",
      width: "100px",
      minWidth: "100px",
    },
    {
      title: "% Corretora",
      tooltip: "Porcentagem que a corretora recebe",
      align: "left",
      width: "100px",
      minWidth: "100px",
    },
    {
      title: "% Corretor",
      tooltip: "Porcentagem que a corretora paga para o corretor",
      align: "left",
      width: "100px",
      minWidth: "100px",
    },
    {
      title: "% supervisor",
      tooltip: "Porcentagem que a corretora paga para o supervisor",
      align: "left",
      width: "100px",
      minWidth: "110px",
    },
    {
      title: "% Gerente",
      tooltip: "Porcentagem que a corretora paga para o gerente",
      align: "left",
      width: "100px",
      minWidth: "100px",
    },
    {
      title: "Corretor",
      align: "left",
      width: "200px",
    },
    {
      title: "T. contrato",
      align: "center",
      minWidth: "110px",
      width: "110px",
      tooltip: "Tipo de contrato",
    },
    {
      title: "Titular/Razão",
      align: "left",
      width: "200px",
      minWidth: "150px",
    },
    {
      title: "CPF/CNPJ",
      align: "left",
      width: "150px",
    },
    {
      title: "Obs",
      align: "left",
      width: "70px",
      minWidth: "100px",
    },
  ];

  return <ThCoreIndex dataTh={dataTh} />;
};
