export const UrlConfig = {
  home: "/home",

  help: {
    title: "Duvidas/suporte",
    url: "/help",
  },

  controle: {
    title: "Controle",
  },

  dashboard: {
    title: "Dashboard",

    dashboardBroker: {
      url: "/dashboard/brokers",
      title: "Dashboard corretora",
    },
    dashboardProducer: {
      url: "/dashboard/producer",
      title: "Dashboard produtor",
    },
  },

  config: {
    title: "Configurações",

    companyDetails: {
      url: "/config/details",
      title: "Empresa detalhes",
    },

    plans: {
      url: "/config/plans",
      title: "Planos",
    },

    logs: {
      url: "/config/logs",
      title: "Logs",
    },
  },

  usuarios: {
    title: "Usuários",

    filiais: {
      url: "/usuarios/Filiais",
      title: "Filiais",
    },

    perfilAcesso: {
      url: "/usuarios/PerfisAcesso",
      title: "Perfis de Acesso",
    },

    usuarios: {
      url: "/usuarios/usuario",
      title: "Usuários",
    },
  },

  produtores: {
    title: "Produtores",

    produtores: {
      url: "/Produtores/Produtores",
      title: "Produtores",
    },
    contaCorrente: {
      url: "/Produtores/ContaCorrente",
      title: "Conta Corrente",
    },
    impostos: {
      url: "/Produtores/impostos",
      title: "Impostos",
    },
  },

  pagamentos: {
    title: "Pagamentos",

    recibos: {
      url: "/Pagamentos/recibos",
      title: "Recibos",
    },
  },

  relatoriosProducao: {
    title: "Relatórios",

    producaoContract: {
      url: "/report/productionContract",
      title: "Produção contrato",
    },
    producaoProducer: {
      url: "/report/productionProducer",
      title: "Produção corretor",
    },
    producaoOperator: {
      url: "/report/productionOperator",
      title: "Produção operadora",
    },
    confirmationContract: {
      url: "/report/confirmationContract",
      title: "Confirmação contrato",
    },
    confirmationProducer: {
      url: "/report/confirmationProducer",
      title: "Confirmação produtor",
    },
  },

  comissao: {
    title: "Comissões",

    operadora: {
      url: "/Comissoes/operadora",
      title: "Operadora",
    },
    modalidade: {
      url: "/Comissoes/modalidade",
      title: "Modalidade",
    },
    administradora: {
      url: "/Comissoes/administradora",
      title: "Administradora",
    },
    corretora: {
      url: "/Comissoes/corretora",
      title: "Comissões Corretora",
    },
    produtor: {
      url: "/Comissoes/produtor",
      title: "Comissões Produtor",
    },
  },

  lancamentos: {
    title: "Lançamentos",

    contratos: {
      url: "/Lancamentos/contratos",
      title: "Contratos",
    },

    contrato: {
      url: "/Lancamentos/contrato/:id",
      title: "Contrato interno",
    },
    contratoParcelasInterno: {
      url: "/Lancamentos/contrato/parcelas/:id",
      title: "Parcelas contrato",
    },

    parcelas: {
      url: "/Lancamentos/parcelas",
      title: "Parcelas",
    },
  },
};
