import { useState } from "react";
import { usePageControle } from "src/Contexts/ControlCompanies/ControleEmpresaContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

interface IHandleChangeCompany {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IChangeCompany {
  id: string;
}

export const UseFormChangeCompany = ({
  setOpenModal,
}: IHandleChangeCompany) => {
  const { valuesInputChangeCompany } = usePageControle();

  const { handleGetAlert } = useLayoutMainContext();

  const [loading, setLoading] = useState(false);

  const handleChangeCompany = async ({ id }: IChangeCompany) => {
    setLoading(true);

    await api
      .patch(`/controleEmpresas/changeCompany/${id}`, {
        days_limit_free: valuesInputChangeCompany.days_limit_free,
        is_plan: valuesInputChangeCompany.is_plan,
        is_plan_test: valuesInputChangeCompany.is_plan_test,
      })
      .then((res) => {
        handleGetAlert({ message: res.data.message });

        setOpenModal(false);
      })
      .catch((error) => {
        handleGetAlert({ message: error.response.data.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    handleChange: handleChangeCompany,
    loading,
  };
};
