import { ChangeEvent, useState } from "react";
import { Box, MenuItem } from "@mui/material";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { ICreateContrato } from "src/Contexts/Lancamentos/Contrato/ContratosTypes";
import { AppTextField, ButtonPersonalized } from "src/Pages/components";
import { AutoCompleteAdministradoras } from "src/Pages/components/AutoCompleted/AutoCompleteAdministradoras";
import { AutoCompleteFiliaisGeneric } from "src/Pages/components/AutoCompleted/AutoCompleteFiliais";
import { AutoCompleteModalidade } from "src/Pages/components/AutoCompleted/AutoCompleteModalidade";
import { AutoCompleteOperadora } from "src/Pages/components/AutoCompleted/AutoCompleteOperadora";
import { DividerCore } from "src/Pages/components/DividerCore/DividerCore";

import { AutoCompleteProdutorActiveCorretor } from "../../../../../components/AutoCompleted/AutoCompleteProdutorActiveCorretor";
import { CardPercentsData } from "../CreateContrato/CardPercentsData/CardPercentsData";
import { UseGetComissoesGrade } from "../CreateContrato/CardPercentsData/Hooks/UseGetComissoesGrade";

interface IFormContrato {
  inputRef: React.RefObject<HTMLInputElement>;
}

export const FormContrato = ({ inputRef }: IFormContrato) => {
  UseGetComissoesGrade();

  const { active_incluir } = useConfigPageContext();

  const { valuesInputsContrato, setValuesInputsContrato } =
    useContextContrato();

  const [open, setOpen] = useState(false);
  const [view, setView] = useState(true);

  const handleChange =
    (prop: keyof ICreateContrato) => (event: ChangeEvent<HTMLInputElement>) => {
      setValuesInputsContrato({
        ...valuesInputsContrato,
        [prop]: event.target.value,
      });
    };

  return (
    <>
      <DividerCore title="Contrato" onClick={() => setView((item) => !item)} />

      <Box sx={{ display: view ? "flex" : "none", flexWrap: "wrap" }}>
        <AppTextField
          inputRef={inputRef}
          label="Data assinatura *"
          sxStyle={{ width: "150px" }}
          type="date"
          value={valuesInputsContrato.assinatura_data}
          onChange={handleChange("assinatura_data")}
        />

        <AppTextField
          select
          id="tipo_contrato"
          label="Fisica | Juridica"
          sxStyle={{ width: "150px" }}
          value={valuesInputsContrato?.tipo_contrato}
          onChange={handleChange("tipo_contrato")}
        >
          <MenuItem value="PF">Fisica</MenuItem>
          <MenuItem value="PJ">Juridica</MenuItem>
        </AppTextField>

        <AutoCompleteOperadora
          disabled={!active_incluir}
          label="Operadora *"
          tamanho="200px"
        />

        <AutoCompleteAdministradoras
          disabled={!active_incluir}
          label="Administradora"
          tamanho="200px"
        />

        <AutoCompleteModalidade
          disabled={!active_incluir}
          label="Modalidade *"
          tamanho="200px"
        />

        <AutoCompleteProdutorActiveCorretor
          disabled={!active_incluir}
          label="Corretor *"
          tamanho="250px"
        />

        <AppTextField
          label="Proposta *"
          sxStyle={{ width: "150px" }}
          tooltip="Código de proposta deve ser único"
          value={valuesInputsContrato.cod_proposta}
          onChange={handleChange("cod_proposta")}
        />

        <AutoCompleteFiliaisGeneric
          disabled={!active_incluir}
          label="Filial *"
          tamanho="200px"
        />

        <ButtonPersonalized
          disabled={!active_incluir}
          title="Comissão"
          titleToolTip="Tabela de comissionamento"
          variant="outlined"
          onClick={() => setOpen(true)}
        />

        <CardPercentsData open={open} setOpen={setOpen} />
      </Box>
    </>
  );
};
