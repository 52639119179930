import { useContextRecibos } from "src/Contexts/Recibos/RecibosContext";
import { UseAutoCompletedAll } from "src/Contexts/Utils/UseAutoCompletedAll";
import { AutoCompleteGeneric } from "src/Pages/components/InputAutoCompleted/AutoCompleteGeneric";
import { IPropsAutoCompleted } from "src/Pages/components/InputAutoCompleted/types";

export const AutoCompleteProdutores = (props: IPropsAutoCompleted) => {
  const { label, width, tamanho, minWidth, maxWidth, fullWidth, disabled } =
    props;
  const { valuesInputsRecibos } = useContextRecibos();

  const { valueAutoCompProdutorActive, setValueAutoCompProdutorActive } =
    UseAutoCompletedAll();

  return (
    <AutoCompleteGeneric
      disabled={disabled}
      fullWidth={fullWidth}
      label={label}
      maxWidth={maxWidth}
      minWidth={minWidth}
      setValueAutoCompleted={setValueAutoCompProdutorActive}
      tamanho={tamanho}
      url={`/getAllProdutores?funcao_produtor=${valuesInputsRecibos.funcao_produtor}`}
      valueAutoCompleted={valueAutoCompProdutorActive}
      width={width}
    />
  );
};
