import { Box, Checkbox, Typography } from "@mui/material";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { LinkCore } from "src/Pages/components/LinkCore/LinkCore";

export const FooterFom = () => {
  const { valuesAutoComplete } = useAuthContext();

  return (
    <Box
      sx={{
        display: valuesAutoComplete.length > 1 ? "none" : "flex",
        flexWrap: "wrap",
        alignItems: { sm: "center" },
        justifyContent: "space-between",
        mb: { xs: 1, xl: 2 },
        "& > *": {
          color: "#474747",
        },
      }}
    >
      <Box
        sx={{
          justifyContent: "center",
          flexDirection: "row",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Checkbox sx={{ color: "#4e4e4e" }} />

        <Typography
          sx={{
            fontSize: { xs: 12, sm: 14 },
            fontWeight: "bold",
            color: "#4e4e4e",
          }}
        >
          Salvar login?
        </Typography>
      </Box>

      <Typography
        sx={{
          fontSize: { xs: 12, sm: 14 },
          alignItems: "center",
          fontWeight: "bold",
          cursor: "pointer",
          display: "flex",
          color: "#4e4e4e",
        }}
      >
        <LinkCore color="#2478fff5" to="/SendLinkRecoverPassword">
          Esqueceu a senha ?
        </LinkCore>
      </Typography>
    </Box>
  );
};
