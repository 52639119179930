import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { UseAutoCompletedAll } from "src/Contexts/Utils/UseAutoCompletedAll";
import { AutoCompleteGeneric } from "src/Pages/components/InputAutoCompleted/AutoCompleteGeneric";
import { IPropsAutoCompleted } from "src/Pages/components/InputAutoCompleted/types";

export const AutoCompleteProdutorActive = (props: IPropsAutoCompleted) => {
  const { active_incluir } = useConfigPageContext();

  const { label, width, tamanho, minWidth, maxWidth, fullWidth, variant } =
    props;
  const { valueAutoCompProdutorActive, setValueAutoCompProdutorActive } =
    UseAutoCompletedAll();

  return (
    <AutoCompleteGeneric
      isGroupBy
      disabled={!active_incluir}
      fullWidth={fullWidth}
      label={label}
      maxWidth={maxWidth}
      minWidth={minWidth}
      setValueAutoCompleted={setValueAutoCompProdutorActive}
      tamanho={tamanho}
      url="/getAllProdutores?is_active_producer=true"
      valueAutoCompleted={valueAutoCompProdutorActive}
      variant={variant}
      width={width}
    />
  );
};
