import { Children, useEffect } from "react";
import { Box, LinearProgress } from "@mui/material";
import { PDFViewer, Document, View } from "@react-pdf/renderer";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextReportsProduction } from "src/Contexts/ReportProduction/ReportsProductionContextContext";
import { PDFCabeçalho } from "src/Pages/components/PDF/Cabeçalho";
import { ItemMapToInfoSearch } from "src/Pages/components/PDF/ItemMapToInfoSearch";
import { LineItem } from "src/Pages/components/PDF/LineItem/LineItem";
import { PageCorePDF } from "src/Pages/components/PDF/PageCorePDF";
import { TableBase } from "src/Pages/components/PDF/TableBase";
import { PDFTableRow } from "src/Pages/components/PDF/TableRow";
import { TableRowContainer } from "src/Pages/components/PDF/TableRowContainer";
import { handleFormatDateWithHours } from "src/shared/Utils/FormatDate/FormatDateToCodeProcucer";
import { FormatDateBR, GetDateUSSemValue } from "src/shared/Utils/FormatDateBR";
import { FormatMoney } from "src/shared/Utils/FormatMoney";
import { handleFormatDateIfIsNull } from "src/shared/Utils/handleFormatDateIfIsNull";
import { HandleGetHoursCurrency } from "src/shared/Utils/HandleGetHoursCurrency";
import { handleIfLimitCharacter } from "src/shared/Utils/LimitText";

import { HeadReportConfirmationContractPDF } from "./HeadReportConfirmationContractPDF";

export const ReportConfirmationContractPDF = () => {
  const { userPerfil } = useAuthContext();
  const { loadingGravar } = useConfigPageContext();
  const { listReportConfirmationContract, setListReportConfirmationContract } =
    useContextReportsProduction();

  const data = listReportConfirmationContract?.data;
  const meta = listReportConfirmationContract?.meta;
  const resume = listReportConfirmationContract?.resume;

  useEffect(() => setListReportConfirmationContract(undefined), []);

  return (
    <>
      {userPerfil && data?.length && resume && (
        <PDFViewer style={{ height: "84vh" }} width="100%">
          <Document
            creator="Brokeris"
            title={
              meta?.typeReport === "ALL"
                ? "Relatório de confirmação por contrato"
                : `${data[0].produtor.name}-${handleFormatDateWithHours()}`
            }
          >
            <PageCorePDF
              key={resume.totalValorComissao}
              orientation="landscape"
              padding="16px 8px 30px 8px"
            >
              {meta?.dataPayments.startDate && meta?.dataPayments.endDate && (
                <PDFCabeçalho
                  dateCurrency={FormatDateBR(GetDateUSSemValue())}
                  empresa=""
                  endDate={FormatDateBR(meta.dataPayments.endDate)}
                  hoursCurrency={HandleGetHoursCurrency()}
                  nomePdf="Relatório de confirmação por contrato"
                  startDate={FormatDateBR(meta.dataPayments.startDate)}
                />
              )}

              <View
                key={resume.totalValorComissao}
                fixed
                style={{
                  borderBottom: "1px solid black",
                  marginBottom: "14px",
                }}
              >
                <ItemMapToInfoSearch
                  itens={
                    meta?.typeReport === "UNIQUE" ? [data[0].produtor.name] : []
                  }
                  title="Produtor"
                />
                <ItemMapToInfoSearch
                  itens={
                    [meta?.dataPayments.typeDate as string] || ["PAGEMENTO"]
                  }
                  title="Tipo de data"
                />
                <ItemMapToInfoSearch itens={meta?.filiais} title="Filiais" />
                <ItemMapToInfoSearch
                  itens={meta?.operadoras}
                  title="Operadoras"
                />
                <ItemMapToInfoSearch
                  itens={meta?.modalidades}
                  title="Modalidades"
                />
                <ItemMapToInfoSearch
                  itens={meta?.administradoras}
                  title="Administradoras"
                />
              </View>

              <TableBase key={resume.totalValorComissao} margin="0">
                <HeadReportConfirmationContractPDF />

                {Children.toArray(
                  data.map(({ contratos, produtor, resume }) => {
                    return (
                      <>
                        <LineItem id={produtor.id} name={produtor.name} />

                        {Children.toArray(
                          contratos.map((itemContrato) => {
                            return (
                              <TableRowContainer key={produtor.id}>
                                <PDFTableRow
                                  paddingLeft="8px"
                                  title={handleIfLimitCharacter({
                                    LIMIT: 28,
                                    value: itemContrato.operadora.name,
                                  })}
                                  width="15%"
                                />
                                <PDFTableRow
                                  paddingLeft="8px"
                                  title={handleIfLimitCharacter({
                                    LIMIT: 30,
                                    value: itemContrato.codProposta,
                                  })}
                                  width="18%"
                                />
                                <PDFTableRow
                                  title={handleIfLimitCharacter({
                                    LIMIT: 40,
                                    value: itemContrato.titular,
                                  })}
                                  width="20%"
                                />

                                <PDFTableRow
                                  textAlign="right"
                                  title={FormatDateBR(
                                    itemContrato.dataAssinatura
                                  )}
                                  width="12%"
                                />
                                <PDFTableRow
                                  textAlign="center"
                                  title={itemContrato.numero_parcela}
                                  width="6%"
                                />
                                <PDFTableRow
                                  textAlign="right"
                                  title={FormatDateBR(
                                    handleFormatDateIfIsNull(
                                      itemContrato.data_recebimento
                                    )
                                  )}
                                  width="9%"
                                />
                                <PDFTableRow
                                  textAlign="right"
                                  title={FormatMoney(
                                    itemContrato.valor_recebido
                                  )}
                                  width="11%"
                                />
                                <PDFTableRow
                                  textAlign="right"
                                  title={`${itemContrato.porcentagem_comissao_corretora.replace(
                                    ".",
                                    ","
                                  )} %`}
                                  width="8%"
                                />

                                <PDFTableRow
                                  textAlign="right"
                                  title={`${itemContrato.porcentagem_comissao_corretor.replace(
                                    ".",
                                    ","
                                  )} %`}
                                  width="7%"
                                />

                                <PDFTableRow
                                  paddingRight="8px"
                                  textAlign="right"
                                  title={FormatMoney(
                                    itemContrato.valor_comissao_corretor
                                  )}
                                  width="10%"
                                />
                              </TableRowContainer>
                            );
                          })
                        )}

                        <TableRowContainer key={produtor.id}>
                          <PDFTableRow
                            fontSize={10}
                            paddingLeft="8px"
                            title={`Total - ${resume.totalQtdParcelas}`}
                            width="70.5%"
                          />

                          <PDFTableRow
                            fontSize={10}
                            textAlign="right"
                            title={FormatMoney(
                              resume.totalValorRecebido?.toFixed(2)
                            )}
                            width="8%"
                          />
                          <PDFTableRow
                            fontSize={10}
                            paddingRight="8px"
                            textAlign="right"
                            title={FormatMoney(
                              resume.totalValorComissao?.toFixed(2)
                            )}
                            width="21.5%"
                          />
                        </TableRowContainer>
                      </>
                    );
                  })
                )}

                <TableRowContainer
                  key={resume.totalQtdParcelas}
                  backgroundColor="#eeeeee"
                >
                  <PDFTableRow
                    fontSize={11}
                    paddingLeft="8px"
                    title={`Total - ${resume.totalQtdParcelas}`}
                    width="70.5%"
                  />

                  <PDFTableRow
                    fontSize={11}
                    textAlign="right"
                    title={FormatMoney(resume.totalValorRecebido.toFixed(2))}
                    width="8%"
                  />
                  <PDFTableRow
                    fontSize={11}
                    paddingRight="8px"
                    textAlign="right"
                    title={FormatMoney(resume.totalValorComissao.toFixed(2))}
                    width="21.5%"
                  />
                </TableRowContainer>
              </TableBase>
            </PageCorePDF>
          </Document>
        </PDFViewer>
      )}

      {loadingGravar && (
        <Box style={{ width: "100%" }}>
          <LinearProgress color="inherit" />
        </Box>
      )}
    </>
  );
};
