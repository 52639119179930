import { IUpdateCompanyDetails } from "../CompanyContextTypes";

export const valuesDefaultInputsCompanyDefault: IUpdateCompanyDetails = {
  id: "",
  cnpj: "",
  fantasyName: "",

  address_state: "",
  address_city: "",
  address_district: "",
  address_street: "",
  address_address_number: "",
  address_zip_code: "",
  address_complement: "",

  responsible_name: "",
  responsible_email: "",
  responsible_telephone1: "",
  responsible_telephone2: "",

  createdAt: "",
};
