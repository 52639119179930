import { useState } from "react";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

interface IHandleUpdateStatusContratos {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UseFormUploadCommission = ({
  setOpenModal,
}: IHandleUpdateStatusContratos) => {
  const { setAttTable } = useConfigPageContext();
  const { handleGetAlert } = useLayoutMainContext();
  const { valuesUploadCommission } = useContextContrato();

  const [loading, setLoading] = useState(false);

  const handleUploadCommission = async () => {
    if (!valuesUploadCommission.data_pagamento) {
      return handleGetAlert({ message: "Enviar uma data de pagamento" });
    }
    if (!valuesUploadCommission.type_file) {
      return handleGetAlert({ message: "Enviar uma tipo de arquivo" });
    }
    if (!valuesUploadCommission.id_operadora) {
      return handleGetAlert({ message: "Enviar uma operadora" });
    }
    setLoading(true);

    const data = new FormData();

    data.append("file", valuesUploadCommission.file[0]);
    data.append("data_pagamento", valuesUploadCommission.data_pagamento);
    data.append("id_operadora", valuesUploadCommission.id_operadora);

    return api
      .post(`/bordero/uploadCommission`, data)
      .then((res) => {
        handleGetAlert({ message: res.data.message });
        setAttTable((item) => !item);
        setOpenModal(false);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  return { handleUploadCommission, loading };
};
