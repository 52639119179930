import { Children, useEffect, useState } from "react";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextUsuarios } from "src/Contexts/Usuarios/UsuariosContext";
import {
  ModalDeleteCore,
  TableRowCore,
  TableCore,
  TdCore,
} from "src/Pages/components";
import { UseVerifyIsPermissionsUser } from "src/Pages/components/hooks/UseVerifyIsPermissionsUser";
import { DeleteForeverIcon, EditIcon } from "src/Pages/components/Icons/Icons";
import { UseDelete } from "src/Pages/components/ModalDeleteCore/Hooks/UseDelete";
import { ActionPopoverTable } from "src/Pages/components/table/ActionPopover/ActionPopover";
import { valuesPermissions } from "src/shared/Utils/ValuesPermissions";

import { ModalPermissions } from "../FormPermissions";

import { useHttpTablePermissions } from "./Hooks/useHttpTablePermissions";
import { THeadPermissions } from "./THead";

export const TablePerfilAcesso = () => {
  const {
    setNameSearch,
    itensPerPage,
    currentPage,
    nameSearch,
    attTable,
    setId,
  } = useConfigPageContext();

  const { handleVerifyPermissionItem } = UseVerifyIsPermissionsUser();
  const { listPerfilAcesso } = useContextUsuarios();

  const { handleGet } = useHttpTablePermissions();

  const [openModalProfileAccess, setOpenModalProfileAccess] = useState(false);

  const handleGetIdToEdit = (id: string) => {
    setOpenModalProfileAccess(true);
    setId(id);
  };

  const {
    handleDelete,
    setId: setIdModal,
    open: openModalDelete,
    loading: loadingDelete,
    setOpen: setOpenModalDelete,
  } = UseDelete({ url: "/deletePerfilAcesso" });

  useEffect(() => {
    handleGet({ itensPerPage, currentPage, nameSearch });
  }, [attTable, itensPerPage, currentPage, nameSearch]);

  return (
    <TableCore
      Modals={
        <ModalDeleteCore
          loading={loadingDelete}
          modalOpen={openModalDelete}
          no="Não, Cancelar"
          titlePrimary="Você deseja excluir da lista ?"
          yes="Sim, Deletar !"
          onClickFalse={() => setOpenModalDelete(false)}
          onClickTrue={() => handleDelete()}
        />
      }
      THead={<THeadPermissions />}
      cardAdd={{
        title: "Permissões",
        icon: <AddModeratorIcon />,
        buttonAdd: [
          <ModalPermissions
            key={1}
            openModal={openModalProfileAccess}
            setOpenModal={setOpenModalProfileAccess}
          />,
        ],
      }}
      qtdList={listPerfilAcesso.length}
      setNameSearch={setNameSearch}
    >
      {Children.toArray(
        listPerfilAcesso.map((item) => {
          return (
            <TableRowCore>
              <TdCore
                values={
                  <ActionPopoverTable
                    optionsList={[
                      {
                        title: "Editar",
                        icon: <EditIcon fontSize="small" />,
                        background: "warning",
                        isDisabled: handleVerifyPermissionItem(
                          valuesPermissions.PERMISSIONS.EDITAR.id
                        ),
                        onClick: () => {
                          handleGetIdToEdit(item.id_permissao);
                        },
                      },
                      {
                        title: "Excluir",
                        background: "error",
                        icon: <DeleteForeverIcon fontSize="small" />,
                        isDisabled: handleVerifyPermissionItem(
                          valuesPermissions.PERMISSIONS.DELETAR.id
                        ),
                        onClick: () => {
                          setIdModal(item.id_permissao);
                          setOpenModalDelete(true);
                        },
                      },
                    ]}
                  />
                }
              />

              <TdCore values={`${item.id_permissao} - ${item.perfil_acesso}`} />
            </TableRowCore>
          );
        })
      )}
    </TableCore>
  );
};
