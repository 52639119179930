import { useContextViews } from "src/Contexts/Views/contextViews";
import { ButtonNavigationGroup } from "src/Pages/components";

import { TableControle } from "./Components/Table/TableControle";

export const ControleEmpresa = () => {
  const { NavigationControleEmpresa } = useContextViews();

  return (
    <>
      <ButtonNavigationGroup data={NavigationControleEmpresa} />

      <TableControle />
    </>
  );
};
