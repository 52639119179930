import { Children, useEffect } from "react";
import { Box, LinearProgress } from "@mui/material";
import { PDFViewer, Document, View } from "@react-pdf/renderer";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextReportsProduction } from "src/Contexts/ReportProduction/ReportsProductionContextContext";
import { PDFCabeçalho } from "src/Pages/components/PDF/Cabeçalho";
import { ItemMapToInfoSearch } from "src/Pages/components/PDF/ItemMapToInfoSearch";
import { NumberPages } from "src/Pages/components/PDF/NumberPages";
import { PageCorePDF } from "src/Pages/components/PDF/PageCorePDF";
import { TableBase } from "src/Pages/components/PDF/TableBase";
import { PDFTableRow } from "src/Pages/components/PDF/TableRow";
import { TableRowContainer } from "src/Pages/components/PDF/TableRowContainer";
import { handleFormatDateWithHours } from "src/shared/Utils/FormatDate/FormatDateToCodeProcucer";
import { FormatDateBR, GetDateUSSemValue } from "src/shared/Utils/FormatDateBR";
import { FormatMoney } from "src/shared/Utils/FormatMoney";
import { HandleGetHoursCurrency } from "src/shared/Utils/HandleGetHoursCurrency";

import { HeadReportProductionProducer } from "./HeadReportProductionProducerPDF";

export const ReportProductionContractPDF = () => {
  const { userPerfil } = useAuthContext();
  const { loadingGravar } = useConfigPageContext();
  const { listReportProductionProducer, setListReportProductionProducer } =
    useContextReportsProduction();

  const data = listReportProductionProducer?.data;
  const meta = listReportProductionProducer?.meta;
  const resume = listReportProductionProducer?.resume;

  useEffect(() => setListReportProductionProducer(undefined), []);

  const tiposDate = {
    VIGENCIA: "Vigência",
    ASSINATURA: "Assinatura",
    EMISSAO: "Emissão",
  };

  return (
    <>
      {userPerfil && data?.length && resume && meta && (
        <PDFViewer style={{ height: "84vh" }} width="100%">
          <Document
            creator="Brokeris"
            title={
              meta?.typeReport === "ALL"
                ? "Relatório de produção por corretor"
                : `${data[0].produtor.name}-${handleFormatDateWithHours()}`
            }
          >
            <PageCorePDF
              key={resume.totalVidas}
              orientation="portrait"
              padding="16px 8px 30px 8px"
            >
              {meta?.dataPayments.startDate && meta?.dataPayments.endDate && (
                <PDFCabeçalho
                  dateCurrency={FormatDateBR(GetDateUSSemValue())}
                  empresa=""
                  endDate={FormatDateBR(meta.dataPayments.endDate)}
                  hoursCurrency={HandleGetHoursCurrency()}
                  nomePdf="Relatório de produção por corretor"
                  startDate={FormatDateBR(meta.dataPayments.startDate)}
                />
              )}

              <View
                key={resume.totalVidas}
                fixed
                style={{
                  borderBottom: "1px solid black",
                  marginBottom: "14px",
                }}
              >
                <ItemMapToInfoSearch
                  itens={
                    meta?.typeReport === "UNIQUE" ? [data[0].produtor.name] : []
                  }
                  title="Produtor"
                />
                <ItemMapToInfoSearch
                  itens={[tiposDate[meta.dataPayments.typeDate]]}
                  title="Tipo de data"
                />
                <ItemMapToInfoSearch itens={meta?.filiais} title="Filiais" />
                <ItemMapToInfoSearch
                  itens={meta?.operadoras}
                  title="Operadoras"
                />
                <ItemMapToInfoSearch
                  itens={meta?.modalidades}
                  title="Modalidades"
                />
                <ItemMapToInfoSearch
                  itens={meta?.administradoras}
                  title="Administradoras"
                />
              </View>

              <TableBase key={resume.totalVidas} margin="0">
                <HeadReportProductionProducer />

                {Children.toArray(
                  data.map(({ produtor }) => {
                    return (
                      <TableRowContainer key={produtor.id}>
                        <PDFTableRow
                          paddingLeft="8px"
                          title={`${produtor.id} - ${produtor.name}`}
                          width="30%"
                        />
                        <PDFTableRow
                          textAlign="right"
                          title={FormatMoney(produtor.resume.totalMensalidades)}
                          width="30%"
                        />
                        <PDFTableRow
                          textAlign="center"
                          title={produtor.resume.totalQtdPropostas}
                          width="20%"
                        />
                        <PDFTableRow
                          textAlign="center"
                          title={produtor.resume.totalQtdVidas}
                          width="20%"
                        />
                      </TableRowContainer>
                    );
                  })
                )}

                <TableRowContainer backgroundColor="#eeeeee">
                  <PDFTableRow
                    fontSize={11}
                    paddingLeft="8px"
                    title={`Total - ${resume.totalCorretores} Corretor${
                      resume.totalCorretores > 1 ? "es" : ""
                    }`}
                    width="30%"
                  />

                  <PDFTableRow
                    fontSize={11}
                    textAlign="right"
                    title={FormatMoney(resume.totalMensalidades.toFixed(2))}
                    width="30%"
                  />
                  <PDFTableRow
                    fontSize={11}
                    textAlign="center"
                    title={resume.totalPropostas}
                    width="20%"
                  />
                  <PDFTableRow
                    fontSize={11}
                    textAlign="center"
                    title={resume.totalVidas}
                    width="20%"
                  />
                </TableRowContainer>
              </TableBase>

              <NumberPages />
            </PageCorePDF>
          </Document>
        </PDFViewer>
      )}

      {loadingGravar && (
        <Box style={{ width: "100%" }}>
          <LinearProgress color="inherit" />
        </Box>
      )}
    </>
  );
};
