import { SetStateAction, Dispatch } from "react";
import { Box, CardMedia, Typography } from "@mui/material";
import { ModalCore } from "src/Pages/components";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";
import { toAbsoluteUrl } from "src/shared/setup/toAbsoluteUrl";

interface IModalParceria {
  empresa: string;
  valueDiscount: string;
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
}
export const ModalParceria = ({
  valueDiscount,
  empresa,
  setOpen,
  open,
}: IModalParceria) => {
  return (
    <ModalCore
      open={open}
      setOpen={setOpen}
      subTitle={`Parceiros através da ${empresa} você garante desconto`}
      sxStyle={{ width: "600px" }}
    >
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: "36px" }}>
        <CardMedia
          alt="Imagem de boas vindas"
          component="img"
          image={toAbsoluteUrl("/media/logo-zunzunzum.png")}
          sx={{ width: { xs: "55px", sm: "85px" }, marginRight: "16px" }}
        />
        <Typography
          sx={{
            fontSize: { xs: ".8rem", md: ".9rem", lg: "1rem" },
          }}
        >
          Seja muito bem-vindo ao Brokeris! Estamos muito felizes em recebê-lo
          como cliente através da nossa parceria com a{" "}
          <strong>{empresa}</strong>.
        </Typography>
      </Box>

      <Typography
        mb="36px"
        sx={{
          fontSize: { xs: ".8rem", md: ".9rem", lg: "1rem" },
        }}
      >
        Como parte da nossa parceria, oferecemos{" "}
        <strong>descontos exclusivos de {valueDiscount} </strong> em nossos
        planos de assinatura para que você organizar sua empresa com o melhor
        custo.
      </Typography>

      <Box sx={{ textAlign: "center" }}>
        <ButtonCore
          sx={{ width: "200px" }}
          title="Garantir desconto"
          onClick={() => setOpen(false)}
        />
      </Box>
    </ModalCore>
  );
};
