import { Dispatch, SetStateAction, useState } from "react";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

import { IValueComissaoModal } from "../Index";

interface IUseGetChangeCompany {
  id: string;
  setDataCommission: Dispatch<SetStateAction<IValueComissaoModal | undefined>>;
}

export const UseGetComissaoCorretora = () => {
  const { handleGetAlert } = useLayoutMainContext();

  const [loading, setLoading] = useState(false);

  const handleGetChangeCompany = async ({
    id,
    setDataCommission,
  }: IUseGetChangeCompany) => {
    setLoading(true);

    await api
      .get(`/comissoes/corretoraViewModal/${id}`)
      .then((res) => {
        setDataCommission(res.data);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    loading,
    handleGet: handleGetChangeCompany,
  };
};
