import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { useContextProdutor } from "src/Contexts/produtores/ProdutoresContext";
import { api } from "src/shared/setup/API/api";

interface IHandleGet {
  currentPage?: number;
  itensPerPage?: number;
  nameSearch?: string;
  codeProducer?: string;
  startData?: string;
  endDate?: string;
  isReceipt?: "TRUE" | "FALSE" | "ALL";
}

export const useHttpContaCorrente = () => {
  const {
    setQtd_registros,
    setLoadingTable,
    itensPerPage,
    currentPage,
    nameSearch,
  } = useConfigPageContext();

  const { handleGetAlert } = useLayoutMainContext();
  const { setListContaCorrente } = useContextProdutor();

  const handleGet = async (body: IHandleGet) => {
    setLoadingTable(true);

    api
      .post("/getContaCorrente", {
        ...body,
        limit: itensPerPage,
        filter: nameSearch,
        page: currentPage,
      })
      .then((res) => {
        setListContaCorrente(res.data.data);
        setQtd_registros(res.data.count);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoadingTable(false));
  };

  return { handleGet };
};
