import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { useContextProdutor } from "src/Contexts/produtores/ProdutoresContext";
import { api } from "src/shared/setup/API/api";
import { handleFormatToNumeric } from "src/shared/Utils/FormatMoney";

interface IUseFormImpostoGlobal {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UseFormImpostoGlobal = ({ setOpen }: IUseFormImpostoGlobal) => {
  const { setLoadingGravar } = useConfigPageContext();
  const { valuesInputsImpostoGlobal } = useContextProdutor();
  const { handleGetAlert } = useLayoutMainContext();

  const AllDatas = {
    descricao: valuesInputsImpostoGlobal.descricao,
    valor: handleFormatToNumeric(valuesInputsImpostoGlobal?.valor),
  };

  const handleEditImpostoGlobal = async () => {
    if (!valuesInputsImpostoGlobal.descricao)
      return handleGetAlert({ message: `Digite uma descrição` });
    if (!valuesInputsImpostoGlobal.valor)
      return handleGetAlert({ message: `Digite um valor` });

    setLoadingGravar(true);

    return api
      .put(`/impostoGlobal`, AllDatas)
      .then((res) => {
        handleGetAlert({ message: res.data.message });
        setOpen(false);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoadingGravar(false));
  };

  const handleSubmit = () => handleEditImpostoGlobal();

  return { handleSubmit };
};
