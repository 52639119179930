import { CardCore } from "src/Pages/components/CardCore/CardCore";
import { CardBodyCore } from "src/Pages/components/CardCore/Components/CardBodyCore";

import { FormProfilePassword } from "./Form/FormProfilePassword";

export const CardProfilePassword = () => {
  return (
    <CardCore sxStyle={{ margin: 0 }}>
      <CardBodyCore title="Trocar senha">
        <FormProfilePassword />
      </CardBodyCore>
    </CardCore>
  );
};
