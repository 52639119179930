import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

interface IIsFirstAccessUser {
  idTenant?: string;
}

export const UseModalFirstLogin = () => {
  const { handleGetAlert } = useLayoutMainContext();

  const handleIsFirstAccess = async ({ idTenant }: IIsFirstAccessUser) => {
    return api
      .patch(`/usuarios/isFirst/${idTenant}`)
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      );
  };

  return { handleIsFirstAccess };
};
