import { Dispatch, SetStateAction } from "react";
import styled from "@emotion/styled";
import { Dialog, Grid, Stack, Typography } from "@mui/material";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { IValueComissaoCorretoraGrade } from "src/Contexts/Lancamentos/Contrato/ContratosTypes";
import { ListComissaoGrade } from "src/Pages/components/ListComissaoGrade/ListComissaoGrade";
import { FormatDateBR } from "src/shared/Utils/FormatDateBR";
import { valuesPermissions } from "src/shared/Utils/ValuesPermissions";
import { UseVerifyIsPermissionsUser } from "src/Pages/components/hooks/UseVerifyIsPermissionsUser";

interface ICardPercentsData {
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
}

interface ITitleCard {
  gradeCorretora: IValueComissaoCorretoraGrade;
  title: string;
}

const BootstrapDialog = styled(Dialog)(() => ({
  "& .css-tlc64q-MuiPaper-root-MuiDialog-paper": {
    borderRadius: "8px",
  },
}));

export const TitleCard = ({ gradeCorretora, title }: ITitleCard) => {
  const { code, name, date_vigencia } = gradeCorretora;

  return (
    <Grid
      item
      md={6}
      sx={{
        padding: "8px 12px",
        border: "1px solid gray",
        borderRadius: "8px",
      }}
      xs={12}
    >
      <Typography
        sx={{
          borderBottom: `2px solid "#0e6aff"`,
          textAlign: "center",
          borderRadius: "16px",
          marginBottom: "8px",
          color: "#3b3b3b",
        }}
      >
        {title}
      </Typography>
      <Typography>{`${code} - ${name}`}</Typography>
      <Typography> Vigência: {`${FormatDateBR(date_vigencia)}`}</Typography>
    </Grid>
  );
};

export const CardPercentsData = ({ open, setOpen }: ICardPercentsData) => {
  const { valueComissaoCorretoraGrade, valueComissaoProdutorGrade } =
    useContextContrato();
  const { handleVerifyPermissionItem } = UseVerifyIsPermissionsUser();

  return (
    <BootstrapDialog
      fullWidth
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      open={open}
      onClose={() => setOpen(false)}
    >
      <Grid container sx={{ padding: "8px" }}>
        {valueComissaoCorretoraGrade && (
          <TitleCard
            gradeCorretora={valueComissaoCorretoraGrade}
            title="Corretora"
          />
        )}

        {valueComissaoProdutorGrade?.corretor && (
          <TitleCard
            gradeCorretora={valueComissaoProdutorGrade.corretor}
            title="Corretor"
          />
        )}

        {valueComissaoProdutorGrade?.supervisor && (
          <TitleCard
            gradeCorretora={valueComissaoProdutorGrade.supervisor}
            title="Supervisor"
          />
        )}

        {valueComissaoProdutorGrade?.gerente && (
          <TitleCard
            gradeCorretora={valueComissaoProdutorGrade.gerente}
            title="Gerente"
          />
        )}
      </Grid>

      <Stack
        sx={{
          flexDirection: "row",
          maxHeight: "350px",
          overflow: "auto",
          padding: "0 12px 12px 12px",
        }}
      >
        {!handleVerifyPermissionItem(
          valuesPermissions.COMMISSIONS_CORRETORA.VER_TELA.id
        ) && (
          <ListComissaoGrade
            input
            list={valueComissaoCorretoraGrade}
            title="Corretora"
          />
        )}
        <ListComissaoGrade
          list={valueComissaoProdutorGrade?.corretor}
          title="Corretor"
        />
        <ListComissaoGrade
          list={valueComissaoProdutorGrade?.supervisor}
          title="Supervisor"
        />
        <ListComissaoGrade
          list={valueComissaoProdutorGrade?.gerente}
          title="Gerente"
        />
      </Stack>
    </BootstrapDialog>
  );
};
