import { useEffect, useState } from "react";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { Box, Typography } from "@mui/material";
import { CardCore } from "src/Pages/components/CardCore/CardCore";

import { ModalParceria } from "./ModalParceria";

export const listParceiros = [
  {
    id: "e83eed67-7e59-435b-9d1e-ba0c1941b543",
    parceria: "ZunZunZum",
    discount: "10% à 20%",
  },
];

interface IHeaderParceria {
  parceria?: string;
}

export const HeaderParceria = ({ parceria }: IHeaderParceria) => {
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (listParceiros.some((item) => item.parceria === parceria)) {
      setOpenModal(true);
    }
  }, []);

  return (
    <>
      {listParceiros.map(
        (item) =>
          item.parceria === parceria && (
            <>
              <ModalParceria
                empresa={item.parceria}
                open={openModal}
                setOpen={setOpenModal}
                valueDiscount={item.discount}
              />
              <CardCore
                sxStyle={{
                  margin: "0 0 24px 0",
                  alignItems: "center",
                  display: "flex",
                  padding: "16px 8px",
                  background: "white",
                }}
              >
                <LocalOfferIcon
                  sx={{ color: "#5a98fff5", marginRight: "8px" }}
                />
                <Box sx={{ display: "flex" }}>
                  <Typography
                    sx={{
                      fontSize: { xs: ".8rem", md: ".9rem", lg: "1rem" },
                    }}
                  >
                    Parceiros da{" "}
                    <Box component="strong" sx={{ color: "#0e6aff" }}>
                      {item.parceria}
                    </Box>{" "}
                    tem 10% à 20% de desconto !
                  </Typography>
                </Box>
              </CardCore>
            </>
          )
      )}
    </>
  );
};
