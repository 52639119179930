import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { useContextRecibos } from "src/Contexts/Recibos/RecibosContext";
import { useContextReportsProduction } from "src/Contexts/ReportProduction/ReportsProductionContextContext";
import { UseAutoCompletedAll } from "src/Contexts/Utils/UseAutoCompletedAll";
import { api } from "src/shared/setup/API/api";

export const UseFormReceipt = () => {
  const { setListRecibos, valuesInputsRecibos } = useContextRecibos();
  const { setLoadingGravar } = useConfigPageContext();
  const { handleGetAlert } = useLayoutMainContext();

  const { valueAutoCompOperadoras } = useContextReportsProduction();
  const { valueAutoCompletedFiliais, valueAutoCompProdutorActive } =
    UseAutoCompletedAll();

  const objComissao = {
    ...valuesInputsRecibos,

    code_produtor: valueAutoCompProdutorActive.id,
    operadoras: valueAutoCompOperadoras.map((item) => item.id),
    id_filial: valueAutoCompletedFiliais.id,

    movimentar: {
      contaCorrente: valuesInputsRecibos.contaCorrente,
      parcelas: valuesInputsRecibos.parcelas,
      visualizar: valuesInputsRecibos.visualizar,
    },
  };

  const handleCreate = async () => {
    if (!valuesInputsRecibos.start_date)
      return handleGetAlert({ message: `Digite um nome de comissão !` });

    if (!valuesInputsRecibos.end_date)
      return handleGetAlert({ message: `Escolha uma data de vigência !` });

    setLoadingGravar(true);
    setListRecibos(undefined);

    const AllDatas = { ...objComissao };

    return api
      .post("/payments", AllDatas)
      .then((res) => {
        handleGetAlert({ message: res.data.message });
        setListRecibos(res.data);
      })
      .catch((error) => {
        handleGetAlert({ message: error.response.data.message });
      })
      .finally(() => setLoadingGravar(false));
  };

  const handleSubmit = () => handleCreate();

  return { handleSubmit };
};
