import { useState } from "react";
import { CardMedia, Typography } from "@mui/material";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { toAbsoluteUrl } from "src/shared/setup/toAbsoluteUrl";

import { ButtonCore } from "../ButtonCore/ButtonCore";
import { DividerCore } from "../DividerCore/DividerCore";
import { ModalContentCore } from "../ModalCoreBase/Components/ModalContentCore";
import { ModalCoreBase } from "../ModalCoreBase/ModalCoreBase";

import { UseModalWelcome } from "./Hooks/UseModalWelcome";

export const ModalWelcome = () => {
  const { userPerfil } = useAuthContext();
  const { handleIsFirstAccess } = UseModalWelcome();

  const [open, setOpen] = useState<boolean>(
    !!userPerfil?.empresa.is_first_access
  );

  return (
    <ModalCoreBase open={open}>
      <ModalContentCore
        sx={{
          width: { xs: "340px", sm: "500px", md: "700px" },
          maxWidth: "95vw",
          background: "#ffffff",
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: ".9rem", sm: "1.2rem", fontWeight: "500" },
            textAlign: "center",
            marginBottom: "10px",
          }}
        >
          Seja bem-vindo ao BROKERIS 🚀
        </Typography>

        <Typography
          sx={{
            fontSize: { xs: ".9rem", sm: "1.1rem" },
            textAlign: "center",
            marginBottom: "10px",
          }}
        >
          Sua empresa foi criada com sucesso 🎉🎉
        </Typography>

        <DividerCore />

        <Typography
          sx={{
            fontSize: { xs: ".9rem", sm: "1.1rem" },
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          Vamos iniciar o tutorial ?
        </Typography>

        <CardMedia
          alt="Imagem de boas vindas"
          component="img"
          image={toAbsoluteUrl("/media/projectManagement.png")}
          sx={{ width: 151, textAlign: "center", margin: "1rem auto" }}
        />

        <ButtonCore
          sx={{
            display: "flex",
            margin: "0 auto",
            width: { xs: "150px", sm: "200px", md: "280px" },
          }}
          title="Vamos lá "
          onClick={() => {
            setOpen(false);
            handleIsFirstAccess();
          }}
        />
      </ModalContentCore>
    </ModalCoreBase>
  );
};
