import { Text, View } from "@react-pdf/renderer";

interface IItemMapToInfoSearch {
  title: string;
  itens: string[] | undefined;
}
export const ItemMapToInfoSearch = ({ itens, title }: IItemMapToInfoSearch) => {
  return (
    <>
      {itens?.length && itens.length > 0 ? (
        <View key={title} style={{ padding: "4px 0", paddingLeft: "2px" }}>
          <Text style={{ fontSize: "8px" }}>
            {`${title}: ${itens
              .map((item) => `${item}`)
              .join(", ")
              .toString()}`}
          </Text>
        </View>
      ) : null}
    </>
  );
};
