import { Player } from "@lottiefiles/react-lottie-player";

export const PageNotFound = () => {
  return (
    <div
      style={{
        minHeight: `calc(100vh - 9rem)`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Player
        autoplay
        loop
        src="https://assets7.lottiefiles.com/datafiles/OzG1c5GtuAvq10U/data.json"
        style={{
          height: "40%",
          width: "40%",
          minHeight: "250x",
          minWidth: "250x",
        }}
      />
    </div>
  );
};
