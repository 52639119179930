import {
  createContext,
  useContext,
  useState,
  FC,
  useEffect,
  ReactNode,
} from "react";
import { GetDateUSSemValue } from "src/shared/Utils/FormatDateBR";

import { IAutoCompleted } from "../../types/ProdutoresContext.Types";

import {
  IListBordero,
  IListContratos,
  ICreateContrato,
  IContextContrato,
  IGetByIdContratos,
  ICancelarContrato,
  IValuesFilterBordero,
  ICreateBorderoParcela,
  IValuesFilterContrato,
  IValueContractInternal,
  IValuesUploadCommission,
  ICreateNovaParcelaBordero,
  ICreateEditarParcelaBordero,
  IValueComissaoCorretorGrade,
  IValueComissaoCorretoraGrade,
  IValuesTypesUploadCommission,
} from "./ContratosTypes";
import {
  valueDefaultInputBorderoParcelas,
  valuesDefaultUploadCommission,
  valuesDefaultFilterContrato,
  valueDefaultInputContratos,
  valuesDefaultFilterBordero,
} from "./valuesDefaultInputsContratos/ValueDefaultInputContratos";

const ContratosContext = createContext({} as IContextContrato);

export const ContratoContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [listContratos, setListContratos] = useState([] as IListContratos[]);
  const [listBordero, setListBordero] = useState([] as IListBordero[]);

  const [dataGetByIdContrato, setDataGetByIdContrato] =
    useState<IGetByIdContratos>();

  const [valuesInputCancelarContrato, setValuesInputCancelarContrato] =
    useState<ICancelarContrato>({
      date: `${GetDateUSSemValue()}`,
      description: "",
    });

  const [valuesInputEditarParcelaBordero, setValuesInputEditarParcelaBordero] =
    useState<ICreateEditarParcelaBordero>();

  const [valuesInputNovaParcelaBordero, setValuesInputNovaParcelaBordero] =
    useState<ICreateNovaParcelaBordero>();

  const [valueAutoCompProdutorAll, setValueAutoCompProdutorAll] =
    useState<IAutoCompleted>({ label: "", id: "" });

  const [valueComissaoCorretoraGrade, setValueComissaoCorretoraGrade] =
    useState<IValueComissaoCorretoraGrade>();

  const [valueComissaoProdutorGrade, setValueComissaoProdutorGrade] =
    useState<IValueComissaoCorretorGrade>();

  const [valueContractInternal, setValueContractInternal] =
    useState<IValueContractInternal>();

  const [valuesInputsContrato, setValuesInputsContrato] =
    useState<ICreateContrato>({ ...valueDefaultInputContratos });

  const [valuesFilterContrato, setValuesFilterContrato] =
    useState<IValuesFilterContrato>(valuesDefaultFilterContrato);

  const [valuesFilterBordero, setValuesFilterBordero] =
    useState<IValuesFilterBordero>(valuesDefaultFilterBordero);

  const [valuesUploadCommission, setValuesUploadCommission] =
    useState<IValuesUploadCommission>(valuesDefaultUploadCommission);

  const [valuesTypesUploadCommission, setValuesTypesUploadCommission] =
    useState<IValuesTypesUploadCommission[]>([]);

  const [valuesInputsBorderoParcela, setValuesInputsBorderoParcela] =
    useState<ICreateBorderoParcela>({ ...valueDefaultInputBorderoParcelas });

  useEffect(() => {
    setValuesFilterContrato((prevValue) => ({
      ...prevValue,
      endDateSignature: valuesFilterContrato.startDateSignature,
    }));
  }, [valuesFilterContrato.startDateSignature]);

  useEffect(() => {
    setValuesFilterContrato((prevValue) => ({
      ...prevValue,
      endEffectiveDate: valuesFilterContrato.startEffectiveDate,
    }));
  }, [valuesFilterContrato.startEffectiveDate]);

  useEffect(() => {
    setValuesFilterContrato((prevValue) => ({
      ...prevValue,
      endDateEmission: valuesFilterContrato.startDateEmission,
    }));
  }, [valuesFilterContrato.startDateEmission]);

  useEffect(() => {
    setValuesFilterBordero({
      ...valuesFilterBordero,
      endDateSignature: valuesFilterBordero.startDateSignature,
    });
  }, [valuesFilterBordero.startDateSignature]);

  useEffect(() => {
    setValuesFilterBordero({
      ...valuesFilterBordero,
      endDateReceipt: valuesFilterBordero.startDateReceipt,
    });
  }, [valuesFilterBordero.startDateReceipt]);

  useEffect(() => {
    setValuesFilterBordero({
      ...valuesFilterBordero,
      endDueDate: valuesFilterBordero.startDueDate,
    });
  }, [valuesFilterBordero.startDueDate]);

  useEffect(() => {
    setValuesFilterBordero({
      ...valuesFilterBordero,
      endDateEmission: valuesFilterBordero.startDateEmission,
    });
  }, [valuesFilterBordero.startDateEmission]);

  return (
    <ContratosContext.Provider
      value={{
        setValuesInputEditarParcelaBordero,
        setValuesInputNovaParcelaBordero,
        valuesInputEditarParcelaBordero,
        setValueComissaoCorretoraGrade,
        setValuesInputCancelarContrato,
        setValueComissaoProdutorGrade,
        setValuesInputsBorderoParcela,
        setValuesTypesUploadCommission,
        valuesInputNovaParcelaBordero,
        valuesInputCancelarContrato,
        valueComissaoCorretoraGrade,
        setValueAutoCompProdutorAll,
        valuesTypesUploadCommission,
        valueComissaoProdutorGrade,
        valuesInputsBorderoParcela,
        setValuesUploadCommission,
        valueAutoCompProdutorAll,
        setValueContractInternal,
        setValuesInputsContrato,
        setValuesFilterContrato,
        setDataGetByIdContrato,
        setValuesFilterBordero,
        valuesUploadCommission,
        valueContractInternal,
        valuesInputsContrato,
        valuesFilterContrato,
        valuesFilterBordero,
        dataGetByIdContrato,
        setListContratos,
        setListBordero,
        listContratos,
        listBordero,
      }}
    >
      {children}
    </ContratosContext.Provider>
  );
};

export const useContextContrato = (): IContextContrato =>
  useContext(ContratosContext);
