import { alpha } from "@mui/material";
import {
  ResponsiveContainer,
  CartesianGrid,
  AreaChart,
  Tooltip,
  XAxis,
  YAxis,
  Area,
} from "recharts";
import { useContextDashboardBrokerContext } from "src/Contexts/Dashboard/ContextDashboardBroker";
import { CardCore } from "src/Pages/components/CardCore/CardCore";
import { CardBodyCore } from "src/Pages/components/CardCore/Components/CardBodyCore";
import { FormatMoney } from "src/shared/Utils/FormatMoney";

import { FormMonthByMonthProfitChart } from "./Form";

export const MonthByMonthProfitChart = () => {
  const { dataProfitableValues } = useContextDashboardBrokerContext();

  const colorStroke = "#b8b8b8";

  const gradientOffset = () => {
    const dataMax = Math.max(...dataProfitableValues.data.map((i) => i.value));
    const dataMin = Math.min(...dataProfitableValues.data.map((i) => i.value));

    if (dataMax <= 0) {
      return 0;
    }
    if (dataMin >= 0) {
      return 1;
    }

    return dataMax / (dataMax - dataMin);
  };

  const off = gradientOffset();

  return (
    <CardCore sxStyle={{ margin: "1rem 0", marginBottom: "24px" }}>
      <CardBodyCore
        actions={[<FormMonthByMonthProfitChart key={1} />]}
        title="Lucro de parcelas (comparação)"
      >
        <ResponsiveContainer height={350} width="100%">
          <AreaChart data={dataProfitableValues.data}>
            <defs>
              <linearGradient id="colorPv" x1="0" x2="0" y1="0" y2="1">
                <stop offset={off} stopColor="blue" stopOpacity={0.15} />
                <stop offset={off} stopColor="red" stopOpacity={0.15} />
              </linearGradient>
            </defs>

            <XAxis dataKey="label" fontSize={14} stroke={colorStroke} />
            <YAxis
              fontSize={12}
              stroke={colorStroke}
              tickFormatter={(value) => FormatMoney(String(value.toFixed(2)))}
              tickLine={false}
            />

            <CartesianGrid
              horizontal
              stroke={alpha(colorStroke, 0.7)}
              vertical={false}
            />

            <Tooltip
              formatter={(value, name) => {
                return [
                  `${name}: R$ ${
                    Number(value) < 0
                      ? `- ${FormatMoney(String(Number(value).toFixed(2)))}`
                      : `${FormatMoney(String(Number(value).toFixed(2)))}`
                  }`,
                ];
              }}
              labelStyle={{ color: colorStroke }}
            />

            <Area
              dot
              baseValue={0}
              dataKey="value"
              fill="url(#colorPv)"
              fillOpacity={1}
              name="Lucro"
              stroke="rgb(61, 90, 254)"
              strokeWidth={3}
              type="bump"
            />
          </AreaChart>
        </ResponsiveContainer>
      </CardBodyCore>
    </CardCore>
  );
};
