import { createRef, ChangeEvent } from "react";
import { Box, InputAdornment } from "@mui/material";
import { useContextComissoes } from "src/Contexts/comissoes/ComissoesContext";
import { ICreateComissaoCorretora } from "src/Contexts/comissoes/ComissoesTypes";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { ButtonsFeaturesFormularioCore, FormCore } from "src/Pages/components";
import { AppTextField } from "src/Pages/components/AppFormComponents/AppTextField";
import { UseVerifyIsPermissionsUser } from "src/Pages/components/hooks/UseVerifyIsPermissionsUser";
import { valuesPermissions } from "src/shared/Utils/ValuesPermissions";

import { AutoCompleteAdministradoras } from "../../../../../components/AutoCompleted/AutoCompleteAdministradoras";
import { AutoCompleteModalidade } from "../../../../../components/AutoCompleted/AutoCompleteModalidade";
import { AutoCompleteOperadora } from "../../../../../components/AutoCompleted/AutoCompleteOperadora";

import { UseChangeNameComissaoCorretora } from "./hook/UseChangeNameComissaoCorretora";
import { UseFeaturesFormulario } from "./hook/UseFeaturesFormulario";

export const FormComissaoCorretora = () => {
  const { handleVerifyPermissionItem } = UseVerifyIsPermissionsUser();
  const { active_incluir } = useConfigPageContext();
  const { handleSubmit } = UseFeaturesFormulario();

  UseChangeNameComissaoCorretora();

  const { valuesInputsComissaoCorretora, setValuesInputsComissaoCorretora } =
    useContextComissoes();

  const refOrigem = createRef<HTMLInputElement>();
  const handleFocusedID = () => refOrigem.current?.focus();

  const handleChange =
    (prop: keyof ICreateComissaoCorretora, option?: "limitNumber") =>
    (event: ChangeEvent<HTMLInputElement>) => {
      if (option === "limitNumber") {
        if (event.target.value.length <= 6) {
          setValuesInputsComissaoCorretora({
            ...valuesInputsComissaoCorretora,
            [prop]: event.target.value.replace(",", ""),
          });
        }
      } else {
        setValuesInputsComissaoCorretora({
          ...valuesInputsComissaoCorretora,
          [prop]: event.target.value,
        });
      }
    };

  return (
    <FormCore>
      <ButtonsFeaturesFormularioCore
        disabledEditar={handleVerifyPermissionItem(
          valuesPermissions.COMMISSIONS_CORRETORA.EDITAR.id
        )}
        disabledIncluir={handleVerifyPermissionItem(
          valuesPermissions.COMMISSIONS_CORRETORA.CREATE.id
        )}
        handleFocusedID={handleFocusedID}
        handleSubmit={handleSubmit}
      />
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <AppTextField
          disabled
          inputRef={refOrigem}
          label="Nome comissão"
          sxStyle={{ width: "250px" }}
          value={valuesInputsComissaoCorretora?.name}
          onChange={handleChange("name")}
        />

        <AutoCompleteOperadora
          disabled={!active_incluir}
          label="Operadora *"
          tamanho="180px"
        />

        <AutoCompleteAdministradoras
          disabled={!active_incluir}
          label="Administradora"
          tamanho="180px"
        />

        <AutoCompleteModalidade
          disabled={!active_incluir}
          label="Modalidade *"
          tamanho="180px"
        />

        <AppTextField
          label="Vigência *"
          sxStyle={{ width: "120px" }}
          type="date"
          value={valuesInputsComissaoCorretora?.date_vigencia}
          onChange={handleChange("date_vigencia")}
        />

        <AppTextField
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          label="1Parc."
          sxStyle={{ width: "70px" }}
          type="text"
          value={valuesInputsComissaoCorretora?.parc_1}
          onChange={handleChange("parc_1", "limitNumber")}
        />
        <AppTextField
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          label="2Parc."
          sxStyle={{ width: "70px" }}
          type="text"
          value={valuesInputsComissaoCorretora?.parc_2}
          onChange={handleChange("parc_2", "limitNumber")}
        />
        <AppTextField
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          label="3Parc."
          sxStyle={{ width: "70px" }}
          type="text"
          value={valuesInputsComissaoCorretora?.parc_3}
          onChange={handleChange("parc_3", "limitNumber")}
        />
        <AppTextField
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          label="4Parc."
          sxStyle={{ width: "70px" }}
          type="text"
          value={valuesInputsComissaoCorretora?.parc_4}
          onChange={handleChange("parc_4", "limitNumber")}
        />
        <AppTextField
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          label="5Parc."
          sxStyle={{ width: "70px" }}
          type="text"
          value={valuesInputsComissaoCorretora?.parc_5}
          onChange={handleChange("parc_5", "limitNumber")}
        />
        <AppTextField
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          label="6Parc."
          sxStyle={{ width: "70px" }}
          type="text"
          value={valuesInputsComissaoCorretora?.parc_6}
          onChange={handleChange("parc_6", "limitNumber")}
        />
        <AppTextField
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          label="7Parc."
          sxStyle={{ width: "70px" }}
          type="text"
          value={valuesInputsComissaoCorretora?.parc_7}
          onChange={handleChange("parc_7", "limitNumber")}
        />
        <AppTextField
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          label="8Parc."
          sxStyle={{ width: "70px" }}
          type="text"
          value={valuesInputsComissaoCorretora?.parc_8}
          onChange={handleChange("parc_8", "limitNumber")}
        />
        <AppTextField
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          label="9Parc."
          sxStyle={{ width: "70px" }}
          type="text"
          value={valuesInputsComissaoCorretora?.parc_9}
          onChange={handleChange("parc_9", "limitNumber")}
        />
        <AppTextField
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          label="10Parc."
          sxStyle={{ width: "70px" }}
          type="text"
          value={valuesInputsComissaoCorretora?.parc_10}
          onChange={handleChange("parc_10", "limitNumber")}
        />
        <AppTextField
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          label="11Parc."
          sxStyle={{ width: "70px" }}
          type="text"
          value={valuesInputsComissaoCorretora?.parc_11}
          onChange={handleChange("parc_11", "limitNumber")}
        />
        <AppTextField
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          label="12Parc."
          sxStyle={{ width: "70px" }}
          type="text"
          value={valuesInputsComissaoCorretora?.parc_12}
          onChange={handleChange("parc_12", "limitNumber")}
        />

        <AppTextField
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          label="13Parc."
          sxStyle={{ width: "70px" }}
          type="text"
          value={valuesInputsComissaoCorretora?.parc_13}
          onChange={handleChange("parc_13", "limitNumber")}
        />
        <AppTextField
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          label="14Parc."
          sxStyle={{ width: "70px" }}
          type="text"
          value={valuesInputsComissaoCorretora?.parc_14}
          onChange={handleChange("parc_14", "limitNumber")}
        />
        <AppTextField
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          label="15Parc."
          sxStyle={{ width: "70px" }}
          type="text"
          value={valuesInputsComissaoCorretora?.parc_15}
          onChange={handleChange("parc_15", "limitNumber")}
        />
        <AppTextField
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          label="16Parc."
          sxStyle={{ width: "70px" }}
          type="text"
          value={valuesInputsComissaoCorretora?.parc_16}
          onChange={handleChange("parc_16", "limitNumber")}
        />
      </Box>
    </FormCore>
  );
};
