import { Settings } from "@mui/icons-material";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { CardHeaderCore } from "src/Pages/components/CardCore/Components/CardHeaderCore";

export const CardHeaderProfile = () => {
  const { userPerfil } = useAuthContext();

  return (
    <CardHeaderCore
      icon={<Settings color="info" sx={{ marginRight: "4px" }} />}
      sxStyle={{
        padding: { xs: "12px", sm: "20px" },
        margin: 0,
        marginBottom: "16px",
      }}
      title={`Olá, ${userPerfil?.nome}. Aqui você pode configurar seus dados pessoais.`}
    />
  );
};
