import React, { ChangeEvent, useEffect, useState } from "react";
import { Box, Button, Checkbox, FormControlLabel } from "@mui/material";
import { DividerCore } from "src/Pages/components/DividerCore/DividerCore";
import { SendIcon } from "src/Pages/components/Icons/Icons";

import { UseFormFormChangeFunctionProducer } from "../UseFeaturesFormulario";

interface IFormChangeIsActive {
  isActive: boolean;
}

export const FormChangeIsActiveAccess = ({ isActive }: IFormChangeIsActive) => {
  const { handleChangeIsActiveProducer, loading } =
    UseFormFormChangeFunctionProducer();

  const [newIsActive, setNewIsActive] = useState<boolean>(false);

  useEffect(() => {
    setNewIsActive(isActive);
  }, [isActive]);

  const handleChangeCheck = (event: ChangeEvent<HTMLInputElement>) => {
    setNewIsActive(event.target.checked);
  };

  return (
    <>
      <DividerCore
        sxStyle={{ margin: "12px 0" }}
        title="Ativação acesso online"
      />

      <Box
        sx={{
          borderBottom: "#3b3b3b",
          display: "flex",
          textAlign: "center",
          alignItems: "center",
          paddingBottom: "1rem",
          justifyContent: "space-between",
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={newIsActive}
              name="is_active"
              sx={{
                width: "30px",
                height: "30px",
                "& .MuiSvgIcon-root": { fontSize: 28 },
              }}
              onChange={handleChangeCheck}
            />
          }
          label="Ativo ?"
          labelPlacement="top"
        />

        <Button
          disabled={loading}
          endIcon={<SendIcon />}
          variant="outlined"
          onClick={() => handleChangeIsActiveProducer(newIsActive)}
        >
          Salvar
        </Button>
      </Box>
    </>
  );
};
