import { ChangeEvent, useEffect } from "react";
import { formatString } from "@format-string/core";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useContextCompany } from "src/Contexts/Company/CompanyContext";
import { IUpdateCompanyDetails } from "src/Contexts/Company/CompanyContextTypes";
import { AppTextField, FormCore } from "src/Pages/components";
import { CardCore } from "src/Pages/components/CardCore/CardCore";
import { CardBodyCore } from "src/Pages/components/CardCore/Components/CardBodyCore";
import { UseVerifyIsPermissionsUser } from "src/Pages/components/hooks/UseVerifyIsPermissionsUser";
import { handleFormatCep } from "src/shared/Utils/FormatCEP";
import { unidadesFederativas } from "src/shared/Utils/ListUFBR";
import { valuesPermissions } from "src/shared/Utils/ValuesPermissions";

import { FormButtonsSubmitCompany } from "./ButtonsFeaturesFormulario";
import { UseGetDataCompany } from "./hook/UseGetByIdData";
import { UseGetCEPCompany } from "./hook/UseGetCEPCompany";

export const FormCompanyDetails = () => {
  const { handleGetCompanyDetails } = UseGetDataCompany();
  const { handleVerifyPermissionItem } = UseVerifyIsPermissionsUser();

  const { setValuesInputsCompanyDetails, valuesInputsCompanyDetails } =
    useContextCompany();

  UseGetCEPCompany();

  useEffect(() => {
    handleGetCompanyDetails();
  }, []);

  const handleChange =
    (prop: keyof IUpdateCompanyDetails, option?: "phone" | "CEP" | "CNPJ") =>
    (event: ChangeEvent<HTMLInputElement>) => {
      if (option === "phone") {
        setValuesInputsCompanyDetails((prevEvent) => ({
          ...prevEvent,
          [prop]: formatString({ value: event.target.value, type: "phone" }),
        }));
      } else if (option === "CEP") {
        setValuesInputsCompanyDetails((prevEvent) => ({
          ...prevEvent,
          [prop]: handleFormatCep(event.target.value),
        }));
      } else if (option === "CNPJ") {
        setValuesInputsCompanyDetails((prevEvent) => ({
          ...prevEvent,
          [prop]: formatString({ value: event.target.value, type: "cnpj" }),
        }));
      } else
        setValuesInputsCompanyDetails((prevEvent) => ({
          ...prevEvent,
          [`${prop}`]: event.target.value,
        }));
    };

  const isDisabled = handleVerifyPermissionItem(
    valuesPermissions.COMPANY.EDITAR_DETALHES.id
  );

  return (
    <FormCore sxStyle={{ margin: 0 }}>
      <CardCore sxStyle={{ margin: 0, marginBottom: "16px" }}>
        <CardBodyCore title="Dados da empresa">
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              "& > *": { marginBottom: "1.2rem" },
            }}
          >
            <AppTextField
              disabled={isDisabled}
              label="Nome Fantasia"
              sxStyle={{ width: "180px", opacity: "", pointerEvents: "" }}
              value={valuesInputsCompanyDetails?.fantasyName}
              onChange={handleChange("fantasyName")}
            />

            <AppTextField
              disabled={isDisabled}
              label="CNPJ"
              sxStyle={{ width: "200px", opacity: "", pointerEvents: "" }}
              value={valuesInputsCompanyDetails?.cnpj}
              onChange={handleChange("cnpj", "CNPJ")}
            />
          </Box>
        </CardBodyCore>
      </CardCore>

      <CardCore sxStyle={{ margin: 0, marginBottom: "16px" }}>
        <CardBodyCore title="Endereço da empresa">
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              "& > *": { marginBottom: "1.3rem" },
            }}
          >
            <AppTextField
              disabled={isDisabled}
              label="CEP"
              sxStyle={{ width: "100px", opacity: "", pointerEvents: "" }}
              value={valuesInputsCompanyDetails?.address_zip_code}
              onChange={handleChange("address_zip_code", "CEP")}
            />

            <FormControl focused sx={{ width: "80px" }} variant="standard">
              <InputLabel id="demo-simple-select-label">UF</InputLabel>
              <Select
                MenuProps={{
                  PaperProps: {
                    style: {
                      width: 100,
                      maxHeight: 250,
                    },
                  },
                }}
                disabled={isDisabled}
                id="demo-simple-select"
                label="UF"
                labelId="demo-simple-select-label"
                value={valuesInputsCompanyDetails?.address_state}
                onChange={handleChange("address_state") as never}
              >
                <MenuItem value=" " />
                {unidadesFederativas.map((item) => {
                  return (
                    <MenuItem key={item.sigla} value={item.sigla}>
                      {item.nome}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <AppTextField
              disabled={isDisabled}
              label="Cidade"
              sxStyle={{ width: "160px", opacity: "", pointerEvents: "" }}
              value={valuesInputsCompanyDetails?.address_city}
              onChange={handleChange("address_city")}
            />
            <AppTextField
              disabled={isDisabled}
              label="Bairro"
              sxStyle={{ width: "160px", opacity: "", pointerEvents: "" }}
              value={valuesInputsCompanyDetails?.address_district}
              onChange={handleChange("address_district")}
            />
            <AppTextField
              disabled={isDisabled}
              label="Endereço"
              sxStyle={{ width: "180px", opacity: "", pointerEvents: "" }}
              value={valuesInputsCompanyDetails?.address_street}
              onChange={handleChange("address_street")}
            />
            <AppTextField
              disabled={isDisabled}
              label="Numero"
              sxStyle={{ width: "70px", opacity: "", pointerEvents: "" }}
              value={valuesInputsCompanyDetails?.address_address_number}
              onChange={handleChange("address_address_number")}
            />
            <AppTextField
              disabled={isDisabled}
              label="Complemento"
              sxStyle={{ width: "180px", opacity: "", pointerEvents: "" }}
              value={valuesInputsCompanyDetails?.address_complement}
              onChange={handleChange("address_complement")}
            />
          </Box>
        </CardBodyCore>
      </CardCore>

      <CardCore sxStyle={{ margin: 0 }}>
        <CardBodyCore title="Dados do responsável">
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              "& > *": { marginBottom: "1.3rem" },
            }}
          >
            <AppTextField
              disabled={isDisabled}
              label="Nome do responsável"
              sxStyle={{ width: "300px", opacity: "", pointerEvents: "" }}
              value={valuesInputsCompanyDetails?.responsible_name}
              onChange={handleChange("responsible_name")}
            />

            <AppTextField
              disabled={isDisabled}
              label="Email Responsável"
              sxStyle={{ width: "300px", opacity: "", pointerEvents: "" }}
              value={valuesInputsCompanyDetails?.responsible_email}
              onChange={handleChange("responsible_email")}
            />

            <AppTextField
              disabled={isDisabled}
              label="Telefone 1"
              sxStyle={{ width: "180px", opacity: "", pointerEvents: "" }}
              value={valuesInputsCompanyDetails?.responsible_telephone1}
              onChange={handleChange("responsible_telephone1", "phone")}
            />
            <AppTextField
              disabled={isDisabled}
              label="Telefone 1"
              sxStyle={{ width: "180px", opacity: "", pointerEvents: "" }}
              value={valuesInputsCompanyDetails?.responsible_telephone2}
              onChange={handleChange("responsible_telephone2", "phone")}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <FormButtonsSubmitCompany />
          </Box>
        </CardBodyCore>
      </CardCore>
    </FormCore>
  );
};
