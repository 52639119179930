import { createRef, ChangeEvent } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useContextComissoes } from "src/Contexts/comissoes/ComissoesContext";
import { ICreateModalidade } from "src/Contexts/comissoes/ComissoesTypes";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { ButtonsFeaturesFormularioCore, FormCore } from "src/Pages/components";
import { AppTextField } from "src/Pages/components/AppFormComponents/AppTextField";
import { UseVerifyIsPermissionsUser } from "src/Pages/components/hooks/UseVerifyIsPermissionsUser";
import { valuesPermissions } from "src/shared/Utils/ValuesPermissions";

import { UseFeaturesFormulario } from "./hook/UseFeaturesFormulario";

export const FormModalidade = () => {
  const { handleVerifyPermissionItem } = UseVerifyIsPermissionsUser();
  const { handleSubmit } = UseFeaturesFormulario();
  const { active_incluir } = useConfigPageContext();

  const { valuesInputsModalidade, setValuesInputsModalidade } =
    useContextComissoes();

  const refOrigem = createRef<HTMLInputElement>();
  const handleFocusedID = () => refOrigem.current?.focus();

  const handleChange =
    (prop: keyof ICreateModalidade) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setValuesInputsModalidade({
        ...valuesInputsModalidade,
        [prop]: event.target.value,
      });
    };

  const handleChangeCheck = (event: ChangeEvent<HTMLInputElement>) => {
    setValuesInputsModalidade({
      ...valuesInputsModalidade,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <FormCore>
      <ButtonsFeaturesFormularioCore
        disabledEditar={handleVerifyPermissionItem(
          valuesPermissions.MODALIDADE.EDITAR.id
        )}
        disabledIncluir={handleVerifyPermissionItem(
          valuesPermissions.MODALIDADE.CREATE.id
        )}
        handleFocusedID={handleFocusedID}
        handleSubmit={handleSubmit}
      />

      <AppTextField
        inputRef={refOrigem}
        label="Modalidade *"
        sxStyle={{ width: "280px" }}
        value={valuesInputsModalidade?.name}
        onChange={handleChange("name")}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={valuesInputsModalidade.is_active}
            name="is_active"
            sx={{
              width: "30px",
              height: "30px",
              "& .MuiSvgIcon-root": { fontSize: 28 },
            }}
            onChange={handleChangeCheck}
          />
        }
        disabled={!active_incluir}
        label="Ativo ?"
        labelPlacement="top"
      />
    </FormCore>
  );
};
