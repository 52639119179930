import { Children, useEffect } from "react";
import { useContextComissoes } from "src/Contexts/comissoes/ComissoesContext";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useResetInputs } from "src/Contexts/hooks/useResetInputs";
import { ModalDeleteCore, TableCore, TdCore } from "src/Pages/components";
import { UseVerifyIsPermissionsUser } from "src/Pages/components/hooks/UseVerifyIsPermissionsUser";
import {
  DeleteForeverIcon,
  CheckIcon,
  CloseIcon,
  EditIcon,
} from "src/Pages/components/Icons/Icons";
import { UseDelete } from "src/Pages/components/ModalDeleteCore/Hooks/UseDelete";
import { TableRowCore } from "src/Pages/components/table/@Core/TableRowCore/TableRowCore";
import { ActionPopoverTable } from "src/Pages/components/table/ActionPopover/ActionPopover";
import { valuesPermissions } from "src/shared/Utils/ValuesPermissions";

import { UseGetByIdAdministradoras } from "./Hooks/UseGetByIdAdministradoras";
import { useHttpTableAdministradora } from "./Hooks/useHttpTableAdministradora";
import { THeadAdministradora } from "./THead";

export const TableAdministradora = () => {
  const { resetInputs } = useResetInputs();
  const { listAdministradora } = useContextComissoes();
  const { handleGetById } = UseGetByIdAdministradoras();

  const { handleVerifyPermissionItem } = UseVerifyIsPermissionsUser();
  const { handleGet } = useHttpTableAdministradora();

  const {
    setActive_incluir,
    setNameSearch,
    itensPerPage,
    currentPage,
    setIdTable,
    nameSearch,
    attTable,
    setId,
  } = useConfigPageContext();

  const handleGetIdToEdit = (id: string) => {
    resetInputs();
    setId(id);
    setIdTable(id);
    handleGetById(id);
  };

  useEffect(() => {
    handleGet({ itensPerPage, currentPage, nameSearch });
  }, [attTable, itensPerPage, currentPage, nameSearch]);

  const {
    handleDelete,
    open: openModalDelete,
    loading: loadingDelete,
    setId: setIdModalDelete,
    setOpen: setOpenModalDelete,
  } = UseDelete({ url: "/comissoes/administradoras" });

  return (
    <TableCore
      Modals={
        <ModalDeleteCore
          loading={loadingDelete}
          modalOpen={openModalDelete}
          no="Não, Cancelar"
          titlePrimary="Você deseja excluir da lista ?"
          yes="Sim, Deletar !"
          onClickFalse={() => setOpenModalDelete(false)}
          onClickTrue={() => handleDelete()}
        />
      }
      THead={<THeadAdministradora />}
      qtdList={listAdministradora.length}
      setNameSearch={setNameSearch}
    >
      {Children.toArray(
        listAdministradora.map((item) => {
          return (
            <TableRowCore id={String(item.code)}>
              <TdCore
                values={
                  <ActionPopoverTable
                    optionsList={[
                      {
                        icon: <EditIcon fontSize="small" />,
                        title: "Editar",
                        background: "warning",
                        isDisabled: handleVerifyPermissionItem(
                          valuesPermissions.ADMINISTRADORA.EDITAR.id
                        ),
                        onClick: () => {
                          setId(String(item.code));
                          handleGetIdToEdit(String(item.code));
                          setActive_incluir(true);
                        },
                      },
                      {
                        icon: <DeleteForeverIcon fontSize="small" />,
                        title: "Excluir",
                        background: "error",
                        isDisabled: handleVerifyPermissionItem(
                          valuesPermissions.ADMINISTRADORA.DELETAR.id
                        ),
                        onClick: () => {
                          setIdModalDelete(item.code);
                          setOpenModalDelete(true);
                        },
                      },
                    ]}
                  />
                }
              />
              <TdCore
                values={`${item.code} - ${item.name}`}
                onClick={() => handleGetIdToEdit(String(item.code))}
              />
              <TdCore
                textAlign="left"
                values={
                  item.is_active ? (
                    <CheckIcon color="success" />
                  ) : (
                    <CloseIcon color="error" />
                  )
                }
                onClick={() => handleGetIdToEdit(String(item.code))}
              />
            </TableRowCore>
          );
        })
      )}
    </TableCore>
  );
};
