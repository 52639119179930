import { FormAdministradora } from "./Components/Form/FormularioAdministradora";
import { TableAdministradora } from "./Components/table/TableAdministradora";

export const PageComissoesAdministradora = () => {
  return (
    <>
      <FormAdministradora />
      <TableAdministradora />
    </>
  );
};
