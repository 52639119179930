import { useEffect } from "react";
import { useContextProdutor } from "src/Contexts/produtores/ProdutoresContext";
import { useGetCep } from "src/shared/Hooks/useGetCep";

export const UseGetCEPProducer = () => {
  const { handleGetCEP: handleGetCEP1 } = useGetCep();

  const { valuesInputsProdutor, setValuesInputsProdutor } =
    useContextProdutor();

  useEffect(() => {
    if (valuesInputsProdutor.cep?.length === 9) {
      const handleGetCEP = async () => {
        const { erro, bairro, localidade, logradouro, uf } =
          await handleGetCEP1(valuesInputsProdutor.cep);

        if (erro) {
          setValuesInputsProdutor((prevValue) => ({
            ...prevValue,
            bairro: "",
            endereco: "",
            estado: "",
            cidade: "",
          }));
        } else {
          setValuesInputsProdutor((prevValue) => ({
            ...prevValue,
            bairro,
            endereco: logradouro,
            estado: uf,
            cidade: localidade,
          }));
        }
      };

      handleGetCEP();
    }
  }, [valuesInputsProdutor.cep]);
};
