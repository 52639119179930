import { Box, Button, Typography, MenuItem } from "@mui/material";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { AppTextField } from "src/Pages/components";
import { ArrowBack } from "src/Pages/components/Icons/Icons";

import { useStylesInput } from "./FormLogin";

interface IInputControl {
  values: { label: string }[];
}

export const InputSelectEmpresa = ({ values }: IInputControl) => {
  const {
    setValuesAutoComplete,
    setInputValueSelect,
    valuesAutoComplete,
    inputValueSelect,
  } = useAuthContext();

  const classes = useStylesInput();

  const voltarPaginaAnterior = () => {
    setInputValueSelect("");
    setValuesAutoComplete([]);
  };

  return (
    <Box
      sx={{
        display: valuesAutoComplete.length > 1 ? "block" : "none",
        marginBottom: "1rem",
      }}
    >
      <Button
        startIcon={<ArrowBack />}
        sx={{ marginBottom: "1rem" }}
        variant="outlined"
        onClick={voltarPaginaAnterior}
      >
        Voltar
      </Button>

      <Typography
        sx={{
          textAlign: "start",
          color: "#242424",
          marginBottom: "8px",
          fontSize: {
            xs: ".8rem",
            sm: "1rem",
          },
        }}
      >
        Por favor escolha qual empresa
      </Typography>

      <AppTextField
        fullWidth
        select
        InputProps={{ className: classes.input }}
        id="Tem_recibo"
        label="Escolha a empresa"
        sxStyle={{
          opacity: "",
          pointerEvents: "auto",
          "& .MuiInput-input": {
            color: "red",
          },
        }}
        value={inputValueSelect}
        variant="outlined"
        onChange={(e) => setInputValueSelect(e.target.value)}
      >
        {values.map((option) => (
          <MenuItem key={option.label} value={option.label}>
            {option.label}
          </MenuItem>
        ))}
      </AppTextField>
    </Box>
  );
};
