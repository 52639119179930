import { Tooltip, Box } from "@mui/material";
import moment from "moment";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";
import { LinkCore } from "src/Pages/components/LinkCore/LinkCore";
import { UrlConfig } from "src/shared/Utils/paths";

export const IsPlanTeste = () => {
  const { userPerfil } = useAuthContext();

  const isViewAllowedAdm =
    userPerfil?.type_user === "ADM" || userPerfil?.type_user === "ROOT";

  const isButtonVisible = (createdDate?: string) => {
    const creationDate = moment(createdDate);
    const threeMonthsLater = moment(createdDate).add(5, "months");
    const now = moment();

    return now.isBetween(creationDate, threeMonthsLater, undefined, "[]");
  };

  return (
    <>
      {isViewAllowedAdm && (
        <>
          {isButtonVisible(userPerfil?.empresa.dateStartCompany) && (
            <Tooltip title="Tire suas duvidas">
              <Box
                sx={{
                  marginRight: "6px",
                  display: { xs: "none", sm: "flex" },
                }}
              >
                <LinkCore to={UrlConfig.help.url}>
                  <ButtonCore title="Duvidas" />
                </LinkCore>
              </Box>
            </Tooltip>
          )}

          {userPerfil?.empresa.isPlanTest && (
            <Tooltip
              title={`Clique para contratar um plano ! Aproveite o(s) ${userPerfil?.empresa.remainingTestDays} dia(s) de período de avaliação.`}
            >
              <Box
                sx={{
                  marginRight: "6px",
                  display: { xs: "none", sm: "flex" },
                }}
              >
                <LinkCore to={UrlConfig.config.plans.url}>
                  <ButtonCore title="Contratar plano" />
                </LinkCore>
              </Box>
            </Tooltip>
          )}
        </>
      )}
    </>
  );
};
