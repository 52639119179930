import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";

import { apiGeneric } from "../setup/API/api";
import { apenasNumeros } from "../Utils/FormatMoney";

interface IGetCEP {
  bairro: string;
  logradouro: string;
  uf: string;
  localidade: string;
  erro: unknown;
}
export const useGetCep = () => {
  const { handleGetAlert } = useLayoutMainContext();

  const handleGetCEP = async (cep?: string) => {
    const { data } = await apiGeneric.get<IGetCEP>(
      `https://viacep.com.br/ws/${apenasNumeros(cep)}/json`
    );

    if (data.erro) {
      handleGetAlert({
        message: "CEP informado não encontrado, preencha os dados manuais",
      });
    }

    return data;
  };

  return { handleGetCEP };
};
