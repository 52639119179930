import {
  createContext,
  useContext,
  useEffect,
  useState,
  FC,
  ReactNode,
} from "react";
import { IListAcoesDev } from "src/shared/types/configPageContext/typesContext";

import { IConfigPagesContext } from "./configPagesTypes";

const ConfigPageProvider = createContext({} as IConfigPagesContext);

const ConfigPageProviderContext: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [id, setId] = useState("");

  const [listAcoesDev, setListAcoesDev] = useState([] as IListAcoesDev[]);

  const [qtd_registros, setQtd_registros] = useState<number>(0);
  const [loadingTable, setLoadingTable] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [itensPerPage, setItensPerPage] = useState(8);
  const [currentPage, setCurrentPage] = useState(1);
  const [attTable, setAttTable] = useState(false);

  const [active_incluir, setActive_incluir] = useState(false);
  const [loadingGravar, setLoadingGravar] = useState(false);
  const [nameSearch, setNameSearch] = useState("");
  const [idTable, setIdTable] = useState("");

  useEffect(() => setCurrentPage(1), [itensPerPage]);

  return (
    <ConfigPageProvider.Provider
      value={{
        setActive_incluir,
        setQtd_registros,
        setLoadingGravar,
        setItensPerPage,
        setLoadingTable,
        setListAcoesDev,
        active_incluir,
        setCurrentPage,
        setModalDelete,
        loadingGravar,
        qtd_registros,
        loadingTable,
        setNameSearch,
        listAcoesDev,
        itensPerPage,
        currentPage,
        setAttTable,
        modalDelete,
        nameSearch,
        setIdTable,
        attTable,
        idTable,
        setId,
        id,
      }}
    >
      {children}
    </ConfigPageProvider.Provider>
  );
};

const useConfigPageContext = (): IConfigPagesContext => {
  return useContext(ConfigPageProvider);
};

export { useConfigPageContext, ConfigPageProviderContext };
