import { IDataTh, ThCoreIndex } from "src/Pages/components";

export const THeadRankingCorretores = () => {
  const dataTh: IDataTh[] = [
    {
      title: "Posição",
      align: "left",
      width: "40px",
    },
    {
      title: "Produtor",
      align: "left",
      width: "800px",
    },
    {
      title: "Total mensalidade",
      align: "right",
      width: "200px",
      minWidth: "160px",
    },
    {
      title: "Total de vidas",
      align: "center",
      width: "140px",
      minWidth: "140px",
    },
    {
      title: "Total de contratos",
      align: "center",
      width: "200px",
      minWidth: "140px",
    },
  ];

  return <ThCoreIndex dataTh={dataTh} />;
};
