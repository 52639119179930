import { useState } from "react";
import { formatRemove } from "@format-string/core";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { IUsers } from "src/Contexts/AuthContext/AuthContextTypes";
import { useContextProfileContext } from "src/Contexts/ContextProfile/ContextProfile";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

export const UseFormUpdateProfile = () => {
  const { valuesEditPerfil } = useContextProfileContext();
  const { handleGetAlert } = useLayoutMainContext();
  const { setUserPerfil } = useAuthContext();

  const [loading, setLoading] = useState(false);

  const handleUpdateProfile = () => {
    if (!valuesEditPerfil.nome?.trim())
      return handleGetAlert({ message: `Seu nome não pode estar vazio !` });

    const AllDatas = {
      ...valuesEditPerfil,
      telefone_1: formatRemove(valuesEditPerfil.telefone_1),
    };

    setLoading(true);

    return api
      .put(`/updateProfile`, AllDatas)
      .then(() => {
        setUserPerfil((prev) => ({
          ...(prev as IUsers),
          nome: AllDatas.nome as string,
        }));
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  const handleUpdate = () => handleUpdateProfile();

  return { handleUpdate, loading, setLoading };
};
