import { useState } from "react";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { useContextProdutor } from "src/Contexts/produtores/ProdutoresContext";
import { api } from "src/shared/setup/API/api";

interface IHandleUpdateStatusContratos {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UseFormFormChangeFunctionProducer = ({
  setOpenModal,
}: IHandleUpdateStatusContratos) => {
  const { setAttTable, id } = useConfigPageContext();
  const { handleGetAlert } = useLayoutMainContext();

  const { changeFunctionProducer } = useContextProdutor();

  const [loading, setLoading] = useState(false);

  const handleChangeFunctionProducer = async () => {
    setLoading(true);

    await api
      .patch(`/produtor/changeFunction/${id}`, {
        functionProducer: changeFunctionProducer,
      })
      .then((res) => {
        handleGetAlert({ message: res.data.message });
        setAttTable((item) => !item);
        setOpenModal(false);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  return {
    handleChangeFunctionProducer,
    loading,
  };
};
