import { ChangeEvent } from "react";
import { formatString } from "@format-string/core";
import { Box, MenuItem } from "@mui/material";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { IValuesFilterBordero } from "src/Contexts/Lancamentos/Contrato/ContratosTypes";
import { valuesDefaultFilterBordero } from "src/Contexts/Lancamentos/Contrato/valuesDefaultInputsContratos/ValueDefaultInputContratos";
import { UseAutoCompletedAll } from "src/Contexts/Utils/UseAutoCompletedAll";
import { AppTextField } from "src/Pages/components";
import { AutoCompleteCorretorGeneric } from "src/Pages/components/AutoCompleted/AutoCompleteCorretor";
import { AutoCompleteOperadoraFilter } from "src/Pages/components/AutoCompleted/Filters/AutoCompleteOperadora";
import { DividerCore } from "src/Pages/components/DividerCore/DividerCore";
import { ModalFilterCore } from "src/Pages/components/ModalFilterCore/Index";
import { apenasNumeros } from "src/shared/Utils/FormatMoney";

import { useHttpTableBordero } from "../../table/Hooks/useHttpTableBordero";
import { AutoCompleteAdministradoras } from "src/Pages/components/AutoCompleted/AutoCompleteAdministradoras";

export const FilterSidebarBordero = () => {
  const { handleGet } = useHttpTableBordero();

  const { valuesFilterBordero, setValuesFilterBordero } = useContextContrato();
  const {
    valueAutoCompOperadoraFilter,
    setValueAutoCompOperadoraFilter,
    valueAutoCompProdutorActive,
    valueAutoCompAdministradora,
    setValueAutoCompProdutorActive,
  } = UseAutoCompletedAll();

  const handleChange =
    (prop: keyof IValuesFilterBordero, option?: "apenasNumero" | "CPF_CNPJ") =>
    (event: ChangeEvent<HTMLInputElement>) => {
      if (option === "apenasNumero") {
        if (event.target.value.length <= 5) {
          setValuesFilterBordero({
            ...valuesFilterBordero,
            [prop]: apenasNumeros(event.target.value),
          });
        }
      } else if (option === "CPF_CNPJ") {
        setValuesFilterBordero({
          ...valuesFilterBordero,
          [prop]: formatString({
            type: "cpfOurCnpj",
            value: event.target.value,
          }),
        });
      } else {
        setValuesFilterBordero({
          ...valuesFilterBordero,
          [prop]: event.target.value,
        });
      }
    };

  const ClearFilter = async () => {
    setValuesFilterBordero(valuesDefaultFilterBordero);
    setValueAutoCompOperadoraFilter({ id: "", label: "" });
    setValueAutoCompProdutorActive({ id: "", label: "" });

    await handleGet({});
  };

  const FilterSearch = async () => {
    await handleGet({
      codProposta: valuesFilterBordero.cod_proposta,

      codeOperadora: valueAutoCompOperadoraFilter.id,
      codeCorretor: valueAutoCompProdutorActive.id,
      codeAdministradora: valueAutoCompAdministradora.id,

      startDateSignature: valuesFilterBordero.startDateSignature,
      endDateSignature: valuesFilterBordero.endDateSignature,

      startDateEmission: valuesFilterBordero.startDateEmission,
      endDateEmission: valuesFilterBordero.endDateEmission,

      endDueDate: valuesFilterBordero.endDueDate,
      startDueDate: valuesFilterBordero.startDueDate,

      startDateReceipt: valuesFilterBordero.startDateReceipt,
      endDateReceipt: valuesFilterBordero.endDateReceipt,

      installmentNumber: valuesFilterBordero.installmentNumber,
      installmentReceipt: valuesFilterBordero.installmentReceipt,

      isInstallmentBroker: valuesFilterBordero.isInstallmentBroker,
      isInstallmentSupervisor: valuesFilterBordero.isInstallmentSupervisor,
      isInstallmentManager: valuesFilterBordero.isInstallmentManager,

      client: {
        nameOrRazao: valuesFilterBordero.nameOrRazao,
        cpfCnpj: valuesFilterBordero.cpfCnpj,
      },
    });
  };

  return (
    <ModalFilterCore FilterClear={ClearFilter} FilterSearch={FilterSearch}>
      <DividerCore
        sxStyle={{ marginBottom: "12px", marginTop: "0" }}
        title="Proposta"
      />
      <Box
        id="container propostas"
        sx={{
          "& > .MuiAutocomplete-root, & > .MuiTextField-root": {
            flexWrap: "wrap",
            display: "flex",
            marginBottom: "1rem",
          },
        }}
      >
        <AppTextField
          label="Código proposta"
          sxStyle={{ opacity: "", pointerEvents: "auto", width: "100%" }}
          value={valuesFilterBordero.cod_proposta}
          variant="outlined"
          onChange={handleChange("cod_proposta")}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
            alignItems: "center",
          }}
        >
          <AppTextField
            label="Data assinatura"
            sxStyle={{ opacity: "", pointerEvents: "auto", width: "45%" }}
            type="date"
            value={valuesFilterBordero.startDateSignature}
            variant="outlined"
            onChange={handleChange("startDateSignature")}
          />
          até
          <AppTextField
            label="Data assinatura"
            sxStyle={{ opacity: "", pointerEvents: "auto", width: "45%" }}
            type="date"
            value={valuesFilterBordero.endDateSignature}
            variant="outlined"
            onChange={handleChange("endDateSignature")}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
            alignItems: "center",
          }}
        >
          <AppTextField
            label="Data emissão"
            sxStyle={{ opacity: "", pointerEvents: "auto", width: "45%" }}
            type="date"
            value={valuesFilterBordero.startDateEmission}
            variant="outlined"
            onChange={handleChange("startDateEmission")}
          />
          até
          <AppTextField
            label="Data emissão"
            sxStyle={{ opacity: "", pointerEvents: "auto", width: "45%" }}
            type="date"
            value={valuesFilterBordero.endDateEmission}
            variant="outlined"
            onChange={handleChange("endDateEmission")}
          />
        </Box>

        <AutoCompleteCorretorGeneric
          fullWidth
          label="Corretor"
          variant="outlined"
        />

        <AutoCompleteOperadoraFilter
          fullWidth
          label="Operadora"
          variant="outlined"
        />

        <AutoCompleteAdministradoras
          fullWidth
          label="Administradoras"
          variant="outlined"
        />
      </Box>

      <DividerCore sxStyle={{ marginBottom: "12px" }} title="Cliente" />
      <Box
        id="container cliente"
        sx={{
          "& > .MuiAutocomplete-root, & > .MuiTextField-root": {
            flexWrap: "wrap",
            display: "flex",
            marginBottom: "1rem",
          },
        }}
      >
        <AppTextField
          label="Titular/Razão"
          sxStyle={{ opacity: "", pointerEvents: "auto", width: "100%" }}
          value={valuesFilterBordero.nameOrRazao}
          variant="outlined"
          onChange={handleChange("nameOrRazao")}
        />
        <AppTextField
          label="CPF/CNPJ"
          sxStyle={{ opacity: "", pointerEvents: "auto", width: "100%" }}
          value={valuesFilterBordero.cpfCnpj}
          variant="outlined"
          onChange={handleChange("cpfCnpj", "CPF_CNPJ")}
        />
      </Box>

      <DividerCore sxStyle={{ marginBottom: "12px" }} title="Parcelas" />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem",
          alignItems: "center",
        }}
      >
        <AppTextField
          label="Data de recebimento"
          sxStyle={{ opacity: "", pointerEvents: "auto", width: "45%" }}
          type="date"
          value={valuesFilterBordero.startDateReceipt}
          variant="outlined"
          onChange={handleChange("startDateReceipt")}
        />
        até
        <AppTextField
          label="Data recebimento"
          sxStyle={{ opacity: "", pointerEvents: "auto", width: "45%" }}
          type="date"
          value={valuesFilterBordero.endDateReceipt}
          variant="outlined"
          onChange={handleChange("endDateReceipt")}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem",
          alignItems: "center",
        }}
      >
        <AppTextField
          label="Data de vencimento"
          sxStyle={{ opacity: "", pointerEvents: "auto", width: "45%" }}
          type="date"
          value={valuesFilterBordero.startDueDate}
          variant="outlined"
          onChange={handleChange("startDueDate")}
        />
        até
        <AppTextField
          label="Data de vencimento"
          sxStyle={{ opacity: "", pointerEvents: "auto", width: "45%" }}
          type="date"
          value={valuesFilterBordero.endDueDate}
          variant="outlined"
          onChange={handleChange("endDueDate")}
        />
      </Box>
      <Box
        id="container cliente"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem",
          alignItems: "center",
        }}
      >
        <AppTextField
          select
          id="parcelas_recebida"
          label="Parcelas recebidas ?"
          sxStyle={{ width: "48%", opacity: "", pointerEvents: "auto" }}
          value={valuesFilterBordero.installmentReceipt}
          variant="outlined"
          onChange={handleChange("installmentReceipt")}
        >
          <MenuItem value="ALL">Todas</MenuItem>
          <MenuItem value="TRUE">Recebidas</MenuItem>
          <MenuItem value="FALSE">Não recebidas</MenuItem>
        </AppTextField>

        <AppTextField
          label="Numero da parcela"
          sxStyle={{ opacity: "", pointerEvents: "auto", width: "48%" }}
          type="number"
          value={valuesFilterBordero.installmentNumber}
          variant="outlined"
          onChange={handleChange("installmentNumber", "apenasNumero")}
        />
      </Box>
      <Box
        id="container cliente"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem",
          alignItems: "center",
        }}
      >
        <AppTextField
          select
          id="Parc_paga_corretor"
          label="Parc. paga corretor ?"
          sxStyle={{ width: "30%", opacity: "", pointerEvents: "auto" }}
          value={valuesFilterBordero?.isInstallmentBroker}
          variant="outlined"
          onChange={handleChange("isInstallmentBroker")}
        >
          <MenuItem value="ALL">Todas</MenuItem>
          <MenuItem value="TRUE">Sim</MenuItem>
          <MenuItem value="FALSE">Não</MenuItem>
        </AppTextField>
        <AppTextField
          select
          id="Parc_paga_supervisor"
          label="Parc. paga supervisor ?"
          sxStyle={{ width: "30%", opacity: "", pointerEvents: "auto" }}
          value={valuesFilterBordero?.isInstallmentSupervisor}
          variant="outlined"
          onChange={handleChange("isInstallmentSupervisor")}
        >
          <MenuItem value="ALL">Todas</MenuItem>
          <MenuItem value="TRUE">Sim</MenuItem>
          <MenuItem value="FALSE">Não</MenuItem>
        </AppTextField>
        <AppTextField
          select
          id="Parc_paga_gerente"
          label="Parc. paga gerente ?"
          sxStyle={{ width: "30%", opacity: "", pointerEvents: "auto" }}
          value={valuesFilterBordero?.isInstallmentManager}
          variant="outlined"
          onChange={handleChange("isInstallmentManager")}
        >
          <MenuItem value="ALL">Todas</MenuItem>
          <MenuItem value="TRUE">Sim</MenuItem>
          <MenuItem value="FALSE">Não</MenuItem>
        </AppTextField>
      </Box>
    </ModalFilterCore>
  );
};
