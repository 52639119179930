import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL_API;

const api = axios.create({ baseURL });
const apiGeneric = axios.create();

export const getToken = "token:brokeris";
export const getMenu = "@menu-brokeris";
export const getTheme = "@ThemeBrokeris";
export const ColorPrimary = "#3980f5";

export { api, apiGeneric };
