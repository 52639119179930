import { FC, ReactNode } from "react";
import { Box, SxProps } from "@mui/material";
import { FormCore } from "src/Pages/components";
import { toAbsoluteUrl } from "src/shared/setup/toAbsoluteUrl";

interface IBasePageLogin {
  sxStyle?: SxProps;
  children: ReactNode;
}

export const BasePageLogin: FC<IBasePageLogin> = ({ children, sxStyle }) => {
  return (
    <FormCore
      sxStyle={{
        margin: "0 auto",
        alignItems: "center",
        padding: { xs: "1.2rem", sm: "0" },
        ...sxStyle,
      }}
    >
      <Box sx={{ height: "60px", textAlign: "left" }}>
        <img
          alt="logo da empresa"
          src={toAbsoluteUrl("/media/logos/Extenso-BrokerIs.svg")}
          style={{ width: "160px" }}
        />
      </Box>

      {children}
    </FormCore>
  );
};
