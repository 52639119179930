import { FC, ReactNode } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { useStyles } from "src/Pages/components/ModalFilterCore/Index";

import { drawerWidth } from "../DrawerDesktop";

export const CardMain: FC<{ children: ReactNode }> = ({ children }) => {
  const { openAside } = useLayoutMainContext();

  const theme = useTheme();
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box
      className={classes.customScrollbar}
      sx={{
        background: "#F1F3F6",

        minHeight: `calc(100vh - 6rem)`,
        maxHeight: matches ? `calc(100vh - 6rem)` : "",
        transition: "max-height 0.5s ease-in-out",

        [theme.breakpoints.up("md")]: {
          transition: ".3s ease-in-out",
          marginLeft: openAside ? `${drawerWidth}px` : "56px",
        },

        borderRadius: "1rem",
        boxShadow: "0 .1rem 1rem .25rem #0000001f",
        overflow: "auto",
      }}
    >
      {children}
    </Box>
  );
};
