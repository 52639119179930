/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createRef, useEffect, ChangeEvent } from "react";
import { Grid } from "@mui/material";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { ICreateNovaParcelaBordero } from "src/Contexts/Lancamentos/Contrato/ContratosTypes";
import { AppTextField } from "src/Pages/components";
import { FormatMoney, handleFormatPercent } from "src/shared/Utils/FormatMoney";

export const FormNovaParcela = () => {
  const { valuesInputNovaParcelaBordero, setValuesInputNovaParcelaBordero } =
    useContextContrato();

  const refOrigem = createRef<HTMLInputElement>();
  const handleFocusedID = () => refOrigem.current?.focus();

  useEffect(() => {
    handleFocusedID();
  }, []);

  const handleChange =
    (
      prop: keyof ICreateNovaParcelaBordero,
      option?: "limitNumber" | "money" | "percent"
    ) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      if (option === "money") {
        setValuesInputNovaParcelaBordero({
          ...valuesInputNovaParcelaBordero!,
          [prop]: FormatMoney(event.target.value),
        });
      } else if (option === "limitNumber") {
        if (event.target.value.length <= 5) {
          setValuesInputNovaParcelaBordero({
            ...valuesInputNovaParcelaBordero!,
            [prop]: event.target.value,
          });
        }
      } else if (option === "percent") {
        if (event.target.value.length <= 7) {
          setValuesInputNovaParcelaBordero({
            ...valuesInputNovaParcelaBordero!,
            [prop]: handleFormatPercent(event.target.value),
          });
        }
      } else {
        setValuesInputNovaParcelaBordero({
          ...valuesInputNovaParcelaBordero!,
          [prop]: event.target.value,
        });
      }
    };

  return (
    <>
      {valuesInputNovaParcelaBordero && (
        <Grid container spacing={2}>
          <Grid container item spacing={2}>
            <Grid item xs={8}>
              <AppTextField
                fullWidth
                label="Proposta"
                sxStyle={{ opacity: ".5", pointerEvents: "none", margin: 0 }}
                value={valuesInputNovaParcelaBordero?.cod_proposta}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <AppTextField
                fullWidth
                label="Parcela *"
                inputRef={refOrigem}
                sxStyle={{ opacity: "", pointerEvents: "auto", margin: 0 }}
                value={valuesInputNovaParcelaBordero.numero_parcela}
                variant="outlined"
                type="number"
                onChange={handleChange("numero_parcela")}
              />
            </Grid>
          </Grid>

          <Grid container item spacing={2}>
            <Grid item xs={6}>
              <AppTextField
                fullWidth
                label="Vencimento *"
                sxStyle={{ opacity: "", pointerEvents: "auto", margin: 0 }}
                type="date"
                value={valuesInputNovaParcelaBordero?.data_vencimento_parcela}
                variant="outlined"
                onChange={handleChange("data_vencimento_parcela")}
              />
            </Grid>
            <Grid item xs={6}>
              <AppTextField
                fullWidth
                label="Vidas *"
                type="number"
                sxStyle={{ opacity: "", pointerEvents: "auto", margin: 0 }}
                value={valuesInputNovaParcelaBordero?.contratos_vidas}
                variant="outlined"
                onChange={handleChange("contratos_vidas")}
              />
            </Grid>
          </Grid>

          <Grid container item spacing={2}>
            <Grid item xs={6}>
              <AppTextField
                fullWidth
                label="Mensalidade *"
                sxStyle={{ opacity: "", pointerEvents: "auto", margin: 0 }}
                value={valuesInputNovaParcelaBordero?.contratos_mensalidade}
                variant="outlined"
                onChange={handleChange("contratos_mensalidade", "money")}
              />
            </Grid>

            <Grid container item spacing={2}>
              <Grid item sm={3} xs={6}>
                <AppTextField
                  fullWidth
                  label="% Escritório *"
                  sxStyle={{ opacity: "", pointerEvents: "auto", margin: 0 }}
                  value={
                    valuesInputNovaParcelaBordero?.porcentagem_comissao_corretora
                  }
                  variant="outlined"
                  onChange={handleChange(
                    "porcentagem_comissao_corretora",
                    "percent"
                  )}
                />
              </Grid>
              <Grid item sm={3} xs={6}>
                <AppTextField
                  fullWidth
                  label="% Corretor *"
                  sxStyle={{ opacity: "", pointerEvents: "auto", margin: 0 }}
                  value={
                    valuesInputNovaParcelaBordero?.porcentagem_comissao_corretor
                  }
                  variant="outlined"
                  onChange={handleChange(
                    "porcentagem_comissao_corretor",
                    "percent"
                  )}
                />
              </Grid>
              <Grid item sm={3} xs={6}>
                <AppTextField
                  fullWidth
                  label="% Supervisor"
                  sxStyle={{ opacity: "", pointerEvents: "auto", margin: 0 }}
                  value={
                    valuesInputNovaParcelaBordero?.porcentagem_comissao_supervisor
                  }
                  variant="outlined"
                  onChange={handleChange(
                    "porcentagem_comissao_supervisor",
                    "percent"
                  )}
                />
              </Grid>
              <Grid item sm={3} xs={6}>
                <AppTextField
                  fullWidth
                  label="% Gerente"
                  sxStyle={{ opacity: "", pointerEvents: "auto", margin: 0 }}
                  value={
                    valuesInputNovaParcelaBordero?.porcentagem_comissao_gerente
                  }
                  variant="outlined"
                  onChange={handleChange(
                    "porcentagem_comissao_gerente",
                    "percent"
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};
