import { ICreateRegister } from "src/Contexts/types/ProdutoresContext.Types";

export const valuesDefaultInputsControlCompany: ICreateRegister = {
  nome_empresa: "",
  telefone_1: "",

  access_email: "",
  access_password: "",
  access_name: "",
};
