import { FC } from "react";
import { Box, styled, BoxProps } from "@mui/material";

type IModalContentCore = BoxProps;

const ModalContentStyle = styled(Box)(() => ({
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "12px",
  padding: "24px",
}));

export const ModalContentCore: FC<IModalContentCore> = ({
  children,
  ...props
}) => {
  return <ModalContentStyle {...props}>{children}</ModalContentStyle>;
};
