import { CardHeaderCore } from "src/Pages/components/CardCore/Components/CardHeaderCore";
import { PreviewIcon } from "src/Pages/components/Icons/Icons";

export const CardHeaderLogs = () => {
  return (
    <CardHeaderCore
      icon={<PreviewIcon />}
      subTitle="Lista do histórico do sistema"
      title="Logs"
    />
  );
};
