import { PhoneInTalk } from "@mui/icons-material";
import { Box, Tooltip } from "@mui/material";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";
import { CardHeaderCore } from "src/Pages/components/CardCore/Components/CardHeaderCore";
import { getLinkWhatApp } from "src/Pages/components/CardPlans/CardPlans";
import { HelpOutlineIcon } from "src/Pages/components/Icons/Icons";

export const CardHeaderHelp = () => {
  const { userPerfil } = useAuthContext();

  return (
    <CardHeaderCore
      buttonAdd={[
        <Tooltip key={1} title="Solicitar treinamento online gratuito">
          <Box
            component="a"
            href={getLinkWhatApp(
              "5511985737008",
              `Olá, me chamo ${userPerfil?.nome}, sou da empresa ${userPerfil?.empresa.nome}, gostaria de agendar um treinamento.`
            )}
            sx={{ textDecoration: "none" }}
            target="_blank"
          >
            <ButtonCore
              startIcon={<PhoneInTalk />}
              title="Solicitar treinamento"
            />
          </Box>
        </Tooltip>,
      ]}
      icon={<HelpOutlineIcon />}
      subTitle="Aqui você aprende tudo, e pode solicitar treinamento gratuito !"
      sxStyle={{ margin: 0, marginBottom: "24px" }}
      title="Duvidas da plataforma"
    />
  );
};
