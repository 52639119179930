interface ICpfOurCnpj {
  text: string;
  type: "cpf" | "cnpj" | "phone";
}

const maskCPF = (text: string) => {
  const replace1 = text.replace(/\D/g, "");
  const replace2 = replace1.replace(/(\d{3})(\d)/, "$1.$2");
  const replace3 = replace2.replace(/(\d{3})(\d)/, "$1.$2");
  const replace4 = replace3.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

  return replace4;
};
const maskCNPJ = (text: string) => {
  const textMask = text
    .replace(/\D/g, "")
    .replace(/^(\d{2})(\d)/, "$1.$2")
    .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
    .replace(/\.(\d{3})(\d)/, ".$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");

  return textMask;
};
const maskPhone = (text: string) => {
  const textMask = text
    .replace(/\D/g, "")
    .replace(/^(\d{2})(\d)/g, "($1) $2")
    .replace(/(\d)(\d{4})$/, "$1-$2");

  return textMask;
};

export const cpfOurCnpj = ({ text, type }: ICpfOurCnpj) => {
  switch (type) {
    case "cpf":
      return maskCPF(text);
    case "cnpj":
      return maskCNPJ(text);
    case "phone":
      return maskPhone(text);

    default:
      return text;
  }
};
