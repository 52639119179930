import { useEffect } from "react";
import { useContextComissoes } from "src/Contexts/comissoes/ComissoesContext";
import { UseAutoCompletedAll } from "src/Contexts/Utils/UseAutoCompletedAll";

export const UseChangeNameComissaoProdutor = () => {
  const { setValuesInputsComissaoProdutor } = useContextComissoes();

  const {
    valueAutoCompAdministradora,
    valueAutoCompModalidade,
    valueAutoCompOperadora,
  } = UseAutoCompletedAll();

  useEffect(() => {
    setValuesInputsComissaoProdutor((prevState) => ({
      ...prevState,
      name: `${valueAutoCompOperadora.label}${
        valueAutoCompAdministradora.label &&
        ` - ${valueAutoCompAdministradora.label}`
      }${
        valueAutoCompModalidade.label && ` - ${valueAutoCompModalidade.label}`
      }`,
    }));
  }, [
    valueAutoCompAdministradora.id,
    valueAutoCompModalidade.id,
    valueAutoCompOperadora.id,
  ]);
};
