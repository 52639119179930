import { useState } from "react";
import { AccordionCore } from "src/Pages/components/AccordionCore/AccordionCore";

import { FormReportProductionOperator } from "./Components/Form/FormReportProductionOperator";
import { ReportProductionOperatorPDF } from "./Components/PDF/ReportProductionOperatorPDF";

export const PageReportProductionOperator = () => {
  const [expanded, setExpanded] = useState(true);

  return (
    <>
      <AccordionCore
        expanded={expanded}
        sxStyle={{ padding: 0 }}
        title="Clique para abrir FILTRO"
        onChange={() => setExpanded((item) => !item)}
      >
        <FormReportProductionOperator />
      </AccordionCore>

      <ReportProductionOperatorPDF />
    </>
  );
};
