import { ChangeEvent } from "react";
import { formatString } from "@format-string/core";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { usePageControle } from "src/Contexts/ControlCompanies/ControleEmpresaContext";
import { IChangeCompany } from "src/Contexts/types/ProdutoresContext.Types";
import { AppTextField } from "src/Pages/components";
import { DividerCore } from "src/Pages/components/DividerCore/DividerCore";
import { FormatDateBR } from "src/shared/Utils/FormatDateBR";

export const FormChangeCompany = () => {
  const { getCompany, valuesInputChangeCompany, setValuesInputChangeCompany } =
    usePageControle();

  const handleChange =
    (prop: keyof IChangeCompany) => (event: ChangeEvent<HTMLInputElement>) => {
      setValuesInputChangeCompany((prevValue) => ({
        ...prevValue,
        [prop]: event.target.value,
      }));
    };

  const handleChangeCheck = (event: ChangeEvent<HTMLInputElement>) => {
    setValuesInputChangeCompany((prevValue) => ({
      ...prevValue,
      [event.target.name]: event.target.checked,
    }));
  };

  return (
    <>
      <Grid container>
        <Grid item mb="12px" xs={12}>
          Data inicial:{" "}
          {FormatDateBR(getCompany?.plan.date_start_company as string)}
        </Grid>

        <Grid item mb="12px" xs={12}>
          Telefone:{" "}
          {formatString({
            value: getCompany?.contact.telephone_1,
            type: "phone",
          })}
        </Grid>
      </Grid>

      <DividerCore sxStyle={{ width: "100%" }} />

      <Grid container sx={{ textAlign: "center" }}>
        <Grid item sm={4} xs={12}>
          <AppTextField
            label="Dias limite"
            sxStyle={{ opacity: "", pointerEvents: "auto", width: "100px" }}
            type="number"
            value={valuesInputChangeCompany?.days_limit_free}
            onChange={handleChange("days_limit_free")}
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={valuesInputChangeCompany.is_plan_test!}
                name="is_plan_test"
                sx={{
                  width: "35px",
                  height: "35px",
                  "& .MuiSvgIcon-root": { fontSize: 25 },
                }}
                onChange={handleChangeCheck}
              />
            }
            label="Plano em teste ?"
            labelPlacement="top"
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={valuesInputChangeCompany.is_plan!}
                name="is_plan"
                sx={{
                  width: "35px",
                  height: "35px",
                  "& .MuiSvgIcon-root": { fontSize: 25 },
                }}
                onChange={handleChangeCheck}
              />
            }
            label="Plano ativo ?"
            labelPlacement="top"
          />
        </Grid>
      </Grid>
    </>
  );
};
