import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { useContextRecoverPassword } from "src/Contexts/RecoverPassword/RecoverPasswordContext";
import { api } from "src/shared/setup/API/api";

export const UseFormSendLinkRecoverPassword = () => {
  const { handleGetAlert } = useLayoutMainContext();

  const { setGetCompaniesRecoverPassword, inputValueSelect } =
    useContextRecoverPassword();

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleCreateProdutores = async () => {
    if (!inputValueSelect)
      return handleGetAlert({ message: `Escolha uma empresa ` });

    setLoading(true);

    return api
      .post(`/recoverPassword/sendLink/${inputValueSelect}`)
      .then(async ({ data }) => {
        handleGetAlert({ message: data.message });
        setGetCompaniesRecoverPassword([]);
        navigate(`/login`);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  return { handleSubmit: handleCreateProdutores, loading };
};
