import { Chip, Divider, SxProps, ChipProps } from "@mui/material";

type IDividerCore = ChipProps & {
  title?: string;
  sxStyle?: SxProps;
  textAlign?: "left" | "right" | "center";
};

export const DividerCore = ({
  title,
  sxStyle,
  textAlign = "center",
  ...props
}: IDividerCore) => {
  return (
    <Divider sx={{ margin: "12px 4px", ...sxStyle }} textAlign={textAlign}>
      {title && (
        <Chip
          {...props}
          label={title}
          size="small"
          sx={{
            color: "#3980f5",
            border: `1px solid #3980f5`,
            padding: ".8rem .3rem",
            fontSize: ".9rem",
          }}
          variant="outlined"
        />
      )}
    </Divider>
  );
};
