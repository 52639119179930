import { Children, useEffect, useState } from "react";
import PercentIcon from "@mui/icons-material/Percent";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextProdutor } from "src/Contexts/produtores/ProdutoresContext";
import {
  ModalDeleteCore,
  TableRowCore,
  TableCore,
  TdCore,
} from "src/Pages/components";
import { UseVerifyIsPermissionsUser } from "src/Pages/components/hooks/UseVerifyIsPermissionsUser";
import { DeleteForeverIcon, EditIcon } from "src/Pages/components/Icons/Icons";
import { UseDelete } from "src/Pages/components/ModalDeleteCore/Hooks/UseDelete";
import { ActionPopoverTable } from "src/Pages/components/table/ActionPopover/ActionPopover";
import { valuesPermissions } from "src/shared/Utils/ValuesPermissions";

import { ModalImpostoGlobal } from "../ModalImpostoGlobal";
import { ModalImpostos } from "../ModalImpostos";

import { useHttpGrupoImposto } from "./Hooks/useHttpGrupoImposto";
import { THeadGrupoImposto } from "./THead";

export const TableImpostoGlobal = () => {
  const { handleVerifyPermissionItem } = UseVerifyIsPermissionsUser();
  const { listGrupoImposto } = useContextProdutor();

  const { handleGet } = useHttpGrupoImposto();

  const [openModalGrupoImposto, setOpenModalGrupoImposto] = useState(false);
  const [openModalImpostoGlobal, setOpenModalImpostoGlobal] = useState(false);

  const {
    setNameSearch,
    itensPerPage,
    currentPage,
    nameSearch,
    attTable,
    setId,
  } = useConfigPageContext();

  useEffect(() => {
    handleGet({ itensPerPage, currentPage, nameSearch });
  }, [attTable, itensPerPage, currentPage, nameSearch]);

  const handleGetIdToEdit = (id: string) => {
    setOpenModalGrupoImposto(true);
    setId(id);
  };

  const {
    handleDelete,
    open: openModalDelete,
    loading: loadingDelete,
    setId: setIdModalDelete,
    setOpen: setOpenModalDelete,
  } = UseDelete({ url: "/gruposImpostos" });

  return (
    <TableCore
      isLastChildren
      Modals={
        <ModalDeleteCore
          loading={loadingDelete}
          modalOpen={openModalDelete}
          no="Não, Cancelar"
          titlePrimary="Você deseja excluir da lista ?"
          yes="Sim, Deletar !"
          onClickFalse={() => setOpenModalDelete(false)}
          onClickTrue={() => handleDelete()}
        />
      }
      THead={<THeadGrupoImposto />}
      cardAdd={{
        title: "Impostos / Imposto global",
        icon: <PercentIcon />,
        buttonAdd: [
          <ModalImpostoGlobal
            key={1}
            openModal={openModalImpostoGlobal}
            setOpenModal={setOpenModalImpostoGlobal}
          />,
          <ModalImpostos
            key={1}
            openModal={openModalGrupoImposto}
            setOpenModal={setOpenModalGrupoImposto}
          />,
        ],
      }}
      qtdList={listGrupoImposto.length}
      setNameSearch={setNameSearch}
    >
      {Children.toArray(
        listGrupoImposto.map((item) => {
          return (
            <TableRowCore id={item.id}>
              <TdCore
                values={
                  <ActionPopoverTable
                    optionsList={[
                      {
                        icon: <EditIcon fontSize="small" />,
                        title: "Editar",
                        background: "warning",
                        isDisabled: handleVerifyPermissionItem(
                          valuesPermissions.IMPOSTOS.EDITAR.id
                        ),
                        onClick: () => handleGetIdToEdit(item.id),
                      },
                      {
                        icon: <DeleteForeverIcon fontSize="small" />,
                        title: "Excluir",
                        background: "error",
                        isDisabled: handleVerifyPermissionItem(
                          valuesPermissions.IMPOSTOS.DELETAR.id
                        ),
                        onClick: () => {
                          setIdModalDelete(item.id);
                          setOpenModalDelete(true);
                        },
                      },
                    ]}
                  />
                }
              />
              <TdCore values={item.descricao} />

              <TdCore color=" #3980f5" values={`${item.valor}%`} />
            </TableRowCore>
          );
        })
      )}
    </TableCore>
  );
};
