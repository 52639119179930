import { Children, FC, ReactNode } from "react";
import { Box, Typography } from "@mui/material";

interface ICardBodyCore {
  title?: string;
  icon?: ReactNode;
  actions?: ReactNode[];
  children: ReactNode;
}

export const CardBodyCore: FC<ICardBodyCore> = ({
  children,
  actions,
  title,
  icon,
}) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        {title && (
          <Box
            sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
          >
            {icon && (
              <Box sx={{ display: "flex", marginRight: "10px" }}>{icon}</Box>
            )}
            {title && (
              <Typography sx={{ fontSize: "1.1rem", fontWeight: "500" }}>
                {title}
              </Typography>
            )}
          </Box>
        )}

        {actions?.length && actions?.length > 0 && (
          <Box sx={{ display: "flex" }}>
            {Children.toArray(actions?.map((item) => <Box>{item}</Box>))}
          </Box>
        )}
      </Box>

      {children}
    </>
  );
};
