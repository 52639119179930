import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { AutoCompleteGeneric } from "src/Pages/components/InputAutoCompleted/AutoCompleteGeneric";
import { IPropsAutoCompleted } from "src/Pages/components/InputAutoCompleted/types";

export const AutoCompleteProdutorActiveCorretor = (
  props: IPropsAutoCompleted
) => {
  const { disabled, label, width, tamanho, minWidth, maxWidth, fullWidth } =
    props;
  const { valueAutoCompProdutorAll, setValueAutoCompProdutorAll } =
    useContextContrato();

  return (
    <AutoCompleteGeneric
      disabled={disabled}
      fullWidth={fullWidth}
      label={label}
      maxWidth={maxWidth}
      minWidth={minWidth}
      setValueAutoCompleted={setValueAutoCompProdutorAll}
      tamanho={tamanho}
      url="/getAllProdutores?funcao_produtor=CORRETOR&is_active_producer=true"
      valueAutoCompleted={valueAutoCompProdutorAll}
      width={width}
    />
  );
};
