import { Children, useState } from "react";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { usePageControle } from "src/Contexts/ControlCompanies/ControleEmpresaContext";
import { useResetInputs } from "src/Contexts/hooks/useResetInputs";
import {
  ModalDeleteCore,
  TableRowCore,
  TableCore,
  TdCore,
} from "src/Pages/components";
import {
  NotInterestedIcon,
  DeleteForeverIcon,
  FolderOffIcon,
  CheckIcon,
  CloseIcon,
  EditIcon,
} from "src/Pages/components/Icons/Icons";
import { ActionPopoverTable } from "src/Pages/components/table/ActionPopover/ActionPopover";

import { ModalChangeCompany } from "../ChangePlan";
import { UseDeleteCompany } from "../ModalDisabledCompany/Hooks/ModalDeleteCompany";
import { UseDisabledCompany } from "../ModalDisabledCompany/Hooks/ModalDisabledCompany";

import { useHttpTableControleEmpresas } from "./Hooks/useHttpTableControleEmpresas";
import { THeadControle } from "./THead";

export const TableControle = () => {
  const { setActive_incluir, setNameSearch, setIdTable, setId } =
    useConfigPageContext();

  const { resetInputs } = useResetInputs();
  const { listControle } = usePageControle();
  const { handleGetById } = useHttpTableControleEmpresas();

  const [openModalChangeCompany, setOpenModalChangeCompany] = useState(false);
  const [idCompany, setIdCompany] = useState("");

  const {
    setOpen: setOpenDisabled,
    loading: loadingDisabled,
    handleDisabledCompany,
    open: openDisabled,
    setIsActive,
  } = UseDisabledCompany();

  const {
    setOpen: setOpenDelete,
    loading: loadingDelete,
    open: openDelete,
    setIsActive: setIsActiveDelete,
    handleDeleteCompany,
  } = UseDeleteCompany();

  const handleGetIdToEdit = (id: string) => {
    resetInputs();
    setId(id);
    setIdTable(id);
    handleGetById(id);
  };

  return (
    <TableCore
      Modals={
        <>
          <ModalDeleteCore
            loading={loadingDisabled}
            modalOpen={openDisabled}
            no="Não, Cancelar"
            titlePrimary="Você deseja desabilitar/habilitar ?"
            yes="Sim, desabilitar/habilitar !"
            onClickFalse={() => setOpenDisabled(false)}
            onClickTrue={() => handleDisabledCompany()}
          />
          <ModalDeleteCore
            loading={loadingDelete}
            modalOpen={openDelete}
            no="Não, Cancelar"
            titlePrimary="Você deseja excluir?"
            yes="Sim, Deletar !"
            onClickFalse={() => setOpenDelete(false)}
            onClickTrue={() => handleDeleteCompany()}
          />

          <ModalChangeCompany
            id={idCompany}
            openModal={openModalChangeCompany}
            setOpenModal={setOpenModalChangeCompany}
          />
        </>
      }
      THead={<THeadControle />}
      qtdList={listControle.length}
      setNameSearch={setNameSearch}
    >
      {Children.toArray(
        listControle.map((item) => {
          return (
            <TableRowCore id={item.id}>
              <TdCore
                values={
                  <ActionPopoverTable
                    optionsList={[
                      {
                        icon: <NotInterestedIcon fontSize="small" />,
                        title: `${
                          item.is_active ? "Desabilitar" : "Habilitar"
                        } acesso`,
                        onClick: () => {
                          setId(item.id);
                          setOpenDisabled(true);
                          setIsActive(!item.is_active);
                        },
                      },
                      {
                        title: "Alterar dados da empresa",
                        background: "warning",
                        onClick: () => {
                          setIdCompany(item.id);
                          setOpenModalChangeCompany(true);
                        },
                        icon: <FolderOffIcon fontSize="small" />,
                      },
                      {
                        icon: <EditIcon fontSize="small" />,
                        title: "Editar",
                        onClick: () => {
                          setId(item.id);
                          handleGetIdToEdit(item.id);
                          setActive_incluir(true);
                        },
                      },
                      {
                        icon: <DeleteForeverIcon fontSize="small" />,
                        title: `Deletar`,
                        onClick: () => {
                          setId(item.id);
                          setOpenDelete(true);
                          setIsActiveDelete(!item.is_active);
                        },
                      },
                    ]}
                  />
                }
              />
              <TdCore
                values={`${item.id} - ${item.nome_empresa}`}
                onClick={() => handleGetIdToEdit(item.id)}
              />
              <TdCore
                textAlign="left"
                values={item.name_user}
                onClick={() => handleGetIdToEdit(item.id)}
              />
              <TdCore
                textAlign="center"
                values={String(item.qtd_limit_user)}
                onClick={() => handleGetIdToEdit(item.id)}
              />

              <TdCore
                textAlign="center"
                values={
                  item.is_active ? (
                    <CheckIcon color="success" />
                  ) : (
                    <CloseIcon color="error" />
                  )
                }
                onClick={() => handleGetIdToEdit(item.id)}
              />
            </TableRowCore>
          );
        })
      )}
    </TableCore>
  );
};
