import { IconButton } from "@mui/material";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { MenuIcon } from "src/Pages/components/Icons/Icons";

export const ToggleOpenMenu = () => {
  const { openAside, setOpenAside } = useLayoutMainContext();

  return (
    <IconButton
      color="inherit"
      sx={{
        color: "#3b3b3b",
        marginLeft: "-16px",
        marginRight: { xs: "0", sm: "1rem" },
      }}
      onClick={() => setOpenAside(!openAside)}
    >
      <MenuIcon />
    </IconButton>
  );
};
