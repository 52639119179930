import { useContextReportsProduction } from "src/Contexts/ReportProduction/ReportsProductionContextContext";
import { AutoCompleteGenericMultiple } from "src/Pages/components/InputAutoCompleted/AutoCompleteGenericMultiple";
import { IPropsAutoCompleted } from "src/Pages/components/InputAutoCompleted/types";

export const SelectedModalidadesGeneric = (props: IPropsAutoCompleted) => {
  const {
    label,
    width,
    tamanho,
    minWidth,
    maxWidth,
    fullWidth,
    disabled,
    variant,
  } = props;

  const { valueAutoCompModalidades, setValueAutoCompModalidades } =
    useContextReportsProduction();

  return (
    <AutoCompleteGenericMultiple
      openInClick
      disabled={disabled}
      fullWidth={fullWidth}
      label={label}
      maxWidth={maxWidth}
      minWidth={minWidth}
      setValueAutoCompleted={setValueAutoCompModalidades}
      tamanho={tamanho}
      url="/comissoes/modalidadeList"
      valueAutoCompleted={valueAutoCompModalidades}
      variant={variant}
      width={width}
    />
  );
};
