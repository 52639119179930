import { useContextComissoes } from "src/Contexts/comissoes/ComissoesContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

export const UseGetByIdModalidade = () => {
  const { setValuesInputsModalidade } = useContextComissoes();
  const { handleGetAlert } = useLayoutMainContext();

  const handleGetById = async (id: string) => {
    await api
      .get(`/comissoes/modalidade/${id}`)
      .then(({ data }) => {
        setValuesInputsModalidade({
          name: data.name,
          is_active: data.is_active,
        });
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      );
  };

  return { handleGetById };
};
