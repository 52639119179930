import { ChangeEvent } from "react";
import { Box, MenuItem } from "@mui/material";
import { useContextComissoes } from "src/Contexts/comissoes/ComissoesContext";
import { IValuesFilterComissaoCorretora } from "src/Contexts/comissoes/ComissoesTypes";
import { valuesDefaultFilterComissaoCorretora } from "src/Contexts/comissoes/ValuesDefaultInputsComissoes/ValuesDefaultInputsComissoes";
import { UseAutoCompletedAll } from "src/Contexts/Utils/UseAutoCompletedAll";
import { AppTextField } from "src/Pages/components";
import { AutoCompleteOperadoraFilter } from "src/Pages/components/AutoCompleted/Filters/AutoCompleteOperadora";
import { ModalFilterCore } from "src/Pages/components/ModalFilterCore/Index";

import { useHttpTableComissaoCorretora } from "../table/Hooks/useHttpTableComissaoCorretora";

export const FilterSidebarComissaoCorretora = () => {
  const { handleGet } = useHttpTableComissaoCorretora();

  const { valueAutoCompOperadoraFilter, setValueAutoCompOperadoraFilter } =
    UseAutoCompletedAll();

  const { valuesFilterComissaoCorretora, setValuesFilterComissaoCorretora } =
    useContextComissoes();

  const handleChange =
    (prop: keyof IValuesFilterComissaoCorretora) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setValuesFilterComissaoCorretora({
        ...valuesFilterComissaoCorretora,
        [prop]: event.target.value,
      });
    };

  const ClearFilter = async () => {
    setValuesFilterComissaoCorretora(valuesDefaultFilterComissaoCorretora);
    setValueAutoCompOperadoraFilter({ id: "", label: "" });

    await handleGet({
      isActive: valuesDefaultFilterComissaoCorretora.isActive,
    });
  };

  const FilterSearch = async () => {
    await handleGet({
      codeOperadora: valueAutoCompOperadoraFilter.id,
      isActive: valuesFilterComissaoCorretora.isActive,
      name: valuesFilterComissaoCorretora.name,
    });
  };

  return (
    <ModalFilterCore FilterClear={ClearFilter} FilterSearch={FilterSearch}>
      <Box sx={{ "& > *": { marginBottom: "1rem" } }}>
        <AppTextField
          fullWidth
          label="Nome da comissão"
          sxStyle={{ opacity: "", pointerEvents: "auto" }}
          value={valuesFilterComissaoCorretora.name}
          variant="outlined"
          onChange={handleChange("name")}
        />
        <AutoCompleteOperadoraFilter
          fullWidth
          label="Operadora"
          variant="outlined"
        />
        <AutoCompleteOperadoraFilter
          disabled
          fullWidth
          label="Modalidade"
          variant="outlined"
        />
        <AutoCompleteOperadoraFilter
          disabled
          fullWidth
          label="Administradora"
          variant="outlined"
        />
        <AppTextField
          fullWidth
          select
          id="Tem_recibo"
          label="Comissao ativa ?"
          sxStyle={{ opacity: "", pointerEvents: "auto" }}
          value={valuesFilterComissaoCorretora.isActive}
          variant="outlined"
          onChange={handleChange("isActive")}
        >
          <MenuItem value="ALL">TODOS</MenuItem>
          <MenuItem value="TRUE">SIM</MenuItem>
          <MenuItem value="FALSE">NÃO</MenuItem>
        </AppTextField>
      </Box>
    </ModalFilterCore>
  );
};
