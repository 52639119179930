import { FC, ReactNode } from "react";
import { View } from "@react-pdf/renderer";

import { styles } from "./style";

interface ITableRowContainer {
  backgroundColor?: string;
  maxWidth?: string;
  margin?: string;
  children: ReactNode;
}

export const TableBase: FC<ITableRowContainer> = ({
  children,
  backgroundColor,
  maxWidth,
  margin,
}) => {
  return (
    <View style={{ ...styles.table, backgroundColor, maxWidth, margin }}>
      {children}
    </View>
  );
};
