import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import { styled, useTheme, Theme, CSSObject } from "@mui/material";

import { useLayoutMainContext } from "../../Contexts/MainLayoutContext";

import { AsideTitle } from "./aside/AsideTitle";

export const drawerWidth = 220;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: ".3s",
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: ".3s",
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(6)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(6.5)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const DrawerDesktop = () => {
  const { openAside } = useLayoutMainContext();

  const theme = useTheme();

  return (
    <Box>
      <CssBaseline />

      <Drawer
        open={openAside}
        sx={{
          "& .MuiPaper-root": {
            background: "#ffffff",
            boxShadow: theme.shadows[10],
            color: "white",
            zIndex: "11",
          },
        }}
        variant="permanent"
      >
        <DrawerHeader />
        <List>
          <AsideTitle />
        </List>
      </Drawer>
    </Box>
  );
};
