import { Children, useEffect } from "react";
import { MoneyRounded } from "@mui/icons-material";
import { Box, Chip, Grid } from "@mui/material";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { useContextCompany } from "src/Contexts/Company/CompanyContext";
import {
  TdCore,
  IDataTh,
  TableCore,
  ThCoreIndex,
  TableRowCore,
} from "src/Pages/components";
import { CardCore } from "src/Pages/components/CardCore/CardCore";
import { FormatDateBR } from "src/shared/Utils/FormatDateBR";
import { FormatMoney } from "src/shared/Utils/FormatMoney";

import { UseGetPaymentMethod } from "./UseGetPaymentMethod";

export const PlanActive = () => {
  const { handleGet } = UseGetPaymentMethod();
  const { userPerfil } = useAuthContext();
  const { valuesPaymentCheckout } = useContextCompany();

  useEffect(() => {
    handleGet();
  }, []);

  const THeadOperadora = () => {
    const dataTh: IDataTh[] = [
      {
        title: "Data Pagamento",
        width: "250px",
        align: "left",
      },
      {
        title: "Valor",
        align: "left",
        width: "150px",
      },
      {
        title: "Pagamento automático ",
        align: "left",
        width: "200px",
      },
      {
        title: "Status",
        align: "left",
        width: "150px",
      },
    ];

    return <ThCoreIndex key={1} dataTh={dataTh} />;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4.5}>
        <CardCore
          sxStyle={{
            margin: 0,
            marginBottom: "24px",
            "& > *": { fontWeight: 500 },
            background: `linear-gradient(180deg, #4b98fdda 1%, rgba(0, 50, 76, 0) 40%)`,
          }}
        >
          <Box display="flex" justifyContent="space-between" mb="24px">
            <Box>
              Plano {userPerfil?.checkout?.subscription?.qtd_user_plans}{" "}
              {userPerfil?.checkout?.subscription?.qtd_user_plans &&
              userPerfil?.checkout?.subscription?.qtd_user_plans > 1
                ? "usuários"
                : "usuário"}
              :{" "}
            </Box>
            <Box>
              R${" "}
              {FormatMoney(
                userPerfil?.checkout.subscription?.unit_amount_plans
              )}
            </Box>
          </Box>

          <Box display="flex" justifyContent="space-between" mb="8px">
            <Box>Status:</Box>

            <Chip
              color="success"
              label={
                userPerfil?.checkout.subscription?.active_plans
                  ? "ATIVO"
                  : "CANCELADO"
              }
              sx={{
                fontSize: ".8rem",
                padding: ".1rem",
                height: "24px",
              }}
              variant="outlined"
            />
          </Box>

          <Box display="flex" justifyContent="space-between" mb="8px">
            <Box>Método de pagamento</Box>
            <Box>Cartão de crédito</Box>
          </Box>

          <Box display="flex" justifyContent="space-between" mb="8px">
            <Box>Proxima cobrança</Box>
            <Box>DD-MM-AAAA</Box>
          </Box>
        </CardCore>
      </Grid>

      <Grid item xs={12} md={7.5}>
        <TableCore
          isNotPagination
          THead={<THeadOperadora />}
          cardAdd={{
            title: "Histórico de pagamentos",
            icon: <MoneyRounded />,
          }}
          heigthTable="calc(500px - 6rem)"
          qtdList={valuesPaymentCheckout?.length}
          sxStyle={{ margin: 0, width: "100%" }}
        >
          {Children.toArray(
            valuesPaymentCheckout?.map((item) => {
              return (
                <TableRowCore>
                  <TdCore
                    color="blue"
                    values={FormatDateBR(item.created.split("T")[0])}
                  />
                  <TdCore
                    color="green"
                    values={`R$${FormatMoney(String(item.amount))}`}
                  />
                  <TdCore values={`${item.capture_method}`} />
                  <TdCore values={`${item.status}`} />
                </TableRowCore>
              );
            })
          )}
        </TableCore>
      </Grid>
    </Grid>
  );
};
