import { useState } from "react";
import { formatString } from "@format-string/core";
import { useContextProfileContext } from "src/Contexts/ContextProfile/ContextProfile";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

export const UseGetProfile = () => {
  const { setValuesEditPerfil } = useContextProfileContext();
  const { handleGetAlert } = useLayoutMainContext();

  const [loading, setLoading] = useState(false);

  const handleGetProfile = async () => {
    setLoading(true);

    api
      .post(`/GetProfile`)
      .then(({ data }) => {
        setValuesEditPerfil({
          nome: data.nome,
          telefone_1: formatString({ type: "phone", value: data.telefone_1 }),
        });
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  return {
    loading,
    handleGet: handleGetProfile,
  };
};
