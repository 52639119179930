import { Box } from "@mui/material";
import { useContextViews } from "src/Contexts/Views/contextViews";
import { ButtonNavigationGroup } from "src/Pages/components";
import { AccordionCore } from "src/Pages/components/AccordionCore/AccordionCore";

import { IndexCardServidor } from "./Components/CardInfoServidor/Index";
import { ModalDeleteLogsErros } from "./Components/Table/ModalDeleteAll/ModalDeleteLogsErros";
import { TableDev } from "./Components/Table/TableDev";

export const PageLogsErrors = () => {
  const { NavigationControleEmpresa } = useContextViews();

  return (
    <>
      <ButtonNavigationGroup data={NavigationControleEmpresa} />

      <Box sx={{ marginTop: { xs: "1.5rem", sm: "0" } }}>
        <AccordionCore sxStyle={{ padding: 0 }} title="Lista de Logs">
          <ModalDeleteLogsErros />
          <TableDev />
        </AccordionCore>

        <AccordionCore title="Informação do servidor">
          <IndexCardServidor />
        </AccordionCore>
      </Box>
    </>
  );
};
