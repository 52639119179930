import { FC } from "react";
import { alpha, Tooltip, SxProps } from "@mui/material";
import Button, { ButtonProps } from "@mui/material/Button";

type IButtonPersonalized = ButtonProps & {
  titleToolTip?: string;
  title?: string;
  sxStyle?: SxProps;
};
export const ButtonPersonalized: FC<IButtonPersonalized> = ({
  titleToolTip = "",
  title,
  sxStyle,
  ...props
}: IButtonPersonalized) => {
  return (
    <Tooltip arrow title={titleToolTip}>
      <Button
        variant="outlined"
        {...props}
        sx={{
          backgroundColor: (theme) => alpha(theme.palette.common.black, 0.05),
          color: "#3b3b3b",
          margin: ".16rem",
          minWidth: "100px",
          boxShadow: "0 .1rem 1rem .25rem #00000015",
          padding: "4px 8px",
          transition: "background-color 0.3s",
          background: props.formNoValidate ? "#3980f5" : "",
          "&:hover": {
            backgroundColor: "#3980f5",
            opacity: 0.8,
          },
          ...sxStyle,
        }}
      >
        {title}
      </Button>
    </Tooltip>
  );
};
