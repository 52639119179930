import { ChangeEvent, useEffect } from "react";
import { Box } from "@mui/material";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { IFilterHome } from "src/Contexts/AuthContext/AuthContextTypes";
import { AppTextField, FormCore } from "src/Pages/components";

import { useHttpRankingCorretores } from "../../TableRankingCorretores/Hooks/useHttpRankingCorretores";
import { UseGetInfoHomePage } from "../../WelcomeCard/Form/Hooks/UseGetInfoHomePage";

export const FormFilterHome = () => {
  const { filterHome, setFilterHome } = useAuthContext();
  const { handleSubmit: submitCard } = UseGetInfoHomePage();
  const { handleSubmit: submitRanking } = useHttpRankingCorretores();

  const handleChange =
    (prop: keyof IFilterHome) => (event: ChangeEvent<HTMLInputElement>) => {
      setFilterHome((prevValue) => ({
        ...prevValue,
        [prop]: event.target.value,
      }));
    };

  const handles = () => {
    submitCard();
    submitRanking();
  };

  useEffect(() => {
    handles();
  }, []);

  return (
    <FormCore
      isNotCancel
      handleSubmit={handles}
      marginTopButtons="0px"
      sxStyle={{
        margin: "0",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        marginTop: { xs: "8px", md: "0px" },
      }}
      titleSubmit="Filtrar"
    >
      <Box>
        <AppTextField
          label="Inicio *"
          sxStyle={{
            width: "150px",
            opacity: "",
            pointerEvents: "auto",
            margin: { xs: "2px", sm: "4px 6px" },
          }}
          type="date"
          value={filterHome?.startDate}
          variant="outlined"
          onChange={handleChange("startDate")}
        />
        <AppTextField
          label="Final *"
          sxStyle={{
            width: "150px",
            opacity: "",
            pointerEvents: "auto",
            margin: { xs: "2px", sm: "4px 6px" },
          }}
          type="date"
          value={filterHome?.endDate}
          variant="outlined"
          onChange={handleChange("endDate")}
        />
      </Box>
    </FormCore>
  );
};
