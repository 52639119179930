import { useContextViews } from "src/Contexts/Views/contextViews";
import { ButtonNavigationGroup } from "src/Pages/components";

import { CardBodyCompany } from "./Components/CardBodyCompany";
import { CardHeaderCompany } from "./Components/CardHeaderCompany";

export const PageCompany = () => {
  const { NavigationCompanyConfig } = useContextViews();

  return (
    <>
      <ButtonNavigationGroup data={NavigationCompanyConfig} />

      <CardHeaderCompany />
      <CardBodyCompany />
    </>
  );
};
