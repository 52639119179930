import { useState } from "react";
import { useContextProfileContext } from "src/Contexts/ContextProfile/ContextProfile";
import { valuesDefaultUpdatePasswordProfile } from "src/Contexts/ContextProfile/ValuesDefaultContextProfile/ValuesDefaultContextProfile";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

export const UseFormUpdateProfilePassword = () => {
  const { handleGetAlert } = useLayoutMainContext();

  const { valuesUpdatePasswordProfile, setValuesUpdatePasswordProfile } =
    useContextProfileContext();

  const [loading, setLoading] = useState(false);

  const handleUpdatePasswordProfile = async () => {
    setLoading(true);

    const AllDatas = { ...valuesUpdatePasswordProfile };

    return api
      .put(`/updatePasswordProfile`, AllDatas)
      .then((res) => {
        handleGetAlert({ message: res.data.message });
        setValuesUpdatePasswordProfile(valuesDefaultUpdatePasswordProfile);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  const handleSubmit = () => handleUpdatePasswordProfile();

  return { handleSubmit, loading };
};
