import { createRef, ChangeEvent, useEffect } from "react";
import { Grid } from "@mui/material";
import { useContextProdutor } from "src/Contexts/produtores/ProdutoresContext";
import { ICreateGrupoDeImposto } from "src/Contexts/produtores/ProdutoresTypes";
import { AppTextField } from "src/Pages/components/AppFormComponents/AppTextField";
import { FormatMoney } from "src/shared/Utils/FormatMoney";

export const FormGrupoImposto = () => {
  const { valuesInputsGrupoDeImposto, setValuesInputsGrupoDeImposto } =
    useContextProdutor();

  const nome = createRef<HTMLInputElement>();
  const handleFocusedID = () => nome.current?.focus();

  useEffect(() => {
    handleFocusedID();
  }, []);

  const handleChange =
    (prop: keyof ICreateGrupoDeImposto, option?: "money") =>
    (event: ChangeEvent<HTMLInputElement>) => {
      if (option === "money") {
        if (event.target.value.length <= 6) {
          setValuesInputsGrupoDeImposto({
            ...valuesInputsGrupoDeImposto,
            [prop]: FormatMoney(event.target.value),
          });
        }
      } else {
        setValuesInputsGrupoDeImposto({
          ...valuesInputsGrupoDeImposto,
          [prop]: event.target.value,
        });
      }
    };

  return (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <AppTextField
          fullWidth
          inputRef={nome}
          label="Descrição *"
          sxStyle={{ opacity: "", pointerEvents: "auto", margin: 0 }}
          value={valuesInputsGrupoDeImposto?.descricao}
          variant="outlined"
          onChange={handleChange("descricao")}
        />
      </Grid>

      <Grid item xs={3}>
        <AppTextField
          fullWidth
          label="Valor *"
          sxStyle={{ opacity: "", pointerEvents: "auto", margin: 0 }}
          value={valuesInputsGrupoDeImposto?.valor}
          variant="outlined"
          onChange={handleChange("valor", "money")}
        />
      </Grid>
    </Grid>
  );
};
