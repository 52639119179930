import { CardCore } from "src/Pages/components/CardCore/CardCore";
import { CardBodyCore } from "src/Pages/components/CardCore/Components/CardBodyCore";

import { FormProfile } from "./Form/FormProfile";

export const CardConfig = () => {
  return (
    <CardCore sxStyle={{ margin: 0 }}>
      <CardBodyCore title="Editar dados">
        <FormProfile />
      </CardBodyCore>
    </CardCore>
  );
};
