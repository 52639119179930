import * as React from "react";
import { BoxProps, Fade, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { useAppThemeContext } from "src/Contexts/ThemeContextConfig";

import { FormCore } from "../AppFormComponents/FormCore";
import { DividerCore } from "../DividerCore/DividerCore";
import { CloseIcon } from "../Icons/Icons";

import { ButtonsHeaderFormFilter } from "./Components/ButtonsHeaderFormFilter";
import { ButtonSubmitFormFilter } from "./Components/ButtonSubmitFormFilter";

export const useStyles = makeStyles({
  customScrollbar: {
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#d4d4d4c3",
      borderRadius: "8px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#d8d8d8",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
  },
});

const style = {
  position: "fixed",
  top: "0",
  right: "0",
  width: 450,
  maxWidth: "100%",
  height: "100%",
  boxShadow: 12,
  padding: "1rem",
  overflow: "auto",
  maxHeight: "100%",
};

type IModalFilterCore = BoxProps & {
  children: React.ReactNode;
  FilterClear: () => Promise<void>;
  FilterSearch: () => Promise<void>;
};

export const ModalFilterCore = ({
  children,
  FilterClear,
  FilterSearch,
}: IModalFilterCore) => {
  const { themeName } = useAppThemeContext();

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const classes = useStyles();

  return (
    <>
      <ButtonsHeaderFormFilter
        FilterClear={FilterClear}
        handleOpen={handleOpen}
      />

      <Modal
        closeAfterTransition
        aria-describedby="modal-modal-description"
        aria-labelledby="modal-modal-title"
        open={open}
        onClose={handleClose}
      >
        <Fade in={open}>
          <Box
            sx={{
              ...style,
              background: themeName === "dark" ? "#353741" : "#fff",
              borderRadius: "12px 0 0 12px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              <Typography component="h2" id="modal-modal-title" variant="h6">
                FILTROS
              </Typography>

              <IconButton aria-label="delete" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>

            <DividerCore sxStyle={{ marginBottom: ".8rem" }} />

            <FormCore sxStyle={{ margin: "0" }}>
              <Box
                className={classes.customScrollbar}
                sx={{
                  overflow: "auto",
                  minHeight: `calc(100vh - 175px)`,
                  maxHeight: {
                    xs: `calc(100vh - 175px)`,
                  },
                }}
              >
                <Box sx={{ padding: "0 14px 0 4px" }}>{children}</Box>
              </Box>

              <DividerCore sxStyle={{ marginBottom: "1rem" }} />

              <ButtonSubmitFormFilter
                FilterClear={FilterClear}
                FilterSearch={FilterSearch}
              />
            </FormCore>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};
