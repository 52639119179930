import { Children, useEffect, useState } from "react";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { IListBordero } from "src/Contexts/Lancamentos/Contrato/ContratosTypes";
import { UseAutoCompletedAll } from "src/Contexts/Utils/UseAutoCompletedAll";
import { ModalDeleteCore, TableCore, TdCore } from "src/Pages/components";
import { UseVerifyIsPermissionsUser } from "src/Pages/components/hooks/UseVerifyIsPermissionsUser";
import {
  EditIcon,
  CheckIcon,
  CloseIcon,
  MoneyOffIcon,
  BackspaceIcon,
  FindInPageIcon,
  DeleteForeverIcon,
  CurrencyExchangeIcon,
} from "src/Pages/components/Icons/Icons";
import { UseDelete } from "src/Pages/components/ModalDeleteCore/Hooks/UseDelete";
import { TableRowCore } from "src/Pages/components/table/@Core/TableRowCore/TableRowCore";
import { ActionPopoverTable } from "src/Pages/components/table/ActionPopover/ActionPopover";
import { FormatDateBR, GetDateUSSemValue } from "src/shared/Utils/FormatDateBR";
import { FormatMoney } from "src/shared/Utils/FormatMoney";
import { handleFormatDateIfIsNull } from "src/shared/Utils/handleFormatDateIfIsNull";
import { cpfOurCnpj } from "src/shared/Utils/MaskCpfCnpj";
import { valuesPermissions } from "src/shared/Utils/ValuesPermissions";

import { ModalCancelarPagamentoBordero } from "../Components/CancelarPagamento/ModalCancelarPagamento";
import { FilterSidebarBordero } from "../Components/FilterSidebar/FilterSidebar";
import { ModalCancelarReceberParcela } from "../Components/ModalCancelarReceberParcela/ModalCancelarReceberParcela";
import { ModalEditarParcela } from "../Components/ModalEditarParcela/FormEditarParcela";
import { ModalNovaParcela } from "../Components/ModalNovaParcela";
import { ModalReceberParcela } from "../Components/ModalParcelaReceber";
import { ModalUploadCommission } from "../Components/ModalUploadCommission";

import { useHttpTableBordero } from "./Hooks/useHttpTableBordero";
import { THeadBordero } from "./THead";

export const TableBordero = () => {
  const { handleVerifyPermissionItem } = UseVerifyIsPermissionsUser();
  const { handleGet } = useHttpTableBordero();

  const {
    setNameSearch,
    itensPerPage,
    currentPage,
    nameSearch,
    attTable,
    setId,
  } = useConfigPageContext();

  const { listBordero, valuesFilterBordero, setValuesInputsBorderoParcela } =
    useContextContrato();

  const { valueAutoCompOperadoraFilter, valueAutoCompAdministradora } =
    UseAutoCompletedAll();

  const [openModalReceberParcela, setOpenModalReceberParcela] = useState(false);
  const [openModalCancelReceber, setOpenModalCancelReceber] = useState(false);
  const [openModalEditarParcela, setOpenModalEditarParcela] = useState(false);
  const [openModalNovaParcela, setOpenModalNovaParcela] = useState(false);
  const [openModalUploadCommission, setOpenModalUploadCommission] =
    useState(false);
  const [openModalCancelarPagamento, setOpenModalCancelarPagamento] =
    useState(false);

  const [openModalCancelarProducer, setOpenModalCancelarProducer] = useState<
    "CORRETOR" | "SUPERVISOR" | "GERENTE"
  >("CORRETOR");

  // const handleGetIdToEdit = (id: string) => {
  //   resetInputs();
  //   setId(id);
  // };

  useEffect(() => {
    handleGet({
      currentPage,
      itensPerPage,
      nameSearch,

      codeOperadora: valueAutoCompOperadoraFilter.id,
      codProposta: valuesFilterBordero.cod_proposta,
      codeAdministradora: valueAutoCompAdministradora.id,

      startDateSignature: valuesFilterBordero.startDateSignature,
      endDateSignature: valuesFilterBordero.endDateSignature,

      endDueDate: valuesFilterBordero.endDueDate,
      startDueDate: valuesFilterBordero.startDueDate,

      startDateEmission: valuesFilterBordero.startDateEmission,
      endDateEmission: valuesFilterBordero.endDateEmission,

      startDateReceipt: valuesFilterBordero.startDateReceipt,
      endDateReceipt: valuesFilterBordero.endDateReceipt,

      installmentNumber: valuesFilterBordero.installmentNumber,

      installmentReceipt: valuesFilterBordero.installmentReceipt,

      isInstallmentBroker: valuesFilterBordero.isInstallmentBroker,
      isInstallmentSupervisor: valuesFilterBordero.isInstallmentSupervisor,
      isInstallmentManager: valuesFilterBordero.isInstallmentManager,

      client: {
        nameOrRazao: valuesFilterBordero.nameOrRazao,
        cpfCnpj: valuesFilterBordero.cpfCnpj,
      },
    });
  }, [attTable, itensPerPage, currentPage, nameSearch]);

  const {
    handleDelete,
    open: openModalDelete,
    loading: loadingDelete,
    setId: setIdModalDelete,
    setOpen: setOpenModalDelete,
  } = UseDelete({ url: "/bordero" });

  const handleReceberParcela = (item: IListBordero) => {
    setId(item.id);
    setOpenModalReceberParcela(true);

    setValuesInputsBorderoParcela({
      id: item.id,
      contratos_vidas: item.contratos_vidas,
      observacao: item.observacao || "",
      valor_recebido: !item.valor_recebido
        ? FormatMoney(item.valor_contrato)
        : FormatMoney(item.valor_recebido),
      data_recebimento: !item.data_recebimento
        ? `${GetDateUSSemValue()}`
        : handleFormatDateIfIsNull(item.data_recebimento),
      data_previsao: handleFormatDateIfIsNull(item.data_previsao),
    });
  };

  return (
    <TableCore
      Modals={
        <>
          <ModalReceberParcela
            openModal={openModalReceberParcela}
            setOpenModal={setOpenModalReceberParcela}
          />

          <ModalCancelarReceberParcela
            modalDelete={openModalCancelReceber}
            setModalDelete={setOpenModalCancelReceber}
          />

          <ModalCancelarPagamentoBordero
            funcao_produtor={openModalCancelarProducer}
            modalCancelarPagamento={openModalCancelarPagamento}
            setModalCancelarPagamento={setOpenModalCancelarPagamento}
          />

          <ModalEditarParcela
            openModal={openModalEditarParcela}
            setOpenModal={setOpenModalEditarParcela}
          />

          <ModalDeleteCore
            loading={loadingDelete}
            modalOpen={openModalDelete}
            no="Não, Cancelar"
            titlePrimary="Você deseja excluir parcela ?"
            yes="Sim, Deletar !"
            onClickFalse={() => setOpenModalDelete(false)}
            onClickTrue={() => handleDelete()}
          />
        </>
      }
      THead={<THeadBordero />}
      cardAdd={{
        title: "Parcelas",
        icon: <FindInPageIcon />,
        buttonAdd: [
          <ModalUploadCommission
            key={1}
            openModal={openModalUploadCommission}
            setOpenModal={setOpenModalUploadCommission}
          />,
          <ModalNovaParcela
            key={1}
            openModal={openModalNovaParcela}
            setOpenModal={setOpenModalNovaParcela}
          />,
        ],
      }}
      filter={<FilterSidebarBordero />}
      qtdList={listBordero.length}
      setNameSearch={setNameSearch}
    >
      {Children.toArray(
        listBordero.map((item) => {
          return (
            <TableRowCore id={item.id}>
              <TdCore
                values={
                  <ActionPopoverTable
                    optionsList={[
                      {
                        icon: <CurrencyExchangeIcon fontSize="small" />,
                        title: "Receber",
                        background: "success",
                        isDisabled:
                          handleVerifyPermissionItem(
                            valuesPermissions.BORDERO.RECEBER.id
                          ) || item.is_parcela_recebida,
                        onClick: () => handleReceberParcela(item),
                      },
                      {
                        icon: (
                          <BackspaceIcon
                            fontSize="small"
                            sx={{ fontSize: ".9rem" }}
                          />
                        ),
                        background: "warning",
                        title: "Zerar recebimento",
                        onClick: () => {
                          setId(item.id);
                          setOpenModalCancelReceber(true);
                        },
                        isDisabled:
                          handleVerifyPermissionItem(
                            valuesPermissions.BORDERO.ZERAR_RECEBIMENTO.id
                          ) || !item.is_parcela_recebida,
                      },
                      {
                        icon: <MoneyOffIcon fontSize="small" />,
                        title: "Zerar pagamento CORRETOR",
                        background: "error",
                        onClick: () => {
                          setId(item.id);
                          setOpenModalCancelarPagamento(true);
                          setOpenModalCancelarProducer("CORRETOR");
                        },
                        isDisabled:
                          handleVerifyPermissionItem(
                            valuesPermissions.BORDERO.VOLTAR_PAGAMENTO.id
                          ) || !item.is_recibo_corretor,
                      },
                      {
                        icon: <MoneyOffIcon fontSize="small" />,
                        title: "Zerar pagamento SUPERVISOR",
                        background: "error",
                        onClick: () => {
                          setId(item.id);
                          setOpenModalCancelarPagamento(true);
                          setOpenModalCancelarProducer("SUPERVISOR");
                        },
                        isDisabled:
                          handleVerifyPermissionItem(
                            valuesPermissions.BORDERO.VOLTAR_PAGAMENTO.id
                          ) || !item.is_recibo_supervisor,
                      },
                      {
                        icon: <MoneyOffIcon fontSize="small" />,
                        title: "Zerar pagamento GERENTE",
                        background: "error",
                        onClick: () => {
                          setId(item.id);
                          setOpenModalCancelarPagamento(true);
                          setOpenModalCancelarProducer("GERENTE");
                        },
                        isDisabled:
                          handleVerifyPermissionItem(
                            valuesPermissions.BORDERO.VOLTAR_PAGAMENTO.id
                          ) || !item.is_recibo_gerente,
                      },
                      {
                        icon: <EditIcon fontSize="small" />,
                        title: "Editar",
                        background: "warning",
                        isDisabled: handleVerifyPermissionItem(
                          valuesPermissions.BORDERO.EDITAR.id
                        ),
                        onClick: () => {
                          setId(item.id);
                          setOpenModalEditarParcela(true);
                        },
                      },
                      {
                        icon: <DeleteForeverIcon fontSize="small" />,
                        title: "Excluir",
                        isDisabled: handleVerifyPermissionItem(
                          valuesPermissions.BORDERO.DELETAR.id
                        ),
                        background: "error",
                        onClick: () => {
                          setIdModalDelete(item.id);
                          setOpenModalDelete(true);
                        },
                      },
                    ]}
                  />
                }
              />

              <TdCore
                color={item.status_status === 1 ? "#3cb916" : "#D0021B"}
                values={item.status_descricao.toUpperCase()}
              />
              <TdCore
                isLimit
                isLimitQTD={35}
                textAlign="left"
                values={`${item.code_operadoras} - ${item.name_operadoras}`}
              />

              <TdCore
                borderBottom={"1px solid  #0e6aff"}
                textAlign="center"
                values={item.cod_proposta}
              />
              <TdCore
                borderBottom={`2px solid #ed6c02`}
                textAlign="center"
                values={item.numero_parcela}
              />

              <TdCore
                borderBottom={"1px solid  #0e6aff"}
                textAlign="center"
                values={item.contratos_vidas}
              />

              <TdCore
                borderBottom={"1px solid  #0e6aff"}
                color="#0e6aff"
                textAlign="center"
                values={
                  item.data_vencimento_parcela
                    ? FormatDateBR(item.data_vencimento_parcela)
                    : ""
                }
              />

              <TdCore
                textAlign="center"
                values={
                  item.is_parcela_recebida ? (
                    <CheckIcon color="success" />
                  ) : (
                    <CloseIcon color="error" />
                  )
                }
              />
              <TdCore
                color="#3cb916"
                textAlign="left"
                values={
                  item.is_parcela_recebida
                    ? `R$ ${item.valor_recebido || ""}`
                    : ""
                }
              />
              <TdCore
                borderBottom={item.data_recebimento ? "1px solid  #0e6aff" : ""}
                color="#0e6aff"
                textAlign="center"
                values={
                  item.data_recebimento
                    ? FormatDateBR(item.data_recebimento)
                    : ""
                }
              />
              <TdCore
                textAlign="center"
                tooltip={
                  item.data_recibo_corretor
                    ? FormatDateBR(item.data_recibo_corretor)
                    : ""
                }
                values={
                  item.is_recibo_corretor ? (
                    <CheckIcon color="success" />
                  ) : (
                    <CloseIcon color="error" />
                  )
                }
              />
              <TdCore
                textAlign="center"
                tooltip={
                  item.data_recibo_supervisor
                    ? FormatDateBR(item.data_recibo_supervisor)
                    : ""
                }
                values={
                  item.is_recibo_supervisor ? (
                    <CheckIcon color="success" />
                  ) : (
                    <CloseIcon color="error" />
                  )
                }
              />
              <TdCore
                textAlign="center"
                tooltip={
                  item.data_recibo_gerente
                    ? FormatDateBR(item.data_recibo_gerente)
                    : ""
                }
                values={
                  item.is_recibo_gerente ? (
                    <CheckIcon color="success" />
                  ) : (
                    <CloseIcon color="error" />
                  )
                }
              />

              <TdCore color="#3cb916" values={`R$ ${item.valor_contrato}`} />
              <TdCore
                color="#3cb916"
                values={`R$ ${item.valor_comissao_corretora}`}
              />
              <TdCore
                color="#3cb916"
                values={`R$ ${item.valor_comissao_corretor}`}
              />
              <TdCore
                color="#3cb916"
                values={`R$ ${item.valor_comissao_supervisor || "0.00"}`}
              />
              <TdCore
                color="#3cb916"
                values={`R$ ${item.valor_comissao_gerente || "0.00"}`}
              />
              <TdCore
                color="#3cb916"
                values={`R$ ${item.valor_lucro_parcela || "0.00"}`}
              />

              <TdCore
                color=" #3980f5"
                values={`${
                  item.porcentagem_comissao_corretora.replace(".", ",") ||
                  "0,00%"
                }%`}
              />
              <TdCore
                color=" #3980f5"
                values={`${
                  item.porcentagem_comissao_corretor.replace(".", ",") ||
                  "0,00%"
                }%`}
              />
              <TdCore
                color=" #3980f5"
                values={`${
                  item.porcentagem_comissao_supervisor.replace(".", ",") ||
                  "0,00"
                }%`}
              />
              <TdCore
                color=" #3980f5"
                values={`${
                  item.porcentagem_comissao_gerente.replace(".", ",") || "0,00%"
                }%`}
              />
              <TdCore values={`${item.id_corretor} - ${item.nome_produtor}`} />
              <TdCore
                borderBottom={`1px solid ${
                  item.tipo_contrato === "PF" ? "#e4a022" : "#9357f5"
                }`}
                color={item.tipo_contrato === "PF" ? "#e4a022" : "#9357f5"}
                textAlign="center"
                values={item.tipo_contrato.toUpperCase()}
              />
              <TdCore
                values={
                  item.tipo_contrato === "PF"
                    ? item.titular_pf
                    : item.razao_social_pj
                }
              />
              <TdCore
                textAlign="left"
                values={
                  item.tipo_contrato === "PF"
                    ? cpfOurCnpj({ text: item.cpf_cnpj, type: "cpf" })
                    : cpfOurCnpj({ text: item.cpf_cnpj, type: "cnpj" })
                }
              />
              <TdCore values={item.observacao} />
            </TableRowCore>
          );
        })
      )}
    </TableCore>
  );
};
