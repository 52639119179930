import { FC } from "react";
import { Modal, ModalProps } from "@mui/material";

import { ModalContentCore } from "./Components/ModalContentCore";

type IModalWelcome = ModalProps;

export const ModalCoreBase: FC<IModalWelcome> = ({ children, ...props }) => {
  return (
    <Modal {...props}>
      <ModalContentCore>{children}</ModalContentCore>
    </Modal>
  );
};
