import { formatString } from "@format-string/core";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { useContextUsuarios } from "src/Contexts/Usuarios/UsuariosContext";
import { UseAutoCompletedAll } from "src/Contexts/Utils/UseAutoCompletedAll";
import { api } from "src/shared/setup/API/api";
import { IReturnGetUserById } from "src/TypesReturnRequest";

export const UseGetByIdUser = () => {
  const { setValuesInputsUsuarios } = useContextUsuarios();
  const { handleGetAlert } = useLayoutMainContext();

  const { setValueAutoCompletedFiliais, setValueAutoCompPerfilAcesso } =
    UseAutoCompletedAll();

  const handleGetById = async (id: string) => {
    await api
      .get<IReturnGetUserById>(`/userByIdTenant/${id}`)
      .then(({ data: { user } }) => {
        setValuesInputsUsuarios({
          email: user.email,
          nome: user?.nome,
          horario_inicial: user?.hoursAccess.start,
          horario_final: user?.hoursAccess.end,
          type_user: user.type_user,
          telefone1: formatString({ type: "phone", value: user.telefone1 }),
        });
        setValueAutoCompPerfilAcesso({
          label: user?.permission.nome,
          id: user?.permission.id,
        });
        setValueAutoCompletedFiliais({
          label: user?.filial.nome,
          id: user?.filial.id,
        });
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      );
  };

  return { handleGetById };
};
