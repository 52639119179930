import { Children } from "react";
import {
  Box,
  DialogTitle,
  LinearProgress,
  linearProgressClasses,
} from "@mui/material";
import { DividerCore } from "src/Pages/components/DividerCore/DividerCore";
import { TitleWithValueCore } from "src/Pages/components/TitleWithValueCore/TitleWithValueCore";
import { FormatDateBR } from "src/shared/Utils/FormatDateBR";

import { IValueComissaoModal } from "../../TelasSistemas/2-Comissoes/4-ComissaoCorretora/components/ModalComissaoCorretora/Index";

interface ModalViewComissaoCorretora {
  dataCommission: IValueComissaoModal;
}

export const ModalCommissionProgress = ({
  dataCommission,
}: ModalViewComissaoCorretora) => {
  return (
    <>
      <DialogTitle
        sx={{
          padding: "0",
          "& > *": { fontSize: ".9rem" },
          "& > *:not(:last-child)": { marginBottom: "4px" },
        }}
      >
        <TitleWithValueCore
          title="Operadora:"
          value={`${dataCommission.operadora_code} - ${dataCommission.operadora_name}`}
        />
        <TitleWithValueCore
          title="Modalidade:"
          value={`${dataCommission.modalidade_code} - ${dataCommission.modalidade_name}`}
        />
        {dataCommission.administradora_code && (
          <TitleWithValueCore
            title="Administradora:"
            value={`${dataCommission.administradora_code} - ${dataCommission.administradora_name}`}
          />
        )}
        <TitleWithValueCore
          title="Vigência:"
          value={FormatDateBR(dataCommission.date_vigencia)}
        />
      </DialogTitle>

      <DividerCore />

      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>Parcelas</Box>
          <Box>Comissão</Box>
        </Box>

        <Box sx={{ border: "1px solid inherit" }}>
          {Children.toArray(
            dataCommission?.parcelas.map((item) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    margin: ".7rem 0",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ width: "20px" }}>{item.parc}</Box>
                  <LinearProgress
                    style={{
                      flex: 1,
                      margin: "0 8px",
                      height: ".5rem",
                      borderRadius: "1rem",
                    }}
                    sx={{
                      [`&.${linearProgressClasses.colorPrimary}`]: {
                        backgroundColor: "#d6d6d6",
                      },
                      [`& .${linearProgressClasses.bar}`]: {
                        borderRadius: "10px #d6d6d6",
                        backgroundColor: "#1a90ff",
                      },
                    }}
                    value={Number(item.value)}
                    variant="determinate"
                  />
                  <Box sx={{ width: "40px" }}>{item.value}%</Box>
                </Box>
              );
            })
          )}
        </Box>
      </Box>
    </>
  );
};
