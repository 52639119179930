import { useState } from "react";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { useContextRecoverPassword } from "src/Contexts/RecoverPassword/RecoverPasswordContext";
import { valuesDefaultInputsGetCompaniesRecoverPassword } from "src/Contexts/RecoverPassword/valuesDefaultInputsRecoverPassword/valuesDefaultInputsRecoverPassword";
import { api } from "src/shared/setup/API/api";

export const UseFormRecoverPassword = () => {
  const { handleGetAlert } = useLayoutMainContext();

  const [loading, setLoading] = useState(false);

  const {
    setValuesInputsGetCompaniesRecoverPassword,
    valuesInputsGetCompaniesRecoverPassword,
    setGetCompaniesRecoverPassword,
  } = useContextRecoverPassword();

  const handleCreateProdutores = async () => {
    if (!valuesInputsGetCompaniesRecoverPassword.email)
      return handleGetAlert({ message: `Digite um email` });

    setLoading(true);

    return api
      .post("/recoverPassword/companies", {
        email: valuesInputsGetCompaniesRecoverPassword.email,
      })
      .then(async ({ data }) => {
        setGetCompaniesRecoverPassword(data.data);
        setValuesInputsGetCompaniesRecoverPassword(
          valuesDefaultInputsGetCompaniesRecoverPassword
        );
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  return {
    handleSubmit: handleCreateProdutores,
    loading,
  };
};
