import React, { useEffect } from "react";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { valuesDefaultUploadCommission } from "src/Contexts/Lancamentos/Contrato/valuesDefaultInputsContratos/ValueDefaultInputContratos";
import { ModalCore } from "src/Pages/components";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";
import { UseVerifyIsPermissionsUser } from "src/Pages/components/hooks/UseVerifyIsPermissionsUser";
import { AddCardIcon } from "src/Pages/components/Icons/Icons";
import { valuesPermissions } from "src/shared/Utils/ValuesPermissions";

import { FormUploadCommission } from "./FormUploadCommission";
import { UseFormUploadCommission } from "./Hooks/UseFormUploadCommission";
import { UseGetGetTypesUploadCommission } from "./Hooks/UseGetGetTypesUploadCommission";

interface IModalUploadCommission {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalUploadCommission = ({
  openModal,
  setOpenModal,
}: IModalUploadCommission) => {
  const { handleGetNovaParcelaById } = UseGetGetTypesUploadCommission();
  const { handleVerifyPermissionItem } = UseVerifyIsPermissionsUser();
  const { setValuesUploadCommission } = useContextContrato();

  useEffect(() => {
    if (openModal) {
      const handle = () => handleGetNovaParcelaById();

      handle();
    } else {
      setValuesUploadCommission(valuesDefaultUploadCommission);
    }
  }, [openModal]);

  const { handleUploadCommission, loading: loadingUploadCommission } =
    UseFormUploadCommission({ setOpenModal });

  return (
    <>
      <ButtonCore
        disabled={handleVerifyPermissionItem(
          valuesPermissions.BORDERO.RECEBER.id
        )}
        startIcon={<AddCardIcon />}
        title="Upload de comissão"
        onClick={() => setOpenModal(true)}
      />

      <ModalCore
        loadingSubmit={loadingUploadCommission}
        open={openModal}
        setOpen={setOpenModal}
        sxStyle={{ width: "650px" }}
        title="Upload de comissão"
        titleSubmit="Enviar"
        onChange={handleUploadCommission}
      >
        <FormUploadCommission />
      </ModalCore>
    </>
  );
};
