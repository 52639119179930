import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { useContextViews } from "src/Contexts/Views/contextViews";
import { PageHome } from "src/Pages/TelasSistemas/Home/PageHome";
import { PageNotFound } from "src/Pages/TelasSistemas/Utils/NotFound/NotFound";
import { UrlConfig } from "src/shared/Utils/paths";

export const PrivateRoutes = () => {
  const { ...navigationsArr } = useContextViews();

  const { pathname } = useLocation();

  return (
    <Routes>
      {Object.values(navigationsArr).map((arrayValues) => {
        return arrayValues.map((routes) => {
          return (
            routes.isView && <Route element={routes.element} path={routes.to} />
          );
        });
      })}

      <Route element={<PageHome />} path={UrlConfig.home} />

      {pathname === "/" && (
        <Route element={<Navigate to={UrlConfig.home} />} path="*" />
      )}

      {pathname === "/login" && (
        <Route element={<Navigate to={UrlConfig.home} />} path="*" />
      )}

      <Route element={<PageNotFound />} path="*" />
    </Routes>
  );
};
