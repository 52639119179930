import { useEffect, useState } from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { CardPlans } from "src/Pages/components/CardPlans/CardPlans";
import { api } from "src/shared/setup/API/api";

import { handleGetAllPlansDetails } from "./CardListDetails/CardListDetails";
import { CardPersonalizedDetails } from "./CardPersonalizedDetails/CardPersonalizedDetails";

export const handleRedirect = (url: string) => {
  const newWindow = window.open(url, "_blank");
  if (
    !newWindow ||
    newWindow.closed ||
    typeof newWindow.closed === "undefined"
  ) {
    // Fallback para window.location.href se window.open for bloqueado
    window.location.href = url;
  }
};

export const CardPlan = () => {
  const { openAside } = useLayoutMainContext();
  const { handleGetAlert } = useLayoutMainContext();

  const theme = useTheme();
  const isBreakPoint = useMediaQuery(theme.breakpoints.down("lg"));

  const valueInicial = 200.0;
  const valueUser = 100.0;

  const [countValueTotal, setValueTotal] = useState<number>(valueInicial);
  const [countUsers, setCountUser] = useState<number>(3);

  const handleClick = (countUsers: number) => {
    api
      .post("/checkoutPayment/checkout", {
        qtdUser: countUsers,
      })
      .then((data) => handleRedirect(data.data.url))
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      );
  };

  useEffect(() => {
    setValueTotal((countUsers - 1) * valueUser + valueInicial);
  }, [countUsers]);

  return (
    <Grid
      container
      spacing={{ xs: 3, md: 2 }}
      sx={{ marginBottom: "2rem", alignItems: "center" }}
    >
      <Grid item md={openAside && isBreakPoint ? 12 : 4} xs={12}>
        <CardPlans
          data={{
            title: "Bronze",
            subTitle: "Plano ideal para você que esta inciando",
            value: 200.0,
            details: handleGetAllPlansDetails(1).map((item) => item),
            onClick: () => handleClick(1),
          }}
        />
      </Grid>

      <Grid item md={openAside && isBreakPoint ? 12 : 4} xs={12}>
        <CardPlans
          data={{
            title: "Custom",
            subTitle: "Plano ideal para grandes equipes de ADM",
            value: countValueTotal,
            onClick: () => handleClick(countUsers),
            details: handleGetAllPlansDetails(countUsers).filter(
              (item) => item.isDefault === true
            ),
            personalizedPlan: (
              <CardPersonalizedDetails
                countUsers={countUsers}
                setCountUser={setCountUser}
              />
            ),
          }}
        />
      </Grid>

      <Grid item md={openAside && isBreakPoint ? 12 : 4} xs={12}>
        <CardPlans
          data={{
            title: "Prata",
            subTitle: "Plano ideal para pequenas empresas",
            value: 300.0,
            onClick: () => handleClick(2),
            details: handleGetAllPlansDetails(2).map((item) => item),
          }}
        />
      </Grid>
    </Grid>
  );
};
