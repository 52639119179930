interface IListFaq {
  title: string;
  description: string;
  defaultExpanded: boolean;
}

export const ListFaq: IListFaq[] = [
  {
    title: "Quais são os planos de mensalidade oferecidos pelo Brokeris ?",
    description: `Temos um plano com todas funcionalidades e você precisar
  personalizar a quantidade de usuários.`,
    defaultExpanded: true,
  },
  {
    title: "Posso alterar meu plano a qualquer momento ?",
    description: `Sim, solicite um novo plano.`,
    defaultExpanded: false,
  },
  {
    title: "Existe um período de teste gratuito ?",
    description: `Sim, 15 dias de avaliação com tudo liberado.`,
    defaultExpanded: false,
  },
  {
    title: `Existe um número máximo de produtores
    (Corretores/Supervisores/Gerentes) ?`,
    description: `Não, é ilimitado o cadastro dos Corretores/Supervisores/Gerentes.`,
    defaultExpanded: false,
  },

  {
    title: "Como faço para cancelar meu plano ?",
    description: `Você pode solicitar o cancelamento a qualquer momento.`,
    defaultExpanded: false,
  },
  {
    title: "O Brokeris oferece suporte técnico ?",
    description: `Sim, nossa equipe esta disponível para manutenção básica em
    horário comercial e para problemas que limita o uso da plataforma
    tem o suporte 24h.`,
    defaultExpanded: false,
  },
  {
    title: "O sistema é atualizado regularmente ?",
    description: `Sim, o Brokeris tem atualização semanal, toda atualização você
    será notificado.`,
    defaultExpanded: false,
  },
  {
    title: "Tenho direito a treinamento ?",
    description: `Sim, solicite o treinamento, normalmente em 1 semana você fica
    apto 100%.`,
    defaultExpanded: false,
  },
  {
    title: "Os preços dos planos podem ser alterados ?",
    description:
      "Nosso plano é ajustado conforme IGPM que acompanha a inflação anual.",
    defaultExpanded: false,
  },
  {
    title: "Posso acessar pelo celular ?",
    description: `Sim, o Brokeris é 100% online, você pode acessar de qualquer
    dispositivo com internet.`,
    defaultExpanded: false,
  },
  {
    title: "Preciso instalar algumas coisa no meu computador ?",
    description: `Não, o Brokeris é 100% online, você precisa de um navegador
    (Google Chrome, Microsoft Edge, Apple Safari, Opera, Mozilla Firefox).`,
    defaultExpanded: false,
  },
  {
    title: "Meus dados estarão seguros ?",
    description: `       Sim. Usamos os servidores mais modernos do mercado, os mesmos de
    grandes marcas como Netflix e iFood..`,
    defaultExpanded: false,
  },
  {
    title: "E se eu tiver mais algumas perguntas ?",
    description: `Nós ficaremos felizes em respondê-las. Entre em contato pelo whats
    (11) 98573-7008.`,
    defaultExpanded: false,
  },
];
