import { CircularProgress } from "@mui/material";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";

import { UseFormSendLinkRecoverPassword } from "./Hooks/UseFormRecoverPassword";
import { SelectCompanyPassword } from "./SelectCompanyP";

export const SelectCompanyRecoverPassword = () => {
  const {
    handleSubmit: handleSubmitSendLinkRecoverPassword,
    loading: loadingSubmitSendLinkRecoverPassword,
  } = UseFormSendLinkRecoverPassword();

  return (
    <>
      <SelectCompanyPassword />

      <ButtonCore
        fullWidth
        disabled={loadingSubmitSendLinkRecoverPassword}
        endIcon={
          loadingSubmitSendLinkRecoverPassword && (
            <CircularProgress size={20} sx={{ color: "black", ml: 4 }} />
          )
        }
        title="Recuperar senha"
        onClick={handleSubmitSendLinkRecoverPassword}
      />
    </>
  );
};
