import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { IAutoCompleted } from "src/Contexts/types/ProdutoresContext.Types";

export const UseValuesDefaultInputUsers = () => {
  const { userPerfil } = useAuthContext();

  const valueDefaultAutoCompletedFiliais: IAutoCompleted = {
    label: userPerfil?.filial.nome as string,
    id: userPerfil?.filial.id.toString() as string,
  };

  return {
    valueDefaultAutoCompletedFiliais,
  };
};
