import { formatRemove } from "@format-string/core";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { useContextProdutor } from "src/Contexts/produtores/ProdutoresContext";
import { api } from "src/shared/setup/API/api";

interface IHandleGet {
  currentPage?: number;
  itensPerPage?: number;
  nameSearch?: string;

  codProducer?: string;
  nomeProducer?: string;
  cpfProducer?: string;

  isActiveProducer: "TRUE" | "FALSE" | "ALL";
  functionProducer?: "ALL" | "CORRETOR" | "SUPERVISOR" | "GERENTE";
}

export const useHttpTableProdutor = () => {
  const { handleGetAlert } = useLayoutMainContext();
  const {
    setQtd_registros,
    setLoadingTable,
    itensPerPage,
    currentPage,
    nameSearch,
  } = useConfigPageContext();

  const { setListProdutor } = useContextProdutor();

  const handleGet = async (body: IHandleGet) => {
    setLoadingTable(true);

    api
      .post("/paginationProdutor", {
        ...body,
        cpfProducer: formatRemove(body.cpfProducer || ""),
        limit: itensPerPage,
        filter: nameSearch,
        page: currentPage,
      })
      .then((res) => {
        setListProdutor(res.data.data);
        setQtd_registros(res.data.count);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoadingTable(false));
  };

  return { handleGet };
};
