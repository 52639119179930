import { ChangeEvent, useEffect } from "react";
import { Box, MenuItem } from "@mui/material";
import { useContextLogs } from "src/Contexts/Logs/LogsContext";
import { IValuesFilterLogs } from "src/Contexts/Logs/LogsContextTypes";
import { valuesDefaultFilterLogs } from "src/Contexts/Logs/valuesDefaultInputsLogs/valuesDefaultInputsLogs";
import { AppTextField } from "src/Pages/components";
import { ModalFilterCore } from "src/Pages/components/ModalFilterCore/Index";

import { UseGetListActionDistinct } from "../TableProducer/hooks/UseGetListActionDistinct";
import { useHttpTableLogs } from "../TableProducer/hooks/useHttpTableLogs";

export const FilterSidebarLogs = () => {
  const { valuesFilterLogs, setValuesFilterLogs } = useContextLogs();

  const { handleGet } = useHttpTableLogs();

  const { handleGetListActionDistinct, listAction } =
    UseGetListActionDistinct();

  useEffect(() => {
    handleGetListActionDistinct();
  }, []);

  const ClearFilter = async () => {
    setValuesFilterLogs(valuesDefaultFilterLogs);

    await handleGet({});
  };

  const FilterSearch = async () => {
    await handleGet({
      start_data: valuesFilterLogs.start_data,
      end_data: valuesFilterLogs.end_data,
      action: valuesFilterLogs.action,
      user: valuesFilterLogs.user,
    });
  };

  const handleChange =
    (prop: keyof IValuesFilterLogs) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setValuesFilterLogs((prevEvent) => ({
        ...prevEvent,
        [prop]: event.target.value,
      }));
    };

  return (
    <ModalFilterCore FilterClear={ClearFilter} FilterSearch={FilterSearch}>
      <Box
        sx={{
          "& > .MuiAutocomplete-root, & > .MuiTextField-root": {
            flexWrap: "wrap",
            display: "flex",
            marginBottom: "1rem",
          },
        }}
      >
        <AppTextField
          disabled
          label="Nome"
          sxStyle={{ opacity: "", pointerEvents: "auto", width: "100%" }}
          value={valuesFilterLogs.user}
          variant="outlined"
          onChange={handleChange("user")}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
            alignItems: "center",
          }}
        >
          <AppTextField
            label="Data"
            sxStyle={{ opacity: "", pointerEvents: "auto", width: "45%" }}
            type="date"
            value={valuesFilterLogs.start_data}
            variant="outlined"
            onChange={handleChange("start_data")}
          />
          até
          <AppTextField
            label="Data"
            sxStyle={{
              opacity: "",
              pointerEvents: "auto",
              width: "45%",
              marginRight: 0,
            }}
            type="date"
            value={valuesFilterLogs.end_data}
            variant="outlined"
            onChange={handleChange("end_data")}
          />
        </Box>

        <AppTextField
          fullWidth
          select
          id="Tem_recibo"
          label="Ação ?"
          sxStyle={{ opacity: "", pointerEvents: "auto" }}
          value={valuesFilterLogs.action}
          variant="outlined"
          onChange={handleChange("action")}
        >
          <MenuItem value="">Todas</MenuItem>
          {listAction.map((option) => (
            <MenuItem key={option.label} value={option.label}>
              {option.label}
            </MenuItem>
          ))}
        </AppTextField>
      </Box>
    </ModalFilterCore>
  );
};
