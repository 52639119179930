import { Children } from "react";
import { Link, useLocation } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useResetInputs } from "src/Contexts/hooks/useResetInputs";
import { IViews } from "src/Contexts/types/ProdutoresContext.Types";

type IArrayNavigation = { data: IViews[] };

export const ButtonNavigationGroup = ({ data }: IArrayNavigation) => {
  const { currentPage, setCurrentPage, setNameSearch } = useConfigPageContext();
  const { resetInputs } = useResetInputs();
  const { pathname } = useLocation();

  const resetCurrentPage = () => {
    if (currentPage !== 1) setCurrentPage(1);
    resetInputs();
    setNameSearch("");
  };

  return (
    <Box sx={{ maxHeight: "1.8rem", display: { xs: "none", sm: "flex" } }}>
      {Children.toArray(
        data.map((buttons) => {
          return (
            <Link
              style={{
                width: "100%",
                textDecoration: "none",
                display: buttons.isView ? "flex" : "none",
              }}
              to={buttons.to}
            >
              <Button
                fullWidth
                sx={{
                  borderRadius: "0 0 12px 12px",
                  background: "#F1F3F6",
                  borderBottom:
                    pathname === buttons.to
                      ? `2.8px solid #3980f5`
                      : "2px solid rgba(100, 100, 100, 0.466)",
                  color: pathname === buttons.to ? "#3980f5" : "#3b3b3b",
                }}
                onClick={resetCurrentPage}
              >
                {buttons.title}
              </Button>
            </Link>
          );
        })
      )}
    </Box>
  );
};
