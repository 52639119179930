import { FormProducer } from "./components/FormProducer/FormProducer";
import { TableProdutores } from "./components/TableProducer/TableProdutores";

export const PageProdutores = () => {
  return (
    <>
      <FormProducer />

      <TableProdutores />
    </>
  );
};
