import { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { AppTextField } from "src/Pages/components";
import { DividerCore } from "src/Pages/components/DividerCore/DividerCore";
import { SendIcon } from "src/Pages/components/Icons/Icons";

import { UseFormFormChangeFunctionProducer } from "../UseFeaturesFormulario";

interface IFormChangeEmail {
  email: string;
}
export const FormChangeEmail = ({ email }: IFormChangeEmail) => {
  const { handleChangeEmailProducer, loading } =
    UseFormFormChangeFunctionProducer();

  const [newEmail, setNewEmail] = useState<string | null>("");

  useEffect(() => {
    setNewEmail(email);
  }, [email]);

  return (
    <>
      <DividerCore
        sxStyle={{ margin: "12px 0" }}
        title="Trocar email de acesso"
      />
      <Box
        sx={{
          display: "flex",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <AppTextField
          label="Email"
          sxStyle={{ opacity: "", pointerEvents: "auto", width: "60%" }}
          value={newEmail}
          variant="outlined"
          onChange={(e) => setNewEmail(e.target.value)}
        />

        <Button
          disabled={loading}
          endIcon={<SendIcon />}
          variant="outlined"
          onClick={() => handleChangeEmailProducer(newEmail)}
        >
          Salvar
        </Button>
      </Box>
    </>
  );
};
