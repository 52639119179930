import { useEffect } from "react";
import { UseValuesDefaultInputUsers } from "src/Contexts/Usuarios/ValuesDefaultInputUsers/ValuesDefaultInputUsers";
import { UseAutoCompletedAll } from "src/Contexts/Utils/UseAutoCompletedAll";
import { AutoCompleteGenericMultiple } from "src/Pages/components/InputAutoCompleted/AutoCompleteGenericMultiple";
import { IPropsAutoCompleted } from "src/Pages/components/InputAutoCompleted/types";
import { valuesPermissions } from "src/shared/Utils/ValuesPermissions";

import { UseVerifyIsPermissionsUser } from "../../hooks/UseVerifyIsPermissionsUser";

export const SelectedFiliaisAutoCompletedGeneric = (
  props: IPropsAutoCompleted
) => {
  const {
    label,
    width,
    tamanho,
    minWidth,
    maxWidth,
    fullWidth,
    disabled,
    variant,
  } = props;
  const { valueAutoCompFiliais, setValueAutoCompFiliais } =
    UseAutoCompletedAll();

  const { handleVerifyPermissionItem } = UseVerifyIsPermissionsUser();
  const { valueDefaultAutoCompletedFiliais } = UseValuesDefaultInputUsers();

  useEffect(() => {
    if (
      handleVerifyPermissionItem(valuesPermissions.UTILS.ESCOLHER_FILIAL.id)
    ) {
      setValueAutoCompFiliais([valueDefaultAutoCompletedFiliais]);
    }
  }, []);

  return (
    <AutoCompleteGenericMultiple
      openInClick
      disabled={
        handleVerifyPermissionItem(
          valuesPermissions.UTILS.ESCOLHER_FILIAL.id
        ) || disabled
      }
      fullWidth={fullWidth}
      label={label}
      maxWidth={maxWidth}
      minWidth={minWidth}
      setValueAutoCompleted={setValueAutoCompFiliais}
      tamanho={tamanho}
      url="/GetAllFiliais"
      valueAutoCompleted={valueAutoCompFiliais}
      variant={variant}
      width={width}
    />
  );
};
