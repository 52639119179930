import React, { useEffect } from "react";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { ModalCore } from "src/Pages/components";

import { FormDependentes } from "../FormDependentes";

interface IModalAddDependentes {
  openModalDependentes: boolean;
  setOpenModalDependentes: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalAddDependentes = ({
  openModalDependentes,
  setOpenModalDependentes,
}: IModalAddDependentes) => {
  const { valuesInputsContrato, setValuesInputsContrato } =
    useContextContrato();

  const removeDependente = (id: string) => {
    setValuesInputsContrato((prevValues) => {
      const updatedDependentes = [...prevValues.dependentes];

      return {
        ...prevValues,
        dependentes: updatedDependentes.filter((item) => item.id !== id),
      };
    });
  };

  useEffect(() => {
    if (!openModalDependentes) {
      valuesInputsContrato.dependentes.forEach(({ id, ...rest }) => {
        const isNotData = Object.values(rest).every((valor) => valor === "");

        if (isNotData) {
          removeDependente(id);
        }
      });
    }
  }, [openModalDependentes]);

  return (
    <ModalCore
      open={openModalDependentes}
      setOpen={setOpenModalDependentes}
      subTitle={`Total de dependentes: ${valuesInputsContrato.dependentes.length}`}
      sxStyle={{
        maxWidth: {
          xs: `calc(100vw - 40px)`,
          md: "1300px",
        },
      }}
      title="Adicionar dependentes"
    >
      <FormDependentes setOpenModalDependentes={setOpenModalDependentes} />
    </ModalCore>
  );
};
