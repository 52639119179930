import { useState } from "react";
import { useContextComissoes } from "src/Contexts/comissoes/ComissoesContext";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";
interface IUseFormOperadora {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UseFormOperadora = ({ setOpenModal }: IUseFormOperadora) => {
  const { handleGetAlert } = useLayoutMainContext();
  const { setAttTable, id } = useConfigPageContext();
  const { valuesInputsOperadora } = useContextComissoes();

  const [loading, setLoading] = useState(false);

  const handleCreate = async () => {
    if (!valuesInputsOperadora.name?.trim())
      return handleGetAlert({ message: `Digite uma operadora` });

    setLoading(true);

    return api
      .post("/comissoes/operadoras/create", valuesInputsOperadora)
      .then((res) => {
        setAttTable((item) => !item);
        handleGetAlert({ message: res.data.message });
        setOpenModal(false);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  const handleEdit = () => {
    if (!valuesInputsOperadora.name?.trim())
      return handleGetAlert({ message: `Digite uma operadora` });

    setLoading(true);

    return api
      .put(`/comissoes/operadora/${id}`, valuesInputsOperadora)
      .then((res) => {
        setAttTable((item) => !item);
        handleGetAlert({ message: res.data.message });
        setOpenModal(false);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  const handleSubmit = () => {
    if (id) {
      handleEdit();
    } else {
      handleCreate();
    }
  };

  return { handleSubmit, loading };
};
