import { FC, ReactNode } from "react";

interface IComponentPrivate {
  isView?: string | boolean;
  children: ReactNode;
}

export const ComponentPrivate: FC<IComponentPrivate> = ({
  children,
  isView,
}) => {
  return <>{isView && children}</>;
};
