export const valuesPermissions = {
  FILIAL: {
    VER_TELA: {
      id: "12-1",
      title: "VER_TELA",
    },
    CREATE: {
      id: "12-2",
      title: "CRIAR",
    },
    DELETAR: {
      id: "12-3",
      title: "DELETAR",
    },
    EDITAR: {
      id: "12-4",
      title: "EDITAR",
    },
  },
  PERMISSIONS: {
    VER_TELA: {
      id: "18-1",
      title: "VER_TELA",
    },
    CREATE: {
      id: "18-2",
      title: "CRIAR",
    },
    DELETAR: {
      id: "18-3",
      title: "DELETAR",
    },
    EDITAR: {
      id: "18-4",
      title: "EDITAR",
    },
  },
  USER: {
    VER_TELA: {
      id: "88-1",
      title: "VER_TELA",
    },
    CREATE: {
      id: "88-2",
      title: "CRIAR",
    },
    DELETAR: {
      id: "88-3",
      title: "DELETAR",
    },
    EDITAR: {
      id: "88-4",
      title: "EDITAR",
    },
    DESATIVAR: {
      id: "88-5",
      title: "DESATIVAR",
    },
  },
  PRODUCER: {
    VER_TELA: {
      id: "178-1",
      title: "VER_TELA",
    },
    CREATE: {
      id: "178-2",
      title: "CRIAR",
    },
    DELETAR: {
      id: "178-3",
      title: "DELETAR",
    },
    EDITAR: {
      id: "178-4",
      title: "EDITAR",
    },
  },
  CONTA_CORRENTE: {
    VER_TELA: {
      id: "816-1",
      title: "VER_TELA",
    },
    CREATE: {
      id: "816-2",
      title: "CRIAR",
    },
    DELETAR: {
      id: "816-3",
      title: "DELETAR",
    },
  },
  IMPOSTOS: {
    VER_TELA: {
      id: "198-1",
      title: "VER_TELA",
    },
    CREATE: {
      id: "198-2",
      title: "CRIAR",
    },
    DELETAR: {
      id: "198-3",
      title: "DELETAR",
    },
    EDITAR: {
      id: "198-4",
      title: "EDITAR",
    },
    IMPOSTO_GLOBAL: {
      id: "198-5",
      title: "IMPOSTO_GLOBAL",
    },
  },
  OPERADORA: {
    VER_TELA: {
      id: "732-1",
      title: "VER_TELA",
    },
    CREATE: {
      id: "732-2",
      title: "CRIAR",
    },
    DELETAR: {
      id: "732-3",
      title: "DELETAR",
    },
    EDITAR: {
      id: "732-4",
      title: "EDITAR",
    },
  },
  MODALIDADE: {
    VER_TELA: {
      id: "717-1",
      title: "VER_TELA",
    },
    CREATE: {
      id: "717-2",
      title: "CRIAR",
    },
    DELETAR: {
      id: "717-3",
      title: "DELETAR",
    },
    EDITAR: {
      id: "717-4",
      title: "EDITAR",
    },
  },
  ADMINISTRADORA: {
    VER_TELA: {
      id: "961-1",
      title: "VER_TELA",
    },
    CREATE: {
      id: "961-2",
      title: "CRIAR",
    },
    DELETAR: {
      id: "961-3",
      title: "DELETAR",
    },
    EDITAR: {
      id: "961-4",
      title: "EDITAR",
    },
  },
  COMMISSIONS_CORRETORA: {
    VER_TELA: {
      id: "347-1",
      title: "VER_TELA",
    },
    CREATE: {
      id: "347-2",
      title: "CRIAR",
    },
    DELETAR: {
      id: "347-3",
      title: "DELETAR",
    },
    EDITAR: {
      id: "347-4",
      title: "EDITAR",
    },
  },
  COMMISSIONS_PRODUCER: {
    VER_TELA: {
      id: "153-1",
      title: "VER_TELA",
    },
    CREATE: {
      id: "153-2",
      title: "CRIAR",
    },
    DELETAR: {
      id: "153-3",
      title: "DELETAR",
    },
    EDITAR: {
      id: "153-4",
      title: "EDITAR",
    },
  },
  CONTRATO: {
    VER_TELA: {
      id: "8821-1",
      title: "VER_TELA",
    },
    CREATE: {
      id: "8821-2",
      title: "CRIAR",
    },
    DELETAR: {
      id: "8821-3",
      title: "DELETAR",
    },
    EDITAR: {
      id: "8821-4",
      title: "EDITAR",
    },
    CANCELAR: {
      id: "8821-5",
      title: "CANCELAR",
    },
  },
  BORDERO: {
    VER_TELA: {
      id: "1728-1",
      title: "VER_TELA",
    },
    CREATE: {
      id: "1728-2",
      title: "CRIAR",
    },
    DELETAR: {
      id: "1728-3",
      title: "DELETAR",
    },
    EDITAR: {
      id: "1728-4",
      title: "EDITAR",
    },
    RECEBER: {
      id: "1728-5",
      title: "RECEBER",
    },
    ZERAR_RECEBIMENTO: {
      id: "1728-6",
      title: "ZERAR_RECEBIMENTO",
    },
    VOLTAR_PAGAMENTO: {
      id: "1728-7",
      title: "VOLTAR_PAGAMENTO",
    },
  },
  RECIBOS: {
    VER_TELA: {
      id: "9281-1",
      title: "VER_TELA",
    },
  },

  RELATORIO_PRODUCAO_CONTRATO: {
    VER_TELA: {
      id: "6619-1",
      title: "VER_TELA",
    },
  },

  RELATORIO_PRODUCAO_CORRETOR: {
    VER_TELA: {
      id: "6919-1",
      title: "VER_TELA",
    },
  },

  RELATORIO_PRODUCAO_OPERADORA: {
    VER_TELA: {
      id: "9919-1",
      title: "VER_TELA",
    },
  },

  RELATORIO_CONFIMACAO_CONTRATO: {
    VER_TELA: {
      id: "9910-1",
      title: "VER_TELA",
    },
  },

  RELATORIO_CONFIMACAO_PRODUCER: {
    VER_TELA: {
      id: "9980-1",
      title: "VER_TELA",
    },
  },

  UTILS: {
    VER_UTILS: {
      id: "5002-0",
      title: "VER_UTILS",
    },
    ESCOLHER_FILIAL: {
      id: "5002-1",
      title: "ESCOLHER_FILIAL",
    },
  },

  COMPANY: {
    VER_DETALHES: {
      id: "1030-0",
      title: "VER_DETALHES",
    },
    EDITAR_DETALHES: {
      id: "1030-1",
      title: "EDITAR_DETALHES",
    },
  },

  LOGS: {
    VER_TELA: {
      id: "5192-0",
      title: "VER_TELA",
    },
  },

  DASHBOARD_BROKER: {
    VER_TELA: {
      id: "7792-0",
      title: "VER_TELA",
    },
  },
};
