import React, { useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useResetInputs } from "src/Contexts";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { ModalCore } from "src/Pages/components";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";
import { valuesPermissions } from "src/shared/Utils/ValuesPermissions";

import { UseGetByIdPermissions } from "../Table/Hooks/UseGetByIdPermissions";

import { FormPermissions } from "./FormPermissions";
import { UseFormPermissions } from "./hook/UseFormPermissions";

interface IModalEditarParcela {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalPermissions = ({
  openModal,
  setOpenModal,
}: IModalEditarParcela) => {
  const { id } = useConfigPageContext();
  const { userPerfil } = useAuthContext();
  const { resetInputs } = useResetInputs();
  const { loadingGravar } = useConfigPageContext();
  const { handleGetById } = UseGetByIdPermissions();

  const { handleSubmit } = UseFormPermissions({ setOpenModal });

  useEffect(() => {
    if (openModal && id) {
      handleGetById(id);
    }

    if (!openModal) {
      resetInputs();
    }
  }, [openModal]);

  return (
    <>
      <ButtonCore
        disabled={
          userPerfil?.type_user === "USER" &&
          !userPerfil?.permissions.permissions.includes(
            valuesPermissions.PERMISSIONS.CREATE.id
          )
        }
        startIcon={<AddIcon />}
        title="Criar permissões"
        onClick={() => setOpenModal(true)}
      />

      <ModalCore
        loadingSubmit={loadingGravar}
        open={openModal}
        setOpen={setOpenModal}
        sxStyle={{ width: "840px" }}
        title="Permissões"
        titleSubmit="Salvar"
        onChange={handleSubmit}
      >
        <FormPermissions />
      </ModalCore>
    </>
  );
};
