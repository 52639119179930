import { ChangeEvent, createRef, useEffect } from "react";
import { formatString } from "@format-string/core";
import { Grid } from "@mui/material";
import { useContextUsuarios } from "src/Contexts/Usuarios/UsuariosContext";
import { ICreateUsuarios } from "src/Contexts/Usuarios/UsuariosTypes";
import { AppTextField } from "src/Pages/components";
import { AutoCompleteFiliaisGeneric } from "src/Pages/components/AutoCompleted/AutoCompleteFiliais";
import { DividerCore } from "src/Pages/components/DividerCore/DividerCore";

import { AutoCompletePerfisGeneric } from "../../../../../components/AutoCompleted/AutoCompletePerfisAcesso";

export const FormUser = () => {
  const { valuesInputsUsuarios, setValuesInputsUsuarios } =
    useContextUsuarios();

  const nome = createRef<HTMLInputElement>();
  const handleFocusedID = () => nome.current?.focus();

  useEffect(() => {
    handleFocusedID();
  }, []);

  const handleChange =
    (prop: keyof ICreateUsuarios, option?: "phone") =>
    (event: ChangeEvent<HTMLInputElement>) => {
      if (option === "phone") {
        setValuesInputsUsuarios((prevValue) => ({
          ...prevValue,
          [prop]: formatString({ value: event.target.value, type: "phone" }),
        }));
      } else if (option === "phone") {
        setValuesInputsUsuarios((prevValue) => ({
          ...prevValue,
          [prop]: formatString({ value: event.target.value, type: "phone" }),
        }));
      } else {
        setValuesInputsUsuarios((prevValue) => ({
          ...prevValue,
          [prop]: event.target.value,
        }));
      }
    };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <AppTextField
            fullWidth
            inputRef={nome}
            label="Nome completo *"
            sxStyle={{ opacity: "", pointerEvents: "auto", margin: 0 }}
            value={valuesInputsUsuarios.nome}
            variant="outlined"
            onChange={handleChange("nome")}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <AppTextField
            fullWidth
            label="Telefone"
            sxStyle={{ opacity: "", pointerEvents: "auto", margin: 0 }}
            value={valuesInputsUsuarios.telefone1}
            variant="outlined"
            onChange={handleChange("telefone1", "phone")}
          />
        </Grid>
      </Grid>

      <DividerCore title="Acesso" />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={7}>
          <AppTextField
            fullWidth
            label="Email *"
            sxStyle={{ opacity: "", pointerEvents: "auto", margin: 0 }}
            value={valuesInputsUsuarios.email}
            variant="outlined"
            onChange={handleChange("email")}
          />
        </Grid>
        <Grid item xs={6} sm={2.5}>
          <AppTextField
            fullWidth
            label="Acesso inicial *"
            sxStyle={{ opacity: "", pointerEvents: "auto", margin: 0 }}
            tooltip="Horário permitido para usuário fazer login"
            type="time"
            value={valuesInputsUsuarios.horario_inicial}
            variant="outlined"
            onChange={handleChange("horario_inicial")}
          />
        </Grid>
        <Grid item xs={6} sm={2.5}>
          <AppTextField
            fullWidth
            label="Acesso final *"
            sxStyle={{ opacity: "", pointerEvents: "auto", margin: 0 }}
            tooltip="Horário permitido para usuário fazer login"
            type="time"
            value={valuesInputsUsuarios.horario_final}
            variant="outlined"
            onChange={handleChange("horario_final")}
          />
        </Grid>
        <Grid item sx={{ marginLeft: "-4px" }} xs={12} sm={6}>
          <AutoCompletePerfisGeneric
            fullWidth
            disabled={false}
            label="Perfis de acesso *"
            tooltip="Permissão de telas/funcionalidades para o usuário"
            variant="outlined"
          />
        </Grid>
        <Grid item sx={{ marginLeft: "-4px" }} xs={12} sm={6}>
          <AutoCompleteFiliaisGeneric
            fullWidth
            disabled={false}
            label="Filial *"
            variant="outlined"
          />
        </Grid>
      </Grid>
    </>
  );
};
