import { createContext, useContext, useState, FC, ReactNode } from "react";

import { ICompanyById } from "../Lancamentos/Contrato/ContratosTypes";
import {
  ICreateRegister,
  IChangeCompany,
  IListControle,
} from "../types/ProdutoresContext.Types";

import { IContextControle } from "./ControlCompaniesTypes";
import { valuesDefaultInputsControlCompany } from "./ValuesDefault/ValuesDefaultControlCompany";

const ControleContext = createContext({} as IContextControle);

export const ControleContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [listControle, setListControle] = useState([] as IListControle[]);

  const [getCompany, setGetCompany] = useState<ICompanyById>();

  const [valuesInputsRegister, setValuesInputsRegister] =
    useState<ICreateRegister>(valuesDefaultInputsControlCompany);

  const [valuesInputChangeCompany, setValuesInputChangeCompany] =
    useState<IChangeCompany>({
      days_limit_free: "15",
      is_plan_test: false,
      is_plan: false,
    });

  return (
    <ControleContext.Provider
      value={{
        getCompany,
        listControle,
        setGetCompany,
        setListControle,
        valuesInputsRegister,
        setValuesInputsRegister,
        valuesInputChangeCompany,
        setValuesInputChangeCompany,
      }}
    >
      {children}
    </ControleContext.Provider>
  );
};

export const usePageControle = (): IContextControle =>
  useContext(ControleContext);
