import { GetDateUSSemValue } from "src/shared/Utils/FormatDateBR";

import {
  ICreateProdutor,
  IValuesFilterProducer,
  IValuesFilterContaCorrente,
} from "../ProdutoresTypes";

export const valueDefaultInputProdutores: ICreateProdutor = {
  id: "",
  agencia: "",
  bairro: "",
  banco: "",
  cep: "",
  cidade: "",
  conta: "",
  cpf_cnpj: "",
  email_contato: "",
  data_nasc: "",
  endereco: "",
  entrada: `${GetDateUSSemValue()}`,
  estado: "",
  favorecido: "",
  fisico_juridico: "fisica",
  funcao_produtor: "CORRETOR",
  tipo_produtor: "SALAO",
  gerente: "",
  id_filial: "",
  id_permissao: "",
  id_tenant: "",
  nome: "",
  observacoes: "",
  type_user: "",
  pix: "",
  rg: "",
  saida: "",
  supervisor: "",
  telefone1: "",
  telefone2: "",
};

export const valuesDefaultFilterContaCorrente: IValuesFilterContaCorrente = {
  codeProducer: "",
  startData: "",
  endDate: "",
  isReceipt: "ALL",
};

export const valuesDefaultFilterProducer: IValuesFilterProducer = {
  codProducer: "",
  nomeProducer: "",
  cpfProducer: "",
  isActiveProducer: "TRUE",
  functionProducer: "ALL",
};
