import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { Box, Divider, Tooltip, alpha } from "@mui/material";
import { useAppThemeContext } from "src/Contexts/ThemeContextConfig";

import { CardCore } from "../CardCore/CardCore";
import { QuizIcon } from "../Icons/Icons";
import { LinkCore } from "../LinkCore/LinkCore";

interface ICardHelp {
  id: string;
  title: string;
  description: string;

  page: {
    title: string;
    url: string;
  };
}

export const CardHelp = ({ description, id, title, page }: ICardHelp) => {
  const { themeName } = useAppThemeContext();

  return (
    <CardCore
      key={id}
      sxStyle={{
        margin: 0,
        height: "100%",
        display: "flex",
        cursor: "pointer",
        overflow: "hidden",
        position: "relative",
        flexDirection: "column",
        justifyContent: "space-between",
        boxShadow: "0 .1rem 1rem .25rem #68686814",
        border: `1px solid ${
          themeName === "dark" ? " #9494948d" : " #64646442"
        }`,
      }}
    >
      <Box
        sx={{
          width: "calc(100% - 50px)",
          marginBottom: "6px",
          fontWeight: "500",
        }}
      >
        {title}
      </Box>

      <Divider sx={{ margin: "12px 0" }} />

      <Box sx={{ flex: 1 }}>{description}</Box>

      <Divider sx={{ margin: "12px 0" }} />

      <LinkCore to={page.url}>
        <Tooltip arrow title="Ir para a página">
          <Box
            sx={{
              fontWeight: "600",
              color: "#0e6aff",
              display: "flex",
              letterSpacing: "0.04rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {page.title}
            <DoubleArrowIcon sx={{ fontSize: "22px" }} />
          </Box>
        </Tooltip>
      </LinkCore>

      <QuizIcon
        sx={{
          top: 0,
          right: 0,
          opacity: 0.7,
          width: "40px",
          margin: "6px",
          height: "40px",
          position: "absolute",
          color: alpha("#0e6aff", 0.7),
        }}
      />
    </CardCore>
  );
};
