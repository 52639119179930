import { useState } from "react";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useResetInputs } from "src/Contexts/hooks/useResetInputs";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { ModalDeleteCore } from "src/Pages/components";
import { api } from "src/shared/setup/API/api";

interface IModalChangeIsActive {
  setModalDelete: React.Dispatch<React.SetStateAction<boolean>>;
  modalDelete: boolean;
  isActive?: boolean;
}

export const ModalChangeIsActive = ({
  setModalDelete,
  modalDelete,
  isActive,
}: IModalChangeIsActive) => {
  const { id, setAttTable } = useConfigPageContext();
  const { handleGetAlert } = useLayoutMainContext();
  const { resetInputs } = useResetInputs();

  const [loading, setLoading] = useState(false);

  const handleChangeIsActive = () => {
    setLoading(true);

    api
      .patch(`/comissoes/corretoraIsActive/${id}`, { is_active: isActive })
      .then((res) => {
        handleGetAlert({ message: res.data.message });
        setAttTable((item) => !item);
        resetInputs();
      })
      .catch((error) => {
        handleGetAlert({ message: error.response.data.message });
      })
      .finally(() => {
        setModalDelete(false);
        setLoading(false);
      });
  };

  return (
    <ModalDeleteCore
      loading={loading}
      modalOpen={modalDelete}
      no="Não, voltar !"
      titlePrimary={`Você deseja ${
        isActive ? "ativar" : "desativar"
      } esta comissão ?`}
      titleSecondary={`${
        isActive
          ? "Ativar grade ficara disponível para utilizar em contratos !"
          : "Esta opção não deleta, desativando comissão não será possível utilizar  em contrato !"
      }`}
      yes={`Sim, ${isActive ? "ativar" : "desativar"} !`}
      onClickFalse={() => setModalDelete(false)}
      onClickTrue={handleChangeIsActive}
    />
  );
};
