import { ChangeEvent } from "react";
import Dropzone from "react-dropzone";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import { Box, IconButton, MenuItem } from "@mui/material";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { IValuesUploadCommission } from "src/Contexts/Lancamentos/Contrato/ContratosTypes";
import { AppTextField } from "src/Pages/components";
import { CloseIcon } from "src/Pages/components/Icons/Icons";
import { getFileSize } from "src/shared/Utils/getFileSize";

export const FormUploadCommission = () => {
  const {
    valuesUploadCommission,
    setValuesUploadCommission,
    valuesTypesUploadCommission,
  } = useContextContrato();

  const handleChange =
    (prop: keyof IValuesUploadCommission) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setValuesUploadCommission((prev) => ({
        ...prev,
        [prop]: event.target.value,
      }));
    };

  return (
    <Box sx={{ padding: { xs: "4px", sm: "8px" } }}>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <AppTextField
          label="Data de recebimento"
          sxStyle={{
            opacity: "",
            pointerEvents: "auto",
            width: { xs: "100%", sm: "200px" },
            marginBottom: { xs: "12px", sm: 0 },
            margin: 0,
          }}
          type="date"
          value={valuesUploadCommission?.data_pagamento}
          variant="outlined"
          onChange={handleChange("data_pagamento")}
        />

        <AppTextField
          select
          id="tipo"
          label="Tipo"
          sxStyle={{
            width: { xs: "100%", sm: "200px" },
            marginBottom: { xs: "12px", sm: 0 },
            opacity: "",
            pointerEvents: "auto",
            margin: 0,
          }}
          value={valuesUploadCommission?.type_file}
          variant="outlined"
          onChange={handleChange("type_file")}
        >
          <MenuItem value="TXT">TXT</MenuItem>
          <MenuItem disabled value="EXCEL">
            EXCEL
          </MenuItem>
        </AppTextField>
      </Box>
      <Box sx={{ marginBottom: "20px" }}>
        <AppTextField
          fullWidth
          select
          id="Operadora"
          label="Operadora"
          sxStyle={{
            marginBottom: { xs: "12px", sm: 0 },
            opacity: "",
            pointerEvents: "auto",
            margin: 0,
          }}
          value={valuesUploadCommission?.id_operadora}
          variant="outlined"
          onChange={handleChange("id_operadora")}
        >
          {valuesTypesUploadCommission.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.title}
            </MenuItem>
          ))}
        </AppTextField>
      </Box>

      <Dropzone
        disabled={valuesUploadCommission.file.length > 0}
        onDrop={(files) =>
          setValuesUploadCommission({ ...valuesUploadCommission, file: files })
        }
      >
        {({ getRootProps, getInputProps }) => (
          <section
            style={{
              width: "100%",
              marginBottom: "20px",
              padding: "20px",

              border: "2px dashed gray",
              borderRadius: "8px",

              opacity: valuesUploadCommission.file.length > 0 ? "0.5" : "1",
              cursor:
                valuesUploadCommission.file.length > 0
                  ? "not-allowed"
                  : "default",
            }}
            {...getRootProps()}
          >
            <div {...getRootProps()} style={{ textAlign: "center" }}>
              <input {...getInputProps()} />
              <p>Arraste ou clique para escolher seu arquivo</p>
            </div>
          </section>
        )}
      </Dropzone>

      {valuesUploadCommission.file.length > 0 && (
        <Box
          sx={{
            width: "100%",
            padding: "12px",
            display: "flex",
            alignItems: "center",
            border: "1px solid gray",
            borderRadius: "8px",
          }}
        >
          <DocumentScannerIcon color="info" sx={{ marginRight: "16px" }} />

          <Box
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              display: "flex",
              width: "100%",
            }}
          >
            <Box>
              <Box>{valuesUploadCommission?.file[0]?.name}</Box>
              <Box>{getFileSize(valuesUploadCommission?.file[0]?.size)}</Box>
            </Box>

            <IconButton
              aria-label="delete"
              sx={{ width: "30px", height: "30px" }}
              onClick={() =>
                setValuesUploadCommission({
                  ...valuesUploadCommission,
                  file: [],
                })
              }
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      )}
    </Box>
  );
};
