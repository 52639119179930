import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { useContextUsuarios } from "src/Contexts/Usuarios/UsuariosContext";
import { api } from "src/shared/setup/API/api";

export const UseGetByIdPermissions = () => {
  const { handleGetAlert } = useLayoutMainContext();

  const { setValuesInputsPerfilAcesso } = useContextUsuarios();

  const handleGetById = async (id: string) => {
    api
      .post(`/getPerfilAcessoById/${id}`)
      .then(({ data }) => {
        const viewsPermissions = data.permissions.split(",");
        const removeTrim = viewsPermissions.map((text: string) => text.trim());

        setValuesInputsPerfilAcesso({
          id_permissao: data?.id_permissao,
          perfil_acesso: data?.perfil_acesso,
          permissions: [...removeTrim],
        });
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      );
  };

  return { handleGetById };
};
