import { useState } from "react";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useResetInputs } from "src/Contexts/hooks/useResetInputs";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { ModalDeleteCore } from "src/Pages/components";
import { api } from "src/shared/setup/API/api";

interface IModalIsActiveProducerBordero {
  modalIsActiveProducer: boolean;
  setModalIsActiveProducer: React.Dispatch<React.SetStateAction<boolean>>;
  isActiveProducer: boolean;
}

export const ModalChangeIsActiveProducer = ({
  setModalIsActiveProducer,
  modalIsActiveProducer,
  isActiveProducer,
}: IModalIsActiveProducerBordero) => {
  const { setAttTable, id } = useConfigPageContext();
  const { handleGetAlert } = useLayoutMainContext();
  const { resetInputs } = useResetInputs();

  const [loading, setLoading] = useState(false);

  const handleIsActiveProducer = async () => {
    if (!id)
      return handleGetAlert({ message: "Escolha uma linha para editar" });

    setLoading(true);

    return api
      .patch(`/produtor/changeIsActive/${id}`, {
        is_active_producer: isActiveProducer,
      })
      .then((res) => {
        handleGetAlert({ message: res.data.message });
        setAttTable((item) => !item);
        resetInputs();
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => {
        setModalIsActiveProducer(false);
        setLoading(false);
      });
  };

  return (
    <ModalDeleteCore
      loading={loading}
      modalOpen={modalIsActiveProducer}
      no="Não, Cancelar"
      titlePrimary={`Você deseja ${
        !isActiveProducer ? "DESATIVAR" : "ATIVAR"
      } produtor `}
      titleSecondary={
        !isActiveProducer
          ? "Produtor não será REMOVIDO ! Apenas será desativado para uso do sistema."
          : "Produtor ficará disponível para uso do sistema."
      }
      yes={`Sim, ${!isActiveProducer ? "Desativar" : "Ativar"} !`}
      onClickFalse={() => setModalIsActiveProducer(false)}
      onClickTrue={() => handleIsActiveProducer()}
    />
  );
};
