import { Text } from "@react-pdf/renderer";

export const NumberPages = () => {
  return (
    <Text
      fixed
      render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
      style={{
        position: "absolute",
        marginLeft: "12px",
        fontSize: "12px",
        width: "100%",
        bottom: "10",
      }}
    />
  );
};
