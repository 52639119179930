import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextLogs } from "src/Contexts/Logs/LogsContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

interface IHandleGet {
  currentPage?: number | undefined;
  itensPerPage?: number | undefined;
  nameSearch?: string | undefined;

  user?: string | undefined;
  start_data?: string | undefined;
  end_data?: string | undefined;
  action?: string | undefined;
}

export const useHttpTableLogs = () => {
  const { handleGetAlert } = useLayoutMainContext();
  const {
    setQtd_registros,
    setLoadingTable,
    itensPerPage,
    currentPage,
    nameSearch,
  } = useConfigPageContext();

  const { setListLogs } = useContextLogs();

  const handleGet = async (body: IHandleGet) => {
    setLoadingTable(true);

    api
      .post("/logsGet", {
        ...body,
        page: currentPage,
        limit: itensPerPage,
        filter: nameSearch,
      })
      .then(({ data }) => {
        setListLogs(data.data);
        setQtd_registros(data.count);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoadingTable(false));
  };

  return { handleGet };
};
