import { useState } from "react";
import { useContextCompany } from "src/Contexts/Company/CompanyContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

export const UseGetPaymentMethod = () => {
  const { setValuesPaymentCheckout } = useContextCompany();
  const { handleGetAlert } = useLayoutMainContext();

  const [loading, setLoading] = useState(false);

  const handleGet = async () => {
    setLoading(true);

    api
      .get(`/checkoutPayment/payments`)
      .then((data) => setValuesPaymentCheckout(data?.data?.data))
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  return { loading, handleGet };
};
