import { Box, Typography, SxProps, Theme } from "@mui/material";

interface ITitleWithValueCore {
  title?: string;
  value: string;

  sxStyleTitle?: SxProps<Theme>;
  sxStyleValue?: SxProps<Theme>;
}

export const TitleWithValueCore = ({
  title,
  value,
  sxStyleTitle,
  sxStyleValue,
}: ITitleWithValueCore) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        "& > *": {
          fontSize: ".9rem",
        },
      }}
    >
      {title && (
        <Typography
          sx={{
            marginRight: "8px",
            fontSize: { xs: "12px", sm: "16px" },
            ...sxStyleTitle,
          }}
        >
          {title}
        </Typography>
      )}

      <Typography
        sx={{ fontSize: { xs: "12px", sm: "16px" }, ...sxStyleValue }}
      >
        {value || "' '"}
      </Typography>
    </Box>
  );
};
