import MenuItem from "@mui/material/MenuItem";
import { useContextRecoverPassword } from "src/Contexts/RecoverPassword/RecoverPasswordContext";
import { AppTextField } from "src/Pages/components";
import { useStylesInput } from "src/Pages/TelasLogin/Login/components/FormLogin";

export const SelectCompanyPassword = () => {
  const classes = useStylesInput();
  const { inputValueSelect, setInputValueSelect, getCompaniesRecoverPassword } =
    useContextRecoverPassword();

  return (
    <AppTextField
      fullWidth
      select
      InputProps={{ className: classes.input }}
      error={!inputValueSelect}
      id="Escolha_empresa"
      label="Escolha a empresa"
      sxStyle={{
        marginBottom: "1rem",
        opacity: "",
        pointerEvents: "auto",
        "& .MuiInput-input": {
          color: "red",
        },
      }}
      value={inputValueSelect}
      variant="outlined"
      onChange={(e) => setInputValueSelect(e.target.value)}
    >
      {getCompaniesRecoverPassword.map((option) => (
        <MenuItem key={option.company} value={option.idTenant}>
          {option.company}
        </MenuItem>
      ))}
    </AppTextField>
  );
};
