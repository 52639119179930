import { ChangeEvent, createRef, useEffect } from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { ILogins } from "src/Contexts/AuthContext/AuthContextTypes";
import { AppTextField } from "src/Pages/components";

export const useStylesInput = makeStyles({
  input: {
    background: "white",
    color: "black",
  },
});

export const FormLogin = () => {
  const { valuesInputsLogins, setValuesInputsLogins } = useAuthContext();

  const classes = useStylesInput();

  const InputEmailRef = createRef<HTMLInputElement>();

  useEffect(() => {
    InputEmailRef.current?.focus();
  }, []);

  const handleChange =
    (prop: keyof ILogins) => (event: ChangeEvent<HTMLInputElement>) => {
      setValuesInputsLogins({
        ...valuesInputsLogins,
        [prop]: event.target.value,
      });
    };

  return (
    <Box sx={{ display: "block" }}>
      <AppTextField
        fullWidth
        InputProps={{ className: classes.input }}
        inputRef={InputEmailRef}
        label="Email"
        name="email"
        sxStyle={{ opacity: "", pointerEvents: "", marginBottom: "16px" }}
        value={valuesInputsLogins.email}
        variant="outlined"
        onChange={handleChange("email")}
      />

      <AppTextField
        fullWidth
        InputProps={{ className: classes.input }}
        label="Senha"
        sxStyle={{ opacity: "", pointerEvents: "" }}
        type="password"
        value={valuesInputsLogins.senha}
        variant="outlined"
        onChange={handleChange("senha")}
      />
    </Box>
  );
};
