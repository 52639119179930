import { useState } from "react";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { useContextProdutor } from "src/Contexts/produtores/ProdutoresContext";
import { api } from "src/shared/setup/API/api";
import { handleFormatToNumeric } from "src/shared/Utils/FormatMoney";

interface IUseFormGrupoImposto {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UseFormGrupoImposto = ({ setOpenModal }: IUseFormGrupoImposto) => {
  const { handleGetAlert } = useLayoutMainContext();
  const { setAttTable, attTable, id, setId } = useConfigPageContext();

  const { valuesInputsGrupoDeImposto } = useContextProdutor();

  const [loading, setLoading] = useState(false);

  const AllDatas = {
    ...valuesInputsGrupoDeImposto,
    valor: handleFormatToNumeric(valuesInputsGrupoDeImposto?.valor),
  };

  const handleCreate = async () => {
    if (!valuesInputsGrupoDeImposto.descricao)
      return handleGetAlert({ message: `Digite uma descrição !` });
    if (!valuesInputsGrupoDeImposto.valor)
      return handleGetAlert({ message: `Digite um valor  !` });

    setLoading(true);

    return api
      .post("/gruposImpostos/create", AllDatas)
      .then((res) => {
        handleGetAlert({ message: res.data.message });
        setAttTable(!attTable);
        setOpenModal(false);
        setId("");
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  const handleEdit = () => {
    if (!valuesInputsGrupoDeImposto.descricao)
      return handleGetAlert({ message: `Digite uma descrição !` });
    if (!valuesInputsGrupoDeImposto.valor)
      return handleGetAlert({ message: `Digite um valor  !` });

    setLoading(true);

    return api
      .put(`/gruposImpostos/${id}`, AllDatas)
      .then((res) => {
        setAttTable(!attTable);
        handleGetAlert({ message: res.data.message });
        setOpenModal(false);
        setId("");
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  const handleSubmit = () => {
    if (id) {
      handleEdit();
    } else {
      handleCreate();
    }
  };

  return { handleSubmit, loading };
};
