import { ChangeEvent, Children, useEffect } from "react";
import { formatString } from "@format-string/core";
import { Box, MenuItem } from "@mui/material";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { AppTextField, ButtonPersonalized } from "src/Pages/components";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";
import { DividerCore } from "src/Pages/components/DividerCore/DividerCore";
import {
  GroupAddIcon,
  GroupRemoveIcon,
  SendIcon,
} from "src/Pages/components/Icons/Icons";
import { handleGenerateUuid } from "src/shared/Utils/handleGenerateUuid";

interface IFormDependentes {
  setOpenModalDependentes: React.Dispatch<React.SetStateAction<boolean>>;
}
export const FormDependentes = ({
  setOpenModalDependentes,
}: IFormDependentes) => {
  const { valuesInputsContrato, setValuesInputsContrato } =
    useContextContrato();

  const addDependente = () => {
    setValuesInputsContrato((prevValues) => ({
      ...prevValues,
      dependentes: [
        ...prevValues.dependentes,
        {
          id: handleGenerateUuid(),
          nome: "",
          cpf: "",
          cpf_mae: "",
          data_nascimento: "",
          estado_civil: "",
          grau_parente: "",
          nome_mae: "",
          sexo: "",
          data_inclusao: "",
        },
      ],
    }));
  };

  const removeDependente = () => {
    setValuesInputsContrato((prevValues) => {
      const updatedDependentes = [...prevValues.dependentes];

      updatedDependentes.pop();

      return {
        ...prevValues,
        dependentes: updatedDependentes,
      };
    });
  };

  useEffect(() => {
    if (valuesInputsContrato.dependentes.length === 0) {
      addDependente();
    }
  }, []);

  const handleDependenteChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    option:
      | "nome"
      | "cpf"
      | "nome_mae"
      | "cpf_mae"
      | "data_inclusao"
      | "sexo"
      | "data_nascimento"
      | "estado_civil"
      | "grau_parente"
  ) => {
    const fieldMap = {
      nome: "nome",
      cpf: "cpf",
      data_nascimento: "data_nascimento",
      estado_civil: "estado_civil",
      nome_mae: "nome_mae",
      cpf_mae: "cpf_mae",
      data_inclusao: "data_inclusao",
      sexo: "sexo",
      grau_parente: "grau_parente",
    };

    const fieldToUpdate = fieldMap[option];

    if (fieldToUpdate) {
      const updatedDependentes = valuesInputsContrato.dependentes.slice(); // Crie uma cópia do array

      updatedDependentes[index] = {
        ...updatedDependentes[index],
        [fieldToUpdate]: fieldToUpdate.includes("cpf")
          ? (formatString({
              value: event.target.value,
              type: "cpf",
            }) as string)
          : event.target.value,
      };
      setValuesInputsContrato({
        ...valuesInputsContrato,
        dependentes: updatedDependentes,
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: {
            xs: "space-between",
            sm: "flex-start",
          },
        }}
      >
        <ButtonPersonalized
          endIcon={<GroupAddIcon sx={{ mr: 1 }} />}
          sxStyle={{ marginRight: "8px", fontSize: "12px" }}
          title="Adicionar Dependente"
          onClick={addDependente}
        />
        <ButtonPersonalized
          disabled={valuesInputsContrato.dependentes.length === 0}
          endIcon={<GroupRemoveIcon sx={{ mr: 1 }} />}
          sxStyle={{ fontSize: "12px" }}
          title="Remover Dependente"
          onClick={removeDependente}
        />
      </Box>

      <Box
        sx={{
          maxHeight: {
            xs: `calc(100vh - 300px)`,
          },
          minHeight: `calc(100vh - 300px)`,
          padding: "4px",
        }}
      >
        {Children.toArray(
          valuesInputsContrato.dependentes.map((dependente, index) => {
            return (
              <>
                <DividerCore title={`Dependente ${index + 1}`} />
                <Box
                  key={dependente.id}
                  sx={{ display: "flex", flexWrap: "wrap" }}
                >
                  <AppTextField
                    label={`Nome do dependente ${index + 1}`}
                    value={dependente.nome}
                    onChange={(e) => handleDependenteChange(e, index, "nome")}
                  />
                  <AppTextField
                    label="CPF do dependente"
                    sxStyle={{ width: "130px" }}
                    value={dependente.cpf}
                    onChange={(e) => handleDependenteChange(e, index, "cpf")}
                  />
                  <AppTextField
                    label="Data de nascimento"
                    sxStyle={{ width: "120px" }}
                    type="date"
                    value={dependente.data_nascimento}
                    onChange={(e) =>
                      handleDependenteChange(e, index, "data_nascimento")
                    }
                  />
                  <AppTextField
                    select
                    id="Estado Civil"
                    label="E.Civil"
                    sxStyle={{ width: "110px" }}
                    value={dependente?.estado_civil}
                    onChange={(e) =>
                      handleDependenteChange(e, index, "estado_civil")
                    }
                  >
                    <MenuItem value="">Nenhum</MenuItem>
                    <MenuItem value="SOLTEIRO">Solteiro</MenuItem>
                    <MenuItem value="CASADO">Casado</MenuItem>
                    <MenuItem value="SEPARADO">Separado</MenuItem>
                    <MenuItem value="DIVORCIADO">Divorciado</MenuItem>
                    <MenuItem value="VIUVO">Viúvo</MenuItem>
                  </AppTextField>

                  <AppTextField
                    label="Data de inclusão"
                    sxStyle={{ width: "120px" }}
                    type="date"
                    value={dependente.data_inclusao}
                    onChange={(e) =>
                      handleDependenteChange(e, index, "data_inclusao")
                    }
                  />

                  <AppTextField
                    select
                    id="Gênero"
                    label="Gênero"
                    sxStyle={{ width: "100px" }}
                    value={dependente.sexo}
                    onChange={(e) => handleDependenteChange(e, index, "sexo")}
                  >
                    <MenuItem value="" />
                    <MenuItem value="MASCULINO">Masculino</MenuItem>
                    <MenuItem value="FEMININO">Feminino</MenuItem>
                    <MenuItem value="OUTROS">Outros</MenuItem>
                  </AppTextField>
                  <AppTextField
                    select
                    id="Grau parentesco"
                    label="Grau parentesco"
                    sxStyle={{ width: "110px" }}
                    value={dependente?.grau_parente}
                    onChange={(e) =>
                      handleDependenteChange(e, index, "grau_parente")
                    }
                  >
                    <MenuItem value=" " />
                    <MenuItem value="CONJUGE">Cônjuge</MenuItem>
                    <MenuItem value="FILHO">Filho(a)</MenuItem>
                    <MenuItem value="MAE">Mãe</MenuItem>
                    <MenuItem value="PAI">Pai</MenuItem>
                    <MenuItem value="SOGRO">Sogro</MenuItem>
                    <MenuItem value="SOGRA">Sogra</MenuItem>
                    <MenuItem value="OUTROS">Outros</MenuItem>
                  </AppTextField>

                  <AppTextField
                    label="Nome da mãe"
                    sxStyle={{ width: "150px" }}
                    value={dependente.nome_mae}
                    onChange={(e) =>
                      handleDependenteChange(e, index, "nome_mae")
                    }
                  />

                  <AppTextField
                    label="CPF da mãe"
                    sxStyle={{ width: "130px" }}
                    value={dependente.cpf_mae}
                    onChange={(e) =>
                      handleDependenteChange(e, index, "cpf_mae")
                    }
                  />
                </Box>
              </>
            );
          })
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <ButtonCore
          endIcon={<SendIcon sx={{ mr: 1 }} />}
          sx={{
            marginRight: "8px",
            padding: "12px 16px",
            fontSize: "12px",
          }}
          title="Salvar"
          onClick={() => setOpenModalDependentes(false)}
        />
      </Box>
    </>
  );
};
