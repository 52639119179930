import { useState } from "react";
import { Box } from "@mui/material";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { ButtonEditDelete, ModalDeleteCore } from "src/Pages/components";
import { DeleteForeverIcon } from "src/Pages/components/Icons/Icons";
import { api } from "src/shared/setup/API/api";

export const ModalDeleteLogsErros = () => {
  const { handleGetAlert } = useLayoutMainContext();
  const { attTable, setAttTable, modalDelete, setModalDelete } =
    useConfigPageContext();

  const [loading, setLoading] = useState(false);

  const handleDeleteLogs = () => {
    setLoading(true);

    api
      .delete(`/errorsLogs`)
      .then((res) => {
        handleGetAlert({ message: res.data.message });
        setAttTable(!attTable);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => {
        setModalDelete(false);
        setLoading(false);
      });
  };

  return (
    <Box
      sx={{
        borderTop: `1px solid #3b3b3b`,
        borderBottom: `1px solid #3b3b3b`,
        padding: ".8rem 0 ",
        marginTop: "-1.3rem",
      }}
    >
      <ButtonEditDelete
        icon={<DeleteForeverIcon />}
        title="Deletar Lista"
        onClick={() => setModalDelete(true)}
      />
      <ModalDeleteCore
        loading={loading}
        modalOpen={modalDelete}
        no="Não, Cancelar"
        titlePrimary="Você deseja excluir lista ?"
        yes="Sim, Deletar !"
        onClickFalse={() => setModalDelete(false)}
        onClickTrue={() => handleDeleteLogs()}
      />
    </Box>
  );
};
