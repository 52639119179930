import { useState } from "react";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useResetInputs } from "src/Contexts/hooks/useResetInputs";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { ModalDeleteCore } from "src/Pages/components";
import { api } from "src/shared/setup/API/api";

interface IModalChangeIsActiveOperadora {
  modalIsActiveOperadora: boolean;
  setModalIsActiveOperadora: React.Dispatch<React.SetStateAction<boolean>>;
  isActiveOperadora: boolean;
}

export const ModalChangeIsActiveOperadora = ({
  setModalIsActiveOperadora,
  modalIsActiveOperadora,
  isActiveOperadora,
}: IModalChangeIsActiveOperadora) => {
  const { setAttTable, id } = useConfigPageContext();
  const { handleGetAlert } = useLayoutMainContext();
  const { resetInputs } = useResetInputs();

  const [loading, setLoading] = useState(false);

  const handleIsActiveOperadora = async () => {
    if (!id)
      return handleGetAlert({ message: "Escolha uma linha para editar" });

    setLoading(true);

    return api
      .patch(`/comissoes/operadora/status/${id}`, {
        is_active: isActiveOperadora,
      })
      .then((res) => {
        handleGetAlert({ message: res.data.message });
        setAttTable((item) => !item);
        resetInputs();
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => {
        setModalIsActiveOperadora(false);
        setLoading(false);
      });
  };

  return (
    <ModalDeleteCore
      loading={loading}
      modalOpen={modalIsActiveOperadora}
      no="Não, Cancelar"
      titlePrimary={`Você deseja ${
        !isActiveOperadora ? "DESATIVAR" : "ATIVAR"
      } operadora ?`}
      titleSecondary={
        !isActiveOperadora
          ? "Operadora não será REMOVIDA ! Apenas será desativado para uso do sistema."
          : "Operadora ficará disponível para uso do sistema."
      }
      yes={`Sim, ${!isActiveOperadora ? "Desativar" : "Ativar"} !`}
      onClickFalse={() => setModalIsActiveOperadora(false)}
      onClickTrue={() => handleIsActiveOperadora()}
    />
  );
};
