import { CircularProgress } from "@mui/material";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";

import { FormSendLinkRecoverPassword } from "./FormSendLinkRecoverPassword";
import { UseFormRecoverPassword } from "./Hooks/UseFormRecoverPassword";

export const PageFormSendLinkRecoverPassword = () => {
  const {
    handleSubmit: handleSubmitGetCompanies,
    loading: loadingSubmitGetCompanies,
  } = UseFormRecoverPassword();

  return (
    <>
      <FormSendLinkRecoverPassword />

      <ButtonCore
        fullWidth
        disabled={loadingSubmitGetCompanies}
        endIcon={
          loadingSubmitGetCompanies && (
            <CircularProgress size={20} sx={{ color: "black", ml: 4 }} />
          )
        }
        title="Recuperar senha"
        onClick={handleSubmitGetCompanies}
      />
    </>
  );
};
