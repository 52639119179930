import React from "react";
import { Box, Typography } from "@mui/material";

export const FormFaqHeader = () => {
  return (
    <Box sx={{ marginBottom: "1rem" }}>
      <Typography
        sx={{
          display: "flex",
          fontSize: { xs: "1.3rem", md: "1.7rem" },
          fontWeight: "500",
          marginBottom: ".5rem",
        }}
      >
        Se tiver mais duvidas ?
      </Typography>
      <Typography sx={{ fontSize: { xs: ".8rem", md: "1rem" } }}>
        Caso tenha duvidas de algum tópico que não esteja listado, entre em
        contato conosco deixando sua duvida.
      </Typography>
    </Box>
  );
};
