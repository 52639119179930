import { createContext, useContext, useState, FC, ReactNode } from "react";

import {
  IContextRecoverPassword,
  IGetCompaniesRecoverPassword,
  IInputsGetCompaniesRecoverPassword,
  IInputsRecoverPassword,
} from "./RecoverPasswordContextTypes";
import {
  valuesDefaultInputsGetCompaniesRecoverPassword,
  valuesDefaultInputsRecoverPassword,
} from "./valuesDefaultInputsRecoverPassword/valuesDefaultInputsRecoverPassword";

const RecoverPasswordContext = createContext({} as IContextRecoverPassword);

export const RecoverPasswordProviderContext: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [inputValueSelect, setInputValueSelect] = useState("");

  const [isValidLink, setIsValidLink] = useState<boolean>(false);

  const [getCompaniesRecoverPassword, setGetCompaniesRecoverPassword] =
    useState([] as IGetCompaniesRecoverPassword[]);

  const [valuesInputsRecoverPassword, setValuesInputsRecoverPassword] =
    useState<IInputsRecoverPassword>(valuesDefaultInputsRecoverPassword);

  const [
    valuesInputsGetCompaniesRecoverPassword,
    setValuesInputsGetCompaniesRecoverPassword,
  ] = useState<IInputsGetCompaniesRecoverPassword>(
    valuesDefaultInputsGetCompaniesRecoverPassword
  );

  return (
    <RecoverPasswordContext.Provider
      value={{
        setValuesInputsGetCompaniesRecoverPassword,
        valuesInputsGetCompaniesRecoverPassword,
        setValuesInputsRecoverPassword,
        setGetCompaniesRecoverPassword,
        getCompaniesRecoverPassword,
        valuesInputsRecoverPassword,
        setInputValueSelect,
        inputValueSelect,
        setIsValidLink,
        isValidLink,
      }}
    >
      {children}
    </RecoverPasswordContext.Provider>
  );
};

export const useContextRecoverPassword = (): IContextRecoverPassword =>
  useContext(RecoverPasswordContext);
