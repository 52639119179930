import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { useContextRecoverPassword } from "src/Contexts/RecoverPassword/RecoverPasswordContext";
import { valuesDefaultInputsRecoverPassword } from "src/Contexts/RecoverPassword/valuesDefaultInputsRecoverPassword/valuesDefaultInputsRecoverPassword";
import { api } from "src/shared/setup/API/api";

interface IRecoverPassword {
  id: string;
}
export const UseFormRecoverPassword = () => {
  const { handleGetAlert } = useLayoutMainContext();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const {
    valuesInputsRecoverPassword,

    setValuesInputsRecoverPassword,
  } = useContextRecoverPassword();

  const handleCreateProdutores = async ({ id }: IRecoverPassword) => {
    if (valuesInputsRecoverPassword.password.length < 6)
      return handleGetAlert({
        message: `Digite uma senha com com mínimo 6 caracteres`,
      });

    setLoading(true);

    return api
      .post(`/recoverPassword/${id}`, {
        password: valuesInputsRecoverPassword.password,
      })
      .then(async ({ data }) => {
        setValuesInputsRecoverPassword(valuesDefaultInputsRecoverPassword);

        handleGetAlert({ message: data.message });

        navigate(`/login`);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  return {
    handleSubmit: handleCreateProdutores,
    loading,
  };
};
