import { GetDateUSSemValue } from "src/shared/Utils/FormatDateBR";

import {
  IFilterInstallmentsReceivedPrimary,
  IFilterInstallmentsReceived,
  IFilterProfitableValues,
} from "../ContextDashboardBrokerTypes";
import { FormatDateFirstDayYear } from "src/shared/Utils/FormatDate/FormatDateFirstDayYear";

export const valuesDefaultFilterProfitableValues: IFilterProfitableValues = {
  start_date: FormatDateFirstDayYear(), // colocar se for menos de 3 meses o inicio do ano então pegar 6 meses para trás e pegar o dia 1 do sexto mes para trás
  // start_date: FormatDateDynamic({
  //   amount: 3,
  //   type: "SUB",
  //   unit: "month",
  //   dateCurrency: GetDateUSSemValue(),
  // }),
  end_date: GetDateUSSemValue(),
  interval: "MONTH",
};

export const valuesDefaultInstallmentsReceived: IFilterInstallmentsReceived = {
  start_date: FormatDateFirstDayYear(), // colocar se for menos de 3 meses o inicio do ano então pegar 6 meses para trás e pegar o dia 1 do sexto mes para trás
  // start_date: FormatDateDynamic({
  //   amount: 3,
  //   type: "SUB",
  //   unit: "month",
  //   dateCurrency: GetDateUSSemValue(),
  // }),
  end_date: GetDateUSSemValue(),
  interval: "MONTH",
  numeroParcelas: "ALL",
};

export const valuesDefaultInstallmentsReceivedPrimary: IFilterInstallmentsReceivedPrimary =
  {
    start_date: FormatDateFirstDayYear(), // colocar se for menos de 3 meses o inicio do ano então pegar 6 meses para trás e pegar o dia 1 do sexto mes para trás
    // start_date: FormatDateDynamic({
    //   amount: 3,
    //   type: "SUB",
    //   unit: "month",
    //   dateCurrency: GetDateUSSemValue(),
    // }),
    end_date: GetDateUSSemValue(),
    interval: "MONTH",
    numeroParcelas: "PRIMARY",
  };
