import { UseAutoCompletedAll } from "src/Contexts/Utils/UseAutoCompletedAll";
import { AutoCompleteGeneric } from "src/Pages/components/InputAutoCompleted/AutoCompleteGeneric";
import { IPropsAutoCompleted } from "src/Pages/components/InputAutoCompleted/types";

export const AutoCompleteOperadoraFilter = (props: IPropsAutoCompleted) => {
  const {
    label,
    width,
    tamanho,
    minWidth,
    maxWidth,
    fullWidth,
    disabled,
    variant,
  } = props;
  const { valueAutoCompOperadoraFilter, setValueAutoCompOperadoraFilter } =
    UseAutoCompletedAll();

  return (
    <AutoCompleteGeneric
      disabled={disabled}
      fullWidth={fullWidth}
      label={label}
      maxWidth={maxWidth}
      minWidth={minWidth}
      setValueAutoCompleted={setValueAutoCompOperadoraFilter}
      tamanho={tamanho}
      url="/comissoes/operadoraList"
      valueAutoCompleted={valueAutoCompOperadoraFilter}
      variant={variant}
      width={width}
    />
  );
};
