import { IDataTh, ThCoreIndex } from "src/Pages/components";

export const THeadPermissions = () => {
  const dataTh: IDataTh[] = [
    {
      title: "Ações",
      align: "left",
      width: "40px",
    },
    {
      title: "Perfil de acesso",
      align: "left",
    },
  ];

  return <ThCoreIndex dataTh={dataTh} />;
};
