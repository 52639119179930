import { Box } from "@mui/material";

interface IIsView {
  loadingTable: boolean;
  qtdList: number;
  title?: string;
}

export const TrNotList = ({
  title = "Nenhum registro encontrado",
  loadingTable,
  qtdList,
}: IIsView) => {
  return (
    <>
      {loadingTable && qtdList === 0 && (
        <Box
          sx={{
            color: "#3b3b3b",
            textAlign: "center",
            padding: "10px",
            width: "100%",
          }}
        >
          {title}
        </Box>
      )}
    </>
  );
};
