import { formatRemove } from "@format-string/core";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

interface IHandleGet {
  currentPage?: number | undefined;
  itensPerPage?: number | undefined;
  nameSearch?: string | undefined;
  codProposta?: string | undefined;
  startDateSignature?: string | undefined;
  endDateSignature?: string | undefined;
  startEffectiveDate?: string | undefined;
  endEffectiveDate?: string | undefined;
  startDateEmission?: string | undefined;
  endDateEmission?: string | undefined;

  codeOperadora?: string | undefined;
  codeCorretor?: string | undefined;
  codeAdministradora?: string | undefined;

  client?: {
    nameOrRazao?: string | undefined;
    cpfCnpj?: string | undefined;
  };
}

export const useHttpTableContratos = () => {
  const { setListContratos } = useContextContrato();
  const { handleGetAlert } = useLayoutMainContext();

  const {
    setQtd_registros,
    setLoadingTable,
    itensPerPage,
    currentPage,
    nameSearch,
  } = useConfigPageContext();

  const handleGet = async (body: IHandleGet) => {
    setLoadingTable(true);

    return api
      .post("/getContratos", {
        ...body,
        client: {
          nameOrRazao: body.client?.nameOrRazao,
          cpfCnpj: formatRemove(body.client?.cpfCnpj || ""),
        },
        startEmission: body.startDateEmission,
        endEmission: body.endDateEmission,

        page: currentPage,
        limit: itensPerPage,
        filter: nameSearch,
      })
      .then((res) => {
        setListContratos(res.data.data);
        setQtd_registros(res.data.count);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoadingTable(false));
  };

  return { handleGet };
};
