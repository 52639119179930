import { Box, Typography } from "@mui/material";
import { CardCore } from "src/Pages/components/CardCore/CardCore";
import { TurnedInIcon } from "src/Pages/components/Icons/Icons";
import { LinkCore } from "src/Pages/components/LinkCore/LinkCore";

export const LinkExpiration = () => {
  return (
    <>
      <CardCore
        sxStyle={{
          margin: "0 0 24px 0",
          alignItems: "center",
          display: "flex",
          padding: "8px",
          background: "white",
        }}
      >
        <TurnedInIcon
          sx={{ color: "#5a98fff5", marginRight: { sx: "8px", sm: "12px" } }}
        />

        <Box>
          <Typography
            sx={{
              margin: "8px 0",
              fontSize: { xs: ".8rem", md: ".9rem", lg: "1rem" },
            }}
          >
            Tempo para trocar senha <strong>expirado</strong> ✨
          </Typography>

          <Typography
            sx={{
              margin: "8px 0",
              fontSize: { xs: ".8rem", md: ".9rem", lg: "1rem" },
            }}
          >
            Solicite novamente sua troca de senha{" "}
            <LinkCore color="#2478fff5" to="/SendLinkRecoverPassword">
              aqui.
            </LinkCore>
          </Typography>
        </Box>
      </CardCore>

      <Typography
        sx={{
          margin: "0px 4px",
          fontSize: { xs: ".9rem", md: "1rem", lg: "1.1rem" },
        }}
      >
        Faça seu login{" "}
        <LinkCore color="#2478fff5" to="login">
          aqui.
        </LinkCore>
      </Typography>
    </>
  );
};
