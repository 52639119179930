import { UseAutoCompletedAll } from "src/Contexts/Utils/UseAutoCompletedAll";
import { AutoCompleteGeneric } from "src/Pages/components/InputAutoCompleted/AutoCompleteGeneric";
import { IPropsAutoCompleted } from "src/Pages/components/InputAutoCompleted/types";

export const AutoCompleteProdutor = (props: IPropsAutoCompleted) => {
  const {
    label,
    width,
    variant,
    tamanho,
    disabled,
    minWidth,
    maxWidth,
    fullWidth,
  } = props;
  const { valueAutoProdutor, setValueAutoProdutor } = UseAutoCompletedAll();

  return (
    <AutoCompleteGeneric
      disabled={disabled}
      fullWidth={fullWidth}
      label={label}
      maxWidth={maxWidth}
      minWidth={minWidth}
      setValueAutoCompleted={setValueAutoProdutor}
      tamanho={tamanho}
      url="/getAllProdutores"
      valueAutoCompleted={valueAutoProdutor}
      variant={variant}
      width={width}
    />
  );
};
