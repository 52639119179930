import { ChangeEvent } from "react";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { ICancelarContrato } from "src/Contexts/Lancamentos/Contrato/ContratosTypes";
import { AppTextField } from "src/Pages/components";

export const FormCancelContrato = () => {
  const { valuesInputCancelarContrato, setValuesInputCancelarContrato } =
    useContextContrato();

  const handleChange =
    (prop: keyof ICancelarContrato) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setValuesInputCancelarContrato((prevValue) => ({
        ...prevValue,
        [prop]: event.target.value,
      }));
    };

  return (
    <>
      <AppTextField
        label="Data de cancelamento"
        sxStyle={{
          opacity: "",
          pointerEvents: "auto",
          width: "150px",
          margin: 0,
          marginBottom: "8px",
        }}
        type="date"
        value={valuesInputCancelarContrato.date}
        onChange={handleChange("date")}
      />

      <AppTextField
        label="Descrição"
        sxStyle={{
          opacity: "",
          pointerEvents: "auto",
          width: "100%",
          margin: 0,
        }}
        value={valuesInputCancelarContrato.description}
        onChange={handleChange("description")}
      />
    </>
  );
};
