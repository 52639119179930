import { FC } from "react";

import { AuthContextProvider } from "./Contexts/AuthContext/authContext";
import { ComissoesContextProvider } from "./Contexts/comissoes/ComissoesContext";
import { CompanyProviderContext } from "./Contexts/Company/CompanyContext";
import { ConfigPageProviderContext } from "./Contexts/configPagesContext/configPagesContext";
import { ContextProfileProviderContext } from "./Contexts/ContextProfile/ContextProfile";
import { ControleContextProvider } from "./Contexts/ControlCompanies/ControleEmpresaContext";
import { ContextDashboardBrokerProviderContext } from "./Contexts/Dashboard/ContextDashboardBroker";
import { ContratoContextProvider } from "./Contexts/Lancamentos/Contrato/ContratosContext";
import { LogsContextProvider } from "./Contexts/Logs/LogsContext";
import { ProdutorContextProvider } from "./Contexts/produtores/ProdutoresContext";
import { RecibosContextProvider } from "./Contexts/Recibos/RecibosContext";
import { RecoverPasswordProviderContext } from "./Contexts/RecoverPassword/RecoverPasswordContext";
import { ReportsProductionContextProvider } from "./Contexts/ReportProduction/ReportsProductionContextContext";
import { UsuariosContextProvider } from "./Contexts/Usuarios/UsuariosContext";
import { AutoCompletedAllContextProvider } from "./Contexts/Utils/UseAutoCompletedAll";
import { ViewsContext } from "./Contexts/Views/contextViews";
import { combineComponents } from "./shared/Utils/ProviderContext/ProviderContext";

const providers = [
  AuthContextProvider,
  ViewsContext,
  AutoCompletedAllContextProvider,
  LogsContextProvider,
  ProdutorContextProvider,
  UsuariosContextProvider,
  ControleContextProvider,
  ComissoesContextProvider,
  ContratoContextProvider,
  RecibosContextProvider,
  ReportsProductionContextProvider,
  ConfigPageProviderContext,
  CompanyProviderContext,
  RecoverPasswordProviderContext,
  ContextProfileProviderContext,
  ContextDashboardBrokerProviderContext,
];

export const AppContextProvider = combineComponents(providers as FC[]);
