import { useState } from "react";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useResetInputs } from "src/Contexts/hooks/useResetInputs";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { ModalDeleteCore } from "src/Pages/components";
import { api } from "src/shared/setup/API/api";

interface IModalCancelarPagamentoBordero {
  modalCancelarPagamento: boolean;
  setModalCancelarPagamento: React.Dispatch<React.SetStateAction<boolean>>;
  funcao_produtor: "CORRETOR" | "SUPERVISOR" | "GERENTE";
}

export const ModalCancelarPagamentoBordero = ({
  modalCancelarPagamento,
  setModalCancelarPagamento,
  funcao_produtor,
}: IModalCancelarPagamentoBordero) => {
  const { handleGetAlert } = useLayoutMainContext();

  const { resetInputs } = useResetInputs();

  const [loading, setLoading] = useState(false);

  const { setAttTable, id } = useConfigPageContext();

  const handleCancelarPagamento = async () => {
    if (!id)
      return handleGetAlert({ message: "Escolha uma linha para deletar" });

    setLoading(true);

    return api
      .patch(`/bordero/cancelarPagamentoProdutor/${id}`, {
        funcao_produtor,
      })
      .then((res) => {
        handleGetAlert({ message: res.data.message });
        setAttTable((item) => !item);
        resetInputs();
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => {
        setModalCancelarPagamento(false);
        setLoading(false);
      });
  };

  return (
    <ModalDeleteCore
      loading={loading}
      modalOpen={modalCancelarPagamento}
      no="Não, Cancelar"
      titlePrimary={`Você deseja remover o histórico que foi pago para o ${funcao_produtor.toUpperCase()} `}
      titleSecondary={`Só será possível remover o pagamento do ${funcao_produtor.toUpperCase()} se esse recibo não estiver na conta corrente. Caso tenha na conta corrente só será possível voltar por lá !`}
      yes="Sim, Deletar !"
      onClickFalse={() => setModalCancelarPagamento(false)}
      onClickTrue={() => handleCancelarPagamento()}
    />
  );
};
