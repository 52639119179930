import { IDataTh, ThCoreIndex } from "src/Pages/components";

export const THeadControle = () => {
  const dataTh: IDataTh[] = [
    {
      title: "Ações",
      width: "40px",
      align: "left",
    },
    {
      title: "Empresa",
      align: "left",
      minWidth: "150px",
      width: "150px",
    },
    {
      title: "Usuário inicial",
      align: "left",
      minWidth: "150px",
      width: "150px",
    },
    {
      title: "Qtd usuários limite",
      align: "center",
      minWidth: "150px",
      width: "150px",
    },
    {
      title: "Ativa",
      align: "center",
      minWidth: "80px",
      width: "80px",
    },
  ];

  return <ThCoreIndex dataTh={dataTh} />;
};
