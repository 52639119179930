import { ChangeEvent, useEffect } from "react";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { useContextProfileContext } from "src/Contexts/ContextProfile/ContextProfile";
import { IUpdateEmailProfile } from "src/Contexts/ContextProfile/ContextProfileTypes";
import { AppTextField, FormCore } from "src/Pages/components";

import { UseFormUpdateProfileEmail } from "./Hooks/UseFormUpdateProfileEmail";

export const FormProfileEmail = () => {
  const { userPerfil } = useAuthContext();
  const { handleSubmit, loading: loadingUpdate } = UseFormUpdateProfileEmail();

  const { setValuesUpdateEmailProfile, valuesUpdateEmailProfile } =
    useContextProfileContext();

  useEffect(() => {
    setValuesUpdateEmailProfile({ email: userPerfil?.email });
  }, []);

  const handleChange =
    (prop: keyof IUpdateEmailProfile) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setValuesUpdateEmailProfile((prevValue) => ({
        ...prevValue,
        [prop]: event.target.value,
      }));
    };

  return (
    <FormCore
      handleCancel={() =>
        setValuesUpdateEmailProfile({ email: userPerfil?.email })
      }
      handleSubmit={handleSubmit}
      loadingSubmit={loadingUpdate}
      sxStyle={{ margin: 0 }}
    >
      <AppTextField
        label="Email"
        sxStyle={{
          pointerEvents: "",
          width: "100%",
          opacity: "",
          margin: 0,
        }}
        value={valuesUpdateEmailProfile?.email}
        variant="outlined"
        onChange={handleChange("email")}
      />
    </FormCore>
  );
};
