import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { useContextProdutor } from "src/Contexts/produtores/ProdutoresContext";
import { api } from "src/shared/setup/API/api";
import { FormatMoney } from "src/shared/Utils/FormatMoney";

export const UseGetByIdGrupoImposto = () => {
  const { setValuesInputsGrupoDeImposto } = useContextProdutor();
  const { handleGetAlert } = useLayoutMainContext();

  const handleGetById = async (id: string) => {
    await api
      .get(`/gruposImpostos/${id}`)
      .then(({ data }) => {
        setValuesInputsGrupoDeImposto({
          descricao: data?.descricao,
          valor: FormatMoney(data?.valor),
        });
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      );
  };

  return { handleGetById };
};
