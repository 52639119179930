import {
  IInputsGetCompaniesRecoverPassword,
  IInputsRecoverPassword,
} from "../RecoverPasswordContextTypes";

export const valuesDefaultInputsGetCompaniesRecoverPassword: IInputsGetCompaniesRecoverPassword =
  {
    email: "",
  };

export const valuesDefaultInputsRecoverPassword: IInputsRecoverPassword = {
  password: "",
};
