import { useEffect, useState } from "react";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

interface ICountStatics {
  data: {
    countPropostas: string;
    countParcelasRecebidas: string;
    countParcelasPagas: string;
    countAdministradoras: string;
    countModalidades: string;
    countOperadoras: string;
  };
}

export const UseGetCountStatics = () => {
  const { handleGetAlert } = useLayoutMainContext();

  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState<ICountStatics>();

  useEffect(() => {
    setLoading(true);

    api
      .post("/dashboard/corretora/count")
      .then((res) => setValues(res.data))
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  }, []);

  const dataCountStatics = [
    {
      value: values?.data.countOperadoras || "0",
      name: "Total operadoras",
      icon: "/assets/images/dashboard/icon-available-staff.svg",
    },
    {
      value: values?.data.countModalidades || "0",
      name: "Total modalidades",
      icon: "/assets/images/dashboard/icon-available-staff.svg",
    },
    {
      value: values?.data.countAdministradoras || "0",
      name: "Total administradora",
      icon: "/assets/images/dashboard/icon-available-staff.svg",
    },
    {
      value: values?.data.countPropostas || "0",
      name: "Proposta criadas",
      icon: "/assets/images/dashboard/icon-avg-cost.svg",
    },
    {
      value: values?.data.countParcelasRecebidas || "0",
      name: "Parcelas recebidas",
      icon: "/assets/images/dashboard/icon-avg-cost.svg",
    },
    {
      value: values?.data.countParcelasPagas || "0",
      name: "Parcelas pagas (corretor)",
      icon: "/assets/images/dashboard/icon-avg-cost.svg",
    },
  ];

  return { dataCountStatics, loading };
};
