import { useEffect } from "react";
import { useContextCompany } from "src/Contexts/Company/CompanyContext";
import { useGetCep } from "src/shared/Hooks/useGetCep";

export const UseGetCEPCompany = () => {
  const { handleGetCEP: handleGetCEP1 } = useGetCep();

  const { setValuesInputsCompanyDetails, valuesInputsCompanyDetails } =
    useContextCompany();

  useEffect(() => {
    if (valuesInputsCompanyDetails.address_zip_code?.length === 9) {
      const handleGetCEP = async () => {
        const { erro, bairro, localidade, logradouro, uf } =
          await handleGetCEP1(valuesInputsCompanyDetails.address_zip_code);

        if (erro) {
          setValuesInputsCompanyDetails({
            ...valuesInputsCompanyDetails,
            address_district: "",
            address_street: "",
            address_state: "",
            address_city: "",
          });
        } else {
          setValuesInputsCompanyDetails({
            ...valuesInputsCompanyDetails,
            address_district: bairro,
            address_street: logradouro,
            address_state: uf,
            address_city: localidade,
          });
        }
      };

      handleGetCEP();
    }
  }, [valuesInputsCompanyDetails.address_zip_code]);
};
