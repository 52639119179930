import { IDataTh, ThCoreIndex } from "src/Pages/components";

export const THeadComissaoProdutor = () => {
  const dataTh: IDataTh[] = [
    {
      title: "Ações",
      minWidth: "30px",
      width: "30px",
      align: "left",
    },
    {
      title: "Nome comissão",
      align: "left",
      minWidth: "140px",
      width: "140px",
    },
    {
      title: "Operadora",
      minWidth: "170px",
      align: "left",
      width: "170px",
    },
    {
      title: "Administradora",
      minWidth: "170px",
      width: "170px",
      align: "left",
    },
    {
      title: "Modalidade",
      minWidth: "170px",
      width: "170px",
      align: "left",
    },
    {
      title: "Produtor",
      align: "left",
      width: "160px",
    },
    {
      title: "Função",
      align: "left",
      width: "60px",
    },
    {
      title: "Tipo",
      align: "left",
      width: "60px",
    },
    {
      title: "Vigência",
      minWidth: "100px",
      align: "center",
      width: "100px",
      infoTitle: "Comissão valida a partir desta data",
    },
    {
      title: "Ativa",
      minWidth: "80px",
      align: "center",
      width: "80px",
      infoTitle: "Comissão ativa ficara disponível para utilizar em contratos",
    },
    {
      title: "Info",
      align: "center",
      width: "50px",
    },
  ];

  return <ThCoreIndex dataTh={dataTh} />;
};
