import { useNavigate } from "react-router-dom";

import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { usePageControle } from "src/Contexts/ControlCompanies/ControleEmpresaContext";
import { valuesDefaultInputsControlCompany } from "src/Contexts/ControlCompanies/ValuesDefault/ValuesDefaultControlCompany";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api, getToken } from "src/shared/setup/API/api";
import { apenasNumeros } from "src/shared/Utils/FormatMoney";
import { UrlConfig } from "src/shared/Utils/paths";

export const UseFormRegister = () => {
  const { setLoadingGravar } = useConfigPageContext();
  const { valuesInputsRegister, setValuesInputsRegister } = usePageControle();
  const { handleGetAlert } = useLayoutMainContext();
  const { setToken, RefreshSession } = useAuthContext();

  const navigate = useNavigate();

  const handleCreateProdutores = async (parceria?: string) => {
    const AllDatas = {
      accessLogin: {
        email: valuesInputsRegister.access_email,
        password: valuesInputsRegister.access_password,
        name: valuesInputsRegister.access_name,
      },
      nome_empresa: valuesInputsRegister.nome_empresa,
      telefone_1: apenasNumeros(valuesInputsRegister.telefone_1),
      id_parceria: parceria,
    };

    if (!AllDatas.accessLogin.name)
      return handleGetAlert({ message: `Digite um nome` });
    if (!AllDatas.accessLogin.email)
      return handleGetAlert({ message: `Digite um email` });
    if (!AllDatas.accessLogin.password)
      return handleGetAlert({ message: `Digite uma senha` });
    if (!AllDatas.nome_empresa)
      return handleGetAlert({ message: `Digite o nome da empresa` });
    if (!AllDatas.telefone_1)
      return handleGetAlert({ message: `Digite um telefone` });

    setLoadingGravar(true);

    return api
      .post("/registerCompany", AllDatas)
      .then(async (res) => {
        setToken(res.data.token);
        localStorage.setItem(getToken, res.data.token);

        navigate(`${UrlConfig.home}`);

        setValuesInputsRegister(valuesDefaultInputsControlCompany);
        await RefreshSession();
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoadingGravar(false));
  };

  return { handleSubmit: handleCreateProdutores };
};
