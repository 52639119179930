import { useState } from "react";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { useContextProdutor } from "src/Contexts/produtores/ProdutoresContext";
import { api } from "src/shared/setup/API/api";
import { FormatMoney } from "src/shared/Utils/FormatMoney";

export const useGetImpostoGlobal = () => {
  const { setValuesInputsImpostoGlobal } = useContextProdutor();
  const { handleGetAlert } = useLayoutMainContext();

  const [loading, setLoading] = useState(false);

  const handleGetById = async () => {
    setLoading(true);

    api
      .get("/impostoGlobal")
      .then(({ data }) => {
        setValuesInputsImpostoGlobal({
          descricao: data?.descricao,
          valor: FormatMoney(data?.valor),
        });
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  return { handleGetById, loading };
};
