import React, { useEffect } from "react";
import { useContextProdutor } from "src/Contexts/produtores/ProdutoresContext";
import { ModalCore } from "src/Pages/components";

import { FormChangeFunctionProducer } from "./FormChangeFunctionProducer";
import { UseFormFormChangeFunctionProducer } from "./UseFormChangeFunctionProducer";

interface IModalEditarParcela {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;

  setDataProdutorToEditFunction: React.Dispatch<
    React.SetStateAction<{
      name: string;
      function: string;
    }>
  >;

  dataProdutorToEditFunction: {
    name: string;
    function: string;
  };
}

export const ModalChangeFunctionProducer = ({
  openModal,
  setOpenModal,
  dataProdutorToEditFunction,
  setDataProdutorToEditFunction,
}: IModalEditarParcela) => {
  const { setChangeFunctionProducer } = useContextProdutor();

  useEffect(() => {
    if (!openModal) {
      setChangeFunctionProducer("CORRETOR");
      setDataProdutorToEditFunction({ function: "CORRETOR", name: "" });
    }
  }, [openModal]);

  const { handleChangeFunctionProducer, loading } =
    UseFormFormChangeFunctionProducer({ setOpenModal });

  return (
    <ModalCore
      loadingSubmit={loading}
      open={openModal}
      setOpen={setOpenModal}
      subTitle={`Produtor: ${dataProdutorToEditFunction.name}`}
      sxStyle={{ width: "540px" }}
      title="Trocar função do produtor"
      titleSubmit="Trocar função"
      onChange={handleChangeFunctionProducer}
    >
      <FormChangeFunctionProducer
        dataProdutorToEditFunction={dataProdutorToEditFunction}
      />
    </ModalCore>
  );
};
