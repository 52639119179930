import { UseAutoCompletedAll } from "src/Contexts/Utils/UseAutoCompletedAll";
import { AutoCompleteGeneric } from "src/Pages/components/InputAutoCompleted/AutoCompleteGeneric";
import { IPropsAutoCompleted } from "src/Pages/components/InputAutoCompleted/types";

export const AutoCompleteGrupoImposto = (props: IPropsAutoCompleted) => {
  const { disabled, label, width, tamanho, minWidth, maxWidth, fullWidth } =
    props;
  const { valueAutoCompGrupoImposto, setValueAutoCompGrupoImposto } =
    UseAutoCompletedAll();

  return (
    <AutoCompleteGeneric
      doesNotViewId
      disabled={disabled}
      fullWidth={fullWidth}
      label={label}
      maxWidth={maxWidth}
      minWidth={minWidth}
      setValueAutoCompleted={setValueAutoCompGrupoImposto}
      tamanho={tamanho}
      url="/listAllGruposImposto"
      valueAutoCompleted={valueAutoCompGrupoImposto}
      width={width}
    />
  );
};
