import { useContextComissoes } from "src/Contexts/comissoes/ComissoesContext";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

interface IHandleGet {
  itensPerPage: number;
  currentPage: number;
  nameSearch: string;
}

export const useHttpTableOperadora = () => {
  const { setQtd_registros, setLoadingTable } = useConfigPageContext();
  const { setListOperadora } = useContextComissoes();
  const { handleGetAlert } = useLayoutMainContext();

  const handleGet = async (body: IHandleGet) => {
    setLoadingTable(true);

    api
      .post("/comissoes/GetOperadora", {
        ...body,
        limit: body.itensPerPage,
        filter: body.nameSearch,
        page: body.currentPage,
      })
      .then((res) => {
        setListOperadora(res.data.data);
        setQtd_registros(res.data.count);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoadingTable(false));
  };

  return { handleGet };
};
