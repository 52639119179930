import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";
import { CardHeaderCore } from "src/Pages/components/CardCore/Components/CardHeaderCore";
import { RedeemIcon } from "src/Pages/components/Icons/Icons";
import { LinkCore } from "src/Pages/components/LinkCore/LinkCore";
import { FormatDateBR } from "src/shared/Utils/FormatDateBR";
import { UrlConfig } from "src/shared/Utils/paths";

export const HeaderIsPlanTest = () => {
  const { userPerfil } = useAuthContext();

  return (
    <>
      {userPerfil?.empresa.isPlanTest && (
        <CardHeaderCore
          buttonAdd={[
            <LinkCore key={1} to={UrlConfig.config.plans.url}>
              <ButtonCore title="Ver planos" />
            </LinkCore>,
          ]}
          icon={
            <RedeemIcon
              fontSize="small"
              sx={{ display: { xs: "none", sm: "block" } }}
            />
          }
          sxStyle={{
            border: `${
              userPerfil.empresa.remainingTestDays > 0 ? "2px" : "4px"
            } solid #f38484df`,
            margin: "16px 0",
            marginBottom: "16px",
          }}
          title={
            userPerfil.empresa.remainingTestDays > 0
              ? `Você não possui um plano ativo! Aproveite o(s) ${userPerfil?.empresa.remainingTestDays} dia(s) de período de avaliação.`
              : `Seu plano de teste acabou dia ${FormatDateBR(
                  userPerfil.empresa.dateLimitFree
                )}, faça a contratação do plano para não perder o acesso`
          }
        />
      )}
    </>
  );
};
