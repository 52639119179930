import { UseAutoCompletedAll } from "src/Contexts/Utils/UseAutoCompletedAll";
import { AutoCompleteGeneric } from "src/Pages/components/InputAutoCompleted/AutoCompleteGeneric";
import { IPropsAutoCompleted } from "src/Pages/components/InputAutoCompleted/types";

export const AutoCompleteCorretorGeneric = (props: IPropsAutoCompleted) => {
  const {
    label,
    width,
    tamanho,
    minWidth,
    maxWidth,
    fullWidth,
    disabled,
    variant,
  } = props;

  const { valueAutoCompProdutorActive, setValueAutoCompProdutorActive } =
    UseAutoCompletedAll();

  return (
    <AutoCompleteGeneric
      disabled={disabled}
      fullWidth={fullWidth}
      label={label}
      maxWidth={maxWidth}
      minWidth={minWidth}
      setValueAutoCompleted={setValueAutoCompProdutorActive}
      tamanho={tamanho}
      url="/getAllProdutores?funcao_produtor=CORRETOR"
      valueAutoCompleted={valueAutoCompProdutorActive}
      variant={variant}
      width={width}
    />
  );
};
