import { FC, ReactNode } from "react";
import { View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#000",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 18,
    fontSize: 8,
  },
});

interface ITableRowContainer {
  backgroundColor?: string;
  children: ReactNode;
}

export const TableRowContainer: FC<ITableRowContainer> = ({
  children,
  backgroundColor,
}) => {
  return <View style={{ ...styles.row, backgroundColor }}>{children} </View>;
};
