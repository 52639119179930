import { Children, useEffect } from "react";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextLogs } from "src/Contexts/Logs/LogsContext";
import { TableRowCore, TableCore, TdCore } from "src/Pages/components";
import { handleChipCoreCategories } from "src/Pages/components/CategoryComponentMap";
import { handleFormatDateDayWeek } from "src/shared/Utils/FormatDate/FormatDateToDayWeek";
import { FormatDateBR } from "src/shared/Utils/FormatDateBR";
import { handleFormatDateIfIsNull } from "src/shared/Utils/handleFormatDateIfIsNull";

import { FilterSidebarLogs } from "../FilterSidebar/FilterSidebar";

import { useHttpTableLogs } from "./hooks/useHttpTableLogs";
import { THeadLogs } from "./THead";

export const TableLogs = () => {
  const { valuesFilterLogs } = useContextLogs();
  const { handleGet } = useHttpTableLogs();
  const { listLogs } = useContextLogs();

  const { setNameSearch, itensPerPage, currentPage, nameSearch, attTable } =
    useConfigPageContext();

  useEffect(() => {
    handleGet({
      currentPage,
      itensPerPage,
      nameSearch,
      start_data: valuesFilterLogs.start_data,
      end_data: valuesFilterLogs.end_data,
      action: valuesFilterLogs.action,
      user: valuesFilterLogs.user,
    });
  }, [attTable, itensPerPage, currentPage, nameSearch]);

  return (
    <TableCore
      THead={<THeadLogs />}
      filter={<FilterSidebarLogs />}
      qtdList={listLogs.length}
      setNameSearch={setNameSearch}
    >
      {Children.toArray(
        listLogs.map((item) => {
          return (
            <TableRowCore id={item.id_tenant}>
              <TdCore
                isLimit
                isLimitQTD={35}
                values={
                  item.type_user === "PRODUCER"
                    ? `${item.id_producer} - ${item.name_producer}`
                    : `${item.id_user} - ${item.name_user}`
                }
              />
              <TdCore
                values={handleChipCoreCategories(
                  item.type_user === "PRODUCER" ? "Produtor" : "Usuário"
                )}
              />
              <TdCore
                tooltip={handleFormatDateDayWeek(
                  handleFormatDateIfIsNull(item.data_currency)
                )}
                values={handleChipCoreCategories(
                  `${FormatDateBR(item.data_currency)} - ${item.hours_currency}`
                )}
              />

              <TdCore values={item.action} />

              <TdCore
                tooltip={JSON.stringify(item.data as string)}
                values="..."
              />
            </TableRowCore>
          );
        })
      )}
    </TableCore>
  );
};
