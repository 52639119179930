import { Typography } from "@mui/material";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";

import { BasePageLogin } from "../components/BasePageLogin";
import { ButtonLoginAndSearch } from "../components/ButtonLoginAndSearch";
import { FooterFom } from "../Login/components/FooterFom";
import { FormLogin } from "../Login/components/FormLogin";
import { InputSelectEmpresa } from "../Login/components/InputSelect";

export const PageLoginRoot = () => {
  const {
    handleLogin,
    inputValueSelect,
    valuesInputsLogins,
    valuesAutoComplete,
    handleSearchCompany,
  } = useAuthContext();

  const handleSearchEmpresa = async () => {
    await handleSearchCompany({
      email: valuesInputsLogins.email,
      senha: valuesInputsLogins.senha,
      typeUser: "ROOT",
    });
  };

  const handleChangeLogin = async () => {
    await handleLogin({
      email: valuesInputsLogins.email,
      senha: valuesInputsLogins.senha,
      typeUser: "ROOT",
      tenant: inputValueSelect,
    });
  };

  return (
    <BasePageLogin sxStyle={{ width: { xs: "340px", md: "420px" } }}>
      <Typography mb="12px" ml="8px">
        Login Root
      </Typography>

      <FormLogin />

      <InputSelectEmpresa values={valuesAutoComplete} />

      <FooterFom />

      {valuesAutoComplete.length <= 1 ? (
        <ButtonLoginAndSearch title="Login" onClick={handleSearchEmpresa} />
      ) : (
        <ButtonLoginAndSearch title="Login" onClick={handleChangeLogin} />
      )}
    </BasePageLogin>
  );
};
