import { createContext, useContext, useState, FC, ReactNode } from "react";

import {
  IListFiliais,
  IListUsuarios,
  ICreateFiliais,
  ICreateUsuarios,
  IContextUsuarios,
  IListPerfilAcesso,
  ICreatePerfilAcesso,
} from "./UsuariosTypes";

const UsuariosContext = createContext({} as IContextUsuarios);

export const UsuariosContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [listFiliais, setListFiliais] = useState([] as IListFiliais[]);
  const [listUsuario, setListUsuario] = useState([] as IListUsuarios[]);

  const [listPerfilAcesso, setListPerfilAcesso] = useState(
    [] as IListPerfilAcesso[]
  );

  const [valuesInputsPerfilAcesso, setValuesInputsPerfilAcesso] =
    useState<ICreatePerfilAcesso>({
      id_permissao: "",
      perfil_acesso: "",
      permissions: [""],
    });

  const [valuesInputsUsuarios, setValuesInputsUsuarios] =
    useState<ICreateUsuarios>({
      nome: "",
      email: "",
      telefone1: "",
      horario_inicial: "08:00",
      horario_final: "20:00",
      type_user: "USER",
    });

  const [valuesInputsFiliais, setValuesInputsFiliais] =
    useState<ICreateFiliais>({ filial: "" });

  return (
    <UsuariosContext.Provider
      value={{
        listFiliais,
        listUsuario,
        setListFiliais,
        setListUsuario,
        listPerfilAcesso,
        valuesInputsFiliais,
        setListPerfilAcesso,
        valuesInputsUsuarios,
        setValuesInputsFiliais,
        setValuesInputsUsuarios,
        valuesInputsPerfilAcesso,
        setValuesInputsPerfilAcesso,
      }}
    >
      {children}
    </UsuariosContext.Provider>
  );
};

export const useContextUsuarios = (): IContextUsuarios =>
  useContext(UsuariosContext);
