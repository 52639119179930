import { View, Text } from "@react-pdf/renderer";

interface ILineItem {
  id: string | number;
  name: string | number;
}

export const LineItem = ({ id, name }: ILineItem) => {
  return (
    <View
      key={id}
      style={{
        width: "100%",
        borderBottomWidth: 1,
        borderStyle: "solid",
        flexDirection: "row",
        backgroundColor: "#e6e6e6",
      }}
    >
      <View style={{ width: "100%" }}>
        <Text
          style={{
            fontSize: 9,
            textAlign: "left",
            padding: "4px 4px 4px 8px",
          }}
        >
          {id} - {name}
        </Text>
      </View>
    </View>
  );
};
