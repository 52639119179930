import { Box, MenuItem } from "@mui/material";
import { useContextProdutor } from "src/Contexts/produtores/ProdutoresContext";
import { AppTextField } from "src/Pages/components";

interface IFormChangeFunctionProducer {
  dataProdutorToEditFunction: {
    name: string;
    function: string;
  };
}
export const FormChangeFunctionProducer = ({
  dataProdutorToEditFunction,
}: IFormChangeFunctionProducer) => {
  const { changeFunctionProducer, setChangeFunctionProducer } =
    useContextProdutor();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "center", sm: "inherit" },
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Box
          sx={{
            borderRadius: "6px",
            border: "1px solid gray",
            alignItems: "center",
            padding: "6px",
            minWidth: "140px",
            justifyContent: "center",
            display: "flex",
            fontWeight: "bold",
          }}
        >
          {dataProdutorToEditFunction?.function.toUpperCase()}
        </Box>

        <Box
          sx={{
            borderRadius: "6px",
            border: "1px solid  #0e6aff",
            width: "50px",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            fontWeight: "bold",
            color: "#0e6aff",
            margin: { xs: "10px", sm: "0" },
          }}
        >
          ≫
        </Box>

        <AppTextField
          select
          id="Função_produtor"
          label="Nova função do produtor"
          sxStyle={{ width: "140px", opacity: "", pointerEvents: "auto" }}
          value={changeFunctionProducer}
          onChange={(e) =>
            setChangeFunctionProducer(
              e.target.value as "CORRETOR" | "SUPERVISOR" | "GERENTE"
            )
          }
        >
          <MenuItem value="CORRETOR">Corretor</MenuItem>
          <MenuItem value="SUPERVISOR">Supervisor</MenuItem>
          <MenuItem value="GERENTE">Gerente</MenuItem>
        </AppTextField>
      </Box>
    </>
  );
};
