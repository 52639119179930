import { Children, useEffect } from "react";
import { Box, Tooltip, useTheme } from "@mui/material";
import { useContextDashboardBrokerContext } from "src/Contexts/Dashboard/ContextDashboardBroker";
import { TableRowCore, TableCore, TdCore } from "src/Pages/components";
import { HelpOutlineIcon } from "src/Pages/components/Icons/Icons";
import { FormatMoney } from "src/shared/Utils/FormatMoney";

import { useTableFilaisDashboard } from "./Hooks/useHttpConfirmationBranch";
import { THeadTableFilaisDashboard } from "./THead";

export const TableConfirmationFiliaisDashboard = () => {
  const theme = useTheme();
  const { handleGet } = useTableFilaisDashboard();
  const { dataConfirmationFiliais } = useContextDashboardBrokerContext();

  useEffect(() => {
    handleGet();
  }, []);

  return (
    <TableCore
      isNotPagination
      THead={<THeadTableFilaisDashboard />}
      heigthTable="300px"
      qtdList={dataConfirmationFiliais.data.length}
      sxStyle={{ margin: 0 }}
      title={
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box mr={1}>Lucro por filiais</Box>
          <Tooltip title="Lucro de parcelas recebidas">
            <HelpOutlineIcon sx={{ fontSize: "18px" }} />
          </Tooltip>
        </Box>
      }
    >
      {Children.toArray(
        dataConfirmationFiliais.data.map((item) => {
          return (
            <TableRowCore id={String(item.position)}>
              <TdCore
                borderBottom={`1px solid #0e6aff`}
                textAlign="center"
                values={item.position}
              />
              <TdCore
                isLimit
                isLimitQTD={22}
                values={`${item.filial.id} - ${item.filial.name}`}
              />
              <TdCore
                color="#3cb916"
                values={`R$ ${FormatMoney(item.lucroTotal)}`}
              />
            </TableRowCore>
          );
        })
      )}
    </TableCore>
  );
};
