import { ChangeEvent, useState } from "react";
import { Box, MenuItem } from "@mui/material";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { ICreateContrato } from "src/Contexts/Lancamentos/Contrato/ContratosTypes";
import { AppTextField, ButtonPersonalized } from "src/Pages/components";
import { DialogCore } from "src/Pages/components/DialogCore/DialogCore";
import { DividerCore } from "src/Pages/components/DividerCore/DividerCore";
import { RichText } from "src/Pages/components/RichText/RichText";
import {
  handleFormatPercent,
  apenasNumeros,
  FormatMoney,
} from "src/shared/Utils/FormatMoney";
import { cpfOurCnpj } from "src/shared/Utils/MaskCpfCnpj";

export const FormDados = () => {
  const { idTable } = useConfigPageContext();
  const { active_incluir } = useConfigPageContext();

  const { valuesInputsContrato, setValuesInputsContrato } =
    useContextContrato();

  const handleChange =
    (
      prop: keyof ICreateContrato,
      option?: "phone" | "money" | "percent" | "apenasNumero"
    ) =>
    (
      event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
    ) => {
      if (option === "money") {
        setValuesInputsContrato({
          ...valuesInputsContrato,
          [prop]: FormatMoney(event.target.value),
        });
      } else if (option === "phone") {
        setValuesInputsContrato({
          ...valuesInputsContrato,
          [prop]: cpfOurCnpj({ text: event.target.value, type: "phone" }),
        });
      } else if (option === "percent") {
        setValuesInputsContrato({
          ...valuesInputsContrato,
          [prop]: handleFormatPercent(event.target.value),
        });
      } else if (option === "apenasNumero") {
        setValuesInputsContrato({
          ...valuesInputsContrato,
          [prop]: apenasNumeros(event.target.value),
        });
      } else
        setValuesInputsContrato({
          ...valuesInputsContrato,
          [prop]: event.target.value,
        });
    };

  const [open, setOpen] = useState(false);
  const [view, setView] = useState(true);

  return (
    <>
      <DividerCore title="Dados" onClick={() => setView((item) => !item)} />
      <Box sx={{ display: view ? "flex" : "none", flexWrap: "wrap" }}>
        <AppTextField
          label="Data de emissão *"
          sxStyle={{ width: "120px" }}
          type="date"
          value={valuesInputsContrato.emissao_data}
          onChange={handleChange("emissao_data")}
        />
        <AppTextField
          label="Data de vigência*"
          sxStyle={{ width: "120px" }}
          type="date"
          value={valuesInputsContrato.vigencia_data}
          onChange={handleChange("vigencia_data")}
        />
        <AppTextField
          label="Data de vencimento 1p *"
          sxStyle={{ width: "135px" }}
          type="date"
          value={valuesInputsContrato.vencimento_data}
          onChange={handleChange("vencimento_data")}
        />

        <AppTextField
          label="Mensalidade *"
          sxStyle={{ width: "120px" }}
          value={valuesInputsContrato.mensalidade_value}
          onChange={handleChange("mensalidade_value", "money")}
        />
        <AppTextField
          select
          disabled={!!idTable}
          id="taxa_corretor"
          label="Cobrar TX corretor"
          sxStyle={{ width: "110px" }}
          value={valuesInputsContrato?.is_collect_fee_producer}
          onChange={handleChange("is_collect_fee_producer")}
        >
          <MenuItem value="NAO">Não</MenuItem>
          <MenuItem value="SIM">Sim</MenuItem>
        </AppTextField>
        <AppTextField
          disabled={
            !!idTable || valuesInputsContrato?.is_collect_fee_producer === "NAO"
          }
          label="Taxa *"
          sxStyle={{ width: "80px" }}
          value={valuesInputsContrato.taxa_value}
          onChange={handleChange("taxa_value", "money")}
        />
        <AppTextField
          label="Vidas *"
          sxStyle={{ width: "60px" }}
          value={valuesInputsContrato.vidas}
          onChange={handleChange("vidas", "apenasNumero")}
        />

        {/* <AppTextField
          label="IOF"
          sxStyle={{ width: "80px" }}
          value={valuesInputsContrato.iof_porc}
          onChange={handleChange("iof_porc", "percent")}
        /> */}

        <ButtonPersonalized
          disabled={!active_incluir}
          title="Observação"
          variant="outlined"
          onClick={() => setOpen(true)}
        />

        <DialogCore
          PaperProps={{
            style: {
              padding: "1rem",
              minHeight: "300px",
              borderRadius: ".7rem",
              width: "80%",
              maxWidth: "800px",
            },
          }}
          open={open}
          setOpen={setOpen}
          title="Observações"
        >
          <RichText
            setValue={(e) => {
              setValuesInputsContrato({
                ...valuesInputsContrato,
                observacoes: e.toString(),
              });
            }}
            value={valuesInputsContrato.observacoes}
          />
        </DialogCore>
      </Box>
    </>
  );
};
