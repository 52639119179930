import { useState } from "react";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useResetInputs } from "src/Contexts/hooks/useResetInputs";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { ModalDeleteCore } from "src/Pages/components";
import { api } from "src/shared/setup/API/api";

interface IModalIsActiveUser {
  modalIsActiveAccess: boolean;
  setModalIsActiveAccess: React.Dispatch<React.SetStateAction<boolean>>;
  IsActiveAccess: boolean;
}

export const ModalChangeIsActiveUser = ({
  setModalIsActiveAccess,
  modalIsActiveAccess,
  IsActiveAccess,
}: IModalIsActiveUser) => {
  const { setAttTable, id } = useConfigPageContext();
  const { handleGetAlert } = useLayoutMainContext();
  const { resetInputs } = useResetInputs();

  const [loading, setLoading] = useState(false);

  const handleIsActiveAccess = async () => {
    if (!id)
      return handleGetAlert({ message: "Escolha uma linha para editar" });

    setLoading(true);

    return api
      .patch(`/usuarios/changeIsActive/${id}`, {
        is_habilitado: IsActiveAccess,
      })
      .then((res) => {
        handleGetAlert({ message: res.data.message });
        setAttTable((item) => !item);
        resetInputs();
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => {
        setModalIsActiveAccess(false);
        setLoading(false);
      });
  };

  return (
    <ModalDeleteCore
      loading={loading}
      modalOpen={modalIsActiveAccess}
      no="Não, Cancelar"
      titlePrimary={`Você deseja ${
        !IsActiveAccess ? "DESATIVAR" : "ATIVAR"
      } o acesso do usuário `}
      titleSecondary={
        !IsActiveAccess
          ? "Usuário não será REMOVIDO ! Apenas será desativado acesso do sistema."
          : "Usuário ficará disponível para acessar o sistema."
      }
      yes={`Sim, ${!IsActiveAccess ? "Desativar" : "Ativar"} !`}
      onClickFalse={() => setModalIsActiveAccess(false)}
      onClickTrue={() => handleIsActiveAccess()}
    />
  );
};
