import { SetStateAction, FC, Dispatch } from "react";
import { Box, Card, Skeleton, Tooltip } from "@mui/material";

export interface InfoWidgets {
  id: number;
  icon: string;
  count: undefined | string;
  details: string;
  bgColor?: string;
}

type IInfoWidgets = {
  data: InfoWidgets;
  loading?: boolean;
  setAttInfo: Dispatch<SetStateAction<boolean>>;
  attInfo: boolean;
};

export const CardInfoServidor: FC<IInfoWidgets> = ({
  data,
  loading,
  attInfo,
  setAttInfo,
}) => {
  return (
    <Card
      sx={{
        border: (theme) => `0.8px solid ${theme.palette.grey[400]}`,
        borderRadius: ".7rem",
        width: { xs: "100%", sm: "240px" },
        maxWidth: "100%",
        margin: { xs: "0.5rem 0", sm: ".7rem" },
        padding: 0.5,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Tooltip followCursor title="Clique para recarregar infos">
          <Box
            sx={{
              cursor: "pointer",
              m: 2,
              "& img": {
                height: { xs: 60, md: 70, xl: 80 },
                width: { xs: 60, md: 70, xl: 80 },
              },
            }}
            onClick={() => setAttInfo(!attInfo)}
          >
            <img alt="icon" src={data.icon} />
          </Box>
        </Tooltip>

        <Box sx={{ textAlign: "center" }}>
          <Box component="h3" sx={{ fontWeight: "500", fontSize: 18 }}>
            {loading ? <Skeleton variant="text" width="100%" /> : data.count}
          </Box>
          <Box component="p" sx={{ color: "text.secondary" }}>
            {data.details}
          </Box>
        </Box>
      </Box>
    </Card>
  );
};
