import { useContextComissoes } from "src/Contexts/comissoes/ComissoesContext";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

interface IHandleGet {
  currentPage?: number;
  itensPerPage?: number;
  nameSearch?: string;

  codeOperadora?: string;
  name?: string;
  isActive?: "TRUE" | "FALSE" | "ALL";
}

export const useHttpTableComissaoProdutor = () => {
  const { handleGetAlert } = useLayoutMainContext();
  const {
    setQtd_registros,
    setLoadingTable,
    itensPerPage,
    currentPage,
    nameSearch,
  } = useConfigPageContext();

  const { setListComissaoProdutor } = useContextComissoes();

  const handleGet = async (body: IHandleGet) => {
    setLoadingTable(true);

    api
      .post("/comissoes/GetProdutores", {
        ...body,
        page: currentPage,
        limit: itensPerPage,
        filter: body.name || nameSearch,
      })
      .then((res) => {
        setListComissaoProdutor(res.data.data);
        setQtd_registros(res.data.count);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoadingTable(false));
  };

  return { handleGet };
};
