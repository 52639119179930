import { useState } from "react";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useResetInputs } from "src/Contexts/hooks/useResetInputs";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

export const UseDisabledCompany = () => {
  const { setAttTable, id } = useConfigPageContext();
  const { handleGetAlert } = useLayoutMainContext();
  const { resetInputs } = useResetInputs();

  const [isActive, setIsActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleDisabledCompany = async () => {
    if (!id)
      return handleGetAlert({ message: "Escolha uma linha para desabilitar" });

    setLoading(true);

    return api
      .put(`/controleEmpresas/disabled/${id}`, {
        is_active: isActive,
      })
      .then((res) => {
        handleGetAlert({ message: res.data.message });
        setAttTable((item) => !item);
        resetInputs();
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => {
        setOpen(false);
        setLoading(false);
      });
  };

  return {
    handleDisabledCompany,
    setIsActive,
    isActive,
    setOpen,
    loading,
    open,
  };
};
