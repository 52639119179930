import { Badge, styled } from "@mui/material";

interface IStyleBadge {
  actived: string;
}

export const StyledBadgeAvatar = styled(Badge)<IStyleBadge>(
  ({ theme, actived }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: actived === "true" ? "#44b700" : "red",
      color: actived === "true" ? "#44b700" : "red",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(0.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  })
);
