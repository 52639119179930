import { useContextComissoes } from "src/Contexts/comissoes/ComissoesContext";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useResetInputs } from "src/Contexts/hooks/useResetInputs";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { UseAutoCompletedAll } from "src/Contexts/Utils/UseAutoCompletedAll";
import { api } from "src/shared/setup/API/api";

export const UseFeaturesFormulario = () => {
  const { valuesInputsComissaoCorretora } = useContextComissoes();
  const { handleGetAlert } = useLayoutMainContext();
  const { resetInputs } = useResetInputs();

  const { setAttTable, attTable, idTable, id, setLoadingGravar } =
    useConfigPageContext();

  const {
    valueAutoCompAdministradora,
    valueAutoCompModalidade,
    valueAutoCompOperadora,
  } = UseAutoCompletedAll();

  const {
    name,
    date_vigencia,
    parc_1,
    parc_2,
    parc_3,
    parc_4,
    parc_5,
    parc_6,
    parc_7,
    parc_8,
    parc_9,
    parc_10,
    parc_11,
    parc_12,
    parc_13,
    parc_14,
    parc_15,
    parc_16,
  } = valuesInputsComissaoCorretora;

  const objComissao = {
    name,
    date_vigencia,

    code_operadora: Number(valueAutoCompOperadora.id),
    code_modalidade: Number(valueAutoCompModalidade.id),
    code_administradora: valueAutoCompAdministradora.id
      ? Number(valueAutoCompAdministradora.id)
      : null,

    numero_parcela: {
      parc_1,
      parc_2,
      parc_3,
      parc_4,
      parc_5,
      parc_6,
      parc_7,
      parc_8,
      parc_9,
      parc_10,
      parc_11,
      parc_12,
      parc_13,
      parc_14,
      parc_15,
      parc_16,
    },
  };

  const handleCreate = async () => {
    if (!valuesInputsComissaoCorretora.name)
      return handleGetAlert({ message: `Digite um nome de comissão !` });
    if (!valuesInputsComissaoCorretora.date_vigencia)
      return handleGetAlert({ message: `Escolha uma data de vigência !` });
    if (!valueAutoCompOperadora.id)
      return handleGetAlert({ message: `Escolha uma operadora !` });
    if (!valueAutoCompModalidade.id)
      return handleGetAlert({ message: `Escolha uma modalidade !` });

    setLoadingGravar(true);

    const AllDatas = { ...objComissao };

    return api
      .post("/comissoes/corretora/create", AllDatas)
      .then((res) => {
        handleGetAlert({ message: res.data.message });
        setAttTable(!attTable);
        resetInputs();
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoadingGravar(false));
  };

  const handleEdit = () => {
    if (!valuesInputsComissaoCorretora.name)
      return handleGetAlert({ message: `Digite um nome de comissão !` });
    if (!valuesInputsComissaoCorretora.date_vigencia)
      return handleGetAlert({ message: `Escolha uma data de vigência !` });
    if (!valueAutoCompOperadora.id)
      return handleGetAlert({ message: `Escolha uma operadora !` });
    if (!valueAutoCompModalidade.id)
      return handleGetAlert({ message: `Escolha uma modalidade !` });

    setLoadingGravar(true);

    const AllDatas = { ...objComissao };

    return api
      .put(`/comissoes/corretora/${id}`, AllDatas)
      .then((res) => {
        setAttTable(!attTable);
        handleGetAlert({ message: res.data.message });
        resetInputs();
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoadingGravar(false));
  };

  const handleSubmit = () => {
    if (idTable) {
      handleEdit();
    } else {
      handleCreate();
    }
  };

  return { handleSubmit };
};
