import { Children } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { IValueComissaoCorretoraGrade } from "src/Contexts/Lancamentos/Contrato/ContratosTypes";

interface IListCoreComissaoGrade {
  title: string;
  list: IValueComissaoCorretoraGrade | undefined;
  input?: boolean;
}

export const ListComissaoGrade = ({
  list,
  input,
  title,
}: IListCoreComissaoGrade) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Typography
        sx={{
          background: "#F1F3F6",
          textAlign: "center",
          border: "1px solid #cacaca",
          borderRadius: "8px 8px 0 0",
          position: "sticky",
          top: "0",
          zIndex: "2",
        }}
        variant="subtitle1"
      >
        {title}
      </Typography>

      <Box sx={{ border: "1px solid #cacaca" }}>
        {Children.toArray(
          list?.parcelas?.map((item, index) => {
            return (
              <Tooltip title={`Parcela: ${index + 1}`}>
                <Box
                  sx={{
                    margin: "8px",
                    color: input ? "green" : "orange",
                    textAlign: "center",
                  }}
                >
                  {`${item.value}%`}
                </Box>
              </Tooltip>
            );
          })
        )}
      </Box>
    </Box>
  );
};
