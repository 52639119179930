import { useContextProdutor } from "src/Contexts/produtores/ProdutoresContext";
import { UseAutoCompletedAll } from "src/Contexts/Utils/UseAutoCompletedAll";
import { AutoCompleteGeneric } from "src/Pages/components/InputAutoCompleted/AutoCompleteGeneric";
import { IPropsAutoCompleted } from "src/Pages/components/InputAutoCompleted/types";

export const AutoCompleteGerenteAll = (props: IPropsAutoCompleted) => {
  const { label, width, tamanho, minWidth, maxWidth, fullWidth } = props;
  const { valuesInputsProdutor } = useContextProdutor();

  const { setValueAutoCompGerente, valueAutoCompGerente } =
    UseAutoCompletedAll();

  return (
    <AutoCompleteGeneric
      disabled={
        valuesInputsProdutor.funcao_produtor === "CORRETOR" ||
        valuesInputsProdutor.funcao_produtor === "GERENTE"
      }
      fullWidth={fullWidth}
      label={label}
      maxWidth={maxWidth}
      minWidth={minWidth}
      setValueAutoCompleted={setValueAutoCompGerente}
      tamanho={tamanho}
      url="/getAllProdutores?funcao_produtor=GERENTE&is_active_producer=true"
      valueAutoCompleted={valueAutoCompGerente}
      width={width}
    />
  );
};
