import { Box } from "@mui/material";

import { CardPlan } from "./Components/CardPlan/CardPlan";
import { Faq } from "./Components/Faq/Faq";

export const PagePlans = () => {
  return (
    <Box sx={{ padding: { xs: ".7rem", sm: "16px" } }}>
      <CardPlan />

      <Faq />
    </Box>
  );
};
