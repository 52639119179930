import { useContextDashboardBrokerContext } from "src/Contexts/Dashboard/ContextDashboardBroker";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

export const useHttpConfirmationOperator = () => {
  const { handleGetAlert } = useLayoutMainContext();
  const { setDataConfirmationOperator } = useContextDashboardBrokerContext();

  const handleGet = async () => {
    api
      .post("/dashboard/corretora/confirmationOperator")
      .then(({ data }) => setDataConfirmationOperator(data))
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      );
  };

  return { handleGet };
};
