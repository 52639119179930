import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { CardContract } from "src/Pages/components/CardContract/CardContract";
import { TitleWithValueCore } from "src/Pages/components/TitleWithValueCore/TitleWithValueCore";
import { FormatDateBR } from "src/shared/Utils/FormatDateBR";
import { FormatMoney } from "src/shared/Utils/FormatMoney";

export const CardDadosComplementar = () => {
  const { valueContractInternal } = useContextContrato();

  if (!valueContractInternal) return null;

  const { mensalidade_value, vidas, vigencia_data, emissao_data } =
    valueContractInternal;

  return (
    <>
      <CardContract title="Dados complementar">
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="Data de emissão:"
          value={FormatDateBR(emissao_data)}
        />
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="Data de vigência:"
          value={FormatDateBR(vigencia_data)}
        />
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="Mensalidade:"
          value={`R$ ${FormatMoney(mensalidade_value)}`}
        />
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="Vidas:"
          value={vidas}
        />
      </CardContract>
      {/* <CardContract>
        <TitleWithValueCore
          title="Observações:"
          value={observacoes}
          sxStyleTitle={{ fontWeight: "500" }}
        />
      </CardContract> */}
    </>
  );
};
