import { Dispatch, SetStateAction, useState } from "react";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { IRecibos } from "src/Contexts/Recibos/RecibosTypes";
import { api } from "src/shared/setup/API/api";

interface IUseGetReciboById {
  setValueReceipt: Dispatch<SetStateAction<IRecibos | undefined>>;
}

export const useGetReciboById = ({ setValueReceipt }: IUseGetReciboById) => {
  const { handleGetAlert } = useLayoutMainContext();

  const [loading, setLoading] = useState(false);

  const handleGetReciboById = async (id: string) => {
    setLoading(true);

    await api
      .get(`/contaCorrenteRecibos/${id}`)
      .then((response) => setValueReceipt(response.data))
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  return { handleGetReciboById, loading };
};
