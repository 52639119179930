import { useState } from "react";
import { useContextDashboardBrokerContext } from "src/Contexts/Dashboard/ContextDashboardBroker";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

export const UseFormMonthByMonthProfitChart = () => {
  const { handleGetAlert } = useLayoutMainContext();

  const { filterProfitableValues, setDataProfitableValues } =
    useContextDashboardBrokerContext();

  const [loading, setLoading] = useState(false);

  const handleCreatePerfilAcesso = async () => {
    if (!filterProfitableValues.start_date)
      return handleGetAlert({ message: `Escolha uma data inicial !` });
    if (!filterProfitableValues.end_date)
      return handleGetAlert({ message: `Escolha uma data final !` });

    setLoading(true);

    return api
      .post("/dashboard/corretora/profitableValues", filterProfitableValues)
      .then((res) => {
        setDataProfitableValues(res.data);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  return { handleSubmit: handleCreatePerfilAcesso, loading };
};
