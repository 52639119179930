import { ChangeEvent, useEffect } from "react";
import { Box, MenuItem } from "@mui/material";
import { useContextDashboardBrokerContext } from "src/Contexts/Dashboard/ContextDashboardBroker";
import { IFilterProfitableValues } from "src/Contexts/Dashboard/ContextDashboardBrokerTypes";
import { AppTextField, FormCore } from "src/Pages/components";

import { UseFormMonthByMonthProfitChart } from "./Hooks/UseFormMonthByMonthProfitChart";

export const FormMonthByMonthProfitChart = () => {
  const { filterProfitableValues, setFilterProfitableValues } =
    useContextDashboardBrokerContext();

  const { handleSubmit, loading } = UseFormMonthByMonthProfitChart();

  const handleChange =
    (prop: keyof IFilterProfitableValues) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setFilterProfitableValues({
        ...filterProfitableValues,
        [prop]: event.target.value,
      });
    };

  useEffect(() => {
    handleSubmit();
  }, []);

  return (
    <FormCore
      isNotCancel
      handleSubmit={handleSubmit}
      loadingSubmit={loading}
      marginTopButtons="0px"
      sxStyle={{
        margin: "0 0 12px 0",
        display: "flex",
        alignItems: "center",
      }}
      titleSubmit="Filtrar"
    >
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <AppTextField
          label="Inicio *"
          sxStyle={{ width: "150px", opacity: "", pointerEvents: "auto" }}
          type="date"
          value={filterProfitableValues?.start_date}
          variant="outlined"
          onChange={handleChange("start_date")}
        />
        <AppTextField
          label="Final *"
          sxStyle={{ width: "150px", opacity: "", pointerEvents: "auto" }}
          type="date"
          value={filterProfitableValues?.end_date}
          variant="outlined"
          onChange={handleChange("end_date")}
        />
        <AppTextField
          select
          id="tipo"
          label="Tipo *"
          sxStyle={{ width: "150px", opacity: "", pointerEvents: "auto" }}
          value={filterProfitableValues?.interval}
          variant="outlined"
          onChange={handleChange("interval")}
        >
          <MenuItem value="DAY">Dia</MenuItem>
          <MenuItem value="WEEK">Semana</MenuItem>
          <MenuItem value="MONTH">Mês</MenuItem>
        </AppTextField>
      </Box>
    </FormCore>
  );
};
