import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";

import { CardAsideProfile } from "./Components/CardAsideProfile/CardAsideProfile";
import { CardConfig } from "./Components/CardProfile";
import { CardProfileEmail } from "./Components/CardProfileEmail";
import { CardProfileImg } from "./Components/CardProfileImg/CardProfileImg";
import { CardProfilePassword } from "./Components/CardProfilePassword";
import { CardHeaderProfile } from "./Components/Header/CardHeaderProfile";

export const PagePerfil = () => {
  const { openAside } = useLayoutMainContext();

  const theme = useTheme();
  const isBreakPoint = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Grid container>
      <Grid item xs={12}>
        <CardHeaderProfile />
      </Grid>

      <Grid container spacing={2}>
        <Grid
          container
          item
          direction="column"
          lg={openAside && isBreakPoint ? 12 : 7}
          md={12}
          spacing={2}
        >
          <Grid item>
            <CardProfileImg />
          </Grid>

          <Grid item>
            <CardConfig />
          </Grid>

          <Grid container item spacing={2}>
            <Grid item md={openAside && isBreakPoint ? 12 : 5} xs={12}>
              <CardProfilePassword />
            </Grid>
            <Grid item md={openAside && isBreakPoint ? 12 : 7} xs={12}>
              <CardProfileEmail />
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={openAside && isBreakPoint ? 12 : 5} md={12}>
          <CardAsideProfile />
        </Grid>
      </Grid>
    </Grid>
  );
};
