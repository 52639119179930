import { IDataTh, ThCoreIndex } from "src/Pages/components";

export const THeadAdministradora = () => {
  const dataTh: IDataTh[] = [
    {
      title: "Ações",
      align: "left",
      width: "40px",
    },
    {
      tooltip: "Código - Administradora",
      title: "Administradora",
      align: "left",
      width: "150px",
    },
    {
      title: "Ativo",
      align: "left",
      width: "75%",
      infoTitle:
        "Administradora ativa ficara disponível para utilizar em grades de comissão",
    },
  ];

  return <ThCoreIndex dataTh={dataTh} />;
};
