import { formatString } from "@format-string/core";
import { useContextCompany } from "src/Contexts/Company/CompanyContext";
import { IGetCompanyDetails } from "src/Contexts/Company/CompanyContextTypes";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";
import { handleFormatCep } from "src/shared/Utils/FormatCEP";

export const UseGetDataCompany = () => {
  const { setValuesInputsCompanyDetails } = useContextCompany();
  const { setLoadingGravar } = useConfigPageContext();
  const { handleGetAlert } = useLayoutMainContext();

  const handleGetCompanyDetails = () => {
    setLoadingGravar(true);

    api
      .get<IGetCompanyDetails>(`/company/details`)
      .then(
        ({
          data: { fantasyName, id, address, cnpj, createdAt, responsible },
        }) =>
          setValuesInputsCompanyDetails({
            id,
            fantasyName,
            cnpj: formatString({ value: cnpj, type: "cnpj" }),

            responsible_telephone2: formatString({
              value: responsible?.telephone2,
              type: "phone",
            }),

            responsible_telephone1: formatString({
              value: responsible?.telephone1,
              type: "phone",
            }) as string,

            responsible_email: responsible.email,
            responsible_name: responsible.name,

            address_city: address.city,
            address_district: address.district,
            address_state: address.state,
            address_street: address.street,
            address_zip_code: handleFormatCep(address.zipCode || ""),
            address_address_number: address?.addressNumber,
            address_complement: address.complement,

            createdAt,
          })
      )
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoadingGravar(false));
  };

  return {
    handleGetCompanyDetails,
  };
};
