import { Box, Grid } from "@mui/material";
import { CardCore } from "src/Pages/components/CardCore/CardCore";
import { CardBodyCore } from "src/Pages/components/CardCore/Components/CardBodyCore";

import { FormCompanyDetails } from "../CardConfig/Form/FormCompanyDetails";

export const CardBodyCompany = () => {
  return (
    <Grid container spacing={2}>
      <Grid item md={8} xs={12}>
        <FormCompanyDetails />
      </Grid>

      <Grid item md={4} xs={12}>
        <CardCore sxStyle={{ margin: 0 }}>
          <CardBodyCore title="EM BREVE">
            <Box sx={{ textAlign: "center" }}>Logo</Box>
          </CardBodyCore>
        </CardCore>
      </Grid>
    </Grid>
  );
};
