import { useEffect } from "react";
import { UseValuesDefaultInputUsers } from "src/Contexts/Usuarios/ValuesDefaultInputUsers/ValuesDefaultInputUsers";
import { UseAutoCompletedAll } from "src/Contexts/Utils/UseAutoCompletedAll";
import { UseVerifyIsPermissionsUser } from "src/Pages/components/hooks/UseVerifyIsPermissionsUser";
import { AutoCompleteGeneric } from "src/Pages/components/InputAutoCompleted/AutoCompleteGeneric";
import { IPropsAutoCompleted } from "src/Pages/components/InputAutoCompleted/types";
import { valuesPermissions } from "src/shared/Utils/ValuesPermissions";

export const AutoCompleteFiliaisGeneric = (props: IPropsAutoCompleted) => {
  const {
    disabled,
    label,
    width,
    tamanho,
    minWidth,
    maxWidth,
    fullWidth,
    variant,
  } = props;

  const { handleVerifyPermissionItem } = UseVerifyIsPermissionsUser();
  const { valueDefaultAutoCompletedFiliais } = UseValuesDefaultInputUsers();
  const { valueAutoCompletedFiliais, setValueAutoCompletedFiliais } =
    UseAutoCompletedAll();

  useEffect(() => {
    setValueAutoCompletedFiliais(valueDefaultAutoCompletedFiliais);
  }, []);

  return (
    <AutoCompleteGeneric
      disabled={
        handleVerifyPermissionItem(
          valuesPermissions.UTILS.ESCOLHER_FILIAL.id
        ) || disabled
      }
      fullWidth={fullWidth}
      label={label}
      maxWidth={maxWidth}
      minWidth={minWidth}
      setValueAutoCompleted={setValueAutoCompletedFiliais}
      tamanho={tamanho}
      url="/GetAllFiliais"
      valueAutoCompleted={valueAutoCompletedFiliais}
      variant={variant}
      width={width}
    />
  );
};
