import { CardCore } from "src/Pages/components/CardCore/CardCore";
import { CardBodyCore } from "src/Pages/components/CardCore/Components/CardBodyCore";

import { FormProfileEmail } from "./Form/FormProfileEmail";

export const CardProfileEmail = () => {
  return (
    <CardCore sxStyle={{ margin: 0 }}>
      <CardBodyCore title="Trocar email">
        <FormProfileEmail />
      </CardBodyCore>
    </CardCore>
  );
};
