import { createContext, useContext, useState, FC, ReactNode } from "react";

import { IContextLogs, IListLogs, IValuesFilterLogs } from "./LogsContextTypes";
import { valuesDefaultFilterLogs } from "./valuesDefaultInputsLogs/valuesDefaultInputsLogs";

const LogsContext = createContext({} as IContextLogs);

export const LogsContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [listLogs, setListLogs] = useState([] as IListLogs[]);

  const [valuesFilterLogs, setValuesFilterLogs] = useState<IValuesFilterLogs>(
    valuesDefaultFilterLogs
  );

  return (
    <LogsContext.Provider
      value={{
        setValuesFilterLogs,
        valuesFilterLogs,
        setListLogs,
        listLogs,
      }}
    >
      {children}
    </LogsContext.Provider>
  );
};

export const useContextLogs = (): IContextLogs => useContext(LogsContext);
