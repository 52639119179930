import { Navigate, Route, Routes } from "react-router-dom";
import { PageLogin } from "src/Pages/TelasLogin/Login/Index";
import { PageLoginRoot } from "src/Pages/TelasLogin/LoginRoot/Index";
import { PageRecoverPassword } from "src/Pages/TelasLogin/RecoverPassword";
import { PageLoginRegister } from "src/Pages/TelasLogin/Register/Index";
import { PageSendLinkRecoverPassword } from "src/Pages/TelasLogin/SendLinkRecoverPassword/Index";

import { UrlConfigPublic } from "./Config/UrlConfigPublic";

export const PublicRoutes = () => {
  return (
    <Routes>
      <Route element={<PageLogin />} path={UrlConfigPublic.login} />

      <Route element={<PageLoginRegister />} path={UrlConfigPublic.register} />

      <Route element={<PageLoginRoot />} path={UrlConfigPublic.loginRoot} />

      <Route
        element={<PageSendLinkRecoverPassword />}
        path={UrlConfigPublic.SendLinkRecoverPassword}
      />

      <Route
        element={<PageRecoverPassword />}
        path={UrlConfigPublic.RecoverPassword}
      />

      <Route element={<Navigate to="/login" />} path="*" />
    </Routes>
  );
};
