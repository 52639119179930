import { useState } from "react";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

export const UseFormFormChangeFunctionProducer = () => {
  const { setAttTable, id } = useConfigPageContext();
  const { handleGetAlert } = useLayoutMainContext();

  const [loading, setLoading] = useState(false);

  const handleChangeEmailProducer = async (email: string | null) => {
    setLoading(true);

    await api
      .patch(`/produtor/changeEmail/${id}`, {
        email: email || "",
      })
      .then((res) => {
        handleGetAlert({ message: res.data.message });
        setAttTable((item) => !item);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  const handleChangeIsActiveProducer = async (isActive: boolean) => {
    setLoading(true);

    await api
      .patch(`/produtor/changeIsActiveAccess/${id}`, {
        is_active_access: isActive,
      })
      .then((res) => {
        handleGetAlert({ message: res.data.message });
        setAttTable((item) => !item);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  return {
    handleChangeIsActiveProducer,
    handleChangeEmailProducer,
    loading,
  };
};
