import { Children, ReactNode } from "react";
import { formatRemove } from "@format-string/core";
import { Box, Chip, Typography, alpha } from "@mui/material";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { FormatMoney } from "src/shared/Utils/FormatMoney";

import { ButtonCore } from "../ButtonCore/ButtonCore";
import { WorkspacePremiumIcon } from "../Icons/Icons";

import { CompDescriptionPlansDetails } from "./Components/CompDescriptionPlansDetails";
import { handleGetColorPlans } from "./Components/Helpers";

export type ICardPlans = {
  data: {
    title: "Custom" | "Bronze" | "Prata";
    value: number;
    subTitle: string;
    details: {
      title: string;
      exclusive?: boolean;
      fontWeight?: string;
    }[];
    onClick?: () => void;
    personalizedPlan?: ReactNode;
  };
};

export function getLinkWhatApp(whats: string, encodedMessage?: string): string {
  return `https://wa.me/${formatRemove(whats || "")}?text=${encodedMessage}`;
}

export const CardPlans = ({
  data: { subTitle, title, value, details, personalizedPlan, onClick },
}: ICardPlans) => {
  const { userPerfil } = useAuthContext();

  return (
    <Box
      sx={{
        padding: { xs: "16px", sm: "18px" },
        background: `linear-gradient(180deg, ${alpha(
          handleGetColorPlans(title) as string,
          0.3
        )} 1%, rgba(0,50,76,0) 25%)`,

        borderRadius: "8px",
        boxShadow: (theme) => theme.shadows[4],

        "&: hover ": {
          transition: "0.5s ease",
          transform: " scale(1.01)",
        },
      }}
    >
      <Chip
        icon={
          <WorkspacePremiumIcon style={{ color: handleGetColorPlans(title) }} />
        }
        label={title === "Custom" ? "Personalizado" : title}
        size="small"
        sx={{
          fontSize: { xs: ".8rem", sm: "1rem" },
          fontWeight: "500",

          padding: { xs: ".8rem", sm: "1rem" },
          marginBottom: { xs: "1rem", sm: "2rem" },

          color: handleGetColorPlans(title),
          border: `1px solid ${handleGetColorPlans(title)}`,
        }}
        variant="outlined"
      />

      <Box
        sx={{ display: "flex", alignItems: "baseline", marginBottom: ".3rem" }}
      >
        <Typography
          sx={{ fontSize: { xs: "1rem", sm: "1.3rem" }, marginRight: "8px" }}
        >
          R$
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "1.3rem", sm: "1.7rem" },
            fontWeight: "500",
            marginRight: "8px",
          }}
        >
          {FormatMoney(value.toFixed(2))}
        </Typography>
        <Typography sx={{ fontSize: { xs: ".7rem", sm: "1rem" } }}>
          / Mês
        </Typography>
      </Box>

      <Typography
        sx={{ fontSize: { xs: ".7rem", sm: ".8rem" }, marginBottom: "1.2rem" }}
      >
        {subTitle}
      </Typography>

      <ButtonCore
        fullWidth
        sx={{
          alignItems: "center",
          margin: "0 auto",
          display: "flex",
          marginBottom: "1.2rem",
          background: alpha(handleGetColorPlans(title) as string, 1),

          "&:hover": {
            backgroundColor: alpha(handleGetColorPlans(title) as string, 0.9),
          },
        }}
        title={
          userPerfil?.checkout.id_stripe_customer
            ? "Gerenciar plano"
            : "Contratar plano"
        }
        onClick={onClick}
      />

      {personalizedPlan}

      <Box>
        {Children.toArray(
          details.map((item) => {
            return <CompDescriptionPlansDetails data={item} />;
          })
        )}
      </Box>
    </Box>
  );
};
