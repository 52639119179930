import { useState, MouseEvent } from "react";
import MailIcon from "@mui/icons-material/Mail";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import { IconButton, Tooltip, Menu, Box, Badge } from "@mui/material";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { ComponentPrivate } from "src/Pages/components/ComponentPrivate/ComponentPrivate";
import { LinkCore } from "src/Pages/components/LinkCore/LinkCore";
import { UrlConfig } from "src/shared/Utils/paths";

export const MenuNovidade = () => {
  const { userPerfil } = useAuthContext();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const isNotViewProducer = userPerfil?.type_user === "PRODUCER";

  const handleCloseUserMenu = () => setAnchorElUser(null);

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  return (
    <>
      <Tooltip title="Ver novidades">
        <IconButton onClick={handleOpenUserMenu}>
          <Badge badgeContent={1} color="primary">
            <MailIcon color="action" />
          </Badge>
        </IconButton>
      </Tooltip>

      <Menu
        keepMounted
        PaperProps={{
          style: { width: "300px", padding: "8px" },
        }}
        anchorEl={anchorElUser}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(anchorElUser)}
        sx={{ mt: "48px" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleCloseUserMenu}
      >
        <Box
          sx={{
            "& > a > li": {
              border: `1px solid #919191`,
              borderRadius: "12px",
              transition: "background-color 0.3s",
              color: "#3b3b3b",
            },
          }}
        >
          <ComponentPrivate isView={!isNotViewProducer}>
            <LinkCore to={UrlConfig.lancamentos.contratos.url}>
              <Box
                sx={{
                  border: "1px solid gray",
                  padding: "8px",
                  borderRadius: "8px",
                  "& > *": { fontSize: "14px" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "12px",
                  }}
                >
                  <Box sx={{ fontWeight: "500" }}>NOVA TELA</Box>
                  <NewReleasesIcon />
                </Box>

                <Box>
                  {`Na tela de contratos, você pode clicar em "ações" e visualizar
                em formato PDF.`}
                </Box>
              </Box>
            </LinkCore>
          </ComponentPrivate>
        </Box>
      </Menu>
    </>
  );
};
