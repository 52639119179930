import { useContextComissoes } from "src/Contexts/comissoes/ComissoesContext";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useResetInputs } from "src/Contexts/hooks/useResetInputs";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

export const UseFeaturesFormAdministradora = () => {
  const { setAttTable, attTable, idTable, id, setLoadingGravar } =
    useConfigPageContext();

  const { valuesInputsAdministradora } = useContextComissoes();
  const { handleGetAlert } = useLayoutMainContext();
  const { resetInputs } = useResetInputs();

  const handleCreate = async () => {
    if (!valuesInputsAdministradora.name?.trim())
      return handleGetAlert({ message: `Digite um nome de administradora` });

    setLoadingGravar(true);

    return api
      .post("/comissoes/administradoras/create", valuesInputsAdministradora)
      .then((res) => {
        handleGetAlert({ message: res.data.message });
        setAttTable(!attTable);
        resetInputs();
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoadingGravar(false));
  };

  const handleEdit = () => {
    if (!valuesInputsAdministradora.name?.trim())
      return handleGetAlert({ message: `Digite um nome de administradora` });

    setLoadingGravar(true);

    return api
      .put(`/comissoes/administradoras/${id}`, valuesInputsAdministradora)
      .then((res) => {
        setAttTable(!attTable);
        handleGetAlert({ message: res.data.message });
        resetInputs();
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoadingGravar(false));
  };

  const handleSubmit = () => {
    if (idTable) {
      handleEdit();
    } else {
      handleCreate();
    }
  };

  return { handleSubmit };
};
