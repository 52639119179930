import { alpha, useTheme } from "@mui/material";
import {
  ResponsiveContainer,
  CartesianGrid,
  LineChart,
  Tooltip,
  XAxis,
  Line,
  YAxis,
} from "recharts";
import { useContextDashboardBrokerContext } from "src/Contexts/Dashboard/ContextDashboardBroker";
import { CardCore } from "src/Pages/components/CardCore/CardCore";
import { CardBodyCore } from "src/Pages/components/CardCore/Components/CardBodyCore";

import { FormInstallmentsReceived } from "./Form";

export const InstallmentsReceived = () => {
  const { dataInstallmentsReceived } = useContextDashboardBrokerContext();

  const theme = useTheme();
  const colorStroke = "#b8b8b8";

  return (
    <CardCore sxStyle={{ margin: "1rem 0", marginBottom: "24px" }}>
      <CardBodyCore
        actions={[<FormInstallmentsReceived key={1} />]}
        title={`Parcelas confirmadas. Total: ${
          dataInstallmentsReceived.meta.total || 0
        }`}
      >
        <ResponsiveContainer height={350} width="100%">
          <LineChart
            data={dataInstallmentsReceived.data}
            margin={{ top: 0, right: 0, left: -10, bottom: 0 }}
          >
            <XAxis dataKey="label" fontSize={14} stroke={colorStroke} />
            <YAxis fontSize={14} stroke={colorStroke} tickLine={false} />

            <CartesianGrid
              horizontal
              stroke={alpha(colorStroke as string, 0.7)}
              vertical={false}
            />

            <Tooltip labelStyle={{ color: "black" }} />

            <Line
              dot
              dataKey="value"
              fill="url(#colorPv)"
              name="Parcelas confirmadas"
              stroke="#4a90e2"
              strokeWidth={3}
              type="bump"
            />
          </LineChart>
        </ResponsiveContainer>
      </CardBodyCore>
    </CardCore>
  );
};
