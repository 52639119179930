import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";
import { useStylesRichText } from "./RichTextStyled";

interface IRichText {
  value?: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}

export const RichText = ({ setValue, value }: IRichText) => {
  const classes = useStylesRichText();

  return (
    <ReactQuill
      className={classes.toolbar}
      theme="snow"
      value={value}
      onChange={setValue}
    />
  );
};
