import { useEffect, useState, SetStateAction, Dispatch } from "react";
import { ModalCore } from "src/Pages/components";

import { ModalCommissionProgress } from "../../../../../components/CommissionGradeProgress/ModalCommissionProgress";

import { UseGetComissaoProdutor } from "./Hooks/UseGetComissaoProdutor";

interface IModalViewRecibos {
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;

  setId: Dispatch<SetStateAction<string>>;
  id: string;
}

interface IValueComissaoModal {
  date_vigencia: string;
  name: string;
  operadora_name: string;
  operadora_code: string;

  modalidade_name: string;
  modalidade_code: string;

  administradora_name: string | null;
  administradora_code: string | null;
  parcelas: {
    parc: number;
    value: number;
  }[];
}

export const ModalViewComissãoCorretora = ({
  id,
  open,
  setId,
  setOpen,
}: IModalViewRecibos) => {
  const { loading, handleGet } = UseGetComissaoProdutor();

  const [dataCommission, setDataCommission] = useState<IValueComissaoModal>();

  useEffect(() => {
    if (id) handleGet({ id, setDataCommission });
  }, [id]);

  useEffect(() => {
    if (!open) setId("");
  }, [open]);

  return (
    <ModalCore
      loading={loading}
      open={open}
      setOpen={setOpen}
      subTitle="Esta é a grade que o escritório paga"
      sxStyle={{ width: "600px" }}
      title="Comissões dos produtores"
    >
      {dataCommission && (
        <ModalCommissionProgress dataCommission={dataCommission} />
      )}
    </ModalCore>
  );
};
