import { useContextComissoes } from "src/Contexts/comissoes/ComissoesContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

export const UseGetByIdAdministradoras = () => {
  const { setValuesInputsAdministradora } = useContextComissoes();
  const { handleGetAlert } = useLayoutMainContext();

  const handleGetById = async (id: string) => {
    await api
      .get(`/comissoes/administradoras/${id}`)
      .then((response) =>
        setValuesInputsAdministradora({
          name: response.data.name,
          is_active: response.data.is_active,
        })
      )
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      );
  };

  return { handleGetById };
};
