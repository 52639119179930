import { createRef, ChangeEvent, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { ICreateBorderoParcela } from "src/Contexts/Lancamentos/Contrato/ContratosTypes";
import { AppTextField } from "src/Pages/components/AppFormComponents/AppTextField";
import { FormatMoney } from "src/shared/Utils/FormatMoney";

export const FormReceberParcela = () => {
  const { valuesInputsBorderoParcela, setValuesInputsBorderoParcela } =
    useContextContrato();

  const refOrigem = createRef<HTMLInputElement>();
  const handleFocusedID = () => refOrigem.current?.focus();

  useEffect(() => {
    handleFocusedID();
  }, []);

  const handleChange =
    (prop: keyof ICreateBorderoParcela, option?: "money") =>
    (event: ChangeEvent<HTMLInputElement>) => {
      if (option === "money") {
        setValuesInputsBorderoParcela({
          ...valuesInputsBorderoParcela,
          [prop]: FormatMoney(event.target.value),
        });
      } else {
        setValuesInputsBorderoParcela({
          ...valuesInputsBorderoParcela,
          [prop]: event.target.value,
        });
      }
    };

  return (
    <Box sx={{ margin: 0, padding: 0 }}>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <AppTextField
            fullWidth
            inputRef={refOrigem}
            label="Data recebimento *"
            sxStyle={{ opacity: "", pointerEvents: "auto", margin: 0 }}
            type="date"
            value={valuesInputsBorderoParcela?.data_recebimento}
            variant="outlined"
            onChange={handleChange("data_recebimento")}
          />
        </Grid>
        <Grid item xs={4.7}>
          <AppTextField
            fullWidth
            label="Valor mensalidade *"
            sxStyle={{ opacity: "", pointerEvents: "auto", margin: 0 }}
            value={valuesInputsBorderoParcela?.valor_recebido}
            variant="outlined"
            onChange={handleChange("valor_recebido", "money")}
          />
        </Grid>
        <Grid item xs={2.3}>
          <AppTextField
            fullWidth
            label="Vidas *"
            type="number"
            sxStyle={{ opacity: "", pointerEvents: "auto", margin: 0 }}
            value={valuesInputsBorderoParcela.contratos_vidas}
            variant="outlined"
            onChange={handleChange("contratos_vidas")}
          />
        </Grid>
        {/* <Grid item xs={5}>
          <AppTextField
            label="Data previsão"
            type="date"
            variant="outlined"
            value={valuesInputsBorderoParcela.data_previsao}
            onChange={handleChange("data_previsao")}
            fullWidth
            sxStyle={{ opacity: "", pointerEvents: "auto", margin: 0 }}
          />
        </Grid> */}
        <Grid item xs={12}>
          <AppTextField
            fullWidth
            label="Observação"
            sxStyle={{ opacity: "", pointerEvents: "auto", margin: 0 }}
            value={valuesInputsBorderoParcela.observacao}
            variant="outlined"
            onChange={handleChange("observacao")}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
