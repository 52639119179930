import { UseAutoCompletedAll } from "src/Contexts/Utils/UseAutoCompletedAll";
import { AutoCompleteGeneric } from "src/Pages/components/InputAutoCompleted/AutoCompleteGeneric";
import { IPropsAutoCompleted } from "src/Pages/components/InputAutoCompleted/types";

export const AutoCompleteModalidade = (props: IPropsAutoCompleted) => {
  const { label, width, tamanho, minWidth, maxWidth, fullWidth, disabled } =
    props;

  const { valueAutoCompModalidade, setValueAutoCompModalidade } =
    UseAutoCompletedAll();

  return (
    <AutoCompleteGeneric
      disabled={disabled}
      fullWidth={fullWidth}
      label={label}
      maxWidth={maxWidth}
      minWidth={minWidth}
      setValueAutoCompleted={setValueAutoCompModalidade}
      tamanho={tamanho}
      url="/comissoes/modalidadeList?is_active=true"
      valueAutoCompleted={valueAutoCompModalidade}
      width={width}
    />
  );
};
