import { useState } from "react";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";
import { FormatMoney } from "src/shared/Utils/FormatMoney";
import { handleFormatDateIfIsNull } from "src/shared/Utils/handleFormatDateIfIsNull";

export const UseGetParcelasByIdContratosToEditar = () => {
  const { handleGetAlert } = useLayoutMainContext();
  const { setValuesInputEditarParcelaBordero } = useContextContrato();
  const { id } = useConfigPageContext();

  const [loading, setLoading] = useState(false);

  const handleGetNovaParcelaById = async () => {
    setValuesInputEditarParcelaBordero(undefined);

    if (!id.trim()) {
      return handleGetAlert({ message: "Digite uma proposta para pesquisar" });
    }
    setLoading(true);

    return api
      .get(`/bordero/${id}`)
      .then(({ data }) => {
        setValuesInputEditarParcelaBordero({
          contratos_vidas: data.contratos_vidas,
          numero_parcela: data.numero_parcela,

          data_vencimento_parcela: handleFormatDateIfIsNull(
            data.data_vencimento_parcela
          ),
          nome_produtor: data.nome_produtor,
          id_corretor: data.id_corretor,
          data_previsao: data.data_previsao,
          valor_contrato: data.valor_contrato,

          nome_tipo_contrato: data.nome_tipo_contrato,
          contratos_mensalidade: FormatMoney(data.valor_contrato),
          cod_proposta: data.cod_proposta,

          porcentagem_comissao_corretora: data.porcentagem_comissao_corretora,
          porcentagem_comissao_corretor: data.porcentagem_comissao_corretor,
          porcentagem_comissao_gerente: data.porcentagem_comissao_gerente,
          porcentagem_comissao_supervisor: data.porcentagem_comissao_supervisor,
          codigo_tipo_contrato: data.codigo_tipo_contrato,
        });
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  return {
    handleGetNovaParcelaById,
    loading,
  };
};
