import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { useContextUsuarios } from "src/Contexts/Usuarios/UsuariosContext";
import { api } from "src/shared/setup/API/api";

interface IHandleGet {
  itensPerPage: number;
  currentPage: number;
  nameSearch: string;
}

export const useHttpTableFiliais = () => {
  const { setQtd_registros, setLoadingTable } = useConfigPageContext();
  const { handleGetAlert } = useLayoutMainContext();
  const { setListFiliais } = useContextUsuarios();

  const handleGet = async (body: IHandleGet) => {
    setLoadingTable(true);

    api
      .post("/paginationFilial", {
        ...body,
        page: body.currentPage,
        limit: body.itensPerPage,
        filter: body.nameSearch,
      })
      .then((res) => {
        setListFiliais(res.data.data);
        setQtd_registros(res.data.count);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoadingTable(false));
  };

  return { handleGet };
};
