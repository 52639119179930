import { formatRemove } from "@format-string/core";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useResetInputs } from "src/Contexts/hooks/useResetInputs";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { useContextProdutor } from "src/Contexts/produtores/ProdutoresContext";
import { UseAutoCompletedAll } from "src/Contexts/Utils/UseAutoCompletedAll";
import { api } from "src/shared/setup/API/api";
import { apenasNumeros } from "src/shared/Utils/FormatMoney";

export const UseFeaturesFormProducer = () => {
  const { valuesInputsProdutor } = useContextProdutor();
  const { handleGetAlert } = useLayoutMainContext();
  const { resetInputs } = useResetInputs();

  const {
    valueAutoCompletedFiliais,
    valueAutoCompGrupoImposto,
    valueAutoCompSupervisor,
    valueAutoCompGerente,
  } = UseAutoCompletedAll();

  const { setAttTable, attTable, idTable, id, setLoadingGravar } =
    useConfigPageContext();

  const AllDatas = {
    ...valuesInputsProdutor,
    gerente: valueAutoCompGerente.id,
    supervisor: valueAutoCompSupervisor.id,
    id_filial: valueAutoCompletedFiliais.id,
    id_grupo_imposto: valueAutoCompGrupoImposto.id,
    cpf_cnpj: formatRemove(valuesInputsProdutor.cpf_cnpj),
    telefone1: formatRemove(valuesInputsProdutor.telefone1),
    telefone2: formatRemove(valuesInputsProdutor.telefone2),
    rg: formatRemove(valuesInputsProdutor.rg),

    cep: apenasNumeros(valuesInputsProdutor.cep),
  };

  const handleCreateProdutores = async () => {
    if (!valuesInputsProdutor.nome)
      return handleGetAlert({ message: `Digite um nome` });

    setLoadingGravar(true);

    return api
      .post("/produtores/create", AllDatas)
      .then((res) => {
        setAttTable(!attTable);
        handleGetAlert({ message: res.data.message });
        resetInputs();
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoadingGravar(false));
  };

  const handleEditProdutores = () => {
    if (!valuesInputsProdutor.nome)
      return handleGetAlert({ message: `Digite um nome` });

    setLoadingGravar(true);

    return api
      .put(`/produtor/${id}`, AllDatas)
      .then((res) => {
        handleGetAlert({ message: res.data.message });
        setAttTable(!attTable);
        resetInputs();
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoadingGravar(false));
  };

  const handleSubmit = () => {
    if (idTable) {
      handleEditProdutores();
    } else {
      handleCreateProdutores();
    }
  };

  return { handleSubmit };
};
