import { Box, Grid } from "@mui/material";
import { PublicRoutes } from "src/Routes/PublicRoutes";

import backgroundImgDesktop from "./components/media/Background5.svg";

export const BasePagePublic = () => {
  return (
    <Grid container>
      <Grid item md={6}>
        <Box
          sx={{
            display: "flex",
            height: "100vh",
            background: "#b6b6b6",
            backgroundImage: {
              md: `url(${backgroundImgDesktop})`,
            },
            backgroundSize: "cover",
            position: "relative",
            boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.363)",
            borderRadius: "0 24px 24px 0",
          }}
        />
      </Grid>

      <Grid
        item
        md={6}
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
        }}
        xs={12}
      >
        <PublicRoutes />
      </Grid>
    </Grid>
  );
};
