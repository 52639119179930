import { IPDFTableHeader } from "src/Pages/components/PDF/TableHeader";
import { PDFTableHeader } from "src/Pages/components/PDF/TableHeader/PDFTableHeader";

export const HeadReportProductionContract = () => {
  const dataHeaderPDF: IPDFTableHeader = [
    {
      style: {
        width: "22%",
        textAlign: "left",
        paddingLeft: "8px",
      },
      title: "Operadora",
    },
    {
      style: {
        width: "22%",
        textAlign: "left",
        paddingLeft: "8px",
      },
      title: "Contrato",
    },
    {
      style: {
        width: "30%",
        textAlign: "left",
      },
      title: "Titular/Razão",
    },
    {
      style: {
        width: "10%",
        textAlign: "left",
      },
      title: "CPF/CNPJ",
    },
    {
      style: {
        width: "10%",
        textAlign: "right",
      },
      title: "Data assinatura",
    },
    {
      style: {
        width: "10%",
        textAlign: "right",
      },
      title: "Mensalidade",
    },
    {
      style: {
        width: "8%",
        textAlign: "center",
      },
      title: "Vidas",
    },
  ];

  return <PDFTableHeader data={dataHeaderPDF} />;
};
