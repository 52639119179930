import {
  createContext,
  useContext,
  useState,
  FC,
  useEffect,
  ReactNode,
} from "react";
import { GetDateUSSemValue } from "src/shared/Utils/FormatDateBR";

import {
  IListProdutor,
  ICreateProdutor,
  IContextProdutor,
  IListContaCorrente,
  IListGrupoDeImposto,
  ICreateContaCorrente,
  ICreateImpostoGlobal,
  IListRankingCorretor,
  ICreateGrupoDeImposto,
  IValuesFilterProducer,
  IValuesFilterContaCorrente,
} from "./ProdutoresTypes";
import {
  valueDefaultInputProdutores,
  valuesDefaultFilterProducer,
  valuesDefaultFilterContaCorrente,
} from "./valuesDefaultInputsProdutor/valuesDefaultInputsProdutor";

const ProdutorContext = createContext({} as IContextProdutor);

export const ProdutorContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [listProdutor, setListProdutor] = useState([] as IListProdutor[]);

  const [listRankingCorretor, setListRankingCorretor] =
    useState<IListRankingCorretor>({
      data: [],
      resume: {
        endDate: "",
        somas: { totalContratos: 0, totalMensalidades: 0, totalVidas: 0 },
        startDate: "",
      },
    });

  const [listContaCorrente, setListContaCorrente] = useState(
    [] as IListContaCorrente[]
  );
  const [listGrupoImposto, setListGrupoImposto] = useState(
    [] as IListGrupoDeImposto[]
  );

  const [changeFunctionProducer, setChangeFunctionProducer] = useState<
    "CORRETOR" | "SUPERVISOR" | "GERENTE"
  >("CORRETOR");

  const [valuesInputsProdutor, setValuesInputsProdutor] =
    useState<ICreateProdutor>({ ...valueDefaultInputProdutores });

  const [valuesFilterContaCorrente, setValuesFilterContaCorrente] =
    useState<IValuesFilterContaCorrente>(valuesDefaultFilterContaCorrente);

  const [valuesFilterProducer, setValuesFilterProducer] =
    useState<IValuesFilterProducer>(valuesDefaultFilterProducer);

  const [valuesInputsImpostoGlobal, setValuesInputsImpostoGlobal] =
    useState<ICreateImpostoGlobal>({
      descricao: "",
      valor: "0.00",
    });

  const [valuesInputsGrupoDeImposto, setValuesInputsGrupoDeImposto] =
    useState<ICreateGrupoDeImposto>({
      descricao: "",
      valor: "0.00",
    });

  const [valuesInputsContaCorrente, setValuesInputsContaCorrente] =
    useState<ICreateContaCorrente>({
      data_lancamento: `${GetDateUSSemValue()}`,
      is_recibo: false,
      descricao: "",
      tipo_transicao: "debito",
      valor: "0.00",
    });

  useEffect(() => {
    setValuesFilterContaCorrente((prevValue) => ({
      ...prevValue,
      endDate: valuesFilterContaCorrente.startData,
    }));
  }, [valuesFilterContaCorrente.startData]);

  return (
    <ProdutorContext.Provider
      value={{
        setValuesInputsGrupoDeImposto,
        setValuesInputsContaCorrente,
        setValuesInputsImpostoGlobal,
        setValuesFilterContaCorrente,
        valuesInputsGrupoDeImposto,
        valuesInputsImpostoGlobal,
        setChangeFunctionProducer,
        valuesFilterContaCorrente,
        valuesInputsContaCorrente,
        setValuesInputsProdutor,
        setValuesFilterProducer,
        changeFunctionProducer,
        setListRankingCorretor,
        valuesInputsProdutor,
        valuesFilterProducer,
        setListContaCorrente,
        listRankingCorretor,
        setListGrupoImposto,
        listGrupoImposto,
        listContaCorrente,
        setListProdutor,
        listProdutor,
      }}
    >
      {children}
    </ProdutorContext.Provider>
  );
};

export const useContextProdutor = (): IContextProdutor =>
  useContext(ProdutorContext);
