import { Box } from "@mui/material";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";

import { ButtonCore } from "../../ButtonCore/ButtonCore";
import { FilterAltOffIcon, SendIcon } from "../../Icons/Icons";

type IButtonsFormFilter = {
  FilterClear: () => Promise<void>;
  FilterSearch: () => Promise<void>;
};

export const ButtonSubmitFormFilter = ({
  FilterClear,
  FilterSearch,
}: IButtonsFormFilter) => {
  const { loadingTable } = useConfigPageContext();

  return (
    <Box sx={{ display: "flex" }}>
      <ButtonCore
        endIcon={<FilterAltOffIcon />}
        sx={{ width: "25%" }}
        title="Limpar"
        onClick={FilterClear}
      />

      <ButtonCore
        disabled={loadingTable}
        endIcon={<SendIcon />}
        sx={{ marginLeft: "8px", width: "75%" }}
        title="Filtrar"
        type="submit"
        onClick={FilterSearch}
      />
    </Box>
  );
};
