import { Children } from "react";
import { Grid } from "@mui/material";

import { CardsStatics } from "../../../../components/CardsStatics";

import { UseGetCountStatics } from "./Hooks/UseGetInfoHomePage";

export const CardsCount = () => {
  const { dataCountStatics, loading } = UseGetCountStatics();

  return (
    <Grid container mb="24px" spacing={2}>
      {Children.toArray(
        dataCountStatics.map((data) => (
          <Grid item md={4} sm={6} xs={12}>
            <CardsStatics data={data} loading={loading} />
          </Grid>
        ))
      )}
    </Grid>
  );
};
