import {
  createContext,
  useContext,
  useState,
  FC,
  useEffect,
  ReactNode,
} from "react";

import { UseAutoCompletedAll } from "../Utils/UseAutoCompletedAll";

import { IContextRecibos, IGetRecibos, IRecibos } from "./RecibosTypes";
import { valuesDefaultInputsRecibos } from "./ValuesDefaultInputsRecibos/ValuesDefaultInputsRecibos";

const RecibosContext = createContext({} as IContextRecibos);

export const RecibosContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { setValueAutoCompProdutorActive } = UseAutoCompletedAll();

  const [listRecibos, setListRecibos] = useState<IRecibos>();

  const [valuesInputsRecibos, setValuesInputsRecibos] = useState<IGetRecibos>(
    valuesDefaultInputsRecibos
  );

  useEffect(() => {
    setValueAutoCompProdutorActive({ id: "", label: "" });
  }, [valuesInputsRecibos.type_payments, valuesInputsRecibos.funcao_produtor]);

  return (
    <RecibosContext.Provider
      value={{
        setValuesInputsRecibos,
        valuesInputsRecibos,
        setListRecibos,
        listRecibos,
      }}
    >
      {children}
    </RecibosContext.Provider>
  );
};

export const useContextRecibos = (): IContextRecibos =>
  useContext(RecibosContext);
