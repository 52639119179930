import { CircularProgress } from "@mui/material";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";

import { HeaderRecoverPassword } from "../Header/Header";

import { FormRecoverPassword } from "./FormRecoverPassword";
import { UseFormRecoverPassword } from "./Hooks/UseFormRecoverPassword";

interface IPageFormRecoverPassword {
  id: string;
}
export const PageFormRecoverPassword = ({ id }: IPageFormRecoverPassword) => {
  const {
    handleSubmit: handleSubmitRecoverPassword,
    loading: loadingSubmitRecoverPassword,
  } = UseFormRecoverPassword();

  return (
    <>
      <HeaderRecoverPassword />

      <FormRecoverPassword />

      <ButtonCore
        fullWidth
        disabled={loadingSubmitRecoverPassword}
        endIcon={
          loadingSubmitRecoverPassword && (
            <CircularProgress size={20} sx={{ color: "black", ml: 4 }} />
          )
        }
        title="Definir nova senha"
        onClick={() => {
          handleSubmitRecoverPassword({ id });
        }}
      />
    </>
  );
};
