import { Children, useEffect, useState } from "react";
import { useContextComissoes } from "src/Contexts/comissoes/ComissoesContext";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useResetInputs } from "src/Contexts/hooks/useResetInputs";
import { UseAutoCompletedAll } from "src/Contexts/Utils/UseAutoCompletedAll";
import { ModalDeleteCore, TableCore, TdCore } from "src/Pages/components";
import { handleChipCoreCategories } from "src/Pages/components/CategoryComponentMap";
import { UseVerifyIsPermissionsUser } from "src/Pages/components/hooks/UseVerifyIsPermissionsUser";
import {
  DeleteForeverIcon,
  InfoOutlinedIcon,
  FolderCopyIcon,
  FolderOffIcon,
  ModeEditIcon,
  Visibility,
  CheckIcon,
  CloseIcon,
} from "src/Pages/components/Icons/Icons";
import { UseDelete } from "src/Pages/components/ModalDeleteCore/Hooks/UseDelete";
import { TableRowCore } from "src/Pages/components/table/@Core/TableRowCore/TableRowCore";
import { ActionPopoverTable } from "src/Pages/components/table/ActionPopover/ActionPopover";
import { FormatDateBR } from "src/shared/Utils/FormatDateBR";
import { handleFormatDateIfIsNull } from "src/shared/Utils/handleFormatDateIfIsNull";
import { handleIfLimitCharacter } from "src/shared/Utils/LimitText";
import { valuesPermissions } from "src/shared/Utils/ValuesPermissions";

import { FilterSidebarComissaoProdutor } from "../FilterSidebar/FilterSidebar";
import { ModalChangeIsActive } from "../ModalChangeIsActive/ModalChangeIsActive";
import { ModalViewComissãoCorretora } from "../ModalComissaoProdutor/Index";

import { UseGetByIdComissaoProdutor } from "./Hooks/UseGetByIdComissaoProdutor";
import { useHttpTableComissaoProdutor } from "./Hooks/useHttpTableComissaoProdutor";
import { THeadComissaoProdutor } from "./THead";

export const TableComissaoProdutor = () => {
  const { handleVerifyPermissionItem } = UseVerifyIsPermissionsUser();
  const { valueAutoCompOperadoraFilter } = UseAutoCompletedAll();
  const { handleGet } = useHttpTableComissaoProdutor();
  const { handleGetById } = UseGetByIdComissaoProdutor();
  const { resetInputs } = useResetInputs();

  const { listComissaoProdutor, valuesFilterComissaoProdutor } =
    useContextComissoes();

  const {
    setActive_incluir,
    setNameSearch,
    itensPerPage,
    currentPage,
    setIdTable,
    nameSearch,
    attTable,
    setId,
  } = useConfigPageContext();

  const [openModalComissao, setOpenModalComissao] = useState(false);
  const [openModalChangeIsActive, setOpenModalChangeIsActive] = useState(false);
  const [stateIsActiveComissao, setIsActiveComissao] = useState<
    boolean | undefined
  >();

  const [idModalCommission, setIdModalCommission] = useState<string>("");

  const handleGetIdToEdit = (id: string) => {
    resetInputs();
    setId(id);
    setIdTable(id);
    handleGetById(id);
  };

  useEffect(() => {
    handleGet({
      itensPerPage,
      currentPage,
      nameSearch,
      codeOperadora: valueAutoCompOperadoraFilter.id,
      isActive: valuesFilterComissaoProdutor.isActive,
      name: valuesFilterComissaoProdutor.name,
    });
  }, [attTable, itensPerPage, currentPage, nameSearch]);

  const {
    handleDelete,
    open: openModalDelete,
    loading: loadingDelete,
    setId: setIdModalDelete,
    setOpen: setOpenModalDelete,
  } = UseDelete({ url: "/comissoes/produtor" });

  return (
    <TableCore
      Modals={
        <>
          <ModalViewComissãoCorretora
            id={idModalCommission}
            open={openModalComissao}
            setId={setIdModalCommission}
            setOpen={setOpenModalComissao}
          />
          <ModalChangeIsActive
            isActive={stateIsActiveComissao}
            modalDelete={openModalChangeIsActive}
            setModalDelete={setOpenModalChangeIsActive}
          />
          <ModalDeleteCore
            loading={loadingDelete}
            modalOpen={openModalDelete}
            no="Não, Cancelar"
            titlePrimary="Você deseja excluir da lista ?"
            yes="Sim, Deletar !"
            onClickFalse={() => setOpenModalDelete(false)}
            onClickTrue={() => handleDelete()}
          />
        </>
      }
      THead={<THeadComissaoProdutor />}
      filter={<FilterSidebarComissaoProdutor />}
      qtdList={listComissaoProdutor.length}
      setNameSearch={setNameSearch}
    >
      {Children.toArray(
        listComissaoProdutor.map((item) => {
          return (
            <TableRowCore id={item.code}>
              <TdCore
                values={
                  <ActionPopoverTable
                    optionsList={[
                      {
                        icon: <Visibility fontSize="small" />,
                        title: "Ver comissão",
                        background: "info",
                        onClick: () => {
                          setIdModalCommission(item.code);
                          setOpenModalComissao(true);
                        },
                      },
                      {
                        title: item.is_active
                          ? "Desativar grade"
                          : "Ativar grade",
                        background: "warning",
                        isDisabled: handleVerifyPermissionItem(
                          valuesPermissions.COMMISSIONS_PRODUCER.EDITAR.id
                        ),

                        onClick: () => {
                          setId(item.code);
                          setOpenModalChangeIsActive(true);
                          setIsActiveComissao(!item.is_active);
                        },
                        icon: item.is_active ? (
                          <FolderOffIcon fontSize="small" />
                        ) : (
                          <FolderCopyIcon fontSize="small" />
                        ),
                      },
                      {
                        title: "Editar",
                        background: "warning",
                        isDisabled: handleVerifyPermissionItem(
                          valuesPermissions.COMMISSIONS_PRODUCER.EDITAR.id
                        ),
                        onClick: () => {
                          setId(item.code);
                          handleGetIdToEdit(item.code);
                          setActive_incluir(true);
                        },
                        icon: <ModeEditIcon fontSize="small" />,
                      },
                      {
                        title: "Excluir",
                        background: "error",
                        isDisabled: handleVerifyPermissionItem(
                          valuesPermissions.COMMISSIONS_PRODUCER.DELETAR.id
                        ),
                        onClick: () => {
                          setIdModalDelete(item.code);
                          setOpenModalDelete(true);
                        },
                        icon: <DeleteForeverIcon fontSize="small" />,
                      },
                    ]}
                  />
                }
              />
              <TdCore
                values={`${item.code} - ${item.name}`}
                onClick={() => handleGetIdToEdit(item.code)}
              />
              <TdCore
                values={`${item.operadora_code} - ${item.operadora_name}`}
                onClick={() => handleGetIdToEdit(item.code)}
              />

              <TdCore
                values={
                  item.administradora_code
                    ? `${item.administradora_code} - ${item.administradora_name}`
                    : ""
                }
                onClick={() => handleGetIdToEdit(item.code)}
              />

              <TdCore
                values={`${item.modalidade_code} - ${item.modalidade_name}`}
                onClick={() => handleGetIdToEdit(item.code)}
              />
              <TdCore
                tooltip={
                  item?.produtor_code
                    ? `${item.produtor_code} - ${item.produtor_nome}`
                    : ""
                }
                values={
                  item?.produtor_code
                    ? handleIfLimitCharacter({
                        LIMIT: 25,
                        value: `${item.produtor_code} - ${item.produtor_nome}`,
                      })
                    : ""
                }
                onClick={() => handleGetIdToEdit(item.code)}
              />

              <TdCore
                textAlign="left"
                values={
                  item.is_special
                    ? handleChipCoreCategories("COMISSAO_ESPECIAL")
                    : handleChipCoreCategories(item.funcao_produtor)
                }
                onClick={() => handleGetIdToEdit(item.code)}
              />

              <TdCore
                textAlign="left"
                values={
                  item.is_special
                    ? handleChipCoreCategories("COMISSAO_ESPECIAL")
                    : handleChipCoreCategories(item.tipo_produtor)
                }
                onClick={() => handleGetIdToEdit(item.code)}
              />

              <TdCore
                borderBottom={`1px solid #0e6aff`}
                color="#0e6aff"
                textAlign="center"
                values={FormatDateBR(
                  handleFormatDateIfIsNull(item.date_vigencia)
                )}
                onClick={() => handleGetIdToEdit(item.code)}
              />

              <TdCore
                textAlign="center"
                values={
                  item.is_active ? (
                    <CheckIcon color="success" />
                  ) : (
                    <CloseIcon color="error" />
                  )
                }
                onClick={() => handleGetIdToEdit(String(item.code))}
              />

              <TdCore
                textAlign="center"
                tooltip={`Usuário com id: '${
                  item.id_user_create
                }' criou na data: ${FormatDateBR(item.created_at)}
                / Usuário com id '${
                  item.id_user_update
                }' Editou na data: ${FormatDateBR(item.update_at)}`}
                values={<InfoOutlinedIcon />}
              />
            </TableRowCore>
          );
        })
      )}
    </TableCore>
  );
};
