import { useState, useEffect } from "react";

import { Box } from "@mui/material";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

import { CardInfoServidor } from "./CardInfoServidor";

interface IInfosServer {
  cpusList: string;
  plataforma: string;
  freeMemory: string;
  arquitetura: string;
  total_memoria: string;
  tempo_online_pc: string;
  memoria_livre_MB: string;
  memoria_livre_GB: string;
}
export const IndexCardServidor = () => {
  const { handleGetAlert } = useLayoutMainContext();

  const [valueInfos, setValuesInfos] = useState<IInfosServer>();
  const [attInfoServidor, setAttInfoServidor] = useState(false);
  const [loading, setLoading] = useState(false);

  const infoWidgets = [
    {
      id: 1,
      icon: "/assets/images/dashboard/icon_desktop.svg",
      count: `${valueInfos?.total_memoria} - GB`,
      details: "Total memória - RAM",
    },
    {
      id: 2,
      icon: "/assets/images/dashboard/icon_desktop.svg",
      count: `${valueInfos?.memoria_livre_GB} - GB`,
      details: "Memoria livre - RAM",
    },
    {
      id: 3,
      icon: "/assets/images/dashboard/icon_desktop.svg",
      count: `${valueInfos?.memoria_livre_MB} - MB`,
      details: "Memoria livre - RAM",
    },
  ];

  useEffect(() => {
    setLoading(true);

    api
      .get("/infoMaquinas")
      .then((res) => setValuesInfos(res.data))
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  }, [attInfoServidor]);

  return (
    <Box sx={{ display: { xs: "block", sm: "flex" } }}>
      {infoWidgets.map((data) => (
        <CardInfoServidor
          key={data.id}
          attInfo={attInfoServidor}
          data={data}
          loading={loading}
          setAttInfo={setAttInfoServidor}
        />
      ))}
    </Box>
  );
};
