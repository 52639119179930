import { useState } from "react";
import { IGetByIdResultParcelasContratos } from "src/Contexts/Lancamentos/Contrato/ContratosTypes";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

interface IUseFormGetParcelasByIdContratos {
  id: string;
}

export const UseGetParcelasByIdContratos = ({
  id,
}: IUseFormGetParcelasByIdContratos) => {
  const { handleGetAlert } = useLayoutMainContext();

  const [dataGetByIdResultsContrato, setDataGetByIdResultsContrato] =
    useState<IGetByIdResultParcelasContratos>();

  const [loading, setLoading] = useState(false);

  const handleGetParcelasByIdContratos = () => {
    setLoading(true);
    api
      .get(`/contratosByIdVerifyIsPaid/${id}`)
      .then((res) => setDataGetByIdResultsContrato(res.data))
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  return {
    loading,
    dataGetByIdResultsContrato,
    setDataGetByIdResultsContrato,
    handleGetParcelasByIdContratos,
  };
};
