import { SetStateAction, Dispatch } from "react";
import InputBase from "@mui/material/InputBase";
import { SearchIcon } from "src/Pages/components/Icons/Icons";
import { styled } from "@mui/material/styles";
import { alpha } from "@mui/material";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: 30,
  border: `0.8px solid ${theme.palette.grey[600]}`,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "97%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "280px",
  },
  [theme.breakpoints.up("md")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
  [theme.breakpoints.up("lg")]: {
    marginLeft: theme.spacing(1),
    width: "340px",
  },
  [theme.breakpoints.down("md")]: { marginBottom: "1rem" },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(0.7, 0.5, 0.5, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "28ch",
      "&:focus": { width: "30ch" },
    },
  },
}));

type IInputSearch = {
  setNameSearch: Dispatch<SetStateAction<string>>;
};

export const InpuSearchPersonalized = ({ setNameSearch }: IInputSearch) => {
  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="Procurar"
        inputProps={{ "aria-label": "search" }}
        onChange={(e) => setNameSearch(e.target.value)}
        type="search"
      />
    </Search>
  );
};
