import { createRef, ChangeEvent } from "react";
import { Box, InputAdornment, MenuItem } from "@mui/material";
import { useContextComissoes } from "src/Contexts/comissoes/ComissoesContext";
import { ICreateComissaoProdutor } from "src/Contexts/comissoes/ComissoesTypes";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { ButtonsFeaturesFormularioCore, FormCore } from "src/Pages/components";
import { AppTextField } from "src/Pages/components/AppFormComponents/AppTextField";
import { AutoCompleteAdministradoras } from "src/Pages/components/AutoCompleted/AutoCompleteAdministradoras";
import { AutoCompleteModalidade } from "src/Pages/components/AutoCompleted/AutoCompleteModalidade";
import { AutoCompleteOperadora } from "src/Pages/components/AutoCompleted/AutoCompleteOperadora";
import { AutoCompleteProdutorActive } from "src/Pages/components/AutoCompleted/AutoCompleteProdutorActive";
import { UseVerifyIsPermissionsUser } from "src/Pages/components/hooks/UseVerifyIsPermissionsUser";
import { valuesPermissions } from "src/shared/Utils/ValuesPermissions";

import { UseChangeNameComissaoProdutor } from "./Hooks/UseChangeNameComissaoProdutor";
import { UseFeaturesFormulario } from "./Hooks/UseFeaturesFormulario";

export const FormularioComissaoProdutor = () => {
  const { handleVerifyPermissionItem } = UseVerifyIsPermissionsUser();
  const { handleSubmit } = UseFeaturesFormulario();
  const { active_incluir } = useConfigPageContext();

  UseChangeNameComissaoProdutor();

  const { valuesInputsComissaoProdutor, setValuesInputsComissaoProdutor } =
    useContextComissoes();

  const refOrigem = createRef<HTMLInputElement>();
  const handleFocusedID = () => refOrigem.current?.focus();

  const handleChange =
    (prop: keyof ICreateComissaoProdutor, option?: "limitNumber") =>
    (event: ChangeEvent<HTMLInputElement>) => {
      if (option === "limitNumber") {
        if (event.target.value.length <= 6) {
          setValuesInputsComissaoProdutor({
            ...valuesInputsComissaoProdutor,
            [prop]: event.target.value.replace(",", ""),
          });
        }
      } else {
        setValuesInputsComissaoProdutor({
          ...valuesInputsComissaoProdutor,
          [prop]: event.target.value,
        });
      }
    };

  return (
    <FormCore>
      <ButtonsFeaturesFormularioCore
        disabledEditar={handleVerifyPermissionItem(
          valuesPermissions.COMMISSIONS_PRODUCER.EDITAR.id
        )}
        disabledIncluir={handleVerifyPermissionItem(
          valuesPermissions.COMMISSIONS_PRODUCER.CREATE.id
        )}
        handleFocusedID={handleFocusedID}
        handleSubmit={handleSubmit}
      />
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <AppTextField
          disabled
          inputRef={refOrigem}
          label="Nome comissão"
          sxStyle={{ width: "250px" }}
          value={valuesInputsComissaoProdutor?.name}
          onChange={handleChange("name")}
        />

        <AutoCompleteOperadora
          disabled={!active_incluir}
          label="Operadora *"
          tamanho="180px"
        />

        <AutoCompleteAdministradoras
          disabled={!active_incluir}
          label="Administradora"
          tamanho="180px"
        />

        <AutoCompleteModalidade
          disabled={!active_incluir}
          label="Modalidade *"
          tamanho="180px"
        />

        <AppTextField
          select
          id="is_special"
          label="Especial"
          sxStyle={{ width: "60px" }}
          value={valuesInputsComissaoProdutor?.is_special}
          onChange={handleChange("is_special")}
        >
          <MenuItem value="false">Não</MenuItem>
          <MenuItem value="true">Sim</MenuItem>
        </AppTextField>

        {valuesInputsComissaoProdutor?.is_special === "true" ? (
          <AutoCompleteProdutorActive label="Produtores *" tamanho="260px" />
        ) : (
          <>
            <AppTextField
              select
              id="Funcão_Produtor"
              label="Funcão Produtor"
              sxStyle={{ width: "120px" }}
              value={valuesInputsComissaoProdutor?.funcao_produtor}
              onChange={handleChange("funcao_produtor")}
            >
              <MenuItem value="CORRETOR">Corretor</MenuItem>
              <MenuItem value="SUPERVISOR">Supervisor</MenuItem>
              <MenuItem value="GERENTE">Gerente</MenuItem>
            </AppTextField>

            <AppTextField
              select
              id="tipo_relatorio"
              label="Tipo Produtor"
              sxStyle={{ width: "120px" }}
              value={valuesInputsComissaoProdutor?.tipo_produtor}
              onChange={handleChange("tipo_produtor")}
            >
              <MenuItem value="SALAO">Salão</MenuItem>
              <MenuItem value="REPASSE">Repasse</MenuItem>
              <MenuItem value="FREE_LANCE">Free lance</MenuItem>
              <MenuItem value="CONECTOR">Conector</MenuItem>
              <MenuItem value="ADMINISTRATIVO">Administrativo</MenuItem>
            </AppTextField>
          </>
        )}

        <AppTextField
          label="Vigência *"
          sxStyle={{ width: "120px" }}
          type="date"
          value={valuesInputsComissaoProdutor?.date_vigencia}
          onChange={handleChange("date_vigencia")}
        />

        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          <AppTextField
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            label="1Parc."
            sxStyle={{ width: "62px" }}
            type="text"
            value={valuesInputsComissaoProdutor?.parc_1}
            onChange={handleChange("parc_1", "limitNumber")}
          />
          <AppTextField
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            label="2Parc."
            sxStyle={{ width: "62px" }}
            type="text"
            value={valuesInputsComissaoProdutor?.parc_2}
            onChange={handleChange("parc_2", "limitNumber")}
          />
          <AppTextField
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            label="3Parc."
            sxStyle={{ width: "62px" }}
            type="text"
            value={valuesInputsComissaoProdutor?.parc_3}
            onChange={handleChange("parc_3", "limitNumber")}
          />
          <AppTextField
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            label="4Parc."
            sxStyle={{ width: "62px" }}
            type="text"
            value={valuesInputsComissaoProdutor?.parc_4}
            onChange={handleChange("parc_4", "limitNumber")}
          />
          <AppTextField
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            label="5Parc."
            sxStyle={{ width: "62px" }}
            type="text"
            value={valuesInputsComissaoProdutor?.parc_5}
            onChange={handleChange("parc_5", "limitNumber")}
          />
          <AppTextField
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            label="6Parc."
            sxStyle={{ width: "62px" }}
            type="text"
            value={valuesInputsComissaoProdutor?.parc_6}
            onChange={handleChange("parc_6", "limitNumber")}
          />
          <AppTextField
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            label="7Parc."
            sxStyle={{ width: "62px" }}
            type="text"
            value={valuesInputsComissaoProdutor?.parc_7}
            onChange={handleChange("parc_7", "limitNumber")}
          />
          <AppTextField
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            label="8Parc."
            sxStyle={{ width: "62px" }}
            type="text"
            value={valuesInputsComissaoProdutor?.parc_8}
            onChange={handleChange("parc_8", "limitNumber")}
          />
          <AppTextField
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            label="9Parc."
            sxStyle={{ width: "62px" }}
            type="text"
            value={valuesInputsComissaoProdutor?.parc_9}
            onChange={handleChange("parc_9", "limitNumber")}
          />
          <AppTextField
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            label="10Parc."
            sxStyle={{ width: "62px" }}
            type="text"
            value={valuesInputsComissaoProdutor?.parc_10}
            onChange={handleChange("parc_10", "limitNumber")}
          />
          <AppTextField
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            label="11Parc."
            sxStyle={{ width: "62px" }}
            type="text"
            value={valuesInputsComissaoProdutor?.parc_11}
            onChange={handleChange("parc_11", "limitNumber")}
          />
          <AppTextField
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            label="12Parc."
            sxStyle={{ width: "62px" }}
            type="text"
            value={valuesInputsComissaoProdutor?.parc_12}
            onChange={handleChange("parc_12", "limitNumber")}
          />

          <AppTextField
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            label="13Parc."
            sxStyle={{ width: "62px" }}
            type="text"
            value={valuesInputsComissaoProdutor?.parc_13}
            onChange={handleChange("parc_13", "limitNumber")}
          />
          <AppTextField
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            label="14Parc."
            sxStyle={{ width: "62px" }}
            type="text"
            value={valuesInputsComissaoProdutor?.parc_14}
            onChange={handleChange("parc_14", "limitNumber")}
          />
          <AppTextField
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            label="15Parc."
            sxStyle={{ width: "62px" }}
            type="text"
            value={valuesInputsComissaoProdutor?.parc_15}
            onChange={handleChange("parc_15", "limitNumber")}
          />
          <AppTextField
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            label="16Parc."
            sxStyle={{ width: "62px" }}
            type="text"
            value={valuesInputsComissaoProdutor?.parc_16}
            onChange={handleChange("parc_16", "limitNumber")}
          />
        </Box>
      </Box>
    </FormCore>
  );
};
