import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextRecibos } from "src/Contexts/Recibos/RecibosContext";
import { AccordionCore } from "src/Pages/components/AccordionCore/AccordionCore";

import { PDFConfirmationReceipt } from "../../components/Report/PDF/ConfirmationReceipt";

import { FormReceipt } from "./Components/Form/FormReceipt";

export const PageRecibos = () => {
  const { userPerfil } = useAuthContext();
  const { loadingGravar } = useConfigPageContext();
  const { listRecibos, setListRecibos } = useContextRecibos();

  return (
    <>
      <AccordionCore title="Clique para abrir o filtro" defaultExpanded>
        <FormReceipt />
      </AccordionCore>

      <PDFConfirmationReceipt
        isLoading={loadingGravar}
        listRecibos={listRecibos}
        nomeEmpresa={userPerfil?.empresa.nome}
        setListRecibos={setListRecibos}
        key={1}
        title="Recibo de confirmações"
      />
    </>
  );
};
