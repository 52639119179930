import { handleFormatDateToPhraseSignature } from "src/shared/Utils/FormatDate/FormatDateToPhraseSignature";

import { stylesPDFAssinatura } from "./style";
import { View, Text } from "@react-pdf/renderer";
import { formatString } from "@format-string/core";

interface IPDFAssinatura {
  date: string;
  nameProdutor: string;
  cpf_cnpj: string;
  fisico_juridico: "fisica" | "juridica";
  sentenceInFull: string;
  contaBancaria?: {
    pix: string;
    banco: {
      label: string;
      value: string;
    };
    agencia: string;
    conta: string;
    favorecido: string;
  };
}

export const PDFAssinatura = ({
  date,
  cpf_cnpj,
  fisico_juridico,
  nameProdutor,
  sentenceInFull,
  contaBancaria,
}: IPDFAssinatura) => {
  return (
    <View style={stylesPDFAssinatura.container}>
      <Text style={stylesPDFAssinatura.sentenceInFull}>{sentenceInFull}</Text>
      <Text>{handleFormatDateToPhraseSignature(date)}</Text>
      <Text style={stylesPDFAssinatura.lineSignature} />

      <Text style={{ display: "flex", marginBottom: "12px" }}>
        <Text style={stylesPDFAssinatura.nameProdutor}>{nameProdutor}</Text>
        {"  -  "}
        <Text style={stylesPDFAssinatura.nameProdutor}>
          {fisico_juridico === "juridica"
            ? formatString({ value: cpf_cnpj, type: "cnpj" })
            : formatString({ value: cpf_cnpj, type: "cpf" })}
        </Text>
      </Text>

      {contaBancaria && (
        <Text>
          {contaBancaria?.pix.length > 0
            ? `PIX: ${contaBancaria.pix}`
            : `BANCO: ${contaBancaria?.banco.value}${
                contaBancaria?.banco.label
                  ? `- ${contaBancaria.banco.label}`
                  : ""
              }   AG: ${contaBancaria?.agencia}.   C/C: ${
                contaBancaria?.conta
              }.`}
        </Text>
      )}
    </View>
  );
};
