import { Box, FormControl, MenuItem, Select } from "@mui/material";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";

type ISelectTables = { qtd_Itens: number };

export const SelectQtdTables = ({ qtd_Itens }: ISelectTables) => {
  const { itensPerPage, setItensPerPage } = useConfigPageContext();

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ color: "#3b3b3b", fontSize: ".8rem" }}>Mostrando</Box>
      <FormControl size="small" sx={{ margin: "0 8px", color: "#3b3b3b" }}>
        <Select
          sx={{ color: "#3b3b3b" }}
          value={itensPerPage}
          onChange={(e) => setItensPerPage(Number(e.target.value))}
        >
          <MenuItem value="8"> 8 </MenuItem>
          <MenuItem value="15"> 15 </MenuItem>
          <MenuItem value="20"> 20 </MenuItem>
        </Select>
      </FormControl>

      <Box sx={{ fontSize: ".8rem", color: "#3b3b3b" }}>
        de {qtd_Itens} registros
      </Box>
    </div>
  );
};
