import { useState } from "react";
import { AccordionCore } from "src/Pages/components/AccordionCore/AccordionCore";

import { FormReportProductionContract } from "./Components/Form/FormReportProductionContract";
import { ReportProductionContractPDF } from "./Components/PDF/ReportProductionContractPDF";

export const PageReportProductionContract = () => {
  const [expanded, setExpanded] = useState(true);

  return (
    <>
      <AccordionCore
        expanded={expanded}
        sxStyle={{ padding: 0 }}
        title="Clique para abrir FILTRO"
        onChange={() => setExpanded((item) => !item)}
      >
        <FormReportProductionContract />
      </AccordionCore>

      <ReportProductionContractPDF />
    </>
  );
};
