import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { useContextReportsProduction } from "src/Contexts/ReportProduction/ReportsProductionContextContext";
import { UseAutoCompletedAll } from "src/Contexts/Utils/UseAutoCompletedAll";
import { api } from "src/shared/setup/API/api";

export const UseReportConfirmationContract = () => {
  const { userPerfil } = useAuthContext();
  const { handleGetAlert } = useLayoutMainContext();
  const { setLoadingGravar } = useConfigPageContext();
  const { valueAutoCompFiliais, valueAutoCompProdutorActive } =
    UseAutoCompletedAll();

  const {
    setListReportConfirmationContract,
    valueAutoCompAdministradoras,
    inputsConfirmationContract,
    valueAutoCompModalidades,
    valueAutoCompOperadoras,
  } = useContextReportsProduction();

  const objData = {
    start_date_payment: inputsConfirmationContract.start_date_payment,
    end_date_payment: inputsConfirmationContract.end_date_payment,

    start_date_due: inputsConfirmationContract.start_date_due,
    end_date_due: inputsConfirmationContract.end_date_due,

    start_date_signature: inputsConfirmationContract.start_date_signature,
    end_date_signature: inputsConfirmationContract.end_date_signature,

    id_filial: valueAutoCompFiliais.map((item) => item.id),

    operadoras: valueAutoCompOperadoras.map((item) => item.id),
    modalidades: valueAutoCompModalidades.map((item) => item.id),
    administradoras: valueAutoCompAdministradoras.map((item) => item.id),

    type_report:
      userPerfil?.type_user === "PRODUCER"
        ? "UNIQUE"
        : inputsConfirmationContract.type_report,

    code_produtor:
      userPerfil?.type_user === "PRODUCER"
        ? String(userPerfil.id)
        : String(valueAutoCompProdutorActive.id),
  };

  const handleGetReportProductionContract = async () => {
    setLoadingGravar(true);
    setListReportConfirmationContract(undefined);

    const AllDatas = { ...objData };

    return api
      .post("/report/confirmationContract", AllDatas)
      .then((res) => {
        setListReportConfirmationContract(res.data);
      })
      .catch((error) => {
        handleGetAlert({ message: error.response.data.message });
      })
      .finally(() => setLoadingGravar(false));
  };

  const handleSubmit = () => handleGetReportProductionContract();

  return { handleSubmit };
};
