import { Box } from "@mui/material";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { TitleWithValueCore } from "src/Pages/components/TitleWithValueCore/TitleWithValueCore";
import { FormatDateBR } from "src/shared/Utils/FormatDateBR";

export const CardDadosCriacao = () => {
  const { valueContractInternal } = useContextContrato();

  if (!valueContractInternal) return null;

  const { nome_filial, created_at, update_at } = valueContractInternal;

  return (
    <Box
      sx={{
        width: "100%",
        border: "1px solid #a2a2a2ab",
        borderRadius: "8px",
        padding: { xs: "12px", sm: "28px" },
      }}
    >
      <TitleWithValueCore
        sxStyleTitle={{ fontWeight: "500" }}
        title="Filial:"
        value={nome_filial}
      />
      <TitleWithValueCore
        sxStyleTitle={{ fontWeight: "500" }}
        title="Data de criação:"
        value={FormatDateBR(created_at)}
      />
      <TitleWithValueCore
        sxStyleTitle={{ fontWeight: "500" }}
        title="Data de edição:"
        value={FormatDateBR(update_at)}
      />
      {/* <TitleWithValueCore
              title="Criação:"
              value="Usuário 1 - Richard Madrigar"
              sxStyleTitle={{ fontWeight: "500" }}
            />
            <TitleWithValueCore
              title="Editou:"
              value="Usuário 1 - Richard Madrigar"
              sxStyleTitle={{ fontWeight: "500" }}
            /> */}
    </Box>
  );
};
