import {
  createContext,
  useContext,
  useEffect,
  useState,
  FC,
  ReactNode,
} from "react";
import {
  IUsers,
  ILogins,
  TokenState,
  IFilterHome,
  IValuesCards,
  IHandleLogin,
  IAuthContextType,
  IHandleSearchEmpresa,
} from "src/Contexts/AuthContext/AuthContextTypes";
import { api, getToken } from "src/shared/setup/API/api";
import { FormatDateDayLastMonth } from "src/shared/Utils/FormatDate/FormatDateDayLastMonth";

import { useLayoutMainContext } from "../MainLayoutContext";

const AuthContext = createContext({} as IAuthContextType);

export const AuthContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { handleGetAlert } = useLayoutMainContext();

  const [valuesAutoComplete, setValuesAutoComplete] = useState<
    { label: string }[]
  >([]);

  const [inputValueSelect, setInputValueSelect] = useState("");
  const [isConectionNet, setIsConectionNet] = useState(true);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [authorization, setAuthorization] = useState(true);
  const [userPerfil, setUserPerfil] = useState<IUsers>();
  const [loading, setLoading] = useState(false);

  const [valuesCards, setValuesCards] = useState<IValuesCards>();

  const [filterHome, setFilterHome] = useState<IFilterHome>({
    startDate: FormatDateDayLastMonth().firstDayMonth,
    endDate: FormatDateDayLastMonth().lastDayMonth,
  });

  const [valuesInputsLogins, setValuesInputsLogins] = useState<ILogins>({
    senha: "",
    email: "",
  });

  const [, setToken] = useState<TokenState>(() => {
    const token = localStorage.getItem(getToken);

    if (token) {
      api.defaults.headers.common.Authorization = `Bearer ${token}`;

      return { token };
    }

    return {} as TokenState;
  });

  window.addEventListener("offline", () => {
    handleGetAlert({ message: "Usuário sem conexão !" });
    setIsConectionNet(false);
  });
  window.addEventListener("online", () => {
    handleGetAlert({ message: "Conexão online ! " });
    setIsConectionNet(true);
  });

  useEffect(() => {
    document.title = `Brokeris  ${
      userPerfil ? `- ${userPerfil.empresa.nome}` : ""
    }`;
  }, [userPerfil]);

  const RefreshSession = async () => {
    const token = localStorage.getItem(getToken);

    setLoading(true);

    if (!token) {
      setLoading(false);

      return setAuthorization(false);
    }
    api.defaults.headers.common.Authorization = `Bearer ${token}`;

    return api
      .post("/sessions")
      .then(({ data }) => {
        const { token } = data;

        setUserPerfil(data);
        setAuthorization(true);

        setToken(token);
        localStorage.setItem(getToken, token);
      })
      .catch((error) => {
        localStorage.removeItem(getToken);
        setAuthorization(false);

        return handleGetAlert({ message: error?.response?.data?.message });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    RefreshSession();
  }, []);

  const handleSearchCompany = async (data: IHandleSearchEmpresa) => {
    if (!data.email.trim())
      return handleGetAlert({ message: "Insira um Email!" });
    if (!data.senha.trim())
      return handleGetAlert({ message: "Insira uma Senha!" });

    const allUrlSearch = {
      PRODUCER: "GetEmpresasAll",
      USER: "GetEmpresasAll",
      ROOT: "rootGetEmpresasAll",
    };

    setLoadingLogin(true);

    return api
      .post(`/${allUrlSearch[data.typeUser]}`, data)
      .then((response) => setValuesAutoComplete(response.data))
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoadingLogin(false));
  };

  const handleLogin = async (data: IHandleLogin) => {
    const allUrlSearch = {
      PRODUCER: "login",
      USER: "login",
      ROOT: "rootLogin",
    };

    setLoadingLogin(true);

    return api
      .post(`/${allUrlSearch[data.typeUser]}`, {
        senha: data.senha.trim(),
        email: data.email.trim(),
        tenant: data.tenant,
      })
      .then(({ data }) => {
        setLoading(true);
        const { token } = data;

        api.defaults.headers.common.Authorization = `Bearer ${token}`;
        setToken(token);

        setUserPerfil(data);

        localStorage.setItem(getToken, token);

        setValuesInputsLogins({ email: "", senha: "" });
        setInputValueSelect("");
        setValuesAutoComplete([]);

        return setAuthorization(true);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => {
        setLoading(false);
        setLoadingLogin(false);
      });
  };

  return (
    <AuthContext.Provider
      value={{
        setValuesAutoComplete,
        setValuesInputsLogins,
        handleSearchCompany,
        setInputValueSelect,
        valuesInputsLogins,
        valuesAutoComplete,
        inputValueSelect,
        setAuthorization,
        setLoadingLogin,
        isConectionNet,
        RefreshSession,
        setValuesCards,
        setUserPerfil,
        authorization,
        setFilterHome,
        loadingLogin,
        handleLogin,
        valuesCards,
        filterHome,
        userPerfil,
        setToken,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = (): IAuthContextType => useContext(AuthContext);
