import { UrlConfig } from "src/shared/Utils/paths";

interface IDataHelp {
  title: string;
  description: string;
  id: string;
  page: {
    title: string;
    url: string;
  };

  tags?: string[];
  data?: {
    title?: string;
    // description: string;
  };
}

export const dataHelp: IDataHelp[] = [
  {
    title: "Por onde começar ?",
    page: {
      title: UrlConfig.produtores.produtores.title,
      url: UrlConfig.produtores.produtores.url,
    },
    id: "59ad8352-e852-448b-8bc0-99326a590a8c",
    description:
      "O sistema foi projeto para cadastrar de cima para baixo seguindo o menu a esquerda, indicamos você iniciar cadastrando os produtores (CORRETORES,SUPERVISORES,GERENTES), logo depois Operadora, modalidades, administradoras, comissão corretora, comissão produtor; E assim você consegue cadastrar uma proposta.",
    tags: [
      "Como cadastrar",
      "Como lançar proposta",
      "Contratos",
      "Como iniciar",
      "Por onde começar",
    ],
  },
  {
    id: "ceead5b0-8b6c-4f93-94af-ae19ac6c5f58",
    title: "Como criar um novo usuário",
    description:
      "Na tela de usuários você gerencia os logins dos seus funcionários.",
    page: {
      title: UrlConfig.usuarios.usuarios.title,
      url: UrlConfig.usuarios.usuarios.url,
    },
    tags: ["Criar funcionário", "Criar usuários", "usuários", "Richard"],
  },
  {
    id: "f5d2e2ae-3a27-4b4d-8567-cf65df537b4a",
    title: "Como gerenciar a permissão dos funcionários",
    description: "Na tela de perfil de acesso você gerencia as permissões.",
    page: {
      title: UrlConfig.usuarios.perfilAcesso.title,
      url: UrlConfig.usuarios.perfilAcesso.url,
    },
  },
  {
    id: "19308b5f-458c-4c72-ade4-d21825134b57",
    title: "Como criar um novo Corretor/Supervisor/Gerente",
    description:
      "Na tela de produtores você pode gerenciar corretores/supervisores/gerentes.",
    page: {
      title: UrlConfig.produtores.produtores.title,
      url: UrlConfig.produtores.produtores.url,
    },
  },
  {
    title: "Como lançar um (vale ou bônus) na conta corrente do produtor",
    page: {
      title: UrlConfig.produtores.contaCorrente.title,
      url: UrlConfig.produtores.contaCorrente.url,
    },
    id: "cf7be229-1997-4ef2-9273-1a21e2eecfa8",
    description:
      "Na tela de conta corrente você gerencia o extrato dos produtores.",
  },

  {
    title:
      "Ao criar proposta recebi alerta que não existe grade de comissão da corretora",
    page: {
      title: UrlConfig.comissao.corretora.title,
      url: UrlConfig.comissao.corretora.url,
    },
    id: "d77d93d9-65a3-45b9-8784-7bcadf6bbf36",
    description:
      "Precisa verificar se existe grade na tela de comissão da corretora com a mesma operadora/modalidade e data de vigência",
  },
  {
    title:
      "Ao criar proposta recebi alerta que não existe grade de comissão da corretor",
    page: {
      title: UrlConfig.comissao.produtor.title,
      url: UrlConfig.comissao.produtor.url,
    },
    id: "b70899da-b586-4caf-9e81-7901408cfd82",
    description:
      "Precisa verificar se existe grade na tela de comissão da produtor com a mesma operadora/modalidade e data de vigência.",
  },
  {
    title: "Como cadastrar uma proposta",
    page: {
      title: UrlConfig.lancamentos.contratos.title,
      url: UrlConfig.lancamentos.contratos.url,
    },
    id: "2836ddfb-f481-4f6d-9717-1bcf573ec644",
    description:
      "Na tela de Lançamentos/contrato você pode criar/editar/deletar/cancelar. ",
  },
  {
    title:
      "Como sinalizar para o sistema que recebeu da operadora/administradora",
    page: {
      title: UrlConfig.lancamentos.parcelas.title,
      url: UrlConfig.lancamentos.parcelas.url,
    },
    id: "2e8933c8-ba53-49aa-8a67-878174ccfb79",
    description:
      "Na tela parcelas você sinaliza para o sistema que recebeu e libera para pagar os produtores.",
  },
  {
    title: "Como cadastrar a grade de comissão que a corretora recebe",
    page: {
      title: UrlConfig.comissao.corretora.title,
      url: UrlConfig.comissao.corretora.url,
    },
    id: "366082a3-61e8-4609-a2eb-dc27e9d40169",
    description:
      "Na tela de comissão da corretora você pode criar grades que recebe da operadora/administradora.",
  },
  {
    id: "f5d2e2ae-3a27-4b4d-8567-cf65df537b4a",
    title:
      "Como criar grade que vai pagar para o (corretora, supervisor, gerente)",
    page: {
      title: UrlConfig.comissao.produtor.title,
      url: UrlConfig.comissao.produtor.url,
    },
    description:
      "Na tela de comissão da produtor você pode criar grades que vai pagar.",
  },
  {
    id: "4f5773cf-a1cc-44a6-a6d9-cf90ff039903",
    title: "Como efetuar pagamentos e gerar recibos para produtores",
    page: {
      title: UrlConfig.pagamentos.recibos.title,
      url: UrlConfig.pagamentos.recibos.url,
    },
    description:
      "Na tela de recibos é o local para efetuar os pagamentos para (corretor/supervisor/gerente) e gerar recibos",
  },
  {
    title: "Como ver relatório de produção",
    page: {
      title: UrlConfig.relatoriosProducao.producaoContract.title,
      url: UrlConfig.relatoriosProducao.producaoContract.url,
    },
    id: "e5a769ff-e2f7-4754-bcc5-7930edf787bf",
    description:
      "Na tela de relatório de contrato você vai ver a produção com detalhes das propostas.",
  },
  {
    title: "Como ver relatório de confirmação",
    page: {
      title: UrlConfig.relatoriosProducao.confirmationContract.title,
      url: UrlConfig.relatoriosProducao.confirmationContract.url,
    },
    id: "fd278c3c-2a12-40d1-90aa-d5b472279694",
    description:
      "Na tela de relatório de confirmação você vai ver as confirmações com detalhes das parcelas",
  },
  {
    title: "Como criar login para o corretor",
    page: {
      title: UrlConfig.produtores.produtores.title,
      url: UrlConfig.produtores.produtores.url,
    },
    id: "8b436924-274c-4d4c-ace7-9c69d1d63a2b",
    description:
      "Na tela de produtores tem a tabela com ações, ali tem a opção que coloca o login de acesso e o produtor recebe a senha por email",
  },
  {
    title: "Como cadastrar imposto global",
    page: {
      title: UrlConfig.produtores.impostos.title,
      url: UrlConfig.produtores.impostos.url,
    },
    id: "0ea20b23-bbb0-4b92-9ffe-595eafa9249c",
    description:
      "Na tela de imposto você tem o botão de editar o imposto global",
  },
  {
    id: "7113b961-9445-45d2-baa8-15b6bb2c5bf6",
    title: "Como zerar o recebimento da operadora que já lancei no sistema",
    page: {
      title: UrlConfig.produtores.produtores.title,
      url: UrlConfig.produtores.produtores.url,
    },
    description:
      "Na tela de parcelas tem a tabela com ações, ali você pode zerar o recebimento caso não tenha pago algum produtor, caso tenha feito o pagamento você precisa primeiro zerar o pagamento.",
  },
  {
    title: "Como ver o recibo do corretor que ja foi gerado",
    page: {
      title: UrlConfig.produtores.contaCorrente.title,
      url: UrlConfig.produtores.contaCorrente.url,
    },
    id: "63df1429-6bb0-4900-b937-07eebce1aac0",
    description:
      "Na tela de conta corrente tem uma coluna 'Recibo', se estiver em azul o recibo é que tem o recibo para visualizar.",
  },
];
