import { useState } from "react";
import { AccordionCore } from "src/Pages/components/AccordionCore/AccordionCore";

import { FormReportProductionContract } from "./Components/Form/FormReportProductionProducer";
import { ReportProductionContractPDF } from "./Components/PDF/ReportProductionProducerPDF";

export const PageReportProductionProducer = () => {
  const [expanded, setExpanded] = useState(true);

  return (
    <>
      <AccordionCore
        expanded={expanded}
        sxStyle={{ padding: 0 }}
        title="Clique para abrir FILTRO"
        onChange={() => setExpanded((item) => !item)}
      >
        <FormReportProductionContract />
      </AccordionCore>

      <ReportProductionContractPDF />
    </>
  );
};
