import { ChangeEvent, useEffect, createRef } from "react";
import { Box } from "@mui/material";
import { useContextRecoverPassword } from "src/Contexts/RecoverPassword/RecoverPasswordContext";
import { IInputsGetCompaniesRecoverPassword } from "src/Contexts/RecoverPassword/RecoverPasswordContextTypes";
import { AppTextField } from "src/Pages/components";
import { useStylesInput } from "src/Pages/TelasLogin/Login/components/FormLogin";

export const FormSendLinkRecoverPassword = () => {
  const {
    setValuesInputsGetCompaniesRecoverPassword,
    valuesInputsGetCompaniesRecoverPassword,
  } = useContextRecoverPassword();

  const classes = useStylesInput();

  const InputEmailRef = createRef<HTMLInputElement>();

  useEffect(() => {
    InputEmailRef.current?.focus();
  }, []);

  const handleChange =
    (prop: keyof IInputsGetCompaniesRecoverPassword) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setValuesInputsGetCompaniesRecoverPassword((eventPrev) => ({
        ...eventPrev,
        [prop]: event.target.value,
      }));
    };

  return (
    <Box sx={{ marginBottom: "1rem" }}>
      <AppTextField
        fullWidth
        InputProps={{ className: classes.input }}
        inputRef={InputEmailRef}
        label="Email"
        name="email"
        sxStyle={{ opacity: "", pointerEvents: "" }}
        value={valuesInputsGetCompaniesRecoverPassword.email}
        variant="outlined"
        onChange={handleChange("email")}
      />
    </Box>
  );
};
