import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

export const UseGetInfoHomePage = () => {
  const { handleGetAlert } = useLayoutMainContext();

  const { filterHome, setValuesCards } = useAuthContext();

  const handleSubmit = () => {
    api
      .post("/homeInfo", {
        startDate: filterHome.startDate,
        endDate: filterHome.endDate,
      })
      .then((res) => setValuesCards(res.data))
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      );
  };

  return { handleSubmit };
};
