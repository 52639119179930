import { UseAutoCompletedAll } from "src/Contexts/Utils/UseAutoCompletedAll";
import { AutoCompleteGeneric } from "src/Pages/components/InputAutoCompleted/AutoCompleteGeneric";
import { IPropsAutoCompleted } from "src/Pages/components/InputAutoCompleted/types";

export const AutoCompleteOperadora = (props: IPropsAutoCompleted) => {
  const {
    label,
    width,
    tamanho,
    minWidth,
    maxWidth,
    fullWidth,
    disabled,
    variant,
  } = props;
  const { valueAutoCompOperadora, setValueAutoCompOperadora } =
    UseAutoCompletedAll();

  return (
    <AutoCompleteGeneric
      disabled={disabled}
      fullWidth={fullWidth}
      label={label}
      maxWidth={maxWidth}
      minWidth={minWidth}
      setValueAutoCompleted={setValueAutoCompOperadora}
      tamanho={tamanho}
      url="/comissoes/operadoraList?is_active=true"
      valueAutoCompleted={valueAutoCompOperadora}
      variant={variant}
      width={width}
    />
  );
};
