export const FormatRG = (rg: string) => {
  let value = rg;

  // Remove any non-digit characters
  value = value.replace(/\D/g, "");
  // Apply the mask
  if (value.length > 10) {
    value = value.slice(0, 10); // Limit to 10 digits
  }
  value = value.replace(/(\d{2})(\d{3})(\d{3})(\d{0,1})/, "$1.$2.$3-$4");

  return value;
};
