import { ChangeEvent, useEffect } from "react";
import {
  Box,
  Grid,
  Tooltip,
  Checkbox,
  MenuItem,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { useResetInputs } from "src/Contexts";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextRecibos } from "src/Contexts/Recibos/RecibosContext";
import { IGetRecibos } from "src/Contexts/Recibos/RecibosTypes";
import { FormCore } from "src/Pages/components";
import { AppTextField } from "src/Pages/components/AppFormComponents/AppTextField";
import { AutoCompleteFiliaisGeneric } from "src/Pages/components/AutoCompleted/AutoCompleteFiliais";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";
import { UseVerifyIsPermissionsUser } from "src/Pages/components/hooks/UseVerifyIsPermissionsUser";
import { NotListedLocationIcon } from "src/Pages/components/Icons/Icons";

import { SelectedOperadorasGeneric } from "../../../../components/AutoCompleted/Multiple/SelectedOperadoras";

import { AutoCompleteProdutores } from "./AutoCompletedAll/AutoCompletProdutores";
import { UseFormReceipt } from "./Hooks/UseFormReceipt";

export const FormReceipt = () => {
  const { setValuesInputsRecibos, valuesInputsRecibos } = useContextRecibos();
  const { resetInputs } = useResetInputs(true);
  const { handleSubmit } = UseFormReceipt();
  const { loadingGravar, setActive_incluir, active_incluir } =
    useConfigPageContext();

  UseVerifyIsPermissionsUser();

  useEffect(() => setActive_incluir(true), [active_incluir]);

  const handleChange =
    (prop: keyof IGetRecibos) => (event: ChangeEvent<HTMLInputElement>) => {
      setValuesInputsRecibos((prevValue) => ({
        ...prevValue,
        [prop]: event.target.value,
      }));
    };

  const handleChangeCheck = (event: ChangeEvent<HTMLInputElement>) => {
    setValuesInputsRecibos((prevValue) => ({
      ...prevValue,
      [event.target.name]: event.target.checked,
    }));
  };

  return (
    <FormCore sxStyle={{ margin: "0" }}>
      <Grid
        container
        sx={{
          "& > *": {
            padding: "6px 12px",
            margin: "4px 8px 8px 4px",
            border: "1px solid gray",
            borderRadius: "8px",
          },
        }}
      >
        <Grid
          container
          sx={{
            width: { xs: "100%", sm: "270px" },
            "& > *": { marginBottom: { xs: "1.8rem", sm: "0" } },
          }}
        >
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <AppTextField
                fullWidth
                label="Data de pagamento *"
                sxStyle={{ margin: 0 }}
                type="date"
                value={valuesInputsRecibos?.start_date}
                onChange={handleChange("start_date")}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <AppTextField
                fullWidth
                label="Data de pagamento *"
                sxStyle={{ margin: 0 }}
                type="date"
                value={valuesInputsRecibos?.end_date}
                onChange={handleChange("end_date")}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <AppTextField
              fullWidth
              select
              id="Parcelas"
              label="Parcelas *"
              sxStyle={{ margin: 0 }}
              value={valuesInputsRecibos?.numeroParcelas}
              onChange={handleChange("numeroParcelas")}
            >
              <MenuItem value="ALL">Todas</MenuItem>
              <MenuItem value="PRIMARY">Só as primeiras</MenuItem>
              <MenuItem value="NOT_PRIMARY">Sem as primeiras</MenuItem>
            </AppTextField>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            width: { xs: "100%", sm: "270px" },
            "& > *": { marginBottom: { xs: "1.8rem", sm: "0" } },
          }}
        >
          <Grid
            container
            spacing={1}
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={6}>
              <AppTextField
                fullWidth
                select
                id="produtor"
                label="Produtor *"
                sxStyle={{ margin: 0 }}
                value={valuesInputsRecibos?.type_payments}
                onChange={handleChange("type_payments")}
              >
                <MenuItem value="ALL">Todos</MenuItem>
                <MenuItem value="UNIQUE">Por produtor</MenuItem>
              </AppTextField>
            </Grid>

            <Grid item xs={6}>
              <AppTextField
                fullWidth
                select
                id="função produtor"
                label="Função Produtor *"
                sxStyle={{ margin: 0 }}
                value={valuesInputsRecibos?.funcao_produtor}
                onChange={handleChange("funcao_produtor")}
              >
                <MenuItem value="CORRETOR">Corretor</MenuItem>
                <MenuItem value="SUPERVISOR">Supervisor</MenuItem>
                <MenuItem value="GERENTE">Gerente</MenuItem>
              </AppTextField>
            </Grid>
          </Grid>

          <Grid item sx={{ marginLeft: "-6px" }} xs={12}>
            <AutoCompleteProdutores
              disabled={valuesInputsRecibos.type_payments === "ALL"}
              label="Produtor *"
              tamanho="100%"
            />
          </Grid>
        </Grid>

        <Grid container sx={{ width: { xs: "100%", sm: "270px" } }}>
          <Grid
            item
            sx={{
              "& > *": {
                maxHeight: "30px",
                marginBottom: "1.8rem",
                marginLeft: "-4px",
              },
            }}
            xs={12}
          >
            <AutoCompleteFiliaisGeneric fullWidth label="Filial" />
          </Grid>

          <Grid
            item
            sx={{
              "& > *": { maxHeight: "30px", marginBottom: "1.8rem" },
            }}
            xs={12}
          >
            <SelectedOperadorasGeneric fullWidth label="Operadoras" />
          </Grid>
        </Grid>

        <Grid container sx={{ width: { xs: "100%", sm: "270px" } }}>
          <FormGroup>
            <Tooltip title="Cobrar imposto global, é um imposto padrão e será cobrado em cima dessa %">
              <Box sx={{ display: "flex" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={valuesInputsRecibos.isImpostoGlobal}
                      name="isImpostoGlobal"
                      sx={{
                        width: "25px",
                        height: "25px",
                        "& .MuiSvgIcon-root": { fontSize: 20 },
                      }}
                      onChange={handleChangeCheck}
                    />
                  }
                  label="Imposto global"
                  sx={{ marginBottom: "8px", marginRight: "6px" }}
                />
                <NotListedLocationIcon
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Tooltip>
            <Tooltip title="Cobrar taxa de imposto, o produtor tem um grupo de imposto, será cobrado em cima dessa %">
              <Box sx={{ display: "flex" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={valuesInputsRecibos.isGrupoImposto}
                      name="isGrupoImposto"
                      sx={{
                        width: "25px",
                        height: "25px",
                        "& .MuiSvgIcon-root": { fontSize: 20 },
                      }}
                      onChange={handleChangeCheck}
                    />
                  }
                  label="Grupo imposto"
                  sx={{ marginBottom: "8px", marginRight: "6px" }}
                />
                <NotListedLocationIcon
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Tooltip>

            <Tooltip title="Pegar saldo da conta corrente">
              <Box sx={{ display: "flex" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={valuesInputsRecibos.contaCorrente}
                      name="contaCorrente"
                      sx={{
                        width: "25px",
                        height: "25px",
                        "& .MuiSvgIcon-root": { fontSize: 20 },
                      }}
                      onChange={handleChangeCheck}
                    />
                  }
                  label="Conta corrente"
                  sx={{ marginRight: "6px" }}
                />
                <NotListedLocationIcon
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Tooltip>
          </FormGroup>
        </Grid>

        <Grid container sx={{ width: { xs: "100%", sm: "270px" } }}>
          <FormGroup>
            <Tooltip title="Efetuar pagamento, sinaliza para o sistema que você já baixou esses recibos e pagou o produtor">
              <Box sx={{ display: "flex" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={valuesInputsRecibos.parcelas}
                      name="parcelas"
                      sx={{
                        width: "25px",
                        height: "25px",
                        "& .MuiSvgIcon-root": { fontSize: 20 },
                      }}
                      onChange={handleChangeCheck}
                    />
                  }
                  label="Efetuar pagamento"
                  sx={{ marginRight: "6px" }}
                />
                <NotListedLocationIcon
                  fontSize="small"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Tooltip>
          </FormGroup>
        </Grid>
      </Grid>

      <Box sx={{ display: "flex" }}>
        <ButtonCore
          disabled={loadingGravar}
          sx={{ width: "180px" }}
          title="Limpar filtros"
          onClick={resetInputs}
        />
        <ButtonCore
          fullWidth
          disabled={loadingGravar}
          title="Enviar"
          type="submit"
          onClick={() => handleSubmit()}
        />
      </Box>
    </FormCore>
  );
};
