import { useEffect } from "react";
import LanguageIcon from "@mui/icons-material/Language";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { ModalCore } from "src/Pages/components";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";
import { UseVerifyIsPermissionsUser } from "src/Pages/components/hooks/UseVerifyIsPermissionsUser";
import { valuesPermissions } from "src/shared/Utils/ValuesPermissions";

import { FormImpostoGlobal } from "./Form/FormImpostoGlobal";
import { UseFormImpostoGlobal } from "./Form/Hooks/UseFormImpostoGlobal";
import { useGetImpostoGlobal } from "./Form/Hooks/useGetImpostoGlobal";

interface IModalImpostoGlobal {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalImpostoGlobal = ({
  openModal,
  setOpenModal,
}: IModalImpostoGlobal) => {
  const { handleVerifyPermissionItem } = UseVerifyIsPermissionsUser();
  const { loadingGravar } = useConfigPageContext();

  const { handleGetById: handleGetByIdImpostoGlobal } = useGetImpostoGlobal();

  const { handleSubmit } = UseFormImpostoGlobal({
    setOpen: setOpenModal,
  });

  useEffect(() => {
    if (openModal) {
      handleGetByIdImpostoGlobal();
    }
  }, [openModal]);

  return (
    <>
      <ButtonCore
        disabled={handleVerifyPermissionItem(
          valuesPermissions.IMPOSTOS.IMPOSTO_GLOBAL.id
        )}
        startIcon={<LanguageIcon />}
        title="Imposto global"
        onClick={() => setOpenModal(true)}
      />

      <ModalCore
        loadingSubmit={loadingGravar}
        open={openModal}
        setOpen={setOpenModal}
        sxStyle={{ width: "500px" }}
        title="Imposto global"
        titleSubmit="Editar"
        onChange={handleSubmit}
      >
        <FormImpostoGlobal />
      </ModalCore>
    </>
  );
};
