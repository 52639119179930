import { IconButton, Tooltip } from "@mui/material";
import { FullscreenIcon } from "src/Pages/components/Icons/Icons";

export const ToggleFullScreen = () => {
  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  return (
    <Tooltip title="Tela cheia">
      <IconButton sx={{ color: "#3b3b3b" }} onClick={toggleFullScreen}>
        <FullscreenIcon />
      </IconButton>
    </Tooltip>
  );
};
