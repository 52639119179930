import { useState } from "react";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { IUsers } from "src/Contexts/AuthContext/AuthContextTypes";
import { useContextProfileContext } from "src/Contexts/ContextProfile/ContextProfile";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api, getToken } from "src/shared/setup/API/api";

export const UseFormUpdateProfileEmail = () => {
  const { setUserPerfil } = useAuthContext();
  const { handleGetAlert } = useLayoutMainContext();
  const { valuesUpdateEmailProfile } = useContextProfileContext();

  const [loading, setLoading] = useState(false);

  const handleUpdateProfileEmail = () => {
    if (!valuesUpdateEmailProfile.email)
      return handleGetAlert({ message: `Seu email não pode estar vazio !` });

    setLoading(true);

    return api
      .put(`/updateEmailProfile`, {
        email: valuesUpdateEmailProfile.email,
      })
      .then((res) => {
        localStorage.setItem(getToken, res.data.token);
        setUserPerfil((prev) => ({
          ...(prev as IUsers),
          email: valuesUpdateEmailProfile.email as string,
        }));
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  const handleSubmit = () => handleUpdateProfileEmail();

  return { handleSubmit, loading };
};
