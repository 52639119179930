import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { CardContract } from "src/Pages/components/CardContract/CardContract";
import { TitleWithValueCore } from "src/Pages/components/TitleWithValueCore/TitleWithValueCore";
import { FormatDateBR } from "src/shared/Utils/FormatDateBR";

export const CardDadosTitular = () => {
  const { valueContractInternal } = useContextContrato();

  if (!valueContractInternal) return null;

  const {
    titular_pf,
    cpf_cnpj,
    rg_pf,
    estado_civil_pf,
    data_nacimento_pf,
    genero_pf,
    mae_pf,
    data_nascimento_responsavel_pf,
    cpf_responsavel_pf,
    responsavel_pf,
  } = valueContractInternal;

  return (
    <>
      {valueContractInternal.tipo_contrato === "PF" && (
        <CardContract title="Dados do titular">
          <TitleWithValueCore
            sxStyleTitle={{ fontWeight: "500" }}
            title="Titular:"
            value={titular_pf}
          />
          <TitleWithValueCore
            sxStyleTitle={{ fontWeight: "500" }}
            title="CPF:"
            value={cpf_cnpj}
          />
          <TitleWithValueCore
            sxStyleTitle={{ fontWeight: "500" }}
            title="RG:"
            value={rg_pf}
          />
          <TitleWithValueCore
            sxStyleTitle={{ fontWeight: "500" }}
            title="Estado Civil:"
            value={estado_civil_pf.toUpperCase()}
          />
          <TitleWithValueCore
            sxStyleTitle={{ fontWeight: "500" }}
            title="Data nascimento:"
            value={FormatDateBR(data_nacimento_pf)}
          />
          <TitleWithValueCore
            sxStyleTitle={{ fontWeight: "500" }}
            title="Gênero:"
            value={genero_pf}
          />
          <TitleWithValueCore
            sxStyleTitle={{ fontWeight: "500" }}
            title="Mãe:"
            value={mae_pf}
          />
          <TitleWithValueCore
            sxStyleTitle={{ fontWeight: "500" }}
            title="Nome do responsável:"
            value={responsavel_pf}
          />
          <TitleWithValueCore
            sxStyleTitle={{ fontWeight: "500" }}
            title="CPF do responsável:"
            value={cpf_responsavel_pf}
          />
          <TitleWithValueCore
            sxStyleTitle={{ fontWeight: "500" }}
            title="Data de nascimento do responsável:"
            value={FormatDateBR(data_nascimento_responsavel_pf)}
          />
        </CardContract>
      )}
    </>
  );
};
