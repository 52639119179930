import { Chip, alpha, Avatar, Typography } from "@mui/material";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { CardCore } from "src/Pages/components/CardCore/CardCore";

export const CardProfileImg = () => {
  const { userPerfil } = useAuthContext();

  return (
    <CardCore
      sxStyle={{
        padding: { xs: "0 0 12px 0", sm: "0 0 32px 0" },
        flexDirection: "column",
        alignItems: "center",
        display: "flex",
        margin: 0,
      }}
    >
      <CardCore
        sxStyle={{
          background: "white",
          width: "100%",
          padding: 0,
          margin: 0,
        }}
      >
        <CardCore
          sxStyle={{
            height: { xs: "100px", md: "150px" },
            padding: 0,
            margin: 0,
            width: "100%",
            background: `linear-gradient(15.46deg, ${alpha(
              "#3980f5",
              0.7
            )} 26.3%, ${alpha("#3980f5", 0.3)})`,
          }}
        />
      </CardCore>

      <Avatar
        sx={{
          bgcolor: "#3980f5",
          height: { xs: 90, md: 110 },
          width: { xs: 90, md: 110 },
          fontSize: { xs: "2rem", sm: "2.5rem" },
          marginTop: "-55px",
          border: "3px solid white",
        }}
        variant="circular"
      >
        {userPerfil?.nome[0].toUpperCase()}
      </Avatar>

      <Typography
        fontWeight="600"
        m="12px"
        sx={{ fontSize: { xs: "14px", sm: "16px", md: "20px" } }}
      >
        {userPerfil?.nome}
      </Typography>

      {(userPerfil?.type_user === "ADM" ||
        userPerfil?.type_user === "ROOT") && (
        <Chip
          color="info"
          label={userPerfil.type_user === "ADM" ? "ADM" : "ROOT"}
          size="small"
          sx={{ maxWidth: "140px" }}
          variant="outlined"
        />
      )}
    </CardCore>
  );
};
