import { IDataTh, ThCoreIndex } from "src/Pages/components";

export const THeadUser = () => {
  const dataTh: IDataTh[] = [
    {
      title: "Ações",
      align: "left",
      width: "40px",
    },
    {
      title: "Nome completo",
      align: "left",
      width: "280px",
      minWidth: "100px",
    },
    {
      title: "Email",
      align: "left",
      width: "220px",
    },
    {
      title: "Filial",
      align: "left",
      width: "120px",
    },
    {
      title: "Perfil de acesso",
      align: "left",
      width: "120px",
    },
    {
      title: "Horário de acesso",
      align: "left",
      width: "120px",
    },
    {
      title: "Ativo",
      tooltip: "Usuário está ativo ?",
      align: "center",
      width: "50px",
    },
  ];

  return <ThCoreIndex dataTh={dataTh} />;
};
