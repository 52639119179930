import { useState } from "react";
import { useContextDashboardBrokerContext } from "src/Contexts/Dashboard/ContextDashboardBroker";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

export const UseFormInstallmentsReceivedPrimary = () => {
  const { handleGetAlert } = useLayoutMainContext();

  const {
    filterInstallmentsReceivedPrimary,
    setDataInstallmentsReceivedPrimary,
  } = useContextDashboardBrokerContext();

  const [loading, setLoading] = useState(false);

  const handleFormInstallmentsReceivedPrimary = async () => {
    if (!filterInstallmentsReceivedPrimary.start_date)
      return handleGetAlert({ message: `Escolha uma data inicial !` });
    if (!filterInstallmentsReceivedPrimary.end_date)
      return handleGetAlert({ message: `Escolha uma data final !` });

    setLoading(true);

    return api
      .post(
        "/dashboard/corretora/confirmedInstallments",
        filterInstallmentsReceivedPrimary
      )
      .then((res) => {
        setDataInstallmentsReceivedPrimary(res.data);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  return { handleSubmit: handleFormInstallmentsReceivedPrimary, loading };
};
