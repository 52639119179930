import { makeStyles } from "@mui/styles";

export const useStylesRichText = makeStyles(() => ({
  toolbar: {
    backgroundColor: "#f7f7f7",
    borderRadius: ".6rem",

    "& .ql-toolbar ": {
      border: "2px solid gray",
      borderRadius: ".6rem .6rem 0 0 ",
      borderBottom: "1px solid #ccc",
    },
    "& .ql-container ": {
      border: "2px solid gray",
      borderRadius: " 0 0 .6rem .6rem",
      minHeight: "150px",
    },
    "& .ql-editor ": {
      color: "black",
    },
  },
}));
