import { CircularProgress } from "@mui/material";
import { ButtonProps } from "@mui/material/Button";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";

type IButtonLoginAndSearch = ButtonProps & {
  title: string;
};

export const ButtonLoginAndSearch = ({
  title,
  ...props
}: IButtonLoginAndSearch) => {
  const { loadingLogin } = useAuthContext();

  return (
    <ButtonCore
      {...props}
      fullWidth
      disabled={loadingLogin}
      title={title}
      type="submit"
    >
      {loadingLogin && (
        <CircularProgress size={20} sx={{ color: "white", ml: 4 }} />
      )}
    </ButtonCore>
  );
};
