import { useContextReportsProduction } from "src/Contexts/ReportProduction/ReportsProductionContextContext";
import { AutoCompleteGenericMultiple } from "src/Pages/components/InputAutoCompleted/AutoCompleteGenericMultiple";
import { IPropsAutoCompleted } from "src/Pages/components/InputAutoCompleted/types";

export const SelectedOperadorasGeneric = (props: IPropsAutoCompleted) => {
  const {
    label,
    width,
    tamanho,
    minWidth,
    maxWidth,
    fullWidth,
    disabled,
    variant,
  } = props;

  const { valueAutoCompOperadoras, setValueAutoCompOperadoras } =
    useContextReportsProduction();

  return (
    <AutoCompleteGenericMultiple
      openInClick
      disabled={disabled}
      fullWidth={fullWidth}
      label={label}
      maxWidth={maxWidth}
      minWidth={minWidth}
      setValueAutoCompleted={setValueAutoCompOperadoras}
      tamanho={tamanho}
      url="/comissoes/operadoraList"
      valueAutoCompleted={valueAutoCompOperadoras}
      variant={variant}
      width={width}
    />
  );
};
