import { FC } from "react";
import { Box, Skeleton, Typography } from "@mui/material";
import { CardCore } from "src/Pages/components/CardCore/CardCore";

interface ICardsStatics {
  loading?: boolean;
  data: {
    value: string;
    name: string;
    icon: string;
    bgColor?: string;
  };
}

export const CardsStatics: FC<ICardsStatics> = ({ data, loading }) => {
  const { bgColor, icon, value, name } = data;

  return (
    <CardCore
      sxStyle={{
        backgroundColor: bgColor,
        margin: 0,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          alt="icon"
          component="img"
          src={icon}
          sx={{
            width: { xs: "40px", sm: "60px" },
            height: { xs: "40px", sm: "60px" },
            mr: { xs: 2, sm: 3 },
          }}
        />

        <Box sx={{ width: "calc(100% - 70px)" }}>
          <Typography
            color="inherit"
            component="h5"
            sx={{
              fontSize: 16,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: "100%",
            }}
            variant="inherit"
          >
            {loading ? (
              <Skeleton
                height="20px"
                sx={{ borderRadius: "12px" }}
                variant="rounded"
                width="20px"
              />
            ) : (
              value
            )}
          </Typography>

          <Box
            sx={{
              pt: 0.5,
              color: "text.secondary",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: "100%",
            }}
          >
            {name}
          </Box>
        </Box>
      </Box>
    </CardCore>
  );
};
