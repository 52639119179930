import { Children, ReactNode, SetStateAction, Dispatch } from "react";
import { ListItemButton, ListItemText, Collapse, Box } from "@mui/material";
import { IViews } from "src/Contexts/types/ProdutoresContext.Types";
import { IconExpandIsOpen } from "src/MainLayout/Drawers/aside/componentes/IconExpandIsOpen/IconExpandIsOpen";

import { AsideMenuLeft } from "../AsideMenuLeft";

export const handleVerifyAnyIsTrue = (valueButtons: IViews[]) => {
  return valueButtons.some((element) => element.isView === true);
};

interface IBaseMenuAside {
  setOpen: Dispatch<SetStateAction<boolean>>;
  Navigation: IViews[];
  icon: ReactNode;
  title: string;
  open: boolean;
}

export const BaseMenuAside = ({
  Navigation,
  setOpen,
  title,
  open,
  icon,
}: IBaseMenuAside) => {
  return (
    <>
      {handleVerifyAnyIsTrue(Navigation) && (
        <ListItemButton onClick={() => setOpen(!open)}>
          <Box sx={{ color: "#3b3b3b", marginRight: "12px" }}>{icon}</Box>

          <ListItemText primary={title} sx={{ color: "#3b3b3b" }} />
          <IconExpandIsOpen IsOpen={open} />
        </ListItemButton>
      )}

      <Collapse unmountOnExit in={open} timeout="auto">
        {Children.toArray(
          Navigation.map((buttons) => {
            return (
              buttons.isView && (
                <AsideMenuLeft title={buttons.title} to={buttons.to} />
              )
            );
          })
        )}
      </Collapse>
    </>
  );
};
