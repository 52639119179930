import { Avatar, Box } from "@mui/material";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { CardCore } from "src/Pages/components/CardCore/CardCore";

export const HeaderHome = () => {
  const { userPerfil } = useAuthContext();

  return (
    <CardCore
      sxStyle={{
        display: "flex",
        alignItems: "center",

        margin: 0,
        marginBottom: "16px",

        borderBottom: "3px solid #9b9b9bd3",
      }}
    >
      <Avatar
        sx={{
          bgcolor: "#3980f5",
          fontSize: { xs: "1rem", sm: "1.5rem" },
          marginRight: { xs: "12px", sm: "16px" },
          width: { xs: 40, sm: 55 },
          height: { xs: 40, sm: 55 },
        }}
      >
        {userPerfil?.nome[0]?.toUpperCase()}
      </Avatar>

      <Box
        sx={{
          fontWeight: "500",
          letterSpacing: "0.5px",
          fontSize: { sm: "14px", md: "16px" },
          marginBottom: "4px",
        }}
      >
        Bem-vindo, {userPerfil?.nome}
      </Box>
    </CardCore>
  );
};
