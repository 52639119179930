import { useState } from "react";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

interface IHandleUpdateStatusContratos {
  cod_status: string;
  valueDescription: string;
  setOpenModalCancel: React.Dispatch<React.SetStateAction<boolean>>;
  date: string;
}

export const UseFormCancelContratos = ({
  date,
  cod_status,
  valueDescription,
  setOpenModalCancel,
}: IHandleUpdateStatusContratos) => {
  const { setAttTable, id } = useConfigPageContext();

  const { handleGetAlert } = useLayoutMainContext();

  const [loading, setLoading] = useState(false);

  const handleUpdateStatusContratos = () => {
    setLoading(true);

    api
      .patch(`/contratos/updateStatus/${id}`, {
        cod_status,
        description: valueDescription,
        date,
      })
      .then((res) => {
        handleGetAlert({ message: res.data.message });
        setAttTable((item) => !item);
        setOpenModalCancel(false);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  return {
    handleUpdateStatusContratos,
    loading,
  };
};
