import { ChangeEvent, useEffect } from "react";
import { Grid } from "@mui/material";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextProdutor } from "src/Contexts/produtores/ProdutoresContext";
import { ICreateImpostoGlobal } from "src/Contexts/produtores/ProdutoresTypes";
import { AppTextField } from "src/Pages/components/AppFormComponents/AppTextField";
import { FormatMoney } from "src/shared/Utils/FormatMoney";

export const FormImpostoGlobal = () => {
  const { setActive_incluir } = useConfigPageContext();

  const { valuesInputsImpostoGlobal, setValuesInputsImpostoGlobal } =
    useContextProdutor();

  useEffect(() => setActive_incluir(true), []);

  const handleChange =
    (prop: keyof ICreateImpostoGlobal, option?: "money") =>
    (event: ChangeEvent<HTMLInputElement>) => {
      if (option === "money") {
        if (event.target.value.length <= 6) {
          setValuesInputsImpostoGlobal({
            ...valuesInputsImpostoGlobal,
            [prop]: FormatMoney(event.target.value),
          });
        }
      } else {
        setValuesInputsImpostoGlobal({
          ...valuesInputsImpostoGlobal,
          [prop]: event.target.value,
        });
      }
    };

  return (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <AppTextField
          fullWidth
          label="Descrição *"
          sxStyle={{ opacity: "", pointerEvents: "auto", margin: 0 }}
          value={valuesInputsImpostoGlobal?.descricao}
          variant="outlined"
          onChange={handleChange("descricao")}
        />
      </Grid>
      <Grid item xs={3}>
        <AppTextField
          fullWidth
          label="Valor"
          sxStyle={{ opacity: "", pointerEvents: "auto", margin: 0 }}
          value={valuesInputsImpostoGlobal?.valor}
          variant="outlined"
          onChange={handleChange("valor", "money")}
        />
      </Grid>
    </Grid>
  );
};
