import { Dispatch, SetStateAction, useState } from "react";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { IValueComissaoModal } from "src/Pages/TelasSistemas/2-Comissoes/4-ComissaoCorretora/components/ModalComissaoCorretora/Index";
import { api } from "src/shared/setup/API/api";

interface IUseGetChangeCompany {
  id: string;
  setDataCommission: Dispatch<SetStateAction<IValueComissaoModal | undefined>>;
}

export const UseGetComissaoProdutor = () => {
  const { handleGetAlert } = useLayoutMainContext();

  const [loading, setLoading] = useState(false);

  const handleGetChangeCompany = async ({
    id,
    setDataCommission,
  }: IUseGetChangeCompany) => {
    setLoading(true);

    await api
      .get(`/comissoes/getProdutorByIdViewModal/${id}`)
      .then((res) => {
        setDataCommission(res.data);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    loading,
    handleGet: handleGetChangeCompany,
  };
};
