import { useState } from "react";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";
import { handleFormatToNumeric } from "src/shared/Utils/FormatMoney";

interface IUseFormReceberParcela {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UseFormReceberParcela = ({
  setOpenModal,
}: IUseFormReceberParcela) => {
  const { setAttTable, id } = useConfigPageContext();

  const [loading, setLoading] = useState(false);

  const { valuesInputsBorderoParcela } = useContextContrato();
  const { handleGetAlert } = useLayoutMainContext();

  const handleEdit = () => {
    if (!valuesInputsBorderoParcela.valor_recebido)
      return handleGetAlert({ message: `Digite um valor para receber` });
    if (!valuesInputsBorderoParcela.data_recebimento)
      return handleGetAlert({ message: `Digite uma data de recebimento` });

    setLoading(true);
    const AllDatas = {
      id: valuesInputsBorderoParcela.id,
      data_recebimento: valuesInputsBorderoParcela.data_recebimento,
      data_previsao: valuesInputsBorderoParcela.data_previsao,
      contratos_vidas: valuesInputsBorderoParcela.contratos_vidas,
      observacao: valuesInputsBorderoParcela.observacao,
      valor_recebido: handleFormatToNumeric(
        valuesInputsBorderoParcela.valor_recebido
      ),
    };

    return api
      .patch(`/bordero/receberParcela/${id}`, AllDatas)
      .then((res) => {
        setOpenModal(false);
        setAttTable((item) => !item);
        handleGetAlert({ message: res.data.message });
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  return { handleSubmit: handleEdit, loading };
};
