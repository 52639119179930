import { formatString } from "@format-string/core";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { useContextProdutor } from "src/Contexts/produtores/ProdutoresContext";
import { UseAutoCompletedAll } from "src/Contexts/Utils/UseAutoCompletedAll";
import { api } from "src/shared/setup/API/api";
import { handleFormatCep } from "src/shared/Utils/FormatCEP";
import { FormatRG } from "src/shared/Utils/FormatRG/FormatRG";
import { handleFormatDateIfIsNull } from "src/shared/Utils/handleFormatDateIfIsNull";

export const UseGetByIdProducer = () => {
  const { setValuesInputsProdutor } = useContextProdutor();
  const { handleGetAlert } = useLayoutMainContext();

  const {
    setValueAutoCompletedFiliais,
    setValueAutoCompGrupoImposto,
    setValueAutoCompSupervisor,
    setValueAutoCompGerente,
  } = UseAutoCompletedAll();

  const handleGetById = async (id: string) => {
    await api
      .post(`/getProdutoresById/${id}`)
      .then(({ data }) => {
        setValuesInputsProdutor({
          id: data?.id,
          agencia: data?.agencia,
          bairro: data?.bairro,
          banco: data?.banco,

          cep: handleFormatCep(data?.cep || ""),
          cidade: data?.cidade,
          conta: data?.conta,
          cpf_cnpj:
            data.fisico_juridico === "juridica"
              ? formatString({ value: data?.cpf_cnpj, type: "cnpj" })
              : formatString({ value: data?.cpf_cnpj, type: "cpf" }),

          endereco: data?.endereco,
          data_nasc: handleFormatDateIfIsNull(data?.data_nasc),
          entrada: handleFormatDateIfIsNull(data?.entrada),
          estado: data?.estado,
          favorecido: data?.favorecido,
          fisico_juridico: data?.fisico_juridico,
          funcao_produtor: data?.funcao_produtor,
          gerente: data?.gerente,
          id_filial: data?.id_filial,
          id_permissao: data?.id_permissao,
          id_tenant: data?.id_tenant,
          nome: data?.nome,
          observacoes: data?.observacoes,
          type_user: data?.type_user,
          pix: data?.pix,
          rg: FormatRG(data.rg),

          email_contato: data?.email_contato,

          telefone1: formatString({ type: "phone", value: data.telefone1 }),
          telefone2: formatString({ type: "phone", value: data.telefone2 }),
          saida: handleFormatDateIfIsNull(data?.saida),
          supervisor: data?.supervisor,

          tipo_produtor: data?.tipo_produtor,
        });
        setValueAutoCompGerente({
          label: data?.nome_gerente,
          id: data?.id_gerente,
        });
        setValueAutoCompSupervisor({
          label: data?.nome_supervisor,
          id: data?.id_supervisor,
        });
        setValueAutoCompletedFiliais({
          label: data?.filial,
          id: data?.id_filial,
        });
        setValueAutoCompGrupoImposto({
          label: data?.descricao_grupo_imposto,
          id: data?.id_grupo_imposto,
        });
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      );
  };

  return { handleGetById };
};
