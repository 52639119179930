import { FormularioComissaoProdutor } from "./components/Form/FormularioComissaoProdutor";
import { TableComissaoProdutor } from "./components/table/TableComissaoProdutor";

export const PageComissaoProdutor = () => {
  return (
    <>
      <FormularioComissaoProdutor />

      <TableComissaoProdutor />
    </>
  );
};
