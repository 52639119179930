import { FC, ReactNode } from "react";
import { Page } from "@react-pdf/renderer";

type IPageCorePDF = {
  padding?: string;
  orientation?: "landscape" | "portrait";
  children: ReactNode;
};

export const PageCorePDF: FC<IPageCorePDF> = ({
  children,
  padding,
  orientation = "portrait",
}) => {
  return (
    <Page
      id="123"
      orientation={orientation}
      size="A4"
      style={{
        flexDirection: "column",
        backgroundColor: "#FFF",
        padding: padding || "24px 12px 12px 12px",
      }}
    >
      {children}
    </Page>
  );
};
