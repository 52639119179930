import { FC } from "react";
import { alpha, Button } from "@mui/material";
import { ButtonProps } from "@mui/material/Button";

type IButtonCore = ButtonProps & {
  title?: string | undefined;
};

export const ButtonCore: FC<IButtonCore> = ({
  title,
  onClick,
  ...props
}: IButtonCore) => {
  return (
    <Button
      variant="contained" // comentar
      onClick={onClick} // comentar
      {...props}
      sx={{
        color: "#eeeeee", // comentar
        fontSize: {
          xs: "10px",
          sm: "11px",
          md: "12px",
        },
        padding: {
          xs: "6px 8px",
          sm: "6px 10px",
          md: "6px 14px",
        },
        margin: "4px",
        letterSpacing: ".8px",
        "&:hover": {
          // comentar
          backgroundColor: (theme) =>
            alpha(theme.palette.primary.main as string, 0.8),
        },
        ...props.sx,
      }}
    >
      {title}
    </Button>
  );
};
