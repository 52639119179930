import { IDataTh, ThCoreIndex } from "src/Pages/components";

export const THeadLogs = () => {
  const dataTh: IDataTh[] = [
    {
      title: "Usuário/Produtor",
      align: "left",
      width: "250px",
      minWidth: "100px",
    },
    {
      title: "Tipo usuário",
      width: "150px",
      minWidth: "150px",
      align: "left",
    },
    {
      title: "Data - hora",
      width: "150px",
      minWidth: "150px",
      align: "left",
    },
    {
      title: "Ação",
      width: "200px",
      minWidth: "200px",
      align: "left",
    },

    {
      title: "Dados",
      width: "200px",
      minWidth: "200px",
      align: "left",
    },
  ];

  return <ThCoreIndex dataTh={dataTh} />;
};
