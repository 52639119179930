import { Children, useEffect } from "react";
import { Box, LinearProgress } from "@mui/material";
import { PDFViewer, Document } from "@react-pdf/renderer";
import { IRecibos } from "src/Contexts/Recibos/RecibosTypes";
import { PDFAssinatura } from "src/Pages/components/PDF/Assinatura";
import { PDFCabeçalho } from "src/Pages/components/PDF/Cabeçalho";
import { PageCorePDF } from "src/Pages/components/PDF/PageCorePDF";
import { TableBase } from "src/Pages/components/PDF/TableBase";
import { PDFTableRow } from "src/Pages/components/PDF/TableRow";
import { TableRowContainer } from "src/Pages/components/PDF/TableRowContainer";
import { TitleProdutor } from "src/Pages/components/PDF/TitleProdutor";
import { FormatDateBR, GetDateUSSemValue } from "src/shared/Utils/FormatDateBR";
import { FormatMoney } from "src/shared/Utils/FormatMoney";
import { handleIfLimitCharacter } from "src/shared/Utils/LimitText";

import { HeadPDFConfirmationReceipt } from "./HeadPDFConfirmationReceipt";
import { HeadPDFConfirmationReceiptResume } from "./HeadPDFConfirmationReceiptResume";

interface IConfirmationReceipt {
  nomeEmpresa?: string | undefined;
  isLoading: boolean;
  listRecibos: IRecibos | undefined;
  setListRecibos: React.Dispatch<React.SetStateAction<IRecibos | undefined>>;
  title: string;
}

export const PDFConfirmationReceipt = ({
  setListRecibos,
  isLoading,
  nomeEmpresa,
  listRecibos,
  title,
}: IConfirmationReceipt) => {
  const payments = listRecibos?.payments;

  useEffect(() => setListRecibos(undefined), []);

  return (
    <>
      {nomeEmpresa && !!payments?.length && listRecibos?.payments?.length && (
        <PDFViewer style={{ height: "84vh" }} width="100%">
          <Document
            creator="Brokeris"
            title={
              listRecibos.meta.typePayments === "ALL"
                ? title
                : `${listRecibos?.payments[0].codeProdutor} - ${
                    listRecibos?.payments[0].produtor
                  } - ${FormatDateBR(
                    listRecibos.payments[0].dateCurrency.date
                  )}`
            }
          >
            {Children.toArray(
              listRecibos.payments.map((item) => {
                return Children.toArray(
                  <PageCorePDF orientation="landscape">
                    <PDFCabeçalho
                      dateCurrency={FormatDateBR(item.dateCurrency.date)}
                      empresa=""
                      endDate={FormatDateBR(item.resume.dataPayments.endDate)}
                      hoursCurrency={item.dateCurrency.hours}
                      nomePdf="Recibos das confirmações"
                      startDate={FormatDateBR(
                        item.resume.dataPayments.startDate
                      )}
                    />

                    <TitleProdutor
                      code={item.codeProdutor}
                      code_superior={item?.superior?.code}
                      function_producer={listRecibos.meta?.funcao_produtor}
                      name_superior={item?.superior?.name}
                      operadora={
                        item.resume.operadoras.length
                          ? `(${item.resume.operadoras
                              .map((item) => `${item}`)
                              .join(", ")
                              .toString()})`
                          : "Todas"
                      }
                      produtor={item.produtor}
                    />

                    <TableBase>
                      <HeadPDFConfirmationReceipt />

                      {Children.toArray(
                        item.parcelas.map((item) => {
                          return (
                            <TableRowContainer key={item.parcela.codProposta}>
                              <PDFTableRow
                                paddingLeft="8px"
                                title={item.parcela.planoNome}
                                width="15%"
                              />
                              <PDFTableRow
                                paddingLeft="8px"
                                title={item.parcela.titular}
                                width="32%"
                              />
                              <PDFTableRow
                                title={item.parcela.codProposta}
                                width="12%"
                              />
                              <PDFTableRow
                                textAlign="right"
                                title={FormatDateBR(
                                  item.parcela.dataAssinatura
                                )}
                                width="14%"
                              />
                              <PDFTableRow
                                textAlign="center"
                                title={item.parcela.numeroParcela}
                                width="7%"
                              />
                              <PDFTableRow
                                textAlign="right"
                                title={FormatDateBR(
                                  item.parcela.dataRecebimento
                                )}
                                width="13%"
                              />
                              <PDFTableRow
                                textAlign="right"
                                title={`R$ ${FormatMoney(
                                  item.parcela.valorMensalidade
                                )}`}
                                width="14%"
                              />
                              <PDFTableRow
                                textAlign="right"
                                title={item.parcela.porcRecebida}
                                width="7%"
                              />
                              <PDFTableRow
                                paddingRight="8px"
                                textAlign="right"
                                title={`R$ ${FormatMoney(
                                  item.parcela.valorPagar
                                )}`}
                                width="14%"
                              />
                            </TableRowContainer>
                          );
                        })
                      )}
                      <TableRowContainer backgroundColor="#e6e6e6">
                        <PDFTableRow
                          paddingLeft="8px"
                          title="Total"
                          width="60%"
                        />
                        <PDFTableRow
                          textAlign="center"
                          title={item.resume.totalQtdParcelas}
                          width="6%"
                        />
                        <PDFTableRow
                          textAlign="right"
                          title={`R$ ${FormatMoney(
                            item.resume.totalValorParcelas?.toFixed(2)
                          )}`}
                          width="10.4%"
                        />
                        <PDFTableRow
                          paddingRight="8px"
                          textAlign="right"
                          title={`R$ ${FormatMoney(
                            item.resume.totalComissao?.toFixed(2)
                          )}`}
                          width="28.5%"
                        />
                      </TableRowContainer>
                    </TableBase>

                    <TableBase maxWidth="40%">
                      <HeadPDFConfirmationReceiptResume />
                      {/*
                      {Children.toArray(
                        item.resume.parcelas.map((item) => {
                          return (
                            <TableRowContainer>
                              <PDFTableRow
                                title={item.title}
                                width="70%"
                                textAlign="left"
                                paddingLeft="8px"
                              />

                              <PDFTableRow
                                title={FormatMoney(item.valor?.toFixed(2))}
                                width="30%"
                                textAlign="right"
                                paddingRight="8px"
                              />
                            </TableRowContainer>
                          );
                        })
                      )} */}

                      <TableRowContainer backgroundColor="#e6e6e6">
                        <PDFTableRow
                          paddingLeft="8px"
                          textAlign="left"
                          title="Total das parcelas"
                          width="70%"
                        />

                        <PDFTableRow
                          paddingRight="8px"
                          textAlign="right"
                          title={`R$ ${FormatMoney(
                            item.resume.totalComissao?.toFixed(2)
                          )}`}
                          width="32%"
                        />
                      </TableRowContainer>
                      {/*
                      {Children.toArray(
                        [
                          item.resume.globalImposto,
                          item.resume.grupoImposto,
                        ].map((item) => {
                          return (
                            item !== null && (
                              <TableRowContainer>
                                <PDFTableRow
                                  title={item.name}
                                  width="70%"
                                  textAlign="left"
                                  paddingLeft="8px"
                                />

                                <PDFTableRow
                                  title={`${item.porc}%`}
                                  width="30%"
                                  textAlign="right"
                                  paddingRight="8px"
                                />
                              </TableRowContainer>
                            )
                          );
                        })
                      )} */}
                      {(item.resume.globalImposto === null &&
                        item.resume.grupoImposto === null) || (
                        <>
                          <TableRowContainer backgroundColor="#e6e6e6">
                            <PDFTableRow
                              paddingLeft="8px"
                              textAlign="left"
                              title="Total de taxas"
                              width="70%"
                            />

                            <PDFTableRow
                              paddingRight="8px"
                              textAlign="right"
                              title={`${item.resume.totalPorcDescontos}%`}
                              width="32%"
                            />
                          </TableRowContainer>
                        </>
                      )}

                      {Children.toArray(
                        item.resume.contaCorrente.map((item) => {
                          return (
                            item !== null && (
                              <TableRowContainer>
                                <PDFTableRow
                                  paddingLeft="8px"
                                  textAlign="left"
                                  title={handleIfLimitCharacter({
                                    LIMIT: 50,
                                    value: item.title,
                                  })}
                                  width="70%"
                                />

                                <PDFTableRow
                                  paddingRight="8px"
                                  textAlign="right"
                                  title={
                                    item.typeTransaction === "credito"
                                      ? `${FormatMoney(item.valor?.toFixed(2))}`
                                      : `-${FormatMoney(
                                          item.valor?.toFixed(2)
                                        )}`
                                  }
                                  width="30%"
                                />
                              </TableRowContainer>
                            )
                          );
                        })
                      )}
                      {item.resume.contaCorrente.length && (
                        <TableRowContainer backgroundColor="#e6e6e6">
                          <PDFTableRow
                            paddingLeft="8px"
                            textAlign="left"
                            title="Saldo conta corrente"
                            width="70%"
                          />

                          <PDFTableRow
                            paddingRight="8px"
                            textAlign="right"
                            title={
                              item.resume.totalContaCorrente < 0
                                ? `- ${FormatMoney(
                                    item.resume.totalContaCorrente?.toFixed(2)
                                  )}`
                                : `${FormatMoney(
                                    item.resume.totalContaCorrente?.toFixed(2)
                                  )}`
                            }
                            width="30%"
                          />
                        </TableRowContainer>
                      )}
                    </TableBase>

                    <TableBase maxWidth="40%">
                      <TableRowContainer backgroundColor="#e6e6e6">
                        <PDFTableRow
                          paddingLeft="8px"
                          textAlign="left"
                          title="Total a receber"
                          width="70%"
                        />

                        <PDFTableRow
                          paddingRight="8px"
                          textAlign="right"
                          title={
                            item.resume.totalAReceber < 0
                              ? `-${FormatMoney(
                                  item.resume.totalAReceber?.toFixed(2)
                                )}`
                              : `${FormatMoney(
                                  item.resume.totalAReceber?.toFixed(2)
                                )}`
                          }
                          width="30%"
                        />
                      </TableRowContainer>
                    </TableBase>

                    <PDFAssinatura
                      contaBancaria={item.contaBancaria}
                      date={GetDateUSSemValue()}
                      nameProdutor={item.produtor}
                      sentenceInFull={item.resume.sentenceInFull}
                      cpf_cnpj={item.produtores_cpf_cnpj}
                      fisico_juridico={item.produtores_fisico_juridico}
                    />
                  </PageCorePDF>
                );
              })
            )}
          </Document>
        </PDFViewer>
      )}

      {isLoading && (
        <Box style={{ width: "100%" }}>
          <LinearProgress color="inherit" />
        </Box>
      )}
    </>
  );
};
