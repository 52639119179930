import { StyleSheet } from "@react-pdf/renderer";

export const stylesPDFITitleProdutor = StyleSheet.create({
  container: {
    padding: "6px 0",
    borderBottom: "1px solid black",
    fontSize: "12px",
  },

  titleNomePdf: {
    marginBottom: "4px",
  },
  titleOperadorasPdf: {
    fontSize: "10px",
  },
});
