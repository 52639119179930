import { useContextComissoes } from "src/Contexts/comissoes/ComissoesContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { UseAutoCompletedAll } from "src/Contexts/Utils/UseAutoCompletedAll";
import { api } from "src/shared/setup/API/api";
import { handleFormatDateIfIsNull } from "src/shared/Utils/handleFormatDateIfIsNull";

export const UseGetByIdComissaoProdutor = () => {
  const { setValuesInputsComissaoProdutor } = useContextComissoes();
  const { handleGetAlert } = useLayoutMainContext();

  const {
    setValueAutoCompProdutorActive,
    setValueAutoCompAdministradora,
    setValueAutoCompModalidade,
    setValueAutoCompOperadora,
  } = UseAutoCompletedAll();

  const handleGetById = async (id: string) => {
    await api
      .get(`/comissoes/getProdutor/${id}`)
      .then(({ data }) => {
        setValuesInputsComissaoProdutor({
          name: data.name,
          date_vigencia: handleFormatDateIfIsNull(data.date_vigencia),
          parc_1: data.parc_1 || "",
          parc_2: data.parc_2 || "",
          parc_3: data.parc_3 || "",
          parc_4: data.parc_4 || "",
          parc_5: data.parc_5 || "",
          parc_6: data.parc_6 || "",
          parc_7: data.parc_7 || "",
          parc_8: data.parc_8 || "",
          parc_9: data.parc_9 || "",
          parc_10: data.parc_10 || "",
          parc_11: data.parc_11 || "",
          parc_12: data.parc_12 || "",
          parc_13: data.parc_13 || "",
          parc_14: data.parc_14 || "",
          parc_15: data.parc_15 || "",
          parc_16: data.parc_16 || "",

          funcao_produtor: data?.funcao_produtor,
          tipo_produtor: data?.tipo_produtor,

          is_special: data.is_special === true ? "true" : "false",
        });

        setValueAutoCompAdministradora({
          id: data.administradora_code,
          label: data.administradora_name,
        });
        setValueAutoCompOperadora({
          label: data.operadora_name,
          id: data.operadora_code,
        });
        setValueAutoCompModalidade({
          label: data.modalidade_name,
          id: data.modalidade_code,
        });
        setValueAutoCompProdutorActive({
          label: data.produtor_nome,
          id: data.produtor_code,
        });
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      );
  };

  return { handleGetById };
};
