import { createRef } from "react";
import { FormCore, ButtonsFeaturesFormularioCore } from "src/Pages/components";
import { UseVerifyIsPermissionsUser } from "src/Pages/components/hooks/UseVerifyIsPermissionsUser";
import { valuesPermissions } from "src/shared/Utils/ValuesPermissions";

import { FormContato } from "./FormContato";
import { FormContrato } from "./FormContrato";
import { FormDados } from "./FormDados";
import { FormTitular } from "./FormTitular";
import { UseFeatFormContratos } from "./Hooks/UseFeaturesFormulario";

export const FormularioContrato = () => {
  const { handleVerifyPermissionItem } = UseVerifyIsPermissionsUser();
  const { handleSubmit } = UseFeatFormContratos();

  const inputRef = createRef<HTMLInputElement>();
  const handleFocusedID = () => inputRef.current?.focus();

  return (
    <FormCore>
      <ButtonsFeaturesFormularioCore
        disabledEditar={handleVerifyPermissionItem(
          valuesPermissions.CONTRATO.EDITAR.id
        )}
        disabledIncluir={handleVerifyPermissionItem(
          valuesPermissions.CONTRATO.CREATE.id
        )}
        handleFocusedID={handleFocusedID}
        handleSubmit={handleSubmit}
      />

      <FormContrato inputRef={inputRef} />
      <FormTitular />
      <FormContato />
      <FormDados />
    </FormCore>
  );
};
