import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";
import { CardHeaderCore } from "src/Pages/components/CardCore/Components/CardHeaderCore";
import { CreditScoreIcon } from "src/Pages/components/Icons/Icons";
import { api } from "src/shared/setup/API/api";
import AddIcon from "@mui/icons-material/Add";
import { handleRedirect } from "../PagePlans/Components/CardPlan/CardPlan";

export const CardHeaderPlans = () => {
  const { userPerfil } = useAuthContext();

  const handleClick = () => {
    api
      .post("/checkoutPayment/checkout", {
        qtdUser: "10",
      })
      .then((data) => handleRedirect(data.data.url));
  };

  return (
    <CardHeaderCore
      icon={<CreditScoreIcon />}
      subTitle="Aqui você gerencia seus pagamentos, troca de plano, histórico de pagamentos."
      sxStyle={{ margin: 0, marginBottom: "24px" }}
      title="Planos"
      buttonAdd={[
        <ButtonCore
          key={1}
          startIcon={<AddIcon />}
          title={
            userPerfil?.checkout.id_stripe_customer
              ? "Gerenciar plano"
              : "Contratar plano"
          }
          onClick={handleClick}
        />,
      ]}
    />
  );
};
