import { useContextDashboardBrokerContext } from "src/Contexts/Dashboard/ContextDashboardBroker";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

export const useTableAdministratorsDashboard = () => {
  const { handleGetAlert } = useLayoutMainContext();
  const { setDataConfirmationAdministrators } =
    useContextDashboardBrokerContext();

  const handleGet = async () => {
    api
      .post("/dashboard/corretora/confirmationAdministrators")
      .then(({ data }) => setDataConfirmationAdministrators(data))
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      );
  };

  return { handleGet };
};
