import { useContextProdutor } from "src/Contexts/produtores/ProdutoresContext";
import { UseAutoCompletedAll } from "src/Contexts/Utils/UseAutoCompletedAll";
import { AutoCompleteGeneric } from "src/Pages/components/InputAutoCompleted/AutoCompleteGeneric";
import { IPropsAutoCompleted } from "src/Pages/components/InputAutoCompleted/types";

export const AutoCompleteSupervisorAll = (props: IPropsAutoCompleted) => {
  const { disabled, label, width, tamanho, minWidth, maxWidth, fullWidth } =
    props;

  const { valuesInputsProdutor } = useContextProdutor();
  const { setValueAutoCompSupervisor, valueAutoCompSupervisor } =
    UseAutoCompletedAll();

  return (
    <AutoCompleteGeneric
      disabled={
        disabled ||
        valuesInputsProdutor.funcao_produtor === "SUPERVISOR" ||
        valuesInputsProdutor.funcao_produtor === "GERENTE"
      }
      fullWidth={fullWidth}
      label={label}
      maxWidth={maxWidth}
      minWidth={minWidth}
      setValueAutoCompleted={setValueAutoCompSupervisor}
      tamanho={tamanho}
      url="/getAllProdutores?funcao_produtor=SUPERVISOR&is_active_producer=true"
      valueAutoCompleted={valueAutoCompSupervisor}
      width={width}
    />
  );
};
