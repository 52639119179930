import { Box, CircularProgress } from "@mui/material";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";
import { UseVerifyIsPermissionsUser } from "src/Pages/components/hooks/UseVerifyIsPermissionsUser";
import { SendIcon } from "src/Pages/components/Icons/Icons";
import { valuesPermissions } from "src/shared/Utils/ValuesPermissions";

import { UseFeaturesFormCompany } from "./hook/UseFeaturesFormulario";
import { UseGetDataCompany } from "./hook/UseGetByIdData";

export const FormButtonsSubmitCompany = () => {
  const { loadingGravar } = useConfigPageContext();
  const { handleSubmit } = UseFeaturesFormCompany();
  const { handleGetCompanyDetails } = UseGetDataCompany();
  const { handleVerifyPermissionItem } = UseVerifyIsPermissionsUser();

  const isDisabled =
    loadingGravar ||
    handleVerifyPermissionItem(valuesPermissions.COMPANY.EDITAR_DETALHES.id);

  return (
    <Box sx={{ textAlign: "start" }}>
      <ButtonCore
        disabled={isDisabled}
        title="Cancelar"
        onClick={handleGetCompanyDetails}
      />

      <ButtonCore
        disabled={isDisabled}
        endIcon={
          loadingGravar ? (
            <CircularProgress size={15} sx={{ mr: 1, color: "black" }} />
          ) : (
            <SendIcon />
          )
        }
        title="Salvar"
        type="submit"
        onClick={handleSubmit}
      />
    </Box>
  );
};
