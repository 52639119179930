import { useEffect, ReactNode } from "react";
import { Box, CircularProgress } from "@mui/material";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useResetInputs } from "src/Contexts/hooks/useResetInputs";
import { ButtonPersonalized } from "src/Pages/components";

type IButtonsFeaturesProdutores = {
  handleFocusedID: () => void;
  handleSubmit: () => void;

  notEdit?: boolean;
  notIncluir?: boolean;
  children?: ReactNode;
  titleSubmit?: string;
  disabledGravar?: boolean;
  disabledIncluir?: boolean;
  disabledEditar?: boolean;
};

export const ButtonsFeaturesFormularioCore = ({
  handleFocusedID,
  disabledIncluir,
  disabledEditar,
  disabledGravar,
  handleSubmit,
  titleSubmit,
  notIncluir,
  children,
  notEdit,
}: IButtonsFeaturesProdutores) => {
  const { setActive_incluir, active_incluir, loadingGravar, idTable } =
    useConfigPageContext();

  const { resetInputs } = useResetInputs();

  useEffect(() => resetInputs(), []);

  return (
    <Box sx={{ textAlign: "center", margin: "8px 0" }}>
      {children}
      {!notIncluir && (
        <ButtonPersonalized
          disabled={disabledIncluir || !!idTable}
          formNoValidate={active_incluir && !idTable}
          title="Incluir"
          onClick={() => {
            setActive_incluir(true);
            handleFocusedID();
          }}
        />
      )}
      {!notEdit && (
        <ButtonPersonalized
          disabled={disabledEditar || !idTable}
          formNoValidate={!!active_incluir && !!idTable}
          title="Alterar"
          onClick={() => {
            setActive_incluir(true);
            handleFocusedID();
          }}
        />
      )}
      <ButtonPersonalized
        disabled={loadingGravar}
        title="Cancelar"
        onClick={() => {
          resetInputs();
          setActive_incluir(false);
        }}
      />
      <ButtonPersonalized
        disabled={disabledGravar || loadingGravar}
        endIcon={
          loadingGravar && (
            <CircularProgress size={15} sx={{ mr: 1, color: "black" }} />
          )
        }
        title={titleSubmit ?? "Salvar"}
        type="submit"
        onClick={handleSubmit}
      />
    </Box>
  );
};
