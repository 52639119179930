import { useEffect } from "react";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { useContextRecoverPassword } from "src/Contexts/RecoverPassword/RecoverPasswordContext";

import { BasePageLogin } from "../components/BasePageLogin";
import { ButtonLoginAndSearch } from "../components/ButtonLoginAndSearch";

import { FooterFom } from "./components/FooterFom";
import { FormLogin } from "./components/FormLogin";
import { HeaderLogin } from "./components/HeaderLogin";
import { InputSelectEmpresa } from "./components/InputSelect";

export const PageLogin = () => {
  const {
    handleLogin,
    inputValueSelect,
    valuesInputsLogins,
    valuesAutoComplete,
    handleSearchCompany,
  } = useAuthContext();

  const { setInputValueSelect, setGetCompaniesRecoverPassword } =
    useContextRecoverPassword();

  const handleSearchEmpresa = async () => {
    await handleSearchCompany({
      email: valuesInputsLogins.email,
      senha: valuesInputsLogins.senha,
      typeUser: "USER",
    });
  };

  const handleChangeLogin = async () => {
    await handleLogin({
      email: valuesInputsLogins.email,
      senha: valuesInputsLogins.senha,
      typeUser: "USER",
      tenant: inputValueSelect,
    });
  };

  useEffect(() => {
    if (valuesAutoComplete.length === 1) {
      handleLogin({
        email: valuesInputsLogins.email,
        senha: valuesInputsLogins.senha,
        tenant: valuesAutoComplete[0].label,
        typeUser: "USER",
      });
    }
  }, [valuesAutoComplete]);

  useEffect(() => {
    setInputValueSelect("");
    setGetCompaniesRecoverPassword([]);
  }, [valuesAutoComplete]);

  return (
    <BasePageLogin
      sxStyle={{
        width: { xs: "340px", sm: "400px", md: "435px", lg: "500px" },
      }}
    >
      <HeaderLogin />

      {valuesAutoComplete.length <= 1 && <FormLogin />}

      {valuesAutoComplete.length > 1 && (
        <InputSelectEmpresa values={valuesAutoComplete} />
      )}

      <FooterFom />

      {valuesAutoComplete.length <= 1 ? (
        <ButtonLoginAndSearch title="Login" onClick={handleSearchEmpresa} />
      ) : (
        <ButtonLoginAndSearch title="Login" onClick={handleChangeLogin} />
      )}
    </BasePageLogin>
  );
};
