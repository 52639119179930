import { ChangeEvent, createRef, useEffect } from "react";
import { useContextUsuarios } from "src/Contexts/Usuarios/UsuariosContext";
import { ICreateFiliais } from "src/Contexts/Usuarios/UsuariosTypes";
import { AppTextField } from "src/Pages/components";

export const FormFiliais = () => {
  const { setValuesInputsFiliais, valuesInputsFiliais } = useContextUsuarios();

  const Filial = createRef<HTMLInputElement>();
  const handleFocusedID = () => Filial.current?.focus();

  useEffect(() => {
    handleFocusedID();
  }, []);

  const handleChange =
    (prop: keyof ICreateFiliais) => (event: ChangeEvent<HTMLInputElement>) => {
      setValuesInputsFiliais({
        ...valuesInputsFiliais,
        [prop]: event.target.value,
      });
    };

  return (
    <AppTextField
      fullWidth
      inputRef={Filial}
      label="Filial *"
      sxStyle={{ opacity: "", pointerEvents: "auto", margin: 0 }}
      value={valuesInputsFiliais.filial}
      variant="outlined"
      onChange={handleChange("filial")}
    />
  );
};
