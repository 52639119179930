import { IPDFTableHeader } from "src/Pages/components/PDF/TableHeader";
import { PDFTableHeader } from "src/Pages/components/PDF/TableHeader/PDFTableHeader";

export const HeadPDFConfirmationReceipt = () => {
  const dataHeaderPDF: IPDFTableHeader = [
    {
      style: {
        width: "15%",
        textAlign: "left",
        paddingLeft: "8px",
      },
      title: "Plano",
    },
    {
      style: {
        width: "32%",
        textAlign: "left",
        paddingLeft: "8px",
      },
      title: "Titular/Razão",
    },
    {
      style: {
        width: "12%",
        textAlign: "left",
      },
      title: "Contrato",
    },
    {
      style: {
        width: "14%",
        textAlign: "right",
      },
      title: "Data assinatura",
    },
    {
      style: {
        width: "7%",
        textAlign: "center",
      },
      title: "Parcela",
    },
    {
      style: {
        width: "13%",
        textAlign: "right",
      },
      title: "Data pagamento",
    },
    {
      style: {
        width: "14%",
        textAlign: "right",
      },
      title: "Valor devido",
    },
    {
      style: {
        width: "7%",
        textAlign: "right",
      },
      title: "%",
    },
    {
      style: {
        width: "14%",
        textAlign: "right",
        paddingRight: "8px",
      },
      title: "Valor comissão",
    },
  ];

  return <PDFTableHeader data={dataHeaderPDF} />;
};
