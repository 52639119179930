import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { CardCore } from "src/Pages/components/CardCore/CardCore";
import { CardHeaderCore } from "src/Pages/components/CardCore/Components/CardHeaderCore";
import { ComponentPrivate } from "src/Pages/components/ComponentPrivate/ComponentPrivate";
import { PreviewIcon } from "src/Pages/components/Icons/Icons";
import { FormatDateBR } from "src/shared/Utils/FormatDateBR";

import { HeaderHome } from "./components/Header/HeaderHome";
import { HeaderIsPlanTest } from "./components/HeaderIsPlanTest/HeaderIsPlanTest";
import { FormFilterHome } from "./components/HooksFilter/Form";
import { TableRankingCorretores } from "./components/TableRankingCorretores/TableRankingCorretores";
import { Cards } from "./components/WelcomeCard";

export const PageHome = () => {
  const { userPerfil, filterHome } = useAuthContext();

  return (
    <>
      <HeaderHome />

      <HeaderIsPlanTest />

      <ComponentPrivate isView={userPerfil?.type_user !== "PRODUCER"}>
        <CardHeaderCore
          buttonAdd={[<FormFilterHome key={1} />]}
          icon={<PreviewIcon />}
          sxStyle={{ margin: "0 0 16px 0", padding: "12px" }}
          title={`Resumo: ${FormatDateBR(
            filterHome.startDate
          )} até ${FormatDateBR(filterHome.endDate)}`}
        />

        <Cards />

        <TableRankingCorretores />

        <CardCore
          sxStyle={{
            height: { xs: "150px", sm: "200px" },
            margin: "16px 0 ",
          }}
        >
          Tabela da equipe de supervisor
        </CardCore>

        <CardCore
          sxStyle={{
            height: { xs: "150px", sm: "200px" },
            margin: "0",
          }}
        >
          Tabela da equipe de gerente
        </CardCore>
      </ComponentPrivate>
    </>
  );
};
