import { useState } from "react";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";
import { handleFormatToNumeric } from "src/shared/Utils/FormatMoney";

interface IUseFormNovaParcela {
  cod_contrato: string;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UseFormNovaParcela = ({
  cod_contrato,
  setOpenModal,
}: IUseFormNovaParcela) => {
  const { valuesInputNovaParcelaBordero } = useContextContrato();
  const { handleGetAlert } = useLayoutMainContext();
  const { setAttTable } = useConfigPageContext();

  const [loading, setLoading] = useState(false);

  const handleNovaParcela = () => {
    setLoading(true);

    api
      .post(`/bordero/create`, {
        cod_proposta: cod_contrato,

        contratos_mensalidade: handleFormatToNumeric(
          valuesInputNovaParcelaBordero?.contratos_mensalidade
        ),

        data_vencimento_parcela:
          valuesInputNovaParcelaBordero?.data_vencimento_parcela,

        numero_parcela: valuesInputNovaParcelaBordero?.numero_parcela,
        contratos_vidas: valuesInputNovaParcelaBordero?.contratos_vidas,

        porcentagem_comissao_corretora:
          valuesInputNovaParcelaBordero?.porcentagem_comissao_corretora,
        porcentagem_comissao_corretor:
          valuesInputNovaParcelaBordero?.porcentagem_comissao_corretor,
        porcentagem_comissao_gerente:
          valuesInputNovaParcelaBordero?.porcentagem_comissao_gerente,
        porcentagem_comissao_supervisor:
          valuesInputNovaParcelaBordero?.porcentagem_comissao_supervisor,
      })
      .then((res) => {
        setAttTable((item) => !item);
        handleGetAlert({ message: res.data.message });
        setOpenModal(false);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  return { handleNovaParcela, loading };
};
