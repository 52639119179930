import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { CardContract } from "src/Pages/components/CardContract/CardContract";
import { TitleWithValueCore } from "src/Pages/components/TitleWithValueCore/TitleWithValueCore";

export const CardDadosPlano = () => {
  const { valueContractInternal } = useContextContrato();

  if (!valueContractInternal) return null;

  return (
    <>
      <CardContract title="Dados do plano">
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="Tipo de contrato:"
          value={valueContractInternal.tipo_contrato}
        />
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="Produto:"
          value={valueContractInternal.name_modalidades}
        />
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="Operadora:"
          value={valueContractInternal.name_operadoras}
        />
        {valueContractInternal.code_administradoras && (
          <TitleWithValueCore
            sxStyleTitle={{ fontWeight: "500" }}
            title="Administradora:"
            value={valueContractInternal.name_administradoras}
          />
        )}
      </CardContract>

      <CardContract>
        <TitleWithValueCore
          sxStyleTitle={{ fontWeight: "500" }}
          title="Corretor:"
          value={valueContractInternal.nome_produtor}
        />
      </CardContract>
    </>
  );
};
