import { useState } from "react";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

export const UseGetContractById = () => {
  const { handleGetAlert } = useLayoutMainContext();
  const { setValueContractInternal } = useContextContrato();

  const [isLoading, setIsLoading] = useState(false);

  const handleGet = (id: string) => {
    setIsLoading(true);

    api
      .get(`/contratosByid/${id}`)
      .then((result) => {
        setValueContractInternal(result.data);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      );
  };

  return { isLoading, handleGet };
};
