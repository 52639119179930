import { View, Text } from "@react-pdf/renderer";

import { stylesPDFITitleProdutor as styles } from "./style";

interface IPDFTitleProdutor {
  code: number;
  produtor: string;
  operadora: string;

  code_superior?: string;
  name_superior?: string;
  function_producer: "CORRETOR" | "SUPERVISOR" | "GERENTE";
}

export const TitleProdutor = ({
  function_producer,
  name_superior,
  code_superior,
  operadora,
  produtor,
  code,
}: IPDFTitleProdutor) => {
  return (
    <View fixed style={styles.container}>
      <Text style={{ display: "flex", marginBottom: "4px" }}>
        <Text
          style={styles.titleNomePdf}
        >{`Produtor: ${code} - ${produtor}`}</Text>

        {code_superior && (
          <Text style={styles.titleNomePdf}>{` / ${
            function_producer ? "Supervisor" : "Gerente"
          }: ${code_superior} - ${name_superior}`}</Text>
        )}
      </Text>

      <Text style={styles.titleOperadorasPdf}>{`Operadora: ${operadora}`}</Text>
    </View>
  );
};
