import {
  createContext,
  useCallback,
  useContext,
  ReactNode,
  useState,
  useMemo,
  FC,
} from "react";
import { ThemeProvider } from "@mui/material";

import { UseSetColorPrimary } from "./hooks/useSetColorPrymary";

interface IThemeContextData {
  themeName: "light" | "dark";
  toggleTheme: () => void;
}

const ThemeContext = createContext({} as IThemeContextData);

export const AppThemeProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [
    themeName,
    //  setThemeName
  ] = useState<"light" | "dark">(() => {
    // const themeName = localStorage.getItem(getTheme);

    // if (themeName && themeName === "dark") return "dark";

    return "light";
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [LightTheme] = UseSetColorPrimary();

  const toggleTheme = useCallback(() => {
    // setThemeName((oldThemeName) =>
    //   oldThemeName === "light" ? "dark" : "light"
    // );
  }, [themeName]);

  const theme = useMemo(() => {
    if (themeName === "light") {
      // localStorage.setItem(getTheme, "light");
      return LightTheme;
    }
    // localStorage.setItem(getTheme, "dark");

    // return themeDark;
    return LightTheme;
  }, [themeName]);
  console.log(themeName);
  console.log(theme);
  return (
    <ThemeContext.Provider value={{ themeName, toggleTheme }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useAppThemeContext = (): IThemeContextData =>
  useContext(ThemeContext);
