import { useState } from "react";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useResetInputs } from "src/Contexts/hooks/useResetInputs";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { ModalDeleteCore } from "src/Pages/components";
import { api } from "src/shared/setup/API/api";

interface IHandleCancelarReceberParcelaContratos {
  setModalDelete: React.Dispatch<React.SetStateAction<boolean>>;
  modalDelete: boolean;
}

export const ModalCancelarReceberParcela = ({
  modalDelete,
  setModalDelete,
}: IHandleCancelarReceberParcelaContratos) => {
  const { id, setAttTable } = useConfigPageContext();
  const { handleGetAlert } = useLayoutMainContext();
  const { resetInputs } = useResetInputs();

  const [loading, setLoading] = useState(false);

  const handleCancelarReceberParcela = async () => {
    setLoading(true);
    await api
      .patch(`/bordero/cancelerReceberParcela/${id}`)
      .then((res) => {
        handleGetAlert({ message: res.data.message });
        setAttTable((item) => !item);
        resetInputs();
      })
      .catch((error) => {
        handleGetAlert({ message: error.response.data.message });
      })
      .finally(() => {
        setModalDelete(false);
        setLoading(false);
      });
  };

  return (
    <ModalDeleteCore
      loading={loading}
      modalOpen={modalDelete}
      no="Não, voltar !"
      titlePrimary="Você deseja zerar esta parcela ?"
      yes="Sim, zerar !"
      onClickFalse={() => setModalDelete(false)}
      onClickTrue={() => handleCancelarReceberParcela()}
    />
  );
};
