import React, { useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextProdutor } from "src/Contexts/produtores/ProdutoresContext";
import { ModalCore } from "src/Pages/components";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";
import { UseVerifyIsPermissionsUser } from "src/Pages/components/hooks/UseVerifyIsPermissionsUser";
import { valuesPermissions } from "src/shared/Utils/ValuesPermissions";

import { FormGrupoImposto } from "./FormImpostos/FormGrupoImposto";
import { UseFormGrupoImposto } from "./FormImpostos/hook/UseFormGrupoImposto";
import { UseGetByIdGrupoImposto } from "./FormImpostos/hook/UseGetByIdGrupoImposto";

interface IModalImpostos {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalImpostos = ({ openModal, setOpenModal }: IModalImpostos) => {
  const { handleVerifyPermissionItem } = UseVerifyIsPermissionsUser();
  const { setValuesInputsGrupoDeImposto } = useContextProdutor();

  const { setId, id } = useConfigPageContext();

  const { handleSubmit, loading: loadingSubmit } = UseFormGrupoImposto({
    setOpenModal,
  });

  const { handleGetById } = UseGetByIdGrupoImposto();

  useEffect(() => {
    if (openModal && id) {
      handleGetById(id);
    }

    if (!openModal) {
      setId("");
      setValuesInputsGrupoDeImposto({ descricao: "", valor: "" });
    }
  }, [openModal]);

  return (
    <>
      <ButtonCore
        disabled={handleVerifyPermissionItem(
          valuesPermissions.IMPOSTOS.CREATE.id
        )}
        startIcon={<AddIcon />}
        title="Criar Imposto"
        onClick={() => setOpenModal(true)}
      />

      <ModalCore
        loadingSubmit={loadingSubmit}
        open={openModal}
        setOpen={setOpenModal}
        sxStyle={{ width: "500px" }}
        title="Imposto"
        titleSubmit="Salvar"
        onChange={handleSubmit}
      >
        <FormGrupoImposto />
      </ModalCore>
    </>
  );
};
