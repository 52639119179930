import { useContextViews } from "src/Contexts/Views/contextViews";
import { ButtonNavigationGroup } from "src/Pages/components";

import { CardHeaderLogs } from "./Components/CardHeaderLogs";
import { TableLogs } from "./TableProducer/TableLogs";

export const PageLogs = () => {
  const { NavigationCompanyConfig } = useContextViews();

  return (
    <>
      <ButtonNavigationGroup data={NavigationCompanyConfig} />

      <CardHeaderLogs />
      <TableLogs />
    </>
  );
};
