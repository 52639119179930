import { IDataTh, ThCoreIndex } from "src/Pages/components";

export const THeadProducer = () => {
  const dataTh: IDataTh[] = [
    {
      title: "Ações",
      width: "40px",
      align: "left",
    },
    {
      title: "Produtor",
      align: "left",
      width: "250px",
      minWidth: "100px",
    },
    {
      title: "Contato",
      align: "left",
      width: "180px",
      minWidth: "120px",
    },
    {
      title: "Função",
      align: "left",
      width: "100px",
    },
    {
      title: "Tipo",
      align: "left",
      width: "100px",
    },
    {
      title: "Supervisor",
      align: "center",
      width: "180px",
    },
    {
      title: "Gerente",
      align: "center",
      width: "180px",
    },
    {
      title: "Filial",
      align: "left",
      width: "100px",
    },
    {
      title: "Saldo",
      align: "left",
      width: "100px",
      tooltip: "Saldo atual conta corrente",
    },
    {
      title: "Grupo de imp.",
      tooltip: "Grupo de imposto",
      align: "left",
      width: "140px",
    },
    {
      title: "Ativo",
      tooltip: "Corretor está ativo ?",
      align: "center",
      width: "50px",
    },
    {
      title: "Info",
      align: "center",
      width: "50px",
    },
  ];

  return <ThCoreIndex dataTh={dataTh} />;
};
