import { GetDateUSSemValue } from "src/shared/Utils/FormatDateBR";

import {
  InputsConfirmationContract,
  InputsConfirmationProducer,
  InputsProductionContract,
  InputsProductionOperator,
} from "../ReportsProductionTypes";

export const valuesDefaultInputsReportsProduction: InputsProductionContract = {
  type_date: "ASSINATURA",
  start_date: `${GetDateUSSemValue()}`,
  end_date: `${GetDateUSSemValue()}`,

  id_filial: [],
  operadoras: [],
  modalidades: [],
  administradoras: [],

  type_report: "ALL",
  is_group: "TRUE",
};

export const valuesDefaultInputsReportsOperator: InputsProductionOperator = {
  type_date: "ASSINATURA",
  start_date: `${GetDateUSSemValue()}`,
  end_date: `${GetDateUSSemValue()}`,

  id_filial: [],
  operadoras: [],
  modalidades: [],
  administradoras: [],

  type_report: "ALL",
};

export const InputsDefaultReportsConfirmationContract: InputsConfirmationContract =
  {
    start_date_payment: `${GetDateUSSemValue()}`,
    end_date_payment: `${GetDateUSSemValue()}`,

    start_date_signature: "",
    end_date_signature: "",

    start_date_due: "",
    end_date_due: "",

    id_filial: [],
    operadoras: [],
    modalidades: [],
    administradoras: [],

    type_report: "ALL",
  };

export const InputsDefaultReportsConfirmationProducer: InputsConfirmationProducer =
  {
    start_date_payment: `${GetDateUSSemValue()}`,
    end_date_payment: `${GetDateUSSemValue()}`,

    start_date_signature: "",
    end_date_signature: "",

    start_date_due: "",
    end_date_due: "",

    id_filial: [],
    operadoras: [],
    modalidades: [],
    administradoras: [],

    type_report: "ALL",
  };
