import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useResetInputs } from "src/Contexts/hooks/useResetInputs";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { useContextProdutor } from "src/Contexts/produtores/ProdutoresContext";
import { UseAutoCompletedAll } from "src/Contexts/Utils/UseAutoCompletedAll";
import { api } from "src/shared/setup/API/api";
import { handleFormatToNumeric } from "src/shared/Utils/FormatMoney";

export const UseFormContaCorrente = () => {
  const { setAttTable, attTable, setLoadingGravar } = useConfigPageContext();
  const { valueAutoCompProdutorActive } = UseAutoCompletedAll();
  const { valuesInputsContaCorrente } = useContextProdutor();
  const { handleGetAlert } = useLayoutMainContext();
  const { resetInputs } = useResetInputs();

  const handleCreate = async () => {
    if (!valuesInputsContaCorrente.data_lancamento)
      return handleGetAlert({ message: `Digite uma data` });
    if (!valuesInputsContaCorrente.valor)
      return handleGetAlert({ message: `Digite um valor` });

    const AllDatas = {
      ...valuesInputsContaCorrente,
      id_produtor: valueAutoCompProdutorActive.id,
      valor: handleFormatToNumeric(valuesInputsContaCorrente?.valor),
    };

    setLoadingGravar(true);

    return api
      .post("/contaCorrente/create/lancamento", AllDatas)
      .then((res) => {
        handleGetAlert({ message: res.data.message });
        setAttTable(!attTable);
        resetInputs();
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoadingGravar(false));
  };

  const handleSubmit = () => handleCreate();

  return { handleSubmit };
};
