import { SetStateAction, Dispatch } from "react";
import { Box } from "@mui/material";
import { DialogCore } from "src/Pages/components/DialogCore/DialogCore";

import { FormChangeEmail } from "./ChangeEmail/FormChangeEmail";
import { FormChangeIsActiveAccess } from "./ChangeIsActiveAccess/ChangeIsActiveAccess";

interface IModalViewRecibos {
  setModalComissao: Dispatch<SetStateAction<boolean>>;
  modalComissao: boolean;
  data: {
    idTenant: string;
    email: string;
    isActiveAccess: boolean;
  };
}

export const ModalChangeAccessProducer = ({
  data,
  modalComissao,
  setModalComissao,
}: IModalViewRecibos) => {
  const handleCloseDialog = () => setModalComissao(false);

  return (
    <DialogCore
      PaperProps={{
        style: {
          width: "600px",
        },
      }}
      open={modalComissao}
      setOpen={handleCloseDialog}
      sx={{ minWidth: { xs: "100%", md: "700px" } }}
      title="Controle de acesso do produtor"
    >
      <Box sx={{ padding: "10px" }}>
        <FormChangeEmail email={data.email} />
        <FormChangeIsActiveAccess isActive={data.isActiveAccess} />
      </Box>
    </DialogCore>
  );
};
