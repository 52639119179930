import { IDataTh, ThCoreIndex } from "src/Pages/components";

export const THeadContaCorrente = () => {
  const dataTh: IDataTh[] = [
    {
      title: "Ações",
      width: "40px",
      align: "left",
    },
    {
      title: "Produtor",
      align: "left",
      width: "130px",
    },
    {
      title: "Data",
      align: "left",
      width: "110px",
      minWidth: "110px",
    },
    {
      title: "Descrição",
      align: "left",
      width: "250px",
    },
    {
      title: "Categoria",
      align: "left",
      width: "100px",
    },
    {
      title: "Credito",
      align: "right",
      width: "100px",
    },
    {
      title: "Debito",
      align: "right",
      width: "100px",
    },
    {
      title: "Saldo",
      align: "right",
      width: "100px",
    },
    {
      title: "Recibo",
      align: "center",
      width: "100px",
    },
    {
      title: "Info",
      align: "center",
      width: "50px",
    },
  ];

  return <ThCoreIndex dataTh={dataTh} />;
};
