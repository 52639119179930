import { StyleSheet } from "@react-pdf/renderer";

export const stylesPDFAssinatura = StyleSheet.create({
  container: {
    fontSize: "12px",
    width: "100%",
    marginTop: "20px",
  },

  lineSignature: {
    borderBottom: "1px solid black",
    margin: "20px 0 4px 0",
    maxWidth: "40%",
  },
  nameProdutor: {
    marginBottom: "12px",
  },

  sentenceInFull: {
    fontSize: "12px",
    marginBottom: "20px",
  },
});
