import React, { FC, ReactNode, useEffect, useRef } from "react";
import {
  LinearProgress,
  TableContainer,
  SxProps,
  Table,
  Box,
} from "@mui/material";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";

import { CardHeaderCore } from "../../CardCore/Components/CardHeaderCore";
import { useStyles } from "../../ModalFilterCore/Index";
import { JoinPaginationAndSelect } from "../JoinPaginationAndSelect/JoinPaginationAndSelect";
import { TrNotList } from "../TrNotList/TrNotList";

type ILoadingTable = {
  heigthTable?: string;
  qtdList: number;
  isNotPagination?: boolean;
  THead: ReactNode;
  filter?: ReactNode;
  setNameSearch?: React.Dispatch<React.SetStateAction<string>>;
  isLastChildren?: boolean;

  cardAdd?: {
    title?: string;
    buttonAdd?: ReactNode[];
    icon?: ReactNode;
  };
  Modals?: ReactNode;
  sxStyle?: SxProps;
  title?: ReactNode;
  children: ReactNode;
};

export const TableCore: FC<ILoadingTable> = ({
  isNotPagination,
  isLastChildren,
  setNameSearch,
  heigthTable,
  children,
  cardAdd,
  sxStyle,
  qtdList,
  filter,
  THead,
  Modals,
  title,
}) => {
  const { loadingTable, qtd_registros, currentPage, setCurrentPage } =
    useConfigPageContext();

  const tabelaRef = useRef<HTMLTableElement>(null);

  useEffect(() => {
    if (Number(qtdList) === 0) {
      if (currentPage > 1) {
        setCurrentPage((item) => item - 1);
      }
    }
  }, [qtd_registros]);

  const classes = useStyles();

  const handleGoLastChildren = () => {
    const tabela = tabelaRef.current as HTMLTableElement;

    tabela.scrollTop = tabela.scrollHeight - tabela.clientHeight;
  };

  useEffect(() => {
    if (isLastChildren && !loadingTable && qtdList) {
      handleGoLastChildren();
    }
  }, [loadingTable]);

  return (
    <Box sx={{ margin: { xs: "8px", sm: "16px" }, ...sxStyle }}>
      {Modals}

      {loadingTable && (
        <Box style={{ width: "100%" }}>
          <LinearProgress color="inherit" />
        </Box>
      )}

      {cardAdd && (
        <CardHeaderCore
          buttonAdd={cardAdd.buttonAdd}
          icon={cardAdd.icon}
          sxStyle={{
            margin: 0,
            marginBottom: "16px",
          }}
          title={cardAdd.title}
        />
      )}

      <TableContainer
        ref={tabelaRef}
        className={classes.customScrollbar}
        sx={{
          background: "#ffffff",
          maxHeight: heigthTable || "500px",
          overflow: "auto",
          boxShadow: "0 .1rem 1rem .25rem #0000001f",
          borderRadius: "12px",
        }}
      >
        {title && (
          <Box sx={{ margin: { xs: ".7rem", sm: "1rem" }, fontWeight: "500" }}>
            {title}
          </Box>
        )}

        <Table aria-label="simple table">
          {THead}

          <tbody>{children}</tbody>
        </Table>
        <TrNotList loadingTable={!loadingTable} qtdList={qtdList} />
      </TableContainer>

      {isNotPagination !== true && (
        <JoinPaginationAndSelect
          Filter={filter}
          qtd_registros={qtd_registros}
          setNameSearch={setNameSearch || undefined}
        />
      )}
    </Box>
  );
};
