import { IconButton, Tooltip } from "@mui/material";
import { ReplayIcon } from "src/Pages/components/Icons/Icons";

export const ToggleReaload = () => {
  return (
    <Tooltip title="Recarregar">
      <IconButton
        sx={{ color: "#3b3b3b" }}
        onClick={() => document.location.reload()}
      >
        <ReplayIcon />
      </IconButton>
    </Tooltip>
  );
};
