import { GetDateUSSemValue } from "src/shared/Utils/FormatDateBR";

import {
  ICreateComissaoCorretora,
  ICreateComissaoProdutor,
  IValuesFilterComissaoCorretora,
  IValuesFilterComissaoProdutor,
} from "../ComissoesTypes";

export const valuesDefaultFilterComissaoCorretora: IValuesFilterComissaoCorretora =
  {
    codeOperadora: "",
    name: "",
    isActive: "TRUE",
  };

export const valuesDefaultFilterComissaoProdutor: IValuesFilterComissaoProdutor =
  {
    codeOperadora: "",
    name: "",
    isActive: "TRUE",
  };

export const valuesDefaultInputsComissaoCorretora: ICreateComissaoCorretora = {
  name: "",
  date_vigencia: `${GetDateUSSemValue()}`,

  parc_1: "",
  parc_2: "",
  parc_3: "",
  parc_4: "",
  parc_5: "",
  parc_6: "",
  parc_7: "",
  parc_8: "",
  parc_9: "",
  parc_10: "",
  parc_11: "",
  parc_12: "",
  parc_13: "",
  parc_14: "",
  parc_15: "",
  parc_16: "",
};

export const valuesDefaultInputsComissaoProdutor: ICreateComissaoProdutor = {
  name: "",
  date_vigencia: `${GetDateUSSemValue()}`,
  funcao_produtor: "CORRETOR",
  tipo_produtor: "SALAO",

  is_special: "false",

  parc_1: "",
  parc_2: "",
  parc_3: "",
  parc_4: "",
  parc_5: "",
  parc_6: "",
  parc_7: "",
  parc_8: "",
  parc_9: "",
  parc_10: "",
  parc_11: "",
  parc_12: "",
  parc_13: "",
  parc_14: "",
  parc_15: "",
  parc_16: "",
};
