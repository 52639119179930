import { Text, View } from "@react-pdf/renderer";

import { stylesPDFCabecalho } from "./style";

interface IPDFCabeçalho {
  empresa: string;
  nomePdf: string;
  startDate: string;
  endDate: string;
  dateCurrency: string;
  hoursCurrency: string;
}
export const PDFCabeçalho = ({
  hoursCurrency,
  dateCurrency,
  startDate,
  empresa,
  endDate,
  nomePdf,
}: IPDFCabeçalho) => {
  return (
    <View key={empresa} fixed style={stylesPDFCabecalho.container}>
      <Text style={stylesPDFCabecalho.titleEmpresa}>{empresa}</Text>

      <View>
        <View style={stylesPDFCabecalho.titleNomePdf}>
          <Text>{nomePdf}</Text>
        </View>

        <View style={stylesPDFCabecalho.descriptionDate}>
          <Text>{`De ${startDate} à ${endDate}`}</Text>
        </View>
      </View>

      <View style={stylesPDFCabecalho.descriptionDateCurrency}>
        <Text>{dateCurrency}</Text>
        <Text>{hoursCurrency}</Text>
        {/* <View
          render={({ pageNumber }) => (
            <View>
              <Text style={{ fontSize: 12 }}>{pageNumber}</Text>
            </View>
          )}
        /> */}
      </View>
    </View>
  );
};
