import { Typography } from "@mui/material";
import { CardCore } from "src/Pages/components/CardCore/CardCore";
import { TurnedInIcon } from "src/Pages/components/Icons/Icons";

export const HeaderSendLinkRecoverPassword = () => {
  return (
    <CardCore
      sxStyle={{
        margin: "0 0 24px 0",
        alignItems: "center",
        display: "flex",
        padding: "8px",
        background: "white",
      }}
    >
      <TurnedInIcon sx={{ color: "#5a98fff5", marginRight: "8px" }} />

      <Typography
        sx={{
          margin: "8px 0",
          fontSize: { xs: ".8rem", md: ".9rem", lg: "1rem" },
        }}
      >
        Recupere sua senha com email
      </Typography>
    </CardCore>
  );
};
