import { View, Text } from "@react-pdf/renderer";

import { styles } from "./style";

import { IPDFTableHeaderData } from "./index";

export const PDFTableHeader = ({ data }: IPDFTableHeaderData) => (
  <View style={styles.container}>
    {data.map(({ style, title }) => {
      return (
        <Text key={title} style={{ ...style }}>
          {title}
        </Text>
      );
    })}
  </View>
);
