import React, { useEffect } from "react";
import { ModalCore } from "src/Pages/components";

import { FormChangeCompany } from "./Form/FormChangeCompany";
import { UseFormChangeCompany } from "./Form/Hooks/UseFormChangeCompany";
import { UseGetCompany } from "./hooks/UseGetCompany";

interface IModalChangeCompany {
  id: string;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalChangeCompany = ({
  id,
  openModal,
  setOpenModal,
}: IModalChangeCompany) => {
  const { loading: loadingGetCompany, handleGet } = UseGetCompany();

  useEffect(() => {
    if (id) {
      const handle = async () => handleGet({ id });

      handle();
    }
  }, [id]);

  const { handleChange: handleChangeCompany, loading: loadingChangeCompany } =
    UseFormChangeCompany({ setOpenModal });

  return (
    <ModalCore
      loading={loadingGetCompany}
      loadingSubmit={loadingChangeCompany}
      open={openModal}
      setOpen={setOpenModal}
      sxStyle={{ width: 500 }}
      title="Alterar dados empresa"
      onChange={() => handleChangeCompany({ id })}
    >
      <FormChangeCompany />
    </ModalCore>
  );
};
