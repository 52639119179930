import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

export const UseModalWelcome = () => {
  const { handleGetAlert } = useLayoutMainContext();

  const handleIsFirstAccess = async () => {
    return api
      .post("/company/IsFirstAccess")
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      );
  };

  return { handleIsFirstAccess };
};
