import { Children, useEffect } from "react";
import { TableRow } from "@mui/material";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { TableCore, TdCore } from "src/Pages/components";
import { api } from "src/shared/setup/API/api";
import { FormatDateBR } from "src/shared/Utils/FormatDateBR";

import { THeadLogs } from "./THead";

export const TableDev = () => {
  const { handleGetAlert } = useLayoutMainContext();

  const {
    setQtd_registros,
    setListAcoesDev,
    setLoadingTable,
    setNameSearch,
    listAcoesDev,
    itensPerPage,
    currentPage,
    nameSearch,
    attTable,
  } = useConfigPageContext();

  useEffect(() => {
    setLoadingTable(true);

    api
      .post("/errorsLogsPagination", {
        page: currentPage,
        limit: itensPerPage,
        filter: nameSearch,
      })
      .then((res) => {
        setListAcoesDev(res.data.data);
        setQtd_registros(res.data.count);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoadingTable(false));
  }, [itensPerPage, currentPage, nameSearch, attTable]);

  return (
    <TableCore
      THead={<THeadLogs />}
      qtdList={listAcoesDev.length}
      setNameSearch={setNameSearch}
      sxStyle={{ margin: "8px 0" }}
    >
      {Children.toArray(
        listAcoesDev.map((item) => {
          return (
            <TableRow key={item.id}>
              <TdCore values={`${item.code_tenant} - empresa`} />
              <TdCore
                values={`${FormatDateBR(item.data_currency)} - ${
                  item.hours_currency
                }`}
              />
              <TdCore
                values={`${item.method} - ${item.status_code} - ${item.url}`}
              />
              <TdCore values={item.error_intern} />
              <TdCore values={item.action} />
              <TdCore values={item.message_return_error} />
              <TdCore
                tooltip={JSON.stringify(item.data as string)}
                values="... ... ..."
              />
            </TableRow>
          );
        })
      )}
    </TableCore>
  );
};
