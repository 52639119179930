import { Children, useEffect, useState } from "react";
import { formatString } from "@format-string/core";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useResetInputs } from "src/Contexts/hooks/useResetInputs";
import { useContextProdutor } from "src/Contexts/produtores/ProdutoresContext";
import {
  ModalDeleteCore,
  TableRowCore,
  TableCore,
  TdCore,
} from "src/Pages/components";
import { handleChipCoreCategories } from "src/Pages/components/CategoryComponentMap";
import { UseVerifyIsPermissionsUser } from "src/Pages/components/hooks/UseVerifyIsPermissionsUser";
import {
  DeleteForeverIcon,
  PersonOffIcon,
  Visibility,
  PersonIcon,
  CheckIcon,
  CloseIcon,
  EditIcon,
  InfoOutlinedIcon,
} from "src/Pages/components/Icons/Icons";
import { UseDelete } from "src/Pages/components/ModalDeleteCore/Hooks/UseDelete";
import { ActionPopoverTable } from "src/Pages/components/table/ActionPopover/ActionPopover";
import { FormatDateBR } from "src/shared/Utils/FormatDateBR";
import { FormatMoney } from "src/shared/Utils/FormatMoney";
import { valuesPermissions } from "src/shared/Utils/ValuesPermissions";

import { ModalChangeFunctionProducer } from "../ChangeFunction";
import { ModalChangeIsActiveProducer } from "../ChangeIsActiveProducer/ModalChangeIsActiveproducer";
import { FilterSidebarProdutor } from "../FilterSidebar/FilterSidebar";
import { ModalChangeAccessProducer } from "../ModalChangeAccessProducer/ModalChangeAccessProducer";

import { UseGetByIdProducer } from "./hooks/UseGetByIdProducer";
import { useHttpTableProdutor } from "./hooks/useHttpTableProdutor";
import { THeadProducer } from "./THead";

interface IChangeAccess {
  idTenant: string;
  email: string;
  isActiveAccess: boolean;
}

export const TableProdutores = () => {
  const { resetInputs } = useResetInputs();
  const { listProdutor } = useContextProdutor();
  const { handleGet } = useHttpTableProdutor();
  const { handleGetById } = UseGetByIdProducer();
  const { handleVerifyPermissionItem } = UseVerifyIsPermissionsUser();

  const {
    setActive_incluir,
    setNameSearch,
    itensPerPage,
    currentPage,
    setIdTable,
    nameSearch,
    attTable,
    setId,
  } = useConfigPageContext();
  const { valuesFilterProducer } = useContextProdutor();

  const [openModalChangeFunction, setOpenModalChangeFunction] = useState(false);

  const [dataProdutorToEditFunction, setDataProdutorToEditFunction] = useState({
    name: "",
    function: "CORRETOR",
  });

  const [openModalComissao, setOpenModalComissao] = useState(false);
  const [openModalIsActiveProducer, setOpenModalIsActiveProducer] =
    useState(false);

  const [valueIsActiveProducer, setValueIsActiveProducer] =
    useState<boolean>(true);

  const [valueChangeAccessProducer, setValueChangeAccessProducer] =
    useState<IChangeAccess>({ email: "", idTenant: "", isActiveAccess: false });

  const handleGetIdToEdit = (id: string) => {
    resetInputs();
    setId(id);
    setIdTable(id);
    handleGetById(id);
  };

  const {
    handleDelete,
    open: openModalDelete,
    loading: loadingDelete,
    setId: setIdModalDelete,
    setOpen: setOpenModalDelete,
  } = UseDelete({ url: "/produtor" });

  useEffect(() => {
    handleGet({
      currentPage,
      itensPerPage,
      nameSearch,
      isActiveProducer: valuesFilterProducer.isActiveProducer,
      codProducer: valuesFilterProducer.codProducer,
      nomeProducer: valuesFilterProducer.nomeProducer,
      cpfProducer: valuesFilterProducer.cpfProducer,
      functionProducer: valuesFilterProducer.functionProducer,
    });
  }, [attTable, itensPerPage, currentPage, nameSearch]);

  return (
    <TableCore
      Modals={
        <>
          <ModalDeleteCore
            loading={loadingDelete}
            modalOpen={openModalDelete}
            no="Não, Cancelar"
            titlePrimary="Você deseja excluir da lista ?"
            yes="Sim, Deletar !"
            onClickFalse={() => setOpenModalDelete(false)}
            onClickTrue={() => handleDelete()}
          />

          <ModalChangeAccessProducer
            data={valueChangeAccessProducer}
            modalComissao={openModalComissao}
            setModalComissao={setOpenModalComissao}
          />

          <ModalChangeFunctionProducer
            dataProdutorToEditFunction={dataProdutorToEditFunction}
            openModal={openModalChangeFunction}
            setDataProdutorToEditFunction={setDataProdutorToEditFunction}
            setOpenModal={setOpenModalChangeFunction}
          />

          <ModalChangeIsActiveProducer
            isActiveProducer={valueIsActiveProducer}
            modalIsActiveProducer={openModalIsActiveProducer}
            setModalIsActiveProducer={setOpenModalIsActiveProducer}
          />
        </>
      }
      THead={<THeadProducer />}
      filter={<FilterSidebarProdutor />}
      qtdList={listProdutor.length}
      setNameSearch={setNameSearch}
    >
      {Children.toArray(
        listProdutor.map((item) => {
          return (
            <TableRowCore id={item.id_tenant}>
              <TdCore
                values={
                  <ActionPopoverTable
                    key={item.id_tenant}
                    optionsList={[
                      {
                        icon: <Visibility fontSize="small" />,
                        title: "Acesso online",
                        isDisabled: handleVerifyPermissionItem(
                          valuesPermissions.PRODUCER.EDITAR.id
                        ),
                        onClick: () => {
                          setId(item.id_tenant);
                          setOpenModalComissao(true);
                          setValueChangeAccessProducer({
                            email: item.email,
                            idTenant: item.id_tenant,
                            isActiveAccess: item.is_active_access_producer,
                          });
                        },
                      },
                      {
                        title: !item.is_active_producer
                          ? "Ativar produtor"
                          : "Desativar produtor",
                        background: "warning",
                        icon: item.is_active_producer ? (
                          <PersonOffIcon fontSize="small" />
                        ) : (
                          <PersonIcon fontSize="small" />
                        ),
                        isDisabled: handleVerifyPermissionItem(
                          valuesPermissions.PRODUCER.EDITAR.id
                        ),
                        onClick: () => {
                          setId(item.id_tenant);
                          setOpenModalIsActiveProducer(true);
                          setValueIsActiveProducer(!item.is_active_producer);
                        },
                      },
                      {
                        icon: <EditIcon fontSize="small" />,
                        title: "Trocar função",
                        background: "warning",
                        isDisabled: handleVerifyPermissionItem(
                          valuesPermissions.PRODUCER.EDITAR.id
                        ),
                        onClick: () => {
                          setId(item.id_tenant);
                          setOpenModalChangeFunction(true);
                          setDataProdutorToEditFunction({
                            function: item.funcao_produtor,
                            name: item.nome,
                          });
                        },
                      },
                      {
                        icon: <EditIcon fontSize="small" />,
                        title: "Editar",
                        isDisabled: handleVerifyPermissionItem(
                          valuesPermissions.PRODUCER.EDITAR.id
                        ),
                        onClick: () => {
                          setId(item.id_tenant);
                          handleGetIdToEdit(item.id_tenant);
                          setActive_incluir(true);
                        },
                      },
                      {
                        icon: <DeleteForeverIcon fontSize="small" />,
                        title: "Excluir",
                        background: "error",
                        isDisabled: handleVerifyPermissionItem(
                          valuesPermissions.PRODUCER.DELETAR.id
                        ),
                        onClick: () => {
                          setIdModalDelete(item.id_tenant);
                          setOpenModalDelete(true);
                        },
                      },
                    ]}
                  />
                }
              />
              <TdCore
                isAvatar
                isLimit
                isLimitQTD={30}
                subTitle={
                  item.fisico_juridico === "juridica"
                    ? formatString({ value: item?.cpf_cnpj, type: "cnpj" })
                    : formatString({ value: item?.cpf_cnpj, type: "cpf" })
                }
                sxStyle={{ fontWeight: "600", padding: "4px 0" }}
                titleAvatar={item.nome}
                values={`${item.id} - ${item.nome}`}
                onClick={() => handleGetIdToEdit(item.id_tenant)}
              />

              <TdCore
                isLimit
                isLimitQTD={30}
                subTitle={`${formatString({
                  type: "phone",
                  value: item.telefone1,
                })}`}
                sxStyle={{ fontWeight: "600", padding: "4px 0" }}
                values={item.email_contato}
                onClick={() => handleGetIdToEdit(item.id_tenant)}
              />

              <TdCore
                textAlign="center"
                values={handleChipCoreCategories(item.funcao_produtor)}
                onClick={() => handleGetIdToEdit(item.id_tenant)}
              />
              <TdCore
                textAlign="center"
                values={handleChipCoreCategories(item.tipo_produtor)}
                onClick={() => handleGetIdToEdit(item.id_tenant)}
              />

              <TdCore
                isLimit
                isLimitQTD={30}
                textAlign="center"
                values={`${item.supervisor.id} - ${item.supervisor.nome}`}
                onClick={() => handleGetIdToEdit(item.id_tenant)}
              />
              <TdCore
                isLimit
                isLimitQTD={30}
                textAlign="center"
                values={`${item.gerente.id} - ${item.gerente.nome}`}
                onClick={() => handleGetIdToEdit(item.id_tenant)}
              />
              <TdCore
                values={handleChipCoreCategories(item.filial.nome)}
                onClick={() => handleGetIdToEdit(item.id_tenant)}
              />
              <TdCore
                color={item.contaCorrente.saldo < 0 ? "red" : "#3cb916"}
                values={`R$ ${
                  item.contaCorrente.saldo < 0 ? "-" : ""
                }${FormatMoney(String(item.contaCorrente.saldo))}`}
                onClick={() => handleGetIdToEdit(item.id_tenant)}
              />
              <TdCore
                values={handleChipCoreCategories(item.grupoImposto.nome)}
                onClick={() => handleGetIdToEdit(item.id_tenant)}
              />
              <TdCore
                textAlign="center"
                values={
                  item.is_active_producer ? (
                    <CheckIcon color="success" />
                  ) : (
                    <CloseIcon color="error" />
                  )
                }
                onClick={() => handleGetIdToEdit(item.id_tenant)}
              />
              <TdCore
                textAlign="center"
                tooltip={`Usuário com id: '${
                  item.id_user_create
                }' criou na data: ${FormatDateBR(item.created_at)}`}
                values={<InfoOutlinedIcon />}
              />
            </TableRowCore>
          );
        })
      )}
    </TableCore>
  );
};
