import { CardsHelp } from "./components/CardHelp";
import { CardHeaderHelp } from "./components/Header/CardHeaderHelp";

export const PageHelp = () => {
  return (
    <>
      <CardHeaderHelp />

      <CardsHelp />
    </>
  );
};
