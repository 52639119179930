import { useState } from "react";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

export const UseGetGetTypesUploadCommission = () => {
  const { handleGetAlert } = useLayoutMainContext();
  const { setValuesTypesUploadCommission } = useContextContrato();

  const [loading, setLoading] = useState(false);

  const handleGetNovaParcelaById = async () => {
    setValuesTypesUploadCommission([]);

    setLoading(true);

    return api
      .get(`/borderoGetTypesUploadCommission`)
      .then(({ data: { data } }) => {
        setValuesTypesUploadCommission(data);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  return { handleGetNovaParcelaById, loading };
};
