import { useContextComissoes } from "src/Contexts/comissoes/ComissoesContext";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useResetInputs } from "src/Contexts/hooks/useResetInputs";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { UseAutoCompletedAll } from "src/Contexts/Utils/UseAutoCompletedAll";
import { api } from "src/shared/setup/API/api";

export const UseFeaturesFormulario = () => {
  const { valuesInputsComissaoProdutor } = useContextComissoes();
  const { handleGetAlert } = useLayoutMainContext();
  const { resetInputs } = useResetInputs();

  const {
    valueAutoCompProdutorActive,
    valueAutoCompAdministradora,
    valueAutoCompModalidade,
    valueAutoCompOperadora,
  } = UseAutoCompletedAll();

  const { setAttTable, attTable, idTable, id, setLoadingGravar } =
    useConfigPageContext();

  const objComissao = {
    name: valuesInputsComissaoProdutor.name,
    date_vigencia: valuesInputsComissaoProdutor.date_vigencia,

    code_operadora: Number(valueAutoCompOperadora.id),
    code_modalidade: Number(valueAutoCompModalidade.id),

    code_administradora: valueAutoCompAdministradora.id
      ? Number(valueAutoCompAdministradora.id)
      : null,

    funcao_produtor: valuesInputsComissaoProdutor.funcao_produtor,

    tipo_produtor: valuesInputsComissaoProdutor.tipo_produtor,

    is_special: valuesInputsComissaoProdutor.is_special === "true",

    code_produtor: valuesInputsComissaoProdutor.is_special
      ? String(valueAutoCompProdutorActive.id)
      : "",

    numero_parcela: {
      parc_1: valuesInputsComissaoProdutor.parc_1,
      parc_2: valuesInputsComissaoProdutor.parc_2,
      parc_3: valuesInputsComissaoProdutor.parc_3,
      parc_4: valuesInputsComissaoProdutor.parc_4,
      parc_5: valuesInputsComissaoProdutor.parc_5,
      parc_6: valuesInputsComissaoProdutor.parc_6,
      parc_7: valuesInputsComissaoProdutor.parc_7,
      parc_8: valuesInputsComissaoProdutor.parc_8,
      parc_9: valuesInputsComissaoProdutor.parc_9,
      parc_10: valuesInputsComissaoProdutor.parc_10,
      parc_11: valuesInputsComissaoProdutor.parc_11,
      parc_12: valuesInputsComissaoProdutor.parc_12,
      parc_13: valuesInputsComissaoProdutor.parc_13,
      parc_14: valuesInputsComissaoProdutor.parc_14,
      parc_15: valuesInputsComissaoProdutor.parc_15,
      parc_16: valuesInputsComissaoProdutor.parc_16,
    },
  };

  const handleCreate = async () => {
    if (!valuesInputsComissaoProdutor.name)
      return handleGetAlert({ message: `Digite um nome de comissão !` });

    if (!valuesInputsComissaoProdutor.date_vigencia)
      return handleGetAlert({ message: `Escolha uma data de vigência !` });

    setLoadingGravar(true);

    const AllDatas = { ...objComissao };

    return api
      .post("/comissoes/produtor/create", AllDatas)
      .then((res) => {
        handleGetAlert({ message: res.data.message });
        setAttTable(!attTable);
        resetInputs();
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoadingGravar(false));
  };

  const handleEdit = () => {
    if (!valuesInputsComissaoProdutor.name)
      return handleGetAlert({ message: `Digite um nome de comissão !` });

    if (!valuesInputsComissaoProdutor.date_vigencia)
      return handleGetAlert({ message: `Escolha uma data de vigência !` });

    setLoadingGravar(true);

    const AllDatas = { ...objComissao };

    return api
      .put(`/comissoes/produtor/${id}`, AllDatas)
      .then((res) => {
        setAttTable(!attTable);
        handleGetAlert({ message: res.data.message });
        resetInputs();
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoadingGravar(false));
  };

  const handleSubmit = () => {
    if (idTable) {
      handleEdit();
    } else {
      handleCreate();
    }
  };

  return { handleSubmit };
};
