import { FormModalidade } from "./Components/Form/FormularioModalidade";
import { TableModalidade } from "./Components/table/TableModalidade";

export const PageComissoesModalidade = () => {
  return (
    <>
      <FormModalidade />
      <TableModalidade />
    </>
  );
};
