import { FC, ReactNode } from "react";
import { alpha, TableRow } from "@mui/material";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useAppThemeContext } from "src/Contexts/ThemeContextConfig";

type ITableRowCore = {
  id?: string;
  children: ReactNode;
};

export const TableRowCore: FC<ITableRowCore> = ({ id, children }) => {
  const { themeName } = useAppThemeContext();
  const { idTable } = useConfigPageContext();

  const colorTheme = themeName === "dark" ? 0.4 : 0.12;
  const colorTheme1 = themeName === "dark" ? 0.2 : 0.06;

  return (
    <TableRow
      key={id}
      sx={{
        background: (theme) =>
          id === idTable ? alpha(theme.palette.common.black, colorTheme) : "",
        "&:hover": {
          background: (theme) => alpha(theme.palette.common.black, colorTheme1),
        },
      }}
    >
      {children}
    </TableRow>
  );
};
