import { useContextRecoverPassword } from "src/Contexts/RecoverPassword/RecoverPasswordContext";

import { BasePageLogin } from "../components/BasePageLogin";

import { FooterSendLinkRecoverPassword } from "./components/Footer/Footer";
import { PageFormSendLinkRecoverPassword } from "./components/Form";
import { HeaderSendLinkRecoverPassword } from "./components/Header/Header";
import { SelectCompanyRecoverPassword } from "./components/SelectCompanyRecoverPassword";

export const PageSendLinkRecoverPassword = () => {
  const { getCompaniesRecoverPassword } = useContextRecoverPassword();

  return (
    <BasePageLogin
      sxStyle={{
        width: { xs: "340px", sm: "400px", md: "435px", lg: "500px" },
      }}
    >
      <HeaderSendLinkRecoverPassword />

      {getCompaniesRecoverPassword?.length > 0 ? (
        <SelectCompanyRecoverPassword />
      ) : (
        <PageFormSendLinkRecoverPassword />
      )}

      <FooterSendLinkRecoverPassword />
    </BasePageLogin>
  );
};
