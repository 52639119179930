import { GetDateUSSemValue } from "src/shared/Utils/FormatDateBR";

import { useContextComissoes } from "../comissoes/ComissoesContext";
import {
  valuesDefaultInputsComissaoCorretora,
  valuesDefaultInputsComissaoProdutor,
} from "../comissoes/ValuesDefaultInputsComissoes/ValuesDefaultInputsComissoes";
import { useConfigPageContext } from "../configPagesContext/configPagesContext";
import { useContextContrato } from "../Lancamentos/Contrato/ContratosContext";
import {
  valueDefaultInputBorderoParcelas,
  valueDefaultInputContratos,
} from "../Lancamentos/Contrato/valuesDefaultInputsContratos/ValueDefaultInputContratos";
import { useContextProdutor } from "../produtores/ProdutoresContext";
import { valueDefaultInputProdutores } from "../produtores/valuesDefaultInputsProdutor/valuesDefaultInputsProdutor";
import { useContextRecibos } from "../Recibos/RecibosContext";
import { valuesDefaultInputsRecibos } from "../Recibos/ValuesDefaultInputsRecibos/ValuesDefaultInputsRecibos";
import { useContextReportsProduction } from "../ReportProduction/ReportsProductionContextContext";
import {
  InputsDefaultReportsConfirmationContract,
  InputsDefaultReportsConfirmationProducer,
  valuesDefaultInputsReportsProduction,
  valuesDefaultInputsReportsOperator,
} from "../ReportProduction/ValuesDefaultInputsReportProduction/ValuesDefaultInputsReportProduction";
import { useContextUsuarios } from "../Usuarios/UsuariosContext";
import { UseValuesDefaultInputUsers } from "../Usuarios/ValuesDefaultInputUsers/ValuesDefaultInputUsers";
import { UseAutoCompletedAll } from "../Utils/UseAutoCompletedAll";

export const useResetInputs = (isDisabledActive?: boolean) => {
  const { valueDefaultAutoCompletedFiliais } = UseValuesDefaultInputUsers();
  const { setValuesInputsRecibos, setListRecibos } = useContextRecibos();

  const {
    setListReportConfirmationContract,
    setValuesInputsProductionOperator,
    setValuesInputsProductionContract,
    setListReportProductionContract,
    setListReportProductionProducer,
    setValueAutoCompAdministradoras,
    setInputsConfirmationProducer,
    setInputsConfirmationContract,
    setValueAutoCompModalidades,
    setValueAutoCompOperadoras,
  } = useContextReportsProduction();

  const {
    setValueAutoCompOperadoraMultiple,
    setValueAutoCompProdutorActive,
    setValueAutoCompAdministradora,
    setValueAutoCompletedFiliais,
    setValueAutoCompPerfilAcesso,
    setValueAutoCompGrupoImposto,
    setValueAutoCompModalidade,
    setValueAutoCompSupervisor,
    setValueAutoCompOperadora,
    setValueAutoCompFiliais,
    setValueAutoCompGerente,
  } = UseAutoCompletedAll();

  const {
    setValuesInputsComissaoCorretora,
    setValuesInputsComissaoProdutor,
    setValuesInputsAdministradora,
    setValuesInputsModalidade,
    setValuesInputsOperadora,
  } = useContextComissoes();

  const {
    setValuesInputsPerfilAcesso,
    setValuesInputsUsuarios,
    setValuesInputsFiliais,
  } = useContextUsuarios();

  const {
    setValueComissaoCorretoraGrade,
    setValueComissaoProdutorGrade,
    setValuesInputsBorderoParcela,
    setValueAutoCompProdutorAll,
    setValuesInputsContrato,
  } = useContextContrato();

  const { setActive_incluir, setNameSearch, setIdTable, setId } =
    useConfigPageContext();

  const {
    setValuesInputsGrupoDeImposto,
    setValuesInputsContaCorrente,
    setValuesInputsProdutor,
  } = useContextProdutor();

  const resetInputs = () => {
    if (!isDisabledActive) {
      setActive_incluir(false);
    }

    setNameSearch("");
    setIdTable("");
    setId("");

    setValueAutoCompFiliais([]);
    setValueAutoCompOperadoras([]);
    setValueAutoCompModalidades([]);
    setValueAutoCompAdministradoras([]);

    setValuesInputsGrupoDeImposto({ descricao: "", valor: "0.00" });

    setInputsConfirmationProducer(InputsDefaultReportsConfirmationProducer);
    setInputsConfirmationContract(InputsDefaultReportsConfirmationContract);
    setValuesInputsProductionContract(valuesDefaultInputsReportsProduction);
    setValuesInputsProductionOperator(valuesDefaultInputsReportsOperator);

    setValuesInputsPerfilAcesso({
      perfil_acesso: "",
      id_permissao: "",
      permissions: [""],
    });

    setValuesInputsUsuarios({
      horario_inicial: "08:00",
      horario_final: "20:00",
      email: "",
      nome: "",
      telefone1: "",
      type_user: "USER",
    });

    setValuesInputsFiliais({ filial: "" });

    setValuesInputsContaCorrente({
      data_lancamento: `${GetDateUSSemValue()}`,
      is_recibo: false,
      descricao: "",
      tipo_transicao: "debito",
      valor: "0.00",
    });

    setValueAutoCompOperadoraMultiple([]);

    setValuesInputsOperadora({ name: "" });
    setValuesInputsModalidade({ name: "", is_active: true });
    setValuesInputsAdministradora({ name: "", is_active: true });

    setValuesInputsComissaoCorretora(valuesDefaultInputsComissaoCorretora);
    setValuesInputsComissaoProdutor(valuesDefaultInputsComissaoProdutor);
    setValuesInputsContrato(valueDefaultInputContratos);

    setValuesInputsBorderoParcela(valueDefaultInputBorderoParcelas);
    setValuesInputsProdutor(valueDefaultInputProdutores);

    setValueComissaoProdutorGrade(undefined);
    setValueComissaoCorretoraGrade(undefined);

    setValuesInputsRecibos(valuesDefaultInputsRecibos);

    setListReportConfirmationContract(undefined);
    setListReportProductionContract(undefined);
    setListReportProductionProducer(undefined);
    setListRecibos(undefined);

    setValueAutoCompGerente({ id: "", label: "" });
    setValueAutoCompOperadora({ label: "", id: "" });
    setValueAutoCompSupervisor({ id: "", label: "" });
    setValueAutoCompModalidade({ label: "", id: "" });
    setValueAutoCompProdutorAll({ label: "", id: "" });
    setValueAutoCompGrupoImposto({ label: "", id: "" });
    setValueAutoCompPerfilAcesso({ label: "", id: "" });
    setValueAutoCompProdutorActive({ label: "", id: "" });
    setValueAutoCompAdministradora({ label: "", id: "" });

    setValueAutoCompletedFiliais(valueDefaultAutoCompletedFiliais);
  };

  return { resetInputs };
};
