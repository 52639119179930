import { SetStateAction, Dispatch, useState, useEffect } from "react";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { IRecibos } from "src/Contexts/Recibos/RecibosTypes";
import { DialogCore } from "src/Pages/components/DialogCore/DialogCore";
import { PDFConfirmationReceipt } from "src/Pages/components/Report/PDF/ConfirmationReceipt";

import { useGetReciboById } from "./Hooks/GetRecibo";

interface IModalViewRecibos {
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  opemModal: boolean;
  id: string;
}

export const ModalRecibosPDF = ({
  id,
  opemModal,
  setOpenModal,
}: IModalViewRecibos) => {
  const { userPerfil } = useAuthContext();

  const [valueReceipt, setValueReceipt] = useState<IRecibos>();

  const { handleGetReciboById, loading } = useGetReciboById({
    setValueReceipt,
  });

  const handleCloseDialog = () => setOpenModal(false);

  useEffect(() => {
    if (opemModal) handleGetReciboById(id);
  }, [opemModal]);

  return (
    <DialogCore
      PaperProps={{ style: { width: "1200px", maxWidth: "100%" } }}
      open={opemModal}
      setOpen={handleCloseDialog}
      title={`Recibo: ${
        valueReceipt?.payments ? valueReceipt?.payments[0]?.produtor : ""
      }`}
    >
      {/* <PDFDownloadLink
        document={
          fileName="meu-pdf.pdf"
          <ReciboConfirmacao
            listRecibos={valueReceipt}
            loadingGravar={loading}
            userPerfil={userPerfil}
            setListRecibos={setValueReceipt}
            title="Recibo confirmações"
          />
        }
      >
        {({ blob, url, loading, error }) => {
          if (loading) return loading

          console.log({ blob });
          return "Baixar PDF";

          // if (error) return "Erro ao gerar PDF"
        }}
      </PDFDownloadLink> */}

      <PDFConfirmationReceipt
        isLoading={loading}
        listRecibos={valueReceipt}
        nomeEmpresa={userPerfil?.empresa.nome}
        setListRecibos={setValueReceipt}
        title="Recibo de confirmações"
      />
    </DialogCore>
  );
};
