import { useState } from "react";
import { AccordionCore } from "src/Pages/components/AccordionCore/AccordionCore";

import { FormReportConfirmationProducer } from "./Components/Form/FormReportConfirmationProducer";
import { ReportConfirmationProducerPDF } from "./Components/PDF/ReportConfirmationProducerPDF";

export const PageReportConfirmationProducer = () => {
  const [expanded, setExpanded] = useState(true);

  return (
    <>
      <AccordionCore
        expanded={expanded}
        sxStyle={{ padding: 0 }}
        title="Clique para abrir FILTRO"
        onChange={() => setExpanded((item) => !item)}
      >
        <FormReportConfirmationProducer />
      </AccordionCore>

      <ReportConfirmationProducerPDF />
    </>
  );
};
