import { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import html2canvas from "html2canvas";
import JsPDF from "jspdf";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { CardCore } from "src/Pages/components/CardCore/CardCore";

import { CardDadosComplementar } from "./Components/CardDadoscomplementar";
import { CardDadosCriacao } from "./Components/CardDadosCriacao";
import { CardDadosInformaçõesContato } from "./Components/CardDadosInformaçõesContato";
import { CardDadosPlano } from "./Components/CardDadosPlano";
import { CardDadosTitular } from "./Components/CardDadosTitular";
import { CardProposta } from "./Components/CardProposta";
import { UseGetContractById } from "./hooks/UseGetContractById";

interface IContract {
  idContract: string;
}
export const Contract = ({ idContract: id }: IContract) => {
  const { valueContractInternal } = useContextContrato();

  const { handleGet } = UseGetContractById();

  useEffect(() => {
    if (id) handleGet(id);
  }, [id]);

  const printRef = useRef<HTMLDivElement>(null);

  // const handleDownloadPdf = async () => {
  //   if (printRef?.current === null) {
  //     return;
  //   }
  //   const element = printRef.current;
  //   const pdf = new JsPDF({
  //     orientation: "portrait",
  //     unit: "in",
  //   });
  //   const pdfWidth = pdf.internal.pageSize.getWidth();
  //   const pdfHeight = pdf.internal.pageSize.getHeight();

  //   const canvas = await html2canvas(element, {
  //     scale: 2,
  //     useCORS: true,
  //   });

  //   const imgData = canvas.toDataURL("image/png");

  //   // Calcular as proporções da imagem
  //   const imgWidth = canvas.width;
  //   const imgHeight = canvas.height;
  //   const ratio = imgWidth / imgHeight;

  //   // Ajustar a imagem para caber na página PDF
  //   let pdfImageWidth = pdfWidth;
  //   let pdfImageHeight = pdfWidth / ratio;

  //   if (pdfImageHeight > pdfHeight) {
  //     pdfImageHeight = pdfHeight;
  //     pdfImageWidth = pdfHeight * ratio;
  //   }

  //   pdf.addImage(imgData, "PNG", 0, 0, pdfImageWidth, pdfImageHeight);
  //   pdf.save("download.pdf");
  // };

  return (
    <>
      {valueContractInternal && (
        <CardCore sxStyle={{ margin: 0 }}>
          <Box sx={{ display: "flex", marginBottom: "12px" }}>
            <Box
              ref={printRef}
              sx={{
                width: "100%",
                border: "1px solid #a2a2a2ab",
                borderRadius: "8px",
                padding: { xs: "12px", sm: "28px" },
              }}
            >
              <CardProposta />
              <CardDadosPlano />
              <CardDadosTitular />
              <CardDadosInformaçõesContato />
              <CardDadosComplementar />
            </Box>

            {/* <Box
              sx={{
                padding: "0",
                display: "flex",
                flexDirection: "column",

                alignItems: "center",
                margin: "0 auto",
                "& > *": { margin: "8px" },
              }}
            >
              <Tooltip title="Em desenvolvimento">
                <Button
                  variant="contained"
                  fullWidth
                  // onClick={handleDownloadPdf}
                >
                  Download PDF
                </Button>
              </Tooltip>
              <Tooltip title="Em desenvolvimento">
                <Button variant="outlined" fullWidth>
                  Editar
                </Button>
              </Tooltip>
              <Tooltip title="Em desenvolvimento">
                <Button variant="outlined" fullWidth>
                  Deletar
                </Button>
              </Tooltip>
            </Box> */}
          </Box>

          <CardDadosCriacao />
        </CardCore>
      )}
    </>
  );
};
