import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";
import { CardHeaderCore } from "src/Pages/components/CardCore/Components/CardHeaderCore";
import { RedeemIcon } from "src/Pages/components/Icons/Icons";
import { LinkCore } from "src/Pages/components/LinkCore/LinkCore";
import { FormatDateBR } from "src/shared/Utils/FormatDateBR";
import { UrlConfig } from "src/shared/Utils/paths";

export const HeaderIsPlanExpired = () => {
  const { userPerfil } = useAuthContext();

  return (
    <>
      {userPerfil?.empresa.isPlanTest &&
        userPerfil?.empresa.remainingTestDays < 0 && (
          <CardHeaderCore
            buttonAdd={[
              <LinkCore key={1} to={UrlConfig.config.plans.url}>
                <ButtonCore title="Conversar com o suporte / contratar plano" />
              </LinkCore>,
            ]}
            icon={<RedeemIcon sx={{ display: { xs: "none", sm: "block" } }} />}
            sxStyle={{ border: `4px solid #ff0000a7`, margin: 0 }}
            title={`Seu plano de teste acabou dia ${FormatDateBR(
              userPerfil?.empresa.dateLimitFree
            )}, para não ficar sem acesso contrate um plano ou peça mais dias para testar.`}
          />
        )}
    </>
  );
};
