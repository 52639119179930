import { IDataTh, ThCoreIndex } from "src/Pages/components";

export const THeadGrupoImposto = () => {
  const dataTh: IDataTh[] = [
    {
      title: "Ações",
      width: "40px",
      align: "left",
    },
    {
      title: "Descrição",
      align: "left",
      width: "180px",
    },
    {
      title: "Porcentagem",
      align: "left",
      width: "80%",
    },
  ];

  return <ThCoreIndex dataTh={dataTh} />;
};
