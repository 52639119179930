import { ChangeEvent, createRef, useEffect } from "react";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { useContextUsuarios } from "src/Contexts/Usuarios/UsuariosContext";
import { ICreatePerfilAcesso } from "src/Contexts/Usuarios/UsuariosTypes";
import { AppTextField } from "src/Pages/components";
import { ComponentPrivate } from "src/Pages/components/ComponentPrivate/ComponentPrivate";
import { DividerCore } from "src/Pages/components/DividerCore/DividerCore";
import { UseVerifyIsPermissionsUser } from "src/Pages/components/hooks/UseVerifyIsPermissionsUser";
import { valuesPermissions } from "src/shared/Utils/ValuesPermissions";

export const FormPermissions = () => {
  const { setValuesInputsPerfilAcesso, valuesInputsPerfilAcesso } =
    useContextUsuarios();

  const perfil_acesso = createRef<HTMLInputElement>();
  const handleFocusedID = () => perfil_acesso.current?.focus();
  const { handleVerifyViewsIndexOf } = UseVerifyIsPermissionsUser();

  useEffect(() => {
    handleFocusedID();
  }, []);

  const handleChange =
    (prop: keyof ICreatePerfilAcesso) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setValuesInputsPerfilAcesso({
        ...valuesInputsPerfilAcesso,
        [prop]: event.target.value,
      });
    };

  const handleChangeCheck = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked === false) {
      const novoArray = valuesInputsPerfilAcesso.permissions?.filter(
        (item) => item !== event.target.name
      );

      setValuesInputsPerfilAcesso({
        ...valuesInputsPerfilAcesso,
        permissions: novoArray,
      });
    } else {
      setValuesInputsPerfilAcesso({
        ...valuesInputsPerfilAcesso,
        permissions: valuesInputsPerfilAcesso.permissions?.concat(
          event.target.name
        ),
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: `calc(100vh - 300px)`,
        maxHeight: { xs: `calc(100vh - 300px)` },
      }}
    >
      <Box
        sx={{
          padding: "12px",
          border: "1px solid #d8d8d8a0",
          borderRadius: "1rem",
          boxShadow: "0 .1rem 1rem .25rem #0000001f",
        }}
      >
        <AppTextField
          inputRef={perfil_acesso}
          label="Nome da permissão *"
          sxStyle={{
            opacity: "",
            width: "350px",
            maxWidth: "100%",
            pointerEvents: "auto",
          }}
          value={valuesInputsPerfilAcesso.perfil_acesso}
          variant="outlined"
          onChange={handleChange("perfil_acesso")}
        />
      </Box>

      <Box id="container-permissions">
        <Box
          sx={{
            padding: "4px 12px",
            margin: "16px 0",
            border: "1px solid #d8d8d8a0",
            borderRadius: "1rem",
            boxShadow: "0 .1rem 1rem .25rem #0000001f",
          }}
        >
          <ComponentPrivate
            isView={handleVerifyViewsIndexOf(
              valuesPermissions.FILIAL.VER_TELA.id
            )}
          >
            <DividerCore title="Filiais" />
            <Box sx={{ padding: "12px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.FILIAL.VER_TELA.id
                    )}
                    name={valuesPermissions.FILIAL.VER_TELA.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Ver Tela"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.FILIAL.CREATE.id
                    )}
                    name={valuesPermissions.FILIAL.CREATE.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Criar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.FILIAL.EDITAR.id
                    )}
                    name={valuesPermissions.FILIAL.EDITAR.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Editar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.FILIAL.DELETAR.id
                    )}
                    name={valuesPermissions.FILIAL.DELETAR.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Deletar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
            </Box>
          </ComponentPrivate>

          <ComponentPrivate
            isView={handleVerifyViewsIndexOf(
              valuesPermissions.PERMISSIONS.VER_TELA.id
            )}
          >
            <DividerCore title="Permissões" />
            <Box sx={{ padding: "12px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.PERMISSIONS.VER_TELA.id
                    )}
                    name={valuesPermissions.PERMISSIONS.VER_TELA.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Ver Tela"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.PERMISSIONS.CREATE.id
                    )}
                    name={valuesPermissions.PERMISSIONS.CREATE.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Criar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.PERMISSIONS.EDITAR.id
                    )}
                    name={valuesPermissions.PERMISSIONS.EDITAR.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Editar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.PERMISSIONS.DELETAR.id
                    )}
                    name={valuesPermissions.PERMISSIONS.DELETAR.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Deletar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
            </Box>
          </ComponentPrivate>

          <ComponentPrivate
            isView={handleVerifyViewsIndexOf(
              valuesPermissions.USER.VER_TELA.id
            )}
          >
            <DividerCore title="Usuários" />

            <Box sx={{ padding: "12px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.USER.VER_TELA.id
                    )}
                    name={valuesPermissions.USER.VER_TELA.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Ver Tela"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.USER.CREATE.id
                    )}
                    name={valuesPermissions.USER.CREATE.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Criar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.USER.EDITAR.id
                    )}
                    name={valuesPermissions.USER.EDITAR.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Editar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.USER.DELETAR.id
                    )}
                    name={valuesPermissions.USER.DELETAR.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Deletar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.USER.DESATIVAR.id
                    )}
                    name={valuesPermissions.USER.DESATIVAR.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Desativar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
            </Box>
          </ComponentPrivate>
        </Box>

        <Box
          sx={{
            padding: "4px 12px",
            margin: "16px 0",
            border: "1px solid #d8d8d8a0",
            borderRadius: "1rem",
            boxShadow: "0 .1rem 1rem .25rem #0000001f",
          }}
        >
          <ComponentPrivate
            isView={handleVerifyViewsIndexOf(
              valuesPermissions.PRODUCER.VER_TELA.id
            )}
          >
            <DividerCore title="Produtores" />
            <Box sx={{ padding: "12px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.PRODUCER.VER_TELA.id
                    )}
                    name={valuesPermissions.PRODUCER.VER_TELA.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Ver Tela"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.PRODUCER.CREATE.id
                    )}
                    name={valuesPermissions.PRODUCER.CREATE.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Criar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.PRODUCER.EDITAR.id
                    )}
                    name={valuesPermissions.PRODUCER.EDITAR.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Editar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.PRODUCER.DELETAR.id
                    )}
                    name={valuesPermissions.PRODUCER.DELETAR.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Deletar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
            </Box>
          </ComponentPrivate>

          <ComponentPrivate
            isView={handleVerifyViewsIndexOf(
              valuesPermissions.CONTA_CORRENTE.VER_TELA.id
            )}
          >
            <DividerCore title="Conta corrente" />
            <Box sx={{ padding: "12px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.CONTA_CORRENTE.VER_TELA.id
                    )}
                    name={valuesPermissions.CONTA_CORRENTE.VER_TELA.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Ver Tela"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.CONTA_CORRENTE.CREATE.id
                    )}
                    name={valuesPermissions.CONTA_CORRENTE.CREATE.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Criar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.CONTA_CORRENTE.DELETAR.id
                    )}
                    name={valuesPermissions.CONTA_CORRENTE.DELETAR.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Deletar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
            </Box>
          </ComponentPrivate>

          <ComponentPrivate
            isView={handleVerifyViewsIndexOf(
              valuesPermissions.IMPOSTOS.VER_TELA.id
            )}
          >
            <DividerCore title="Impostos" />
            <Box sx={{ padding: "12px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.IMPOSTOS.VER_TELA.id
                    )}
                    name={valuesPermissions.IMPOSTOS.VER_TELA.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Ver Tela"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.IMPOSTOS.CREATE.id
                    )}
                    name={valuesPermissions.IMPOSTOS.CREATE.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Criar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.IMPOSTOS.EDITAR.id
                    )}
                    name={valuesPermissions.IMPOSTOS.EDITAR.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Editar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.IMPOSTOS.DELETAR.id
                    )}
                    name={valuesPermissions.IMPOSTOS.DELETAR.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Deletar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.IMPOSTOS.IMPOSTO_GLOBAL.id
                    )}
                    name={valuesPermissions.IMPOSTOS.IMPOSTO_GLOBAL.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Editar Imp. Global"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
            </Box>
          </ComponentPrivate>
        </Box>

        <Box
          sx={{
            padding: "4px 12px",
            margin: "16px 0",
            border: "1px solid #d8d8d8a0",
            borderRadius: "1rem",
            boxShadow: "0 .1rem 1rem .25rem #0000001f",
          }}
        >
          <ComponentPrivate
            isView={handleVerifyViewsIndexOf(
              valuesPermissions.OPERADORA.VER_TELA.id
            )}
          >
            <DividerCore title="Operadoras" />
            <Box sx={{ padding: "12px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.OPERADORA.VER_TELA.id
                    )}
                    name={valuesPermissions.OPERADORA.VER_TELA.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Ver Tela"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.OPERADORA.CREATE.id
                    )}
                    name={valuesPermissions.OPERADORA.CREATE.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Criar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.OPERADORA.EDITAR.id
                    )}
                    name={valuesPermissions.OPERADORA.EDITAR.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Editar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.OPERADORA.DELETAR.id
                    )}
                    name={valuesPermissions.OPERADORA.DELETAR.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Deletar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
            </Box>
          </ComponentPrivate>

          <ComponentPrivate
            isView={handleVerifyViewsIndexOf(
              valuesPermissions.MODALIDADE.VER_TELA.id
            )}
          >
            <DividerCore title="Modalidades" />
            <Box sx={{ padding: "12px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.MODALIDADE.VER_TELA.id
                    )}
                    name={valuesPermissions.MODALIDADE.VER_TELA.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Ver Tela"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.MODALIDADE.CREATE.id
                    )}
                    name={valuesPermissions.MODALIDADE.CREATE.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Criar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.MODALIDADE.EDITAR.id
                    )}
                    name={valuesPermissions.MODALIDADE.EDITAR.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Editar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.MODALIDADE.DELETAR.id
                    )}
                    name={valuesPermissions.MODALIDADE.DELETAR.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Deletar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
            </Box>
          </ComponentPrivate>
          <ComponentPrivate
            isView={handleVerifyViewsIndexOf(
              valuesPermissions.ADMINISTRADORA.VER_TELA.id
            )}
          >
            <DividerCore title="Administradoras" />
            <Box sx={{ padding: "12px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.ADMINISTRADORA.VER_TELA.id
                    )}
                    name={valuesPermissions.ADMINISTRADORA.VER_TELA.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Ver Tela"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.ADMINISTRADORA.CREATE.id
                    )}
                    name={valuesPermissions.ADMINISTRADORA.CREATE.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Criar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.ADMINISTRADORA.EDITAR.id
                    )}
                    name={valuesPermissions.ADMINISTRADORA.EDITAR.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Editar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.ADMINISTRADORA.DELETAR.id
                    )}
                    name={valuesPermissions.ADMINISTRADORA.DELETAR.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Deletar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
            </Box>
          </ComponentPrivate>

          <ComponentPrivate
            isView={handleVerifyViewsIndexOf(
              valuesPermissions.COMMISSIONS_CORRETORA.VER_TELA.id
            )}
          >
            <DividerCore title="Comissão corretora" />
            <Box sx={{ padding: "12px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.COMMISSIONS_CORRETORA.VER_TELA.id
                    )}
                    name={valuesPermissions.COMMISSIONS_CORRETORA.VER_TELA.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Ver Tela"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.COMMISSIONS_CORRETORA.CREATE.id
                    )}
                    name={valuesPermissions.COMMISSIONS_CORRETORA.CREATE.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Criar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.COMMISSIONS_CORRETORA.EDITAR.id
                    )}
                    name={valuesPermissions.COMMISSIONS_CORRETORA.EDITAR.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Editar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.COMMISSIONS_CORRETORA.DELETAR.id
                    )}
                    name={valuesPermissions.COMMISSIONS_CORRETORA.DELETAR.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Deletar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
            </Box>
          </ComponentPrivate>

          <ComponentPrivate
            isView={handleVerifyViewsIndexOf(
              valuesPermissions.COMMISSIONS_PRODUCER.VER_TELA.id
            )}
          >
            <DividerCore title="Comissão produtor" />
            <Box sx={{ padding: "12px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.COMMISSIONS_PRODUCER.VER_TELA.id
                    )}
                    name={valuesPermissions.COMMISSIONS_PRODUCER.VER_TELA.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Ver Tela"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.COMMISSIONS_PRODUCER.CREATE.id
                    )}
                    name={valuesPermissions.COMMISSIONS_PRODUCER.CREATE.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Criar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.COMMISSIONS_PRODUCER.EDITAR.id
                    )}
                    name={valuesPermissions.COMMISSIONS_PRODUCER.EDITAR.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Editar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.COMMISSIONS_PRODUCER.DELETAR.id
                    )}
                    name={valuesPermissions.COMMISSIONS_PRODUCER.DELETAR.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Deletar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
            </Box>
          </ComponentPrivate>
        </Box>

        <Box
          sx={{
            padding: "4px 12px",
            margin: "16px 0",
            border: "1px solid #d8d8d8a0",
            borderRadius: "1rem",
            boxShadow: "0 .1rem 1rem .25rem #0000001f",
          }}
        >
          <ComponentPrivate
            isView={handleVerifyViewsIndexOf(
              valuesPermissions.CONTRATO.VER_TELA.id
            )}
          >
            <DividerCore title="Contratos" />
            <Box sx={{ padding: "12px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.CONTRATO.VER_TELA.id
                    )}
                    name={valuesPermissions.CONTRATO.VER_TELA.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Ver Tela"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.CONTRATO.CREATE.id
                    )}
                    name={valuesPermissions.CONTRATO.CREATE.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Criar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.CONTRATO.EDITAR.id
                    )}
                    name={valuesPermissions.CONTRATO.EDITAR.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Editar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.CONTRATO.DELETAR.id
                    )}
                    name={valuesPermissions.CONTRATO.DELETAR.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Deletar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.CONTRATO.CANCELAR.id
                    )}
                    name={valuesPermissions.CONTRATO.CANCELAR.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Cancelar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
            </Box>
          </ComponentPrivate>

          <ComponentPrivate
            isView={handleVerifyViewsIndexOf(
              valuesPermissions.BORDERO.VER_TELA.id
            )}
          >
            <DividerCore title="Parcelas" />
            <Box sx={{ padding: "12px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.BORDERO.VER_TELA.id
                    )}
                    name={valuesPermissions.BORDERO.VER_TELA.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Ver Tela"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.BORDERO.CREATE.id
                    )}
                    name={valuesPermissions.BORDERO.CREATE.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Nova parcela"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.BORDERO.EDITAR.id
                    )}
                    name={valuesPermissions.BORDERO.EDITAR.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Editar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.BORDERO.DELETAR.id
                    )}
                    name={valuesPermissions.BORDERO.DELETAR.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Deletar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.BORDERO.RECEBER.id
                    )}
                    name={valuesPermissions.BORDERO.RECEBER.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Receber"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.BORDERO.VOLTAR_PAGAMENTO.id
                    )}
                    name={valuesPermissions.BORDERO.VOLTAR_PAGAMENTO.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Voltar pagamento"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.BORDERO.ZERAR_RECEBIMENTO.id
                    )}
                    name={valuesPermissions.BORDERO.ZERAR_RECEBIMENTO.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Zerar recebimento"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
            </Box>
          </ComponentPrivate>
        </Box>

        <ComponentPrivate
          isView={handleVerifyViewsIndexOf(
            valuesPermissions.RECIBOS.VER_TELA.id
          )}
        >
          <Box
            sx={{
              padding: "4px 12px",
              margin: "16px 0",
              border: "1px solid #d8d8d8a0",
              borderRadius: "1rem",
              boxShadow: "0 .1rem 1rem .25rem #0000001f",
            }}
          >
            <DividerCore title="Recibo" />
            <Box sx={{ padding: "12px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.RECIBOS.VER_TELA.id
                    )}
                    name={valuesPermissions.RECIBOS.VER_TELA.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Ver Tela/Movimentar"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
            </Box>
          </Box>
        </ComponentPrivate>

        <ComponentPrivate
          isView={handleVerifyViewsIndexOf(
            valuesPermissions.RELATORIO_PRODUCAO_CONTRATO.VER_TELA.id
          )}
        >
          <Box
            sx={{
              padding: "4px 12px",
              margin: "16px 0",
              border: "1px solid #d8d8d8a0",
              borderRadius: "1rem",
              boxShadow: "0 .1rem 1rem .25rem #0000001f",
            }}
          >
            <DividerCore title="Relatório produção" />
            <Box sx={{ padding: "12px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.RELATORIO_PRODUCAO_CONTRATO.VER_TELA.id
                    )}
                    name={
                      valuesPermissions.RELATORIO_PRODUCAO_CONTRATO.VER_TELA.id
                    }
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Ver produção contrato"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.RELATORIO_PRODUCAO_CORRETOR.VER_TELA.id
                    )}
                    name={
                      valuesPermissions.RELATORIO_PRODUCAO_CORRETOR.VER_TELA.id
                    }
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Ver produção corretor"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.RELATORIO_PRODUCAO_OPERADORA.VER_TELA.id
                    )}
                    name={
                      valuesPermissions.RELATORIO_PRODUCAO_OPERADORA.VER_TELA.id
                    }
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Ver produção operadora"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
            </Box>
          </Box>
        </ComponentPrivate>

        <ComponentPrivate
          isView={handleVerifyViewsIndexOf(
            valuesPermissions.RELATORIO_CONFIMACAO_CONTRATO.VER_TELA.id
          )}
        >
          <Box
            sx={{
              padding: "4px 12px",
              margin: "16px 0",
              border: "1px solid #d8d8d8a0",
              borderRadius: "1rem",
              boxShadow: "0 .1rem 1rem .25rem #0000001f",
            }}
          >
            <DividerCore title="Relatório confirmação" />
            <Box sx={{ padding: "12px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.RELATORIO_CONFIMACAO_CONTRATO.VER_TELA
                        .id
                    )}
                    name={
                      valuesPermissions.RELATORIO_CONFIMACAO_CONTRATO.VER_TELA
                        .id
                    }
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Confirmação por contrato"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.RELATORIO_CONFIMACAO_PRODUCER.VER_TELA
                        .id
                    )}
                    name={
                      valuesPermissions.RELATORIO_CONFIMACAO_PRODUCER.VER_TELA
                        .id
                    }
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Confirmação produtor"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
            </Box>
          </Box>
        </ComponentPrivate>

        <ComponentPrivate
          isView={handleVerifyViewsIndexOf(
            valuesPermissions.DASHBOARD_BROKER.VER_TELA.id
          )}
        >
          <Box
            sx={{
              padding: "4px 12px",
              margin: "16px 0",
              border: "1px solid #d8d8d8a0",
              borderRadius: "1rem",
              boxShadow: "0 .1rem 1rem .25rem #0000001f",
            }}
          >
            <DividerCore title="Dashboard corretora" />
            <Box sx={{ padding: "12px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.DASHBOARD_BROKER.VER_TELA.id
                    )}
                    name={valuesPermissions.DASHBOARD_BROKER.VER_TELA.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Ver Tela"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
            </Box>
          </Box>
        </ComponentPrivate>

        <ComponentPrivate
          isView={handleVerifyViewsIndexOf(
            valuesPermissions.COMPANY.VER_DETALHES.id
          )}
        >
          <Box
            sx={{
              padding: "4px 12px",
              margin: "16px 0",
              border: "1px solid #d8d8d8a0",
              borderRadius: "1rem",
              boxShadow: "0 .1rem 1rem .25rem #0000001f",
            }}
          >
            <DividerCore title="Empresa configurações/pagamentos" />
            <Box sx={{ padding: "12px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.COMPANY.VER_DETALHES.id
                    )}
                    name={valuesPermissions.COMPANY.VER_DETALHES.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Ver Tela"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.COMPANY.EDITAR_DETALHES.id
                    )}
                    name={valuesPermissions.COMPANY.EDITAR_DETALHES.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="Editar detalhes"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
            </Box>
          </Box>
        </ComponentPrivate>

        <ComponentPrivate
          isView={handleVerifyViewsIndexOf(
            valuesPermissions.UTILS.VER_UTILS.id
          )}
        >
          <Box
            sx={{
              padding: "4px 12px",
              margin: "16px 0",
              border: "1px solid #d8d8d8a0",
              borderRadius: "1rem",
              boxShadow: "0 .1rem 1rem .25rem #0000001f",
            }}
          >
            <DividerCore title="UTILS" />

            <Box sx={{ padding: "12px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.UTILS.VER_UTILS.id
                    )}
                    name={valuesPermissions.UTILS.VER_UTILS.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="VER UTILS"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={valuesInputsPerfilAcesso.permissions?.includes(
                      valuesPermissions.UTILS.ESCOLHER_FILIAL.id
                    )}
                    name={valuesPermissions.UTILS.ESCOLHER_FILIAL.id}
                    sx={{ width: "30px", height: "30px" }}
                    onChange={handleChangeCheck}
                  />
                }
                label="ESCOLHER FILIAL"
                labelPlacement="end"
                sx={{ minWidth: "100px" }}
              />
            </Box>

            <ComponentPrivate
              isView={handleVerifyViewsIndexOf(
                valuesPermissions.LOGS.VER_TELA.id
              )}
            >
              <DividerCore title="CONFIG" />

              <Box sx={{ padding: "12px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={valuesInputsPerfilAcesso.permissions?.includes(
                        valuesPermissions.LOGS.VER_TELA.id
                      )}
                      name={valuesPermissions.LOGS.VER_TELA.id}
                      sx={{ width: "30px", height: "30px" }}
                      onChange={handleChangeCheck}
                    />
                  }
                  label="VER LOGS"
                  labelPlacement="end"
                  sx={{ minWidth: "100px" }}
                />
              </Box>
            </ComponentPrivate>
          </Box>
        </ComponentPrivate>
      </Box>
    </Box>
  );
};
