const colorsPlans = {
  custom: "rgb(240, 205, 52)",
  prata: "rgb(151, 151, 151)",
  bronze: "rgb(233, 139, 32)",
};

export const handleGetColorPlans = (title: string) => {
  if (title === "Custom") return colorsPlans.custom;
  if (title === "Prata") return colorsPlans.prata;
  if (title === "Bronze") return colorsPlans.bronze;

  return "#fff";
};
