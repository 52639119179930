import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { useContextProdutor } from "src/Contexts/produtores/ProdutoresContext";
import { api } from "src/shared/setup/API/api";

export const useHttpRankingCorretores = () => {
  const { setListRankingCorretor } = useContextProdutor();
  const { handleGetAlert } = useLayoutMainContext();
  const { filterHome } = useAuthContext();

  const handleSubmit = async () => {
    api
      .post("/produtor/rankingCorretores", {
        startDate: filterHome.startDate,
        endDate: filterHome.endDate,
        limit: 10,
      })
      .then((res) => setListRankingCorretor(res.data))
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      );
  };

  return { handleSubmit };
};
