import { FormularioContrato } from "./Components/Form/IndexForm";
import { TableContratos } from "./Components/table/TableContratos";

export const PageContratos = () => {
  return (
    <>
      <FormularioContrato />

      <TableContratos />
    </>
  );
};
