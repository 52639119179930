import { Children, useEffect, useState } from "react";
import { formatString } from "@format-string/core";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextUsuarios } from "src/Contexts/Usuarios/UsuariosContext";
import {
  ModalDeleteCore,
  TableRowCore,
  TableCore,
  TdCore,
} from "src/Pages/components";
import { handleChipCoreCategories } from "src/Pages/components/CategoryComponentMap";
import { UseVerifyIsPermissionsUser } from "src/Pages/components/hooks/UseVerifyIsPermissionsUser";
import {
  EditIcon,
  CheckIcon,
  CloseIcon,
  PersonIcon,
  PersonOffIcon,
  DeleteForeverIcon,
} from "src/Pages/components/Icons/Icons";
import { UseDelete } from "src/Pages/components/ModalDeleteCore/Hooks/UseDelete";
import { ActionPopoverTable } from "src/Pages/components/table/ActionPopover/ActionPopover";
import { valuesPermissions } from "src/shared/Utils/ValuesPermissions";

import { ModalChangeIsActiveUser } from "../ChangeIsActiveUser/ModalChangeIsActiveUser";
import { ModalUser } from "../FormUser";

import { useHttpTableUsuarios } from "./Hooks/useHttpTableUsuarios";
import { THeadUser } from "./THead";

export const TableUser = () => {
  const { handleVerifyPermissionItem } = UseVerifyIsPermissionsUser();
  const { handleGet } = useHttpTableUsuarios();
  const { listUsuario } = useContextUsuarios();

  const [openModalIsActiveUser, setOpenModalIsActiveUser] = useState(false);
  const [valueIsActiveUser, setValueIsActiveUser] = useState(true);
  const [openModalUser, setOpenModalUser] = useState(false);

  const {
    setActive_incluir,
    setNameSearch,
    itensPerPage,
    currentPage,
    nameSearch,
    attTable,
    setId,
  } = useConfigPageContext();

  const {
    handleDelete,
    setId: setIdModal,
    open: openModalDelete,
    loading: loadingDelete,
    setOpen: setOpenModalDelete,
  } = UseDelete({ url: "/usuarios" });

  const handleGetIdToEdit = (id: string) => {
    setId(id);
    setOpenModalUser(true);
  };

  useEffect(() => {
    handleGet({ itensPerPage, currentPage, nameSearch });
  }, [attTable, itensPerPage, currentPage, nameSearch]);

  return (
    <TableCore
      Modals={
        <>
          <ModalDeleteCore
            loading={loadingDelete}
            modalOpen={openModalDelete}
            no="Não, Cancelar"
            titlePrimary="Você deseja excluir da lista ?"
            yes="Sim, Deletar !"
            onClickFalse={() => setOpenModalDelete(false)}
            onClickTrue={() => handleDelete()}
          />
          <ModalChangeIsActiveUser
            IsActiveAccess={valueIsActiveUser}
            modalIsActiveAccess={openModalIsActiveUser}
            setModalIsActiveAccess={setOpenModalIsActiveUser}
          />
        </>
      }
      THead={<THeadUser />}
      cardAdd={{
        title: "Usuários",
        icon: <ManageAccountsIcon />,
        buttonAdd: [
          <ModalUser
            key={1}
            openModal={openModalUser}
            setOpenModal={setOpenModalUser}
          />,
        ],
      }}
      qtdList={listUsuario.length}
      setNameSearch={setNameSearch}
    >
      {Children.toArray(
        listUsuario.map((item) => {
          return (
            <TableRowCore id={item.id_tenant}>
              <TdCore
                values={
                  <ActionPopoverTable
                    optionsList={[
                      {
                        title: "Editar",
                        background: "warning",
                        icon: <EditIcon fontSize="small" />,
                        isDisabled: handleVerifyPermissionItem(
                          valuesPermissions.USER.EDITAR.id
                        ),
                        onClick: () => {
                          setId(item.id_tenant);
                          handleGetIdToEdit(item.id_tenant);
                          setActive_incluir(true);
                        },
                      },
                      {
                        title: !item.is_habilitado
                          ? "Ativar usuário"
                          : "Desativar usuário",
                        background: "warning",
                        icon: item.is_habilitado ? (
                          <PersonOffIcon fontSize="small" />
                        ) : (
                          <PersonIcon fontSize="small" />
                        ),
                        isDisabled:
                          item.type_user === "ADM" ||
                          handleVerifyPermissionItem(
                            valuesPermissions.USER.DESATIVAR.id
                          ),

                        onClick: () => {
                          setId(item.id_tenant);
                          setOpenModalIsActiveUser(true);
                          setValueIsActiveUser(!item.is_habilitado);
                        },
                      },
                      {
                        title: "Excluir",
                        background: "error",
                        icon: <DeleteForeverIcon fontSize="small" />,
                        isDisabled:
                          item.type_user === "ADM" ||
                          handleVerifyPermissionItem(
                            valuesPermissions.USER.DELETAR.id
                          ),
                        onClick: () => {
                          setIdModal(item.id_tenant);
                          setOpenModalDelete(true);
                        },
                      },
                    ]}
                  />
                }
              />
              <TdCore
                isAvatar
                isLimit
                isLimitQTD={30}
                subTitle={item.type_user === "ADM" && "ADMINISTRADOR"}
                sxStyle={{ fontWeight: "600", padding: "4px 0" }}
                titleAvatar={item.nome}
                values={`${item.id} - ${item.nome}`}
              />
              <TdCore
                subTitle={`${formatString({
                  type: "phone",
                  value: item.telefone1,
                })}`}
                values={item.email}
              />
              <TdCore values={handleChipCoreCategories(item.nome_filial)} />
              <TdCore values={handleChipCoreCategories(item.perfil_acesso)} />
              <TdCore
                values={
                  item.type_user === "USER"
                    ? handleChipCoreCategories(
                        `${item.horario_inicial} - ${item.horario_final}`
                      )
                    : handleChipCoreCategories("ADMINISTRADOR")
                }
              />
              <TdCore
                textAlign="center"
                values={
                  item.is_habilitado ? (
                    <CheckIcon color="success" />
                  ) : (
                    <CloseIcon color="error" />
                  )
                }
              />
            </TableRowCore>
          );
        })
      )}
    </TableCore>
  );
};
