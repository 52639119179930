import { FC, ReactNode } from "react";
import { Box, SxProps } from "@mui/material";

interface ICardContract {
  title?: string;
  sxStyle?: SxProps;
  children: ReactNode;
}

export const CardContract: FC<ICardContract> = ({
  title,
  sxStyle,
  children,
}) => {
  return (
    <>
      {title && (
        <Box
          sx={{
            background: "#cfd5fd99",
            borderRadius: "12px",
            padding: { xs: "6px 8px", sm: "8px 12px" },
            fontWeight: "500",
            margin: { xs: "6px 0", sm: "12px 0" },
            fontSize: { xs: "12px", sm: "16px" },
          }}
        >
          {title}
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          padding: { xs: "4px 8px", sm: "4px 12px" },

          "& > *:not(:last-child)": { margin: "12px 20px 12px 0" },
          ...sxStyle,
        }}
      >
        {children}
      </Box>
    </>
  );
};
