import React from "react";
import {
  Tooltip,
  TableCell,
  alpha,
  Box,
  Avatar,
  SxProps,
  useTheme,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { handleIfLimitCharacter } from "src/shared/Utils/LimitText";

interface ITdCore {
  values: string | number | null | React.ReactNode;
  textAlign?: "end" | "left" | "center" | "right" | "start";
  borderRadius?: string;
  borderBottom?: string;
  tooltip?: string;
  padding?: string;
  color?: string;

  onClick?: () => void;
  disabledClick?: boolean;
  sxStyle?: SxProps;

  isLimit?: boolean;
  isLimitQTD?: number;
  subTitle?: string | number | null | React.ReactNode;
  isAvatar?: boolean;
  titleAvatar?: string;
}

export const TdCore = ({
  values,
  onClick,
  borderRadius = "12px",
  padding = "5px 0px",
  textAlign = "left",
  borderBottom,
  tooltip,
  color,
  isLimit,
  isLimitQTD,
  sxStyle,
  subTitle,
  isAvatar,
  disabledClick,
  titleAvatar,
}: ITdCore) => {
  const theme = useTheme();

  return (
    <>
      <Tooltip
        title={
          tooltip ||
          (isLimitQTD &&
          typeof values === "string" &&
          values.length > isLimitQTD
            ? values
            : "")
        }
      >
        <TableCell
          sx={{ padding: "4px 12px" }}
          onClick={!disabledClick ? onClick : () => null}
        >
          <Box
            sx={{ display: isAvatar ? "flex" : "block", alignItems: "center" }}
          >
            {isAvatar && titleAvatar && (
              <Avatar
                sx={{
                  bgcolor: blue[600],
                  fontSize: "1.3rem",
                  marginRight: "8px",
                  width: 36,
                  height: 36,
                }}
              >
                {titleAvatar[0]?.toUpperCase()}
              </Avatar>
            )}
            <Box>
              <Box
                component="span"
                sx={{
                  color: color || "#3b3b3b",
                  textAlign: { textAlign },
                  display: "block",
                  fontSize: ".8rem",
                  fontWeight: "500",
                  letterSpacing: ".4px",
                  padding,
                  borderRadius,
                  borderBottom,
                  whiteSpace: "nowrap",
                  ...sxStyle,
                }}
              >
                {typeof values === "string" && isLimit && isLimitQTD
                  ? handleIfLimitCharacter({
                      LIMIT: isLimitQTD,
                      value: values,
                    })
                  : values}
              </Box>

              {subTitle && (
                <Box
                  component="span"
                  sx={{
                    color: alpha((color as string) || "#3b3b3b", 0.9),
                    textAlign: { textAlign },
                    fontSize: ".7rem",
                    fontWeight: "500",
                    letterSpacing: ".4px",
                  }}
                >
                  {subTitle}
                </Box>
              )}
            </Box>
          </Box>
        </TableCell>
      </Tooltip>
    </>
  );
};
