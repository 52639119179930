import { useContextCompany } from "src/Contexts/Company/CompanyContext";
import { IGetCompanyDetails } from "src/Contexts/Company/CompanyContextTypes";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";
import { apenasNumeros } from "src/shared/Utils/FormatMoney";

export const UseFeaturesFormCompany = () => {
  const { setLoadingGravar } = useConfigPageContext();

  const { valuesInputsCompanyDetails } = useContextCompany();
  const { handleGetAlert } = useLayoutMainContext();

  const handleEditCompany = () => {
    if (!valuesInputsCompanyDetails.fantasyName)
      return handleGetAlert({ message: `Enviar um nome fantasia !` });

    const DataUpdateFormat: IGetCompanyDetails = {
      id: valuesInputsCompanyDetails.id,
      fantasyName: valuesInputsCompanyDetails.fantasyName,
      cnpj: apenasNumeros(valuesInputsCompanyDetails.cnpj) as string,

      address: {
        addressNumber: valuesInputsCompanyDetails.address_address_number,
        complement: valuesInputsCompanyDetails.address_complement,
        district: valuesInputsCompanyDetails.address_district,
        zipCode: apenasNumeros(valuesInputsCompanyDetails.address_zip_code),
        street: valuesInputsCompanyDetails.address_street,
        state: valuesInputsCompanyDetails.address_state,
        city: valuesInputsCompanyDetails.address_city,
      },

      responsible: {
        telephone1: apenasNumeros(
          valuesInputsCompanyDetails.responsible_telephone1
        ) as string,

        telephone2: apenasNumeros(
          valuesInputsCompanyDetails.responsible_telephone2
        ) as string,
        email: valuesInputsCompanyDetails.responsible_email,
        name: valuesInputsCompanyDetails.responsible_name,
      },
    };

    setLoadingGravar(true);

    return api
      .put(`/company/details`, DataUpdateFormat)
      .then((res) => handleGetAlert({ message: res.data.message }))
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoadingGravar(false));
  };

  const handleSubmit = () => handleEditCompany();

  return { handleSubmit };
};
