import { ChangeEvent, useState } from "react";
import { formatString } from "@format-string/core";
import { Box, FormControl, MenuItem, Select, InputLabel } from "@mui/material";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { ICreateContrato } from "src/Contexts/Lancamentos/Contrato/ContratosTypes";
import { AppTextField } from "src/Pages/components";
import { DividerCore } from "src/Pages/components/DividerCore/DividerCore";
import { MenuSelectedPermissionsStyle } from "src/shared/StylesTheme/StyleMenuSelect";
import { handleFormatCep } from "src/shared/Utils/FormatCEP";
import { apenasNumeros } from "src/shared/Utils/FormatMoney";
import { unidadesFederativas } from "src/shared/Utils/ListUFBR";

import { UseGetCEPContratos } from "./Hooks/UseGetCEPContratos";
import { UseGetCpfAndCNPJ } from "./Hooks/UseGetCpfAndCNPJ";

export const FormContato = () => {
  const { active_incluir } = useConfigPageContext();
  const { valuesInputsContrato, setValuesInputsContrato } =
    useContextContrato();

  UseGetCEPContratos();
  UseGetCpfAndCNPJ();

  const handleChange =
    (prop: keyof ICreateContrato, option?: "phone" | "apenasNumero" | "CEP") =>
    (event: ChangeEvent<HTMLInputElement>) => {
      if (option === "phone") {
        setValuesInputsContrato({
          ...valuesInputsContrato,
          [prop]: formatString({ value: event.target.value, type: "phone" }),
        });
      } else if (option === "CEP") {
        setValuesInputsContrato({
          ...valuesInputsContrato,
          [prop]: handleFormatCep(event.target.value),
        });
      } else if (option === "apenasNumero") {
        setValuesInputsContrato({
          ...valuesInputsContrato,
          [prop]: apenasNumeros(event.target.value),
        });
      } else
        setValuesInputsContrato({
          ...valuesInputsContrato,
          [prop]: event.target.value,
        });
    };

  const [view, setView] = useState(true);

  return (
    <>
      <DividerCore
        title="Endereço/Contato"
        onClick={() => setView((item) => !item)}
      />
      <Box sx={{ display: view ? "flex" : "none", flexWrap: "wrap" }}>
        <AppTextField
          label="Email"
          sxStyle={{ width: "200px" }}
          value={valuesInputsContrato.email}
          onChange={handleChange("email")}
        />
        <AppTextField
          label="Telefone 1"
          sxStyle={{ width: "150px" }}
          value={valuesInputsContrato.telefone_1}
          onChange={handleChange("telefone_1", "phone")}
        />
        <AppTextField
          label="Telefone 2"
          sxStyle={{ width: "150px" }}
          value={valuesInputsContrato.telefone_2}
          onChange={handleChange("telefone_2", "phone")}
        />

        <AppTextField
          label="CEP"
          sxStyle={{ width: "100px" }}
          value={valuesInputsContrato.cep}
          onChange={handleChange("cep", "CEP")}
        />
        <AppTextField
          label="Endereço"
          sxStyle={{ width: "200px" }}
          value={valuesInputsContrato.endereco}
          onChange={handleChange("endereco")}
        />
        <AppTextField
          label="Número"
          sxStyle={{ width: "70px" }}
          value={valuesInputsContrato.endereco_numero}
          onChange={handleChange("endereco_numero")}
        />

        <FormControl
          focused
          disabled={!active_incluir}
          sx={{ width: "80px" }}
          variant="standard"
        >
          <InputLabel id="demo-simple-select-label">UF</InputLabel>
          <Select
            MenuProps={MenuSelectedPermissionsStyle}
            id="demo-simple-select"
            label="UF"
            labelId="demo-simple-select-label"
            value={valuesInputsContrato?.uf}
            onChange={handleChange("uf") as never}
          >
            <MenuItem value=" " />
            {unidadesFederativas.map((item) => {
              return (
                <MenuItem key={item.sigla} value={item.sigla}>
                  {item.nome}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <AppTextField
          label="Cidade"
          sxStyle={{ width: "100px" }}
          value={valuesInputsContrato.cidade}
          onChange={handleChange("cidade")}
        />
        <AppTextField
          label="Bairro"
          sxStyle={{ width: "150px" }}
          value={valuesInputsContrato.bairro}
          onChange={handleChange("bairro")}
        />
        <AppTextField
          label="Complemento"
          sxStyle={{ width: "150px" }}
          value={valuesInputsContrato.complemento}
          onChange={handleChange("complemento")}
        />
      </Box>
    </>
  );
};
