import { useState } from "react";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { useContextRecoverPassword } from "src/Contexts/RecoverPassword/RecoverPasswordContext";
import { api } from "src/shared/setup/API/api";

interface IValidIsLink {
  id: string;
}

export const UseVerifyIsValidLinkRecoverPassword = () => {
  const { setIsValidLink } = useContextRecoverPassword();
  const { handleGetAlert } = useLayoutMainContext();

  const [loading, setLoading] = useState(false);

  const handleValidIsLink = async ({ id }: IValidIsLink) => {
    setLoading(true);

    return api
      .post(`/recoverPassword/exists/${id}`)
      .then(({ data }) => {
        setIsValidLink(data.isValid);
        handleGetAlert({ message: data.message });
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  return {
    handleSubmit: handleValidIsLink,
    loading,
  };
};
