import { useAuthContext } from "src/Contexts/AuthContext/authContext";

export const UseVerifyIsPermissionsUser = () => {
  const { userPerfil } = useAuthContext();

  const handleVerifyViewsIndexOf = (condition: string): boolean => {
    const userRoot = userPerfil?.type_user === "ROOT";
    const userADM = userPerfil?.type_user === "ADM";

    const userADMWithPermissions =
      userADM ||
      (userPerfil?.permissions.permissions.includes(condition) as boolean);

    return userRoot || userADMWithPermissions;
  };

  const handleVerifyPermissionItem = (condition: string) => {
    const userADM = userPerfil?.type_user === "USER";

    const result =
      userADM && !userPerfil?.permissions.permissions.includes(condition);

    return result;
  };

  return { handleVerifyViewsIndexOf, handleVerifyPermissionItem };
};
