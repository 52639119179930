import { FormComissaoCorretora } from "./components/Form/FormularioComissaoCorretora";
import { TableComissaoCorretora } from "./components/table/TableComissaoCorretora";

export const PageComissaoCorretora = () => {
  return (
    <>
      <FormComissaoCorretora />
      <TableComissaoCorretora />
    </>
  );
};
