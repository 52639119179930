import { useState } from "react";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";
import { handleFormatToNumeric } from "src/shared/Utils/FormatMoney";

interface IHandleUpdateStatusContratos {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UseFormFormNovaParcela = ({
  setOpenModal,
}: IHandleUpdateStatusContratos) => {
  const { setAttTable, id } = useConfigPageContext();
  const { handleGetAlert } = useLayoutMainContext();
  const { valuesInputEditarParcelaBordero } = useContextContrato();

  const [loading, setLoading] = useState(false);

  const handleEditarParcela = async () => {
    setLoading(true);

    await api
      .put(`/bordero/${id}`, {
        contratos_mensalidade: handleFormatToNumeric(
          valuesInputEditarParcelaBordero?.contratos_mensalidade
        ),

        data_vencimento_parcela:
          valuesInputEditarParcelaBordero?.data_vencimento_parcela,

        numero_parcela: valuesInputEditarParcelaBordero?.numero_parcela,
        contratos_vidas: valuesInputEditarParcelaBordero?.contratos_vidas,

        porcentagem_comissao_corretora:
          valuesInputEditarParcelaBordero?.porcentagem_comissao_corretora,
        porcentagem_comissao_corretor:
          valuesInputEditarParcelaBordero?.porcentagem_comissao_corretor,
        porcentagem_comissao_gerente:
          valuesInputEditarParcelaBordero?.porcentagem_comissao_gerente,
        porcentagem_comissao_supervisor:
          valuesInputEditarParcelaBordero?.porcentagem_comissao_supervisor,
      })
      .then((res) => {
        handleGetAlert({ message: res.data.message });
        setAttTable((item) => !item);
        setOpenModal(false);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  return {
    handleEditarParcela,
    loading,
  };
};
