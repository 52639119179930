import { IDataTh, ThCoreIndex } from "src/Pages/components";

export const THeadTableFilaisDashboard = () => {
  const dataTh: IDataTh[] = [
    {
      title: "Posição",
      align: "center",
      width: "30px",
    },
    {
      title: "Filiais",
      align: "left",
      width: "110px",
      minWidth: "110px",
    },
    {
      title: "Lucro",
      align: "left",
      width: "110px",
      minWidth: "110px",
    },
  ];

  return <ThCoreIndex dataTh={dataTh} />;
};
