import { createRef, useEffect } from "react";
import { AppTextField } from "src/Pages/components";
import { DividerCore } from "src/Pages/components/DividerCore/DividerCore";

interface IHandleUpdateStatusContratos {
  setValue: React.Dispatch<React.SetStateAction<string>>;
  value: string;
}

export const FormGetUltimaParcela = ({
  setValue,
  value,
}: IHandleUpdateStatusContratos) => {
  const refOrigem = createRef<HTMLInputElement>();
  const handleFocusedID = () => refOrigem.current?.focus();

  useEffect(() => {
    handleFocusedID();
  }, []);

  return (
    <>
      <DividerCore title="Proposta" />

      <AppTextField
        fullWidth
        label="Proposta *"
        inputRef={refOrigem}
        sxStyle={{ opacity: "", pointerEvents: "auto", margin: 0 }}
        value={value}
        variant="outlined"
        onChange={(e) => setValue(e.target.value)}
      />
    </>
  );
};
