import { Button, Tooltip, useTheme } from "@mui/material";

import { FilterAltOffIcon, ManageSearchIcon } from "../../Icons/Icons";

type IButtonsFormFilter = {
  FilterClear: () => Promise<void>;
  handleOpen: () => void;
};

export const ButtonsHeaderFormFilter = ({
  FilterClear,
  handleOpen,
}: IButtonsFormFilter) => {
  const theme = useTheme();

  return (
    <>
      <Tooltip title="Abrir filtros">
        <Button
          endIcon={<ManageSearchIcon />}
          sx={{
            borderRadius: "6px",
            marginLeft: "6px",
          }}
          variant="outlined"
          onClick={handleOpen}
        >
          Filtro
        </Button>
      </Tooltip>
      <Tooltip title="Limpar filtros">
        <Button
          color="error"
          sx={{
            marginLeft: "6px",
            [theme.breakpoints.down("sm")]: {
              margin: "8px 0",
            },
          }}
          variant="outlined"
          onClick={FilterClear}
        >
          <FilterAltOffIcon />
        </Button>
      </Tooltip>
    </>
  );
};
