import { GetDateUSSemValue } from "src/shared/Utils/FormatDateBR";

import {
  ICreateContrato,
  IValuesFilterBordero,
  IValuesFilterContrato,
  ICreateBorderoParcela,
  IValuesUploadCommission,
} from "../ContratosTypes";

export const valueDefaultInputContratos: ICreateContrato = {
  assinatura_data: `${GetDateUSSemValue()}`,
  cod_proposta: "",
  tipo_contrato: "PF",
  cpf_cnpj: "",

  titular_pf: "",
  rg_pf: "",
  mae_pf: "",
  estado_civil_pf: "",
  data_nacimento_pf: "",
  genero_pf: "",
  responsavel_pf: "",
  cpf_responsavel_pf: "",
  data_nascimento_responsavel_pf: "",

  razao_social_pj: "",
  contato_responsavel_pj: "",
  cargo_pj: "",

  email: "",
  telefone_1: "",
  telefone_2: "",
  cep: "",
  endereco: "",
  endereco_numero: "",
  complemento: "",
  bairro: "",
  cidade: "",
  uf: "",
  emissao_data: `${GetDateUSSemValue()}`,
  vencimento_data: `${GetDateUSSemValue()}`,
  vigencia_data: `${GetDateUSSemValue()}`,
  mensalidade_value: "0.00",

  is_collect_fee_producer: "NAO",

  taxa_value: "0.00",
  vidas: "1",

  iof_porc: "0.00",

  dependentes: [],
  observacoes: "",
};

export const valueDefaultInputBorderoParcelas: ICreateBorderoParcela = {
  id: "",
  valor_recebido: "",
  data_recebimento: `${GetDateUSSemValue()}`,
  data_previsao: "",
  observacao: "",
  contratos_vidas: 1,
};

export const valuesDefaultFilterContrato: IValuesFilterContrato = {
  endDateEmission: "",
  startDateEmission: "",
  cod_proposta: "",
  startDateSignature: "",
  endDateSignature: "",
  startEffectiveDate: "",
  endEffectiveDate: "",
  nameOrRazao: "",
  cpfCnpj: "",
};

export const valuesDefaultFilterBordero: IValuesFilterBordero = {
  cod_proposta: "",
  startDateSignature: "",
  endDateSignature: "",
  endDateReceipt: "",
  endDueDate: "",
  installmentNumber: "",
  startDateReceipt: "",
  startDueDate: "",
  endDateEmission: "",
  startDateEmission: "",

  installmentReceipt: "ALL",
  isInstallmentManager: "ALL",
  isInstallmentBroker: "ALL",
  isInstallmentSupervisor: "ALL",

  nameOrRazao: "",
  cpfCnpj: "",
};
export const valuesDefaultUploadCommission: IValuesUploadCommission = {
  data_pagamento: `${GetDateUSSemValue()}`,
  file: [],
  type_file: "TXT",
  id_operadora: "",
};
