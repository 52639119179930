import { useState } from "react";
import { AccordionCore } from "src/Pages/components/AccordionCore/AccordionCore";

import { FormReportConfirmationContract } from "./Components/Form/FormReportConfirmationContract";
import { ReportConfirmationContractPDF } from "./Components/PDF/ReportConfirmationContractPDF";

export const PageReportConfirmationContract = () => {
  const [expanded, setExpanded] = useState(true);

  return (
    <>
      <AccordionCore
        expanded={expanded}
        sxStyle={{ padding: 0 }}
        title="Clique para abrir FILTRO"
        onChange={() => setExpanded((item) => !item)}
      >
        <FormReportConfirmationContract />
      </AccordionCore>

      <ReportConfirmationContractPDF />
    </>
  );
};
