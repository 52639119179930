import { FC, createContext, useContext, useState, ReactNode } from "react";

import { IAutoCompleted } from "../types/ProdutoresContext.Types";

import { IAutoCompletedAllContextProvider } from "./types";

const UtilsContext = createContext({} as IAutoCompletedAllContextProvider);

const valueDefaultInput = { label: "", id: "" };

export const AutoCompletedAllContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [valueAutoCompOperadoraMultiple, setValueAutoCompOperadoraMultiple] =
    useState<IAutoCompleted[]>([]);

  const [valueAutoCompFiliais, setValueAutoCompFiliais] = useState<
    IAutoCompleted[]
  >([]);

  const [valueAutoCompletedFiliais, setValueAutoCompletedFiliais] =
    useState<IAutoCompleted>(valueDefaultInput);

  const [valueAutoCompPerfilAcesso, setValueAutoCompPerfilAcesso] =
    useState<IAutoCompleted>(valueDefaultInput);

  const [valueAutoCompGrupoImposto, setValueAutoCompGrupoImposto] =
    useState<IAutoCompleted>(valueDefaultInput);

  const [valueAutoCompGerente, setValueAutoCompGerente] =
    useState<IAutoCompleted>(valueDefaultInput);

  const [valueAutoCompSupervisor, setValueAutoCompSupervisor] =
    useState<IAutoCompleted>(valueDefaultInput);

  const [valueAutoCompProdutorActive, setValueAutoCompProdutorActive] =
    useState<IAutoCompleted>(valueDefaultInput);

  const [valueAutoCompAdministradora, setValueAutoCompAdministradora] =
    useState<IAutoCompleted>(valueDefaultInput);

  const [valueAutoCompModalidade, setValueAutoCompModalidade] =
    useState<IAutoCompleted>(valueDefaultInput);

  const [valueAutoCompOperadora, setValueAutoCompOperadora] =
    useState<IAutoCompleted>(valueDefaultInput);

  const [valueAutoCompOperadoraFilter, setValueAutoCompOperadoraFilter] =
    useState<IAutoCompleted>(valueDefaultInput);

  const [valueAutoProdutor, setValueAutoProdutor] =
    useState<IAutoCompleted>(valueDefaultInput);

  return (
    <UtilsContext.Provider
      value={{
        valueAutoProdutor,
        valueAutoCompFiliais,
        setValueAutoProdutor,
        valueAutoCompGerente,
        valueAutoCompOperadora,
        setValueAutoCompGerente,
        valueAutoCompModalidade,
        setValueAutoCompFiliais,
        valueAutoCompSupervisor,
        setValueAutoCompOperadora,
        valueAutoCompPerfilAcesso,
        valueAutoCompGrupoImposto,
        setValueAutoCompModalidade,
        valueAutoCompletedFiliais,
        setValueAutoCompSupervisor,
        valueAutoCompAdministradora,
        valueAutoCompProdutorActive,
        setValueAutoCompPerfilAcesso,
        setValueAutoCompletedFiliais,
        setValueAutoCompGrupoImposto,
        valueAutoCompOperadoraFilter,
        valueAutoCompOperadoraMultiple,
        setValueAutoCompProdutorActive,
        setValueAutoCompAdministradora,
        setValueAutoCompOperadoraFilter,
        setValueAutoCompOperadoraMultiple,
      }}
    >
      {children}
    </UtilsContext.Provider>
  );
};

export const UseAutoCompletedAll = (): IAutoCompletedAllContextProvider =>
  useContext(UtilsContext);
