import { Player } from "@lottiefiles/react-lottie-player";
import { Box } from "@mui/material";

export const LoadingPage = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Player
        autoplay
        loop
        src="https://assets7.lottiefiles.com/packages/lf20_hslwihoj.json"
        style={{ height: "150px", width: "150px" }}
      />
    </Box>
  );
};
