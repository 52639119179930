import { AppBar, Toolbar, Box, Chip } from "@mui/material";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";

import { IsPlanTeste } from "./components/IsPlanTeste/IsPlanTeste";
import { LogoHeader } from "./components/LogoHeader/LogoHeader";
import { ImgLogout } from "./components/LogouImg";
import { MenuNovidade } from "./components/MenuNovidade";
import { ToggleFullScreen } from "./components/ToggleFullScreen/ToggleFullScreen";
import { ToggleOpenMenu } from "./components/ToggleOpenMenu/ToggleOpenMenu";
import { ToggleReaload } from "./components/ToggleReaload/ToggleReaload";
// import { ToggleTheme } from "./components/ToggleTheme/ToggleTheme";

export const AppBarMain = () => {
  const { userPerfil } = useAuthContext();

  return (
    <AppBar sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}>
      <Toolbar sx={{ background: "#ffffff" }}>
        <ToggleOpenMenu />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <LogoHeader />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "& > .MuiButtonBase-root": {
                marginRight: { xs: "-5px", sm: "0" },
              },
            }}
          >
            <IsPlanTeste />

            {(userPerfil?.type_user === "ADM" ||
              userPerfil?.type_user === "ROOT") && (
              <Chip
                color="info"
                label={userPerfil.type_user === "ADM" ? "ADM" : "ROOT"}
                size="small"
                sx={{ maxWidth: "140px" }}
                variant="outlined"
              />
            )}
            <MenuNovidade />
            {/* <ToggleTheme /> */}
            <ToggleReaload />
            <ToggleFullScreen />
            <ImgLogout />
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
