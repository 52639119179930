import {
  createContext,
  useContext,
  useEffect,
  ReactNode,
  useState,
  FC,
} from "react";
import {
  PageComissoesAdministradora,
  PageComissoesModalidade,
  PageComissaoCorretora,
  PageComissaoProdutor,
  PagePerfisDeAcesso,
  PageContaCorrente,
  PageProdutores,
  PageOperadoras,
  PageContratos,
  PageImpostos,
  PageBordero,
  PageRecibos,
  PageFiliais,
  PageUsers,
} from "src/Pages/TelasSistemas";
import { ContractInternal } from "src/Pages/TelasSistemas/3-Lancamentos/0 - Contratos/ContratoInterno";
import { PageCompany } from "src/Pages/TelasSistemas/Company";
import { PageLogs } from "src/Pages/TelasSistemas/Config/Logs";
import { ControleEmpresa } from "src/Pages/TelasSistemas/ControlCompanies";
import { PageDashboardBroker } from "src/Pages/TelasSistemas/DashboardBroker/PageDashboardBroker";
import { PageHelp } from "src/Pages/TelasSistemas/Help/PageHelp";
import { PagePerfil } from "src/Pages/TelasSistemas/Perfil";
import { PagePlansConfig } from "src/Pages/TelasSistemas/Plans";
import { PageReportConfirmationContract } from "src/Pages/TelasSistemas/Relatorios/Confirmation/ConfirmationContract";
import { PageReportConfirmationProducer } from "src/Pages/TelasSistemas/Relatorios/Confirmation/ConfirmationProducer";
import { PageReportProductionContract } from "src/Pages/TelasSistemas/Relatorios/producao/ProductionContract";
import { PageReportProductionOperator } from "src/Pages/TelasSistemas/Relatorios/producao/ProductionOperator";
import { PageReportProductionProducer } from "src/Pages/TelasSistemas/Relatorios/producao/ProductionProducer";
import { PageLogsErrors } from "src/Pages/TelasSistemas/Utils/LogsErrors/Index";
import { UrlConfig } from "src/shared/Utils/paths";
import { valuesPermissions } from "src/shared/Utils/ValuesPermissions";

import { useAuthContext } from "../AuthContext/authContext";
import { IViews } from "../types/ProdutoresContext.Types";

import { INavigationContext } from "./ViewsTypes";

const ViewsProvider = createContext({} as INavigationContext);

export const ViewsContext: FC<{ children: ReactNode }> = ({ children }) => {
  const { userPerfil } = useAuthContext();

  const [NavigationInterno, setNavigationInterno] = useState<IViews[]>([]);
  const [NavigationComissoes, setNavigationComissoes] = useState<IViews[]>([]);
  const [NavigationRelations, setNavigationRelations] = useState<IViews[]>([]);
  const [NavigationUsuarios, setNavigationUsuarios] = useState<IViews[]>([]);
  const [NavigationDashboard, setNavigationDashboard] = useState<IViews[]>([]);
  const [NavigatioPaymentsPlans, setNavigatioPaymentsPlans] = useState<
    IViews[]
  >([]);

  const [NavigationCompanyConfig, setNavigationCompanyConfig] = useState<
    IViews[]
  >([]);

  const [NavigationPagamentos, setNavigationPagamentos] = useState<IViews[]>(
    []
  );
  const [NavigationProdutores, setNavigationProdutores] = useState<IViews[]>(
    []
  );
  const [NavigationLancamentos, setNavigationLancamentos] = useState<IViews[]>(
    []
  );
  const [NavigationControleEmpresa, setNavigationControleEmpresa] = useState<
    IViews[]
  >([]);

  const userPRODUCER = userPerfil?.type_user === "PRODUCER";
  const userRoot = userPerfil?.type_user === "ROOT";
  const userADM = userPerfil?.type_user === "ADM";

  const handleVerifyViewsIndexOf = (condition: string, report?: boolean) => {
    const permissionAccessProfile = userPerfil?.permissions.permissions
      .split(",")
      .includes(condition);

    if (userRoot) return true;

    if (userADM) return true;
    if (permissionAccessProfile) return true;
    if (userPRODUCER && report) return true;

    return false;
  };

  const [NavigatioPerfil] = useState<IViews[]>([
    {
      element: <PagePerfil />,
      title: "Profile",
      to: "/perfil",
      isView: true,
      idPermission: "",
    },
    {
      element: <PageHelp />,
      title: UrlConfig.help.title,
      to: UrlConfig.help.url,
      isView: !userPRODUCER,
      idPermission: "",
    },
  ]);

  useEffect(() => {
    setNavigationControleEmpresa([
      {
        isView: userRoot,
        title: "Controle",
        to: "/controleEmpresa/habitation",
        element: <ControleEmpresa />,
        idPermission: "",
      },
      {
        isView: userRoot,
        title: "Logs Erros",
        to: "/logsErrors",
        element: <PageLogsErrors />,
        idPermission: "",
      },
    ]);

    setNavigationUsuarios([
      {
        isView: handleVerifyViewsIndexOf(valuesPermissions.FILIAL.VER_TELA.id),
        title: UrlConfig.usuarios.filiais.title,
        to: UrlConfig.usuarios.filiais.url,
        element: <PageFiliais />,
        idPermission: valuesPermissions.FILIAL.VER_TELA.id,
      },
      {
        isView: handleVerifyViewsIndexOf(
          valuesPermissions.PERMISSIONS.VER_TELA.id
        ),
        title: UrlConfig.usuarios.perfilAcesso.title,
        to: UrlConfig.usuarios.perfilAcesso.url,
        element: <PagePerfisDeAcesso />,
        idPermission: valuesPermissions.PERMISSIONS.VER_TELA.id,
      },
      {
        isView: handleVerifyViewsIndexOf(valuesPermissions.USER.VER_TELA.id),
        title: UrlConfig.usuarios.usuarios.title,
        to: UrlConfig.usuarios.usuarios.url,
        element: <PageUsers />,
        idPermission: valuesPermissions.USER.VER_TELA.id,
      },
    ]);
    setNavigationDashboard([
      {
        isView: handleVerifyViewsIndexOf(
          valuesPermissions.DASHBOARD_BROKER.VER_TELA.id
        ),
        title: UrlConfig.dashboard.dashboardBroker.title,
        to: UrlConfig.dashboard.dashboardBroker.url,
        idPermission: valuesPermissions.DASHBOARD_BROKER.VER_TELA.id,
        element: <PageDashboardBroker />,
      },
    ]);

    setNavigationProdutores([
      {
        isView: handleVerifyViewsIndexOf(
          valuesPermissions.PRODUCER.VER_TELA.id
        ),
        title: UrlConfig.produtores.produtores.title,
        to: UrlConfig.produtores.produtores.url,
        element: <PageProdutores />,
        idPermission: valuesPermissions.PRODUCER.VER_TELA.id,
      },
      {
        isView: handleVerifyViewsIndexOf(
          valuesPermissions.CONTA_CORRENTE.VER_TELA.id,
          true
        ),
        title: UrlConfig.produtores.contaCorrente.title,
        to: UrlConfig.produtores.contaCorrente.url,
        element: <PageContaCorrente />,
        idPermission: valuesPermissions.CONTA_CORRENTE.VER_TELA.id,
      },
      {
        isView: handleVerifyViewsIndexOf(
          valuesPermissions.IMPOSTOS.VER_TELA.id
        ),
        title: UrlConfig.produtores.impostos.title,
        to: UrlConfig.produtores.impostos.url,
        element: <PageImpostos />,
        idPermission: valuesPermissions.IMPOSTOS.VER_TELA.id,
      },
    ]);

    setNavigationComissoes([
      {
        isView: handleVerifyViewsIndexOf(
          valuesPermissions.OPERADORA.VER_TELA.id
        ),
        title: UrlConfig.comissao.operadora.title,
        to: UrlConfig.comissao.operadora.url,
        element: <PageOperadoras />,
        idPermission: valuesPermissions.OPERADORA.VER_TELA.id,
      },
      {
        isView: handleVerifyViewsIndexOf(
          valuesPermissions.MODALIDADE.VER_TELA.id
        ),
        title: UrlConfig.comissao.modalidade.title,
        to: UrlConfig.comissao.modalidade.url,
        element: <PageComissoesModalidade />,
        idPermission: valuesPermissions.MODALIDADE.VER_TELA.id,
      },
      {
        isView: handleVerifyViewsIndexOf(
          valuesPermissions.ADMINISTRADORA.VER_TELA.id
        ),
        title: UrlConfig.comissao.administradora.title,
        to: UrlConfig.comissao.administradora.url,
        element: <PageComissoesAdministradora />,
        idPermission: valuesPermissions.ADMINISTRADORA.VER_TELA.id,
      },
      {
        isView: handleVerifyViewsIndexOf(
          valuesPermissions.COMMISSIONS_CORRETORA.VER_TELA.id
        ),
        title: UrlConfig.comissao.corretora.title,
        to: UrlConfig.comissao.corretora.url,
        element: <PageComissaoCorretora />,
        idPermission: valuesPermissions.COMMISSIONS_CORRETORA.VER_TELA.id,
      },
      {
        isView: handleVerifyViewsIndexOf(
          valuesPermissions.COMMISSIONS_PRODUCER.VER_TELA.id
        ),
        title: UrlConfig.comissao.produtor.title,
        to: UrlConfig.comissao.produtor.url,
        element: <PageComissaoProdutor />,
        idPermission: valuesPermissions.COMMISSIONS_PRODUCER.VER_TELA.id,
      },
    ]);

    setNavigationLancamentos([
      {
        isView: handleVerifyViewsIndexOf(
          valuesPermissions.CONTRATO.VER_TELA.id
        ),
        title: UrlConfig.lancamentos.contratos.title,
        to: UrlConfig.lancamentos.contratos.url,
        element: <PageContratos />,
        idPermission: valuesPermissions.CONTRATO.VER_TELA.id,
      },
      {
        isView: handleVerifyViewsIndexOf(valuesPermissions.BORDERO.VER_TELA.id),
        title: UrlConfig.lancamentos.parcelas.title,
        to: UrlConfig.lancamentos.parcelas.url,
        element: <PageBordero />,
        idPermission: valuesPermissions.BORDERO.VER_TELA.id,
      },
    ]);

    setNavigationInterno([
      {
        element: <ContractInternal />,
        title: UrlConfig.lancamentos.contrato.title,
        to: UrlConfig.lancamentos.contrato.url,

        isView: handleVerifyViewsIndexOf(
          valuesPermissions.CONTRATO.VER_TELA.id
        ),
        idPermission: valuesPermissions.CONTRATO.VER_TELA.id,
      },
    ]);

    setNavigationPagamentos([
      {
        isView: handleVerifyViewsIndexOf(valuesPermissions.RECIBOS.VER_TELA.id),
        title: UrlConfig.pagamentos.recibos.title,
        to: UrlConfig.pagamentos.recibos.url,
        element: <PageRecibos />,
        idPermission: valuesPermissions.RECIBOS.VER_TELA.id,
      },
    ]);

    setNavigationCompanyConfig([
      {
        isView: handleVerifyViewsIndexOf(
          valuesPermissions.COMPANY.VER_DETALHES.id
        ),
        idPermission: valuesPermissions.COMPANY.VER_DETALHES.id,
        title: UrlConfig.config.companyDetails.title,
        to: UrlConfig.config.companyDetails.url,
        element: <PageCompany />,
      },
      {
        isView: handleVerifyViewsIndexOf(valuesPermissions.LOGS.VER_TELA.id),
        idPermission: valuesPermissions.LOGS.VER_TELA.id,
        title: UrlConfig.config.logs.title,
        to: UrlConfig.config.logs.url,
        element: <PageLogs />,
      },
    ]);

    setNavigatioPaymentsPlans([
      {
        isView:
          userPerfil?.type_user === "ADM" || userPerfil?.type_user === "ROOT",
        idPermission: "",
        title: UrlConfig.config.plans.title,
        to: UrlConfig.config.plans.url,
        element: <PagePlansConfig />,
      },
    ]);

    setNavigationRelations([
      {
        isView: handleVerifyViewsIndexOf(
          valuesPermissions.RELATORIO_PRODUCAO_CONTRATO.VER_TELA.id,
          true
        ),

        idPermission: valuesPermissions.RELATORIO_PRODUCAO_CONTRATO.VER_TELA.id,

        title: UrlConfig.relatoriosProducao.producaoContract.title,
        to: UrlConfig.relatoriosProducao.producaoContract.url,
        element: <PageReportProductionContract />,
      },
      {
        isView: handleVerifyViewsIndexOf(
          valuesPermissions.RELATORIO_PRODUCAO_CORRETOR.VER_TELA.id,
          true
        ),

        idPermission: valuesPermissions.RELATORIO_PRODUCAO_CORRETOR.VER_TELA.id,

        title: UrlConfig.relatoriosProducao.producaoProducer.title,
        to: UrlConfig.relatoriosProducao.producaoProducer.url,
        element: <PageReportProductionProducer />,
      },
      {
        isView: handleVerifyViewsIndexOf(
          valuesPermissions.RELATORIO_PRODUCAO_OPERADORA.VER_TELA.id,
          true
        ),

        idPermission:
          valuesPermissions.RELATORIO_PRODUCAO_OPERADORA.VER_TELA.id,

        title: UrlConfig.relatoriosProducao.producaoOperator.title,
        to: UrlConfig.relatoriosProducao.producaoOperator.url,
        element: <PageReportProductionOperator />,
      },

      {
        isView: handleVerifyViewsIndexOf(
          valuesPermissions.RELATORIO_CONFIMACAO_CONTRATO.VER_TELA.id
        ),
        idPermission:
          valuesPermissions.RELATORIO_CONFIMACAO_CONTRATO.VER_TELA.id,

        title: UrlConfig.relatoriosProducao.confirmationContract.title,
        to: UrlConfig.relatoriosProducao.confirmationContract.url,
        element: <PageReportConfirmationContract />,
      },
      {
        isView: handleVerifyViewsIndexOf(
          valuesPermissions.RELATORIO_CONFIMACAO_PRODUCER.VER_TELA.id
        ),
        idPermission:
          valuesPermissions.RELATORIO_CONFIMACAO_PRODUCER.VER_TELA.id,

        title: UrlConfig.relatoriosProducao.confirmationProducer.title,
        to: UrlConfig.relatoriosProducao.confirmationProducer.url,
        element: <PageReportConfirmationProducer />,
      },
    ]);
  }, [userPerfil?.permissions, userPerfil?.email]);

  return (
    <ViewsProvider.Provider
      value={{
        NavigationControleEmpresa,
        NavigationCompanyConfig,
        NavigatioPaymentsPlans,
        NavigationLancamentos,
        NavigationProdutores,
        NavigationDashboard,
        NavigationPagamentos,
        NavigationComissoes,
        NavigationRelations,
        NavigationUsuarios,
        NavigationInterno,
        NavigatioPerfil,
      }}
    >
      {children}
    </ViewsProvider.Provider>
  );
};

export const useContextViews = (): INavigationContext =>
  useContext(ViewsProvider);
