import { useEffect } from "react";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { usePageControle } from "src/Contexts/ControlCompanies/ControleEmpresaContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

export const useHttpTableControleEmpresas = () => {
  const { handleGetAlert } = useLayoutMainContext();
  const {
    setQtd_registros,
    setLoadingTable,
    itensPerPage,
    currentPage,
    nameSearch,
    attTable,
  } = useConfigPageContext();

  const { setListControle } = usePageControle();

  useEffect(() => {
    setLoadingTable(true);

    api
      .post("/getControleEmpresas", {
        page: currentPage,
        limit: itensPerPage,
        filter: nameSearch,
      })
      .then((res) => {
        setListControle(res.data.data);
        setQtd_registros(res.data.count);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoadingTable(false));
  }, [attTable, itensPerPage, currentPage, nameSearch]);

  const handleGetById = async (id: string) => {
    await api
      .get(`/controleEmpresasByName/${id}`)
      .then()
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      );
  };

  return { handleGetById };
};
