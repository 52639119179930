import { Link } from "react-router-dom";
import { Box, Tooltip } from "@mui/material";
import { useResetInputs } from "src/Contexts";
import { toAbsoluteUrl } from "src/shared/setup/toAbsoluteUrl";
import { UrlConfig } from "src/shared/Utils/paths";

export const LogoHeader = () => {
  const { resetInputs } = useResetInputs();

  return (
    <Tooltip title="Home">
      <Link to={UrlConfig.home} onClick={resetInputs}>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <img
            alt="logo da empresa "
            src={toAbsoluteUrl("/media/logos/Extenso-BrokerIs.svg")}
            style={{ height: "35px" }}
          />
        </Box>
        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <img
            alt="logo da empresa "
            src={toAbsoluteUrl("/media/logos/mini-brokeis.svg")}
            style={{ height: "35px" }}
          />
        </Box>
      </Link>
    </Tooltip>
  );
};
