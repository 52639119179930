import { createContext, useContext, useState, FC, ReactNode } from "react";

import {
  IContextCompany,
  IUpdateCompanyDetails,
  IValuesPaymentCheckout,
} from "./CompanyContextTypes";
import { valuesDefaultInputsCompanyDefault } from "./valuesDefaultInputsCompany/valuesDefaultInputsCompany";

const CompanyContext = createContext({} as IContextCompany);

export const CompanyProviderContext: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [valuesInputsCompanyDetails, setValuesInputsCompanyDetails] =
    useState<IUpdateCompanyDetails>(valuesDefaultInputsCompanyDefault);

  const [valuesPaymentCheckout, setValuesPaymentCheckout] = useState(
    [] as IValuesPaymentCheckout[]
  );

  return (
    <CompanyContext.Provider
      value={{
        setValuesInputsCompanyDetails,
        valuesInputsCompanyDetails,
        setValuesPaymentCheckout,
        valuesPaymentCheckout,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

export const useContextCompany = (): IContextCompany =>
  useContext(CompanyContext);
