import { useState } from "react";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

interface IListAction {
  label: string | undefined;
}

export const UseGetListActionDistinct = () => {
  const { handleGetAlert } = useLayoutMainContext();

  const [listAction, setListAction] = useState([] as IListAction[]);

  const handleGetListActionDistinct = async () => {
    await api
      .get(`/logsGetDistinct`)
      .then(({ data }) => setListAction(data))
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      );
  };

  return { handleGetListActionDistinct, listAction };
};
