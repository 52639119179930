import { useState } from "react";
import { usePageControle } from "src/Contexts/ControlCompanies/ControleEmpresaContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

interface IUseGetChangeCompany {
  id: string;
}

export const UseGetCompany = () => {
  const { setValuesInputChangeCompany, setGetCompany } = usePageControle();
  const { handleGetAlert } = useLayoutMainContext();

  const [loading, setLoading] = useState(false);

  const handleGetChangeCompany = async ({ id }: IUseGetChangeCompany) => {
    setLoading(true);

    await api
      .get(`/company/${id}`)
      .then((res) => {
        setGetCompany({ ...res.data });

        setValuesInputChangeCompany({
          days_limit_free: res.data.plan.days_limit_free,
          is_plan_test: res.data.plan.is_plan_test,
          is_plan: res.data.plan.is_plan,
        });
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    loading,
    handleGet: handleGetChangeCompany,
  };
};
