import { formatString } from "@format-string/core";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { IDependentesContrato } from "src/Contexts/Lancamentos/Contrato/ContratosTypes";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { UseAutoCompletedAll } from "src/Contexts/Utils/UseAutoCompletedAll";
import { api } from "src/shared/setup/API/api";
import { handleFormatCep } from "src/shared/Utils/FormatCEP";
import { FormatMoney } from "src/shared/Utils/FormatMoney";
import { FormatRG } from "src/shared/Utils/FormatRG/FormatRG";
import { handleFormatDateIfIsNull } from "src/shared/Utils/handleFormatDateIfIsNull";
import { cpfOurCnpj } from "src/shared/Utils/MaskCpfCnpj";

export const UseGetByIdContratos = () => {
  const { handleGetAlert } = useLayoutMainContext();

  const {
    setValueAutoCompAdministradora,
    setValueAutoCompletedFiliais,
    setValueAutoCompModalidade,
    setValueAutoCompOperadora,
  } = UseAutoCompletedAll();

  const {
    setValueComissaoCorretoraGrade,
    setValueComissaoProdutorGrade,
    setValueAutoCompProdutorAll,
    setValuesInputsContrato,
    setDataGetByIdContrato,
  } = useContextContrato();

  const handleGetById = async (id: string) => {
    await api
      .get(`/contratosByid/${id}`)
      .then(({ data }) => {
        setDataGetByIdContrato({ ...data });
        setValueComissaoProdutorGrade(data.gradeProdutor);
        setValueComissaoCorretoraGrade(data.gradeCorretora.corretora);

        setValuesInputsContrato({
          ...data,

          dependentes: data.dependentes.map((item: IDependentesContrato) => {
            return {
              nome: item.nome,
              cpf: cpfOurCnpj({ text: item.cpf, type: "cpf" }),
              sexo: item.sexo,
              data_nascimento: handleFormatDateIfIsNull(item.data_nascimento),
              estado_civil: item.estado_civil,
              grau_parente: item.grau_parente,
              data_inclusao: handleFormatDateIfIsNull(item.data_inclusao),
              nome_mae: item.nome_mae,
              cpf_mae: cpfOurCnpj({ text: item.cpf_mae, type: "cpf" }),
            };
          }),

          assinatura_data: handleFormatDateIfIsNull(data.data_assinatura),
          cpf_cnpj: cpfOurCnpj({
            text: data.cpf_cnpj,
            type: data.tipo_contrato === "PF" ? "cpf" : "cnpj",
          }),

          cpf_responsavel_pf: cpfOurCnpj({
            text: data.cpf_responsavel_pf,
            type: "cpf",
          }),

          rg_pf: FormatRG(data.rg_pf),
          telefone_1: formatString({ type: "phone", value: data.telefone_1 }),
          telefone_2: formatString({ type: "phone", value: data.telefone_2 }),

          data_nacimento_pf: handleFormatDateIfIsNull(data.data_nacimento_pf),

          data_nascimento_responsavel_pf: handleFormatDateIfIsNull(
            data.data_nascimento_responsavel_pf
          ),

          endereco_numero: data.endereco_numero ?? "",
          complemento: data.complemento ?? "",

          vencimento_data: handleFormatDateIfIsNull(data.vencimento_data),
          vigencia_data: handleFormatDateIfIsNull(data.vigencia_data),
          emissao_data: handleFormatDateIfIsNull(data.emissao_data),
          mensalidade_value: FormatMoney(data.mensalidade_value),

          is_collect_fee_producer:
            data.is_collect_fee_producer === true ? "SIM" : "NAO",
          taxa_value: FormatMoney(data.taxa_value),

          vidas: String(data.vidas),

          cep: handleFormatCep(data.cep),
        });

        setValueAutoCompProdutorAll({
          label: data?.nome_produtor,
          id: String(data?.id_produtor),
        });
        setValueAutoCompletedFiliais({
          label: data?.nome_filial,
          id: `${String(data?.id_filial)}`,
        });
        setValueAutoCompAdministradora({
          id: data.code_administradoras,
          label: data.name_administradoras,
        });
        setValueAutoCompOperadora({
          label: data.name_operadoras,
          id: data.code_operadoras,
        });
        setValueAutoCompModalidade({
          label: data.name_modalidades,
          id: data.code_modalidades,
        });
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      );
  };

  return { handleGetById };
};
