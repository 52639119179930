import { StyleSheet } from "@react-pdf/renderer";

export const stylesPDFCabecalho = StyleSheet.create({
  container: {
    justifyContent: "space-between",
    flexDirection: "row",
    display: "flex",
    paddingBottom: "6px",
    borderBottom: "1px solid black",
  },

  titleEmpresa: {
    fontSize: 16,
  },

  titleNomePdf: {
    textDecoration: "underline",
    marginBottom: "6px",
  },

  descriptionDate: {
    fontSize: "12px",
    alignItems: "center",
  },

  descriptionDateCurrency: {
    alignItems: "flex-end",
    fontSize: "12px",
  },
});
