import { createTheme } from "@mui/material";

const handleLightTheme = () => {
  const LightTheme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: "8px",
          },
        },
      },
    },
    palette: {
      mode: "light",
      primary: {
        main: "#5a98fff5",
      },
      action: {
        disabled: "#6b78898d",
      },
    },
  });

  return [LightTheme];
};

export { handleLightTheme };
