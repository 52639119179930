export const handleGetAllPlansDetails = (qtd: number) => {
  return [
    {
      title: `${qtd > 1 ? `${qtd} Usuários` : `${qtd} Usuário`}`,
      isDefault: false,
      fontWeight: "500",
    },
    { title: "Propostas/Contratos ilimitados", isDefault: true },
    { title: "Cadastro de produtores ilimitado", isDefault: true },
    { title: "Acesso online para o corretor ver suas vendas", isDefault: true },
    { title: "Recibo para Corretor, supervisor e Gerente", isDefault: true },
    { title: "Relatórios (PDF/EXCEL/DASHBOARD)", isDefault: true },
    { title: "Suporte e treinamento", isDefault: true },
    { title: "Software 100% online", isDefault: true },
  ];
};
