import { IDataTh, ThCoreIndex } from "src/Pages/components";

export const THeadContratos = () => {
  const dataTh: IDataTh[] = [
    {
      title: "Ações",
      width: "40px",
      align: "left",
    },
    {
      title: "Status",
      align: "left",
      width: "80px",
    },
    {
      title: "D. Assinatura",
      align: "left",
      width: "130px",
      minWidth: "130px",
      tooltip: "Data da assinatura",
    },
    {
      title: "Operadora",
      align: "left",
      minWidth: "130px",
      width: "130px",
    },
    {
      title: "Proposta",
      align: "left",
      width: "135px",
      minWidth: "135px",
    },
    {
      title: "Mensalidade",
      align: "left",
      width: "50px",
    },
    // {
    //   title: "Taxa",
    //   align: "left",
    //   width: "50px",
    // },
    {
      title: "Titular/Razão",
      align: "left",
      width: "200px",
      minWidth: "150px",
    },
    {
      title: "CPF/CNPJ",
      align: "left",
      width: "150px",
    },
    // {
    //   title: "T. contrato",
    //   align: "center",
    //   minWidth: "110px",
    //   width: "110px",
    //   tooltip: "Tipo de contrato",
    // },
    {
      title: "Telefone(s)",
      align: "left",
      width: "120px",
      minWidth: "120px",
    },
    {
      title: "Produtor",
      align: "left",
      width: "280px",
      minWidth: "180px",
    },
    {
      title: "Administradora",
      align: "left",
      minWidth: "130px",
      width: "130px",
    },
    {
      title: "Modalidade",
      align: "left",
      minWidth: "130px",
      width: "130px",
    },
    {
      title: "Info",
      align: "center",
      width: "50px",
    },
  ];

  return <ThCoreIndex dataTh={dataTh} />;
};
