import {
  createContext,
  useContext,
  useState,
  FC,
  useEffect,
  ReactNode,
} from "react";

import {
  IListOperadora,
  IListModalidade,
  ICreateOperadora,
  ICreateModalidade,
  IContextComissoes,
  IListAdministradora,
  IListComissaoProdutor,
  ICreateAdministradora,
  IListComissaoCorretora,
  ICreateComissaoProdutor,
  ICreateComissaoCorretora,
  IValuesFilterComissaoCorretora,
  IValuesFilterComissaoProdutor,
} from "./ComissoesTypes";
import {
  valuesDefaultInputsComissaoProdutor,
  valuesDefaultInputsComissaoCorretora,
  valuesDefaultFilterComissaoCorretora,
} from "./ValuesDefaultInputsComissoes/ValuesDefaultInputsComissoes";

const ComissoesContext = createContext({} as IContextComissoes);

export const ComissoesContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [listModalidade, setListModalidade] = useState([] as IListModalidade[]);
  const [listOperadora, setListOperadora] = useState([] as IListOperadora[]);

  const [listAdministradora, setListAdministradora] = useState(
    [] as IListAdministradora[]
  );
  const [listComissaoCorretora, setListComissaoCorretora] = useState(
    [] as IListComissaoCorretora[]
  );
  const [listComissaoProdutor, setListComissaoProdutor] = useState(
    [] as IListComissaoProdutor[]
  );

  const [valuesInputsAdministradora, setValuesInputsAdministradora] =
    useState<ICreateAdministradora>({ name: "", is_active: true });

  const [valuesInputsOperadora, setValuesInputsOperadora] =
    useState<ICreateOperadora>({ name: "" });

  const [valuesInputsModalidade, setValuesInputsModalidade] =
    useState<ICreateModalidade>({ name: "", is_active: true });

  const [valuesInputsComissaoCorretora, setValuesInputsComissaoCorretora] =
    useState<ICreateComissaoCorretora>(valuesDefaultInputsComissaoCorretora);

  const [valuesInputsComissaoProdutor, setValuesInputsComissaoProdutor] =
    useState<ICreateComissaoProdutor>(valuesDefaultInputsComissaoProdutor);

  const [valuesFilterComissaoCorretora, setValuesFilterComissaoCorretora] =
    useState<IValuesFilterComissaoCorretora>(
      valuesDefaultFilterComissaoCorretora
    );
  const [valuesFilterComissaoProdutor, setValuesFilterComissaoProdutor] =
    useState<IValuesFilterComissaoProdutor>(
      valuesDefaultFilterComissaoCorretora
    );

  useEffect(() => {
    if (valuesInputsComissaoProdutor.is_special === "true") {
      setValuesInputsComissaoProdutor((prevValue) => ({
        ...prevValue,
        funcao_produtor: "",
        tipo_produtor: "",
      }));
    }
    if (valuesInputsComissaoProdutor.is_special === "false") {
      setValuesInputsComissaoProdutor((prevValue) => ({
        ...prevValue,
        funcao_produtor: "CORRETOR",
        tipo_produtor: "SALAO",
      }));
    }
  }, [valuesInputsComissaoProdutor.is_special]);

  return (
    <ComissoesContext.Provider
      value={{
        listOperadora,
        listModalidade,
        setListOperadora,
        setListModalidade,
        listAdministradora,
        listComissaoProdutor,
        setListAdministradora,
        listComissaoCorretora,
        valuesInputsOperadora,
        valuesInputsModalidade,
        setListComissaoProdutor,
        setListComissaoCorretora,
        setValuesInputsOperadora,
        setValuesInputsModalidade,
        valuesInputsAdministradora,
        valuesInputsComissaoProdutor,
        valuesFilterComissaoProdutor,
        valuesInputsComissaoCorretora,
        setValuesInputsAdministradora,
        valuesFilterComissaoCorretora,
        setValuesFilterComissaoProdutor,
        setValuesInputsComissaoProdutor,
        setValuesFilterComissaoCorretora,
        setValuesInputsComissaoCorretora,
      }}
    >
      {children}
    </ComissoesContext.Provider>
  );
};

export const useContextComissoes = (): IContextComissoes =>
  useContext(ComissoesContext);
