import { formatString } from "@format-string/core";
import { Box, CardMedia, Chip } from "@mui/material";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { CardContract } from "src/Pages/components/CardContract/CardContract";
import { TitleWithValueCore } from "src/Pages/components/TitleWithValueCore/TitleWithValueCore";
import { toAbsoluteUrl } from "src/shared/setup/toAbsoluteUrl";
import { cpfOurCnpj } from "src/shared/Utils/MaskCpfCnpj";

export const CardProposta = () => {
  const { valueContractInternal } = useContextContrato();

  if (!valueContractInternal) return null;

  const isTypePF = valueContractInternal?.tipo_contrato === "PF";

  return (
    <CardContract
      sxStyle={{ display: "flex", justifyContent: "space-between" }}
      title={`Proposta: ${valueContractInternal?.cod_proposta}`}
    >
      <Box display="flex" padding="8px 12px">
        <CardMedia
          alt="Imagem de um documento"
          component="img"
          image={toAbsoluteUrl("/media/google-docs.png")}
          sx={{
            width: { xs: 50, sm: 70, md: 100 },
            height: { xs: 50, sm: 70, md: 100 },
            marginRight: "1rem",
            marginLeft: "-8px",
          }}
        />
        <Box>
          <Box mb="12px">
            <TitleWithValueCore
              sxStyleTitle={{ fontSize: "20px", fontWeight: "500" }}
              sxStyleValue={{ fontSize: "20px", fontWeight: "500" }}
              value={
                isTypePF
                  ? valueContractInternal.titular_pf
                  : valueContractInternal.razao_social_pj
              }
            />
          </Box>

          <TitleWithValueCore
            sxStyleTitle={{ fontWeight: "500" }}
            title={isTypePF ? `CPF:` : `CNPJ:`}
            value={
              isTypePF
                ? (formatString({
                    type: "cpf",
                    value: valueContractInternal.cpf_cnpj,
                  }) as string)
                : (formatString({
                    type: "cnpj",
                    value: valueContractInternal.cpf_cnpj,
                  }) as string)
            }
          />
          <TitleWithValueCore
            sxStyleTitle={{ fontWeight: "500" }}
            title="Email:"
            value={valueContractInternal.email}
          />
          <TitleWithValueCore
            sxStyleTitle={{ fontWeight: "500" }}
            title="Telefone:"
            value={cpfOurCnpj({
              text: valueContractInternal.telefone_1,
              type: "phone",
            })}
          />
        </Box>
      </Box>

      <Box sx={{ marginRight: "22px" }}>
        <Chip
          color="success"
          label={valueContractInternal.status_descricao.toUpperCase()}
          size="medium"
          sx={{
            maxWidth: "140px",
            padding: { xs: "4px", sm: "8px" },
            fontWeight: "500",
          }}
          variant="filled"
        />
      </Box>
    </CardContract>
  );
};
