import { useContextComissoes } from "src/Contexts/comissoes/ComissoesContext";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useResetInputs } from "src/Contexts/hooks/useResetInputs";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

export const UseFeaturesFormulario = () => {
  const { valuesInputsModalidade } = useContextComissoes();
  const { handleGetAlert } = useLayoutMainContext();
  const { resetInputs } = useResetInputs();

  const { setAttTable, attTable, idTable, id, setLoadingGravar } =
    useConfigPageContext();

  const handleCreate = async () => {
    if (!valuesInputsModalidade.name)
      return handleGetAlert({ message: `Digite uma modalidade` });

    setLoadingGravar(true);

    return api
      .post("/comissoes/modalidade", valuesInputsModalidade)
      .then((res) => {
        handleGetAlert({ message: res.data.message });
        setAttTable(!attTable);
        resetInputs();
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoadingGravar(false));
  };

  const handleEdit = () => {
    if (!valuesInputsModalidade.name)
      return handleGetAlert({ message: `Digite uma modalidade` });

    setLoadingGravar(true);

    return api
      .put(`/comissoes/modalidade/${id}`, valuesInputsModalidade)
      .then((res) => {
        setAttTable(!attTable);
        handleGetAlert({ message: res.data.message });
        resetInputs();
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoadingGravar(false));
  };

  const handleSubmit = () => {
    if (idTable) {
      handleEdit();
    } else {
      handleCreate();
    }
  };

  return { handleSubmit };
};
