import { Text } from "@react-pdf/renderer";

type IInvoiceTableRow = {
  width: string;
  textAlign?: "center" | "left" | "right" | "justify";
  paddingRight?: string;
  paddingLeft?: string;
  title: string | number;
  backgroundColor?: string;
  fontSize?: string | number | undefined;
  fontStyle?:
    | "bold"
    | "normal"
    | "thin"
    | "hairline"
    | "ultralight"
    | "extralight"
    | "light"
    | "medium"
    | "semibold"
    | "demibold"
    | "ultrabold"
    | "extrabold"
    | "heavy"
    | "black";
};

export const PDFTableRow = ({
  textAlign = "left",
  width,
  paddingLeft,
  paddingRight,
  title,
  fontStyle,
  fontSize,
  backgroundColor,
}: IInvoiceTableRow) => {
  return (
    <Text
      style={{
        width,
        textAlign,
        paddingLeft,
        backgroundColor,
        paddingRight,
        fontStyle,
        fontSize,
      }}
    >
      {title}
    </Text>
  );
};
