import {
  alpha,
  Box,
  CssBaseline,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import { HeaderIsPlanExpired } from "src/Pages/components/HeaderIsPlanExpired/HeaderIsPlanExpired";
import { ModalFirstLogin } from "src/Pages/components/ModalFirstLogin/ModalFirstLogin";
import { ModalWelcome } from "src/Pages/components/ModalWelcome/ModalWelcome";

import { PrivateRoutes } from "../Routes/PrivateRoutes";

import { CardMain } from "./Drawers/Components/CardMain";
import { DrawerDesktop } from "./Drawers/DrawerDesktop";
import { DrawerMobile } from "./Drawers/DrawerMobile";
import { AppBarMain } from "./Header/AppBar";

export const Master = () => {
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box sx={{ position: "relative" }}>
      <ModalFirstLogin />
      <ModalWelcome />

      <Box
        sx={{
          background: alpha("#F1F3F6", 0.9),
          padding: "1rem",
          height: "100%",
        }}
      >
        {!matches ? <DrawerMobile /> : <DrawerDesktop />}

        <CssBaseline />

        <AppBarMain />
        <Toolbar />

        <CardMain>
          <PrivateRoutes />
        </CardMain>
      </Box>
      <Box
        sx={{
          zIndex: 999,
          position: "fixed",
          bottom: 0,
          width: "100%", // Para ocupar a largura total
        }}
      >
        <HeaderIsPlanExpired />
      </Box>
    </Box>
  );
};
