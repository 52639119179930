import { createRef, ChangeEvent } from "react";
import { Box, MenuItem } from "@mui/material";
import { useContextProdutor } from "src/Contexts/produtores/ProdutoresContext";
import { ICreateContaCorrente } from "src/Contexts/produtores/ProdutoresTypes";
import { ButtonsFeaturesFormularioCore, FormCore } from "src/Pages/components";
import { AppTextField } from "src/Pages/components/AppFormComponents/AppTextField";
import { UseVerifyIsPermissionsUser } from "src/Pages/components/hooks/UseVerifyIsPermissionsUser";
import { FormatMoney } from "src/shared/Utils/FormatMoney";
import { valuesPermissions } from "src/shared/Utils/ValuesPermissions";

import { AutoCompleteProdutorActive } from "../../../../../components/AutoCompleted/AutoCompleteProdutorActive";

import { UseFormContaCorrente } from "./hook/UseFormContaCorrente";
import { ComponentPrivate } from "src/Pages/components/ComponentPrivate/ComponentPrivate";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";

export const FormContaCorrente = () => {
  const { handleVerifyPermissionItem } = UseVerifyIsPermissionsUser();
  const { handleSubmit } = UseFormContaCorrente();
  const { userPerfil } = useAuthContext();

  const { valuesInputsContaCorrente, setValuesInputsContaCorrente } =
    useContextProdutor();

  const nome = createRef<HTMLInputElement>();
  const handleFocusedID = () => nome.current?.focus();

  const handleChange =
    (prop: keyof ICreateContaCorrente, option?: "money") =>
    (event: ChangeEvent<HTMLInputElement>) => {
      if (option === "money") {
        setValuesInputsContaCorrente({
          ...valuesInputsContaCorrente,
          [prop]: FormatMoney(event.target.value),
        });
      } else {
        setValuesInputsContaCorrente({
          ...valuesInputsContaCorrente,
          [prop]: event.target.value,
        });
      }
    };

  return (
    <ComponentPrivate isView={userPerfil?.type_user !== "PRODUCER"}>
      <FormCore>
        <ButtonsFeaturesFormularioCore
          notEdit
          disabledIncluir={handleVerifyPermissionItem(
            valuesPermissions.CONTA_CORRENTE.CREATE.id
          )}
          handleFocusedID={handleFocusedID}
          handleSubmit={handleSubmit}
        />
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          <AutoCompleteProdutorActive label="Produtor *" tamanho="300px" />

          <AppTextField
            label="Valor *"
            sxStyle={{ width: "100px" }}
            value={valuesInputsContaCorrente?.valor}
            onChange={handleChange("valor", "money")}
          />

          <AppTextField
            label="Descrição *"
            sxStyle={{ width: "280px" }}
            value={valuesInputsContaCorrente?.descricao}
            onChange={handleChange("descricao")}
          />
          <AppTextField
            select
            id="Tipo de entrada (D|C)"
            label="Tipo de entrada (D|C)"
            sxStyle={{ width: "120px" }}
            value={valuesInputsContaCorrente?.tipo_transicao}
            onChange={handleChange("tipo_transicao")}
          >
            <MenuItem value="debito">Debito</MenuItem>
            <MenuItem value="credito">Credito</MenuItem>
          </AppTextField>
          <AppTextField
            disabled
            label="Data"
            sxStyle={{ width: "120px" }}
            type="date"
            value={valuesInputsContaCorrente?.data_lancamento}
            onChange={handleChange("data_lancamento")}
          />
        </Box>
      </FormCore>
    </ComponentPrivate>
  );
};
