import { FC } from "react";
import { Box, SxProps, Theme } from "@mui/material";
import { CardProps } from "@mui/material/Card/Card";

type ICardCore = CardProps & {
  sxStyle?: SxProps<Theme>;
};

export const CardCore: FC<ICardCore> = ({ sxStyle, children }) => {
  return (
    <Box
      sx={{
        background: "#ffffff",

        margin: "1rem",
        padding: { xs: ".7rem", sm: "16px" },

        boxShadow: "0 .1rem 1rem .25rem #0000001f",
        borderRadius: "12px",
        overflow: "hidden",

        "&:hover": {
          transition: "box-shadow .3s ease",
          boxShadow: "0 .1rem 1rem .25rem #0000002b",
        },
        ...sxStyle,
      }}
    >
      {children}
    </Box>
  );
};
