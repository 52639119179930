import { useState } from "react";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { Tab, Tabs } from "@mui/material";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { CardCore } from "src/Pages/components/CardCore/CardCore";

import { CardHeaderPlans } from "./Components/CardHeaderPlans/CardHeaderPlans";
import { PagePlans } from "./Components/PagePlans/PagePlans";
import { PlanActive } from "./Components/PlanActive/Index";

export const PagePlansConfig = () => {
  const { userPerfil } = useAuthContext();
  const [value, setValue] = useState(
    userPerfil?.checkout?.subscription?.status_subscription === "ACTIVE"
      ? "1"
      : "2"
  );

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <CardHeaderPlans />

      <CardCore sxStyle={{ margin: 0, padding: 0 }}>
        <TabContext value={value}>
          <Tabs value={value} onChange={handleChange}>
            {userPerfil?.checkout.id_stripe_customer && (
              <Tab label="Plano atual" value="1" />
            )}
            <Tab label="Planos / Dúvidas" value="2" />
          </Tabs>

          {userPerfil?.checkout.id_stripe_customer && (
            <TabPanel
              value="1"
              sx={{
                padding: { xs: "12px", sm: "24px" },
              }}
            >
              <PlanActive />
            </TabPanel>
          )}

          <TabPanel sx={{ padding: 0, paddingTop: "1rem" }} value="2">
            <PagePlans />
          </TabPanel>
        </TabContext>
      </CardCore>
    </>
  );
};
