import { IDataTh, ThCoreIndex } from "src/Pages/components";

export const THeadLogs = () => {
  const dataTh: IDataTh[] = [
    {
      title: "Empresa",
      width: "150px",
      minWidth: "150px",
      align: "left",
    },
    {
      title: "Data - hora",
      width: "200px",
      minWidth: "200px",
      align: "left",
    },
    {
      title: "Pagina acessada",
      align: "left",
      minWidth: "250px",
      width: "200px",
    },
    {
      title: "Erro interno",
      minWidth: "800px",
      width: "800px",
      align: "left",
    },

    {
      title: "Ação",
      width: "200px",
      minWidth: "200px",
      align: "left",
    },
    {
      title: "Mensagem de retorno",
      width: "200px",
      minWidth: "200px",
      align: "left",
    },
    {
      title: "Data",
      width: "200px",
      minWidth: "200px",
      align: "left",
    },
  ];

  return <ThCoreIndex dataTh={dataTh} />;
};
