import { Typography } from "@mui/material";
import { CardCore } from "src/Pages/components/CardCore/CardCore";
import { TurnedInIcon } from "src/Pages/components/Icons/Icons";
import { LinkCore } from "src/Pages/components/LinkCore/LinkCore";

export const HeaderLogin = () => {
  return (
    <CardCore
      sxStyle={{
        margin: "0 0 24px 0",
        alignItems: "center",
        display: "flex",
        padding: { xs: "2px", sm: "8px" },
        background: "white",
      }}
    >
      <TurnedInIcon sx={{ color: "#5a98fff5", marginRight: "4px" }} />

      <Typography
        sx={{
          margin: "8px 2px",
          marginRight: "8px",
          fontSize: { xs: ".7rem", md: ".8rem", lg: ".9rem" },
        }}
      >
        Gestão para corretoras
      </Typography>
      <Typography
        sx={{
          "& > *": {
            fontWeight: "500",
          },
        }}
      >
        <LinkCore color="#2478fff5" to="/register">
          Experimente grátis por 15 dias !
        </LinkCore>
      </Typography>
    </CardCore>
  );
};
