import { IPDFTableHeader } from "src/Pages/components/PDF/TableHeader";
import { PDFTableHeader } from "src/Pages/components/PDF/TableHeader/PDFTableHeader";

export const HeadReportConfirmationProducerPDF = () => {
  const dataHeaderPDF: IPDFTableHeader = [
    {
      style: {
        width: "10%",
        textAlign: "center",
        paddingLeft: "8px",
      },
      title: "N parcela",
    },
    {
      style: {
        width: "12%",
        textAlign: "center",
      },
      title: "Qtd parcelas",
    },
    {
      style: {
        width: "13%",
        textAlign: "right",
        paddingLeft: "8px",
      },
      title: "Total mensalidade",
    },
    {
      style: {
        width: "13%",
        textAlign: "right",
      },
      title: "Total corretora",
    },
    {
      style: {
        width: "13%",
        textAlign: "right",
      },
      title: "Total corretor",
    },
    {
      style: {
        width: "13%",
        textAlign: "right",
      },
      title: "Total supervisor",
    },
    {
      style: {
        width: "13%",
        textAlign: "right",
      },
      title: "Total gerente",
    },
    {
      style: {
        width: "13%",
        textAlign: "right",
        paddingRight: "8px",
      },
      title: "Total lucro",
    },
  ];

  return <PDFTableHeader data={dataHeaderPDF} />;
};
