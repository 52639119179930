import { ChangeEvent } from "react";
import { formatString } from "@format-string/core";
import { Box } from "@mui/material";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { IValuesFilterContrato } from "src/Contexts/Lancamentos/Contrato/ContratosTypes";
import { valuesDefaultFilterContrato } from "src/Contexts/Lancamentos/Contrato/valuesDefaultInputsContratos/ValueDefaultInputContratos";
import { UseAutoCompletedAll } from "src/Contexts/Utils/UseAutoCompletedAll";
import { AppTextField } from "src/Pages/components";
import { AutoCompleteCorretorGeneric } from "src/Pages/components/AutoCompleted/AutoCompleteCorretor";
import {} from "src/Pages/components/AutoCompleted/AutoCompleteOperadora";
import { AutoCompleteOperadoraFilter } from "src/Pages/components/AutoCompleted/Filters/AutoCompleteOperadora";
import { DividerCore } from "src/Pages/components/DividerCore/DividerCore";
import { ModalFilterCore } from "src/Pages/components/ModalFilterCore/Index";

import { useHttpTableContratos } from "../table/Hooks/useHttpTableContratos";
import { AutoCompleteAdministradoras } from "src/Pages/components/AutoCompleted/AutoCompleteAdministradoras";

export const FilterSidebar = () => {
  const { handleGet } = useHttpTableContratos();

  const { setValuesFilterContrato, valuesFilterContrato } =
    useContextContrato();

  const {
    valueAutoCompProdutorActive,
    valueAutoCompAdministradora,
    valueAutoCompOperadoraFilter,
    setValueAutoCompProdutorActive,
    setValueAutoCompOperadoraFilter,
  } = UseAutoCompletedAll();

  const handleChange =
    (prop: keyof IValuesFilterContrato, option?: "CPF_CNPJ") =>
    (event: ChangeEvent<HTMLInputElement>) => {
      if (option === "CPF_CNPJ") {
        setValuesFilterContrato({
          ...valuesFilterContrato,
          [prop]: formatString({
            type: "cpfOurCnpj",
            value: event.target.value,
          }),
        });
      } else {
        setValuesFilterContrato({
          ...valuesFilterContrato,
          [prop]: event.target.value,
        });
      }
    };

  const ClearFilter = async () => {
    setValuesFilterContrato(valuesDefaultFilterContrato);
    setValueAutoCompOperadoraFilter({ id: "", label: "" });
    setValueAutoCompProdutorActive({ id: "", label: "" });

    await handleGet({});
  };

  const FilterSearch = async () => {
    await handleGet({
      codProposta: valuesFilterContrato.cod_proposta,

      codeOperadora: valueAutoCompOperadoraFilter.id,
      codeCorretor: valueAutoCompProdutorActive.id,
      codeAdministradora: valueAutoCompAdministradora.id,

      endDateSignature: valuesFilterContrato.endDateSignature,
      startDateSignature: valuesFilterContrato.startDateSignature,

      startEffectiveDate: valuesFilterContrato.startEffectiveDate,
      endEffectiveDate: valuesFilterContrato.endEffectiveDate,

      startDateEmission: valuesFilterContrato.startDateEmission,
      endDateEmission: valuesFilterContrato.endDateEmission,

      client: {
        nameOrRazao: valuesFilterContrato.nameOrRazao,
        cpfCnpj: valuesFilterContrato.cpfCnpj,
      },
    });
  };

  return (
    <ModalFilterCore FilterClear={ClearFilter} FilterSearch={FilterSearch}>
      <DividerCore sxStyle={{ marginBottom: "12px" }} title="Proposta" />

      <Box
        id="container propostas"
        sx={{
          "& > .MuiAutocomplete-root, & > .MuiTextField-root": {
            flexWrap: "wrap",
            display: "flex",
            marginBottom: "1rem",
          },
        }}
      >
        <AppTextField
          label="Código proposta"
          sxStyle={{ opacity: "", pointerEvents: "auto", width: "100%" }}
          value={valuesFilterContrato.cod_proposta}
          variant="outlined"
          onChange={handleChange("cod_proposta")}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
            alignItems: "center",
          }}
        >
          <AppTextField
            label="Data assinatura"
            sxStyle={{ opacity: "", pointerEvents: "auto", width: "45%" }}
            type="date"
            value={valuesFilterContrato.startDateSignature}
            variant="outlined"
            onChange={handleChange("startDateSignature")}
          />
          até
          <AppTextField
            label="Data assinatura"
            sxStyle={{ opacity: "", pointerEvents: "auto", width: "45%" }}
            type="date"
            value={valuesFilterContrato.endDateSignature}
            variant="outlined"
            onChange={handleChange("endDateSignature")}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
            alignItems: "center",
          }}
        >
          <AppTextField
            label="Data emissão"
            sxStyle={{ opacity: "", pointerEvents: "auto", width: "45%" }}
            type="date"
            value={valuesFilterContrato.startDateEmission}
            variant="outlined"
            onChange={handleChange("startDateEmission")}
          />
          até
          <AppTextField
            label="Data emissão"
            sxStyle={{ opacity: "", pointerEvents: "auto", width: "45%" }}
            type="date"
            value={valuesFilterContrato.endDateEmission}
            variant="outlined"
            onChange={handleChange("endDateEmission")}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
            alignItems: "center",
          }}
        >
          <AppTextField
            label="Data vigência"
            sxStyle={{ opacity: "", pointerEvents: "auto", width: "45%" }}
            type="date"
            value={valuesFilterContrato.startEffectiveDate}
            variant="outlined"
            onChange={handleChange("startEffectiveDate")}
          />
          até
          <AppTextField
            label="Data vigência"
            sxStyle={{ opacity: "", pointerEvents: "auto", width: "45%" }}
            type="date"
            value={valuesFilterContrato.endEffectiveDate}
            variant="outlined"
            onChange={handleChange("endEffectiveDate")}
          />
        </Box>

        <AutoCompleteCorretorGeneric
          fullWidth
          label="Corretor"
          variant="outlined"
        />

        <AutoCompleteOperadoraFilter
          fullWidth
          label="Operadora"
          variant="outlined"
        />

        <AutoCompleteAdministradoras
          fullWidth
          label="Administradoras"
          variant="outlined"
        />
      </Box>

      <DividerCore sxStyle={{ marginBottom: "12px" }} title="Cliente (PF/PJ)" />
      <Box
        id="container cliente (PF/PJ)"
        sx={{
          "& > .MuiAutocomplete-root, & > .MuiTextField-root": {
            flexWrap: "wrap",
            display: "flex",
            marginBottom: "1rem",
          },
        }}
      >
        <AppTextField
          label="Titular/Razão"
          sxStyle={{ opacity: "", pointerEvents: "auto", width: "100%" }}
          value={valuesFilterContrato.nameOrRazao}
          variant="outlined"
          onChange={handleChange("nameOrRazao")}
        />
        <AppTextField
          label="CPF/CNPJ"
          sxStyle={{ opacity: "", pointerEvents: "auto", width: "100%" }}
          value={valuesFilterContrato.cpfCnpj}
          variant="outlined"
          onChange={handleChange("cpfCnpj", "CPF_CNPJ")}
        />
      </Box>
    </ModalFilterCore>
  );
};
