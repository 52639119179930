import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { ModalCore } from "src/Pages/components";
import { GetDateUSSemValue } from "src/shared/Utils/FormatDateBR";

import { UseFormCancelContratos } from "../hooks/UseFeatFormCancelContratos";
import { UseGetParcelasByIdContratos } from "../hooks/UseGetParcelasByIdContratos";

import { FormCancelContrato } from "./FormCancelContrato";

interface IModalUpdateStatusContrato {
  stateContrato: string;
  openModalCancel: boolean;
  setOpenModalCancel: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalUpdateStatusContrato = ({
  stateContrato,
  openModalCancel,
  setOpenModalCancel,
}: IModalUpdateStatusContrato) => {
  const { id, setId } = useConfigPageContext();

  const { valuesInputCancelarContrato, setValuesInputCancelarContrato } =
    useContextContrato();

  const {
    dataGetByIdResultsContrato,
    setDataGetByIdResultsContrato,
    loading: loadingGetByIdResult,
    handleGetParcelasByIdContratos,
  } = UseGetParcelasByIdContratos({ id });

  useEffect(() => {
    if (id) {
      const handle = async () => handleGetParcelasByIdContratos();

      handle();
    }
  }, [id]);

  useEffect(() => {
    if (!openModalCancel) {
      setValuesInputCancelarContrato({
        description: "",
        date: `${GetDateUSSemValue()}`,
      });
      setId("");
      setDataGetByIdResultsContrato(undefined);
    }
  }, [openModalCancel]);

  const { handleUpdateStatusContratos, loading: loadingCancelModal } =
    UseFormCancelContratos({
      cod_status: stateContrato,
      valueDescription: valuesInputCancelarContrato.description || "",
      setOpenModalCancel,
      date: valuesInputCancelarContrato.date || "",
    });

  const nameStatus = stateContrato === "2" ? "CANCELAR" : "APROVAR";

  const parcelaPaga = dataGetByIdResultsContrato?.resultParcelas?.some(
    (parcela) => parcela.is_parcela_recebida === true
  );

  return (
    <ModalCore
      loading={loadingGetByIdResult}
      loadingSubmit={loadingCancelModal}
      open={openModalCancel}
      setOpen={setOpenModalCancel}
      sxStyle={{ width: 500 }}
      title="Alterar status do Contrato"
      onChange={handleUpdateStatusContratos}
    >
      <Box sx={{ color: "#ed6c02", fontWeight: "bold" }}>
        <Box mb={1}>{parcelaPaga && "Existe parcela(s) que já recebeu !"}</Box>

        <Box mb={1.4}> Realmente deseja {nameStatus} contrato ?</Box>
      </Box>

      {nameStatus === "CANCELAR" && <FormCancelContrato />}
    </ModalCore>
  );
};
